import { Divider, Text, Touchable } from '@elements'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { ListItem } from 'react-native-elements'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { openUrl } from '@helpers/client'
import { unmarshalPhoneNumber } from '@helpers/display'
import { GroupedItemType } from '@screens/SignInSheet/helpers'

// Android does not support the SMS link, so we use tel instead
const smsLinkPrefix = Platform.OS === 'android' ? 'tel' : 'sms'

/** Customer details with contact information*/
export function UserDetails({ item }: { item: GroupedItemType }) {
  return (
    <View style={styles.card}>
      <ListItem.Accordion
        content={
          <ListItem.Content>
            <Text type="medium" size={16}>
              {item.user.name.firstName}
            </Text>
          </ListItem.Content>
        }
        noIcon
        isExpanded
      >
        <View style={globalStyles.padding10}>
          <Text size={14}>{item.user.phoneNumber ? unmarshalPhoneNumber(item.user.phoneNumber, true) : ''}</Text>
          <View style={globalStyles.margin10} />
          <View style={styles.rowJustify}>
            {item.user.phoneNumber ? (
              <>
                <Touchable style={styles.actionCard} onPress={() => openUrl(`tel:${item.user.phoneNumber}`)}>
                  <Ionicons size={16} color={Colors.green} name="phone-portrait-sharp" />
                  <Divider />
                  <Text color={Colors.green}>Call</Text>
                </Touchable>
                <Touchable
                  style={styles.actionCard}
                  onPress={() => openUrl(`${smsLinkPrefix}:${item.user.phoneNumber}`)}
                >
                  <MaterialCommunityIcons size={16} color={Colors.green} name="message" />
                  <Divider />
                  <Text color={Colors.green}>Text</Text>
                </Touchable>
              </>
            ) : null}
            <Touchable style={styles.actionCard} onPress={() => openUrl(`mailto:${item.user.email}`)}>
              <Ionicons size={16} color={Colors.green} name="mail" />
              <Divider />
              <Text color={Colors.green}>Email</Text>
            </Touchable>
          </View>
        </View>
      </ListItem.Accordion>
    </View>
  )
}

const styles = StyleSheet.create({
  rowJustify: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  card: {
    borderRadius: 8,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
    overflow: 'hidden',
  },
  actionCard: {
    borderRadius: 8,
    width: 100,
    height: 76,
    backgroundColor: Colors.lightGreen,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
