import { getCacheMinute } from '@/admin/helpers/reports'
import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { isWeb } from '@/constants/Layout'
import { openUrl } from '@helpers/client'

import { Farm } from '@models/Farm'
import { dateTimeInZone } from '@models/Timezone'
import { StackNavigationProp } from '@react-navigation/stack'
import { DateTime } from 'luxon'

/** This function is used to generate correct way to connect to product or templateProduct screen */
export function getProductLink(
  navigation: StackNavigationProp<any>,
  productId: string,
  templateProductId?: string,
  isWholesale?: boolean,
) {
  if (isWeb) {
    const url = templateProductId
      ? `${grownbyWebsiteBaseUrl(isWholesale)}/admin/products/edit-template/${templateProductId}`
      : `${grownbyWebsiteBaseUrl(isWholesale)}/admin/products/edit/${productId}`

    openUrl(url, { target: '_blank' })
  } else {
    if (templateProductId) {
      navigation.navigate('Products', {
        screen: 'EditTemplate',
        params: { templateId: templateProductId },
      })
    } else {
      navigation.navigate('Products', {
        screen: 'EditProduct',
        params: { prodId: productId },
      })
    }
  }
}

/**
 * This function calculates the deadline for when the BGQuery cache table can still be used.
 * It takes into account the most recent cache update time, the cache refresh interval,
 * and a buffer period to ensure the cache is trustworthy before being used.
 */
export const getUseCacheTableDeadline = (timezone: Farm['timezone']) => {
  const now = dateTimeInZone(timezone)
  // BGQuery cache table updates every 30 minutes
  const cacheInterval = 30
  // 5 minutes buffer to ensure the cache is updated before we trust it
  const buffer = 5

  let useCacheTableDeadline: DateTime

  const cacheTime = getCacheMinute(now, true)

  if (now.diff(cacheTime, 'minutes').minutes < buffer) {
    useCacheTableDeadline = cacheTime.minus({ minutes: cacheInterval + buffer })
  } else {
    useCacheTableDeadline = cacheTime.minus({ minutes: buffer })
  }

  return useCacheTableDeadline
}
