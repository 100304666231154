import { CSA } from './CSA'
import { Location } from './Location'
import { Product } from './Product'

export type ProductFilters = {
  /** A product category to apply as filter */
  categoryId?: Product['category']
  /** A location id to use as filter */
  locationId?: Location['id']
  /** A csa id to use as filter */
  csaId?: CSA['id']
  /** A product type to use as filter. This is intended to receive a ProductType enum string */
  type?: string

  /** Input value to search for */
  searchTerm?: string

  /** Whether to show only ebt-eligible products. False will be treated as undefined */
  ebtOnly?: true

  /** A farm certification to apply as a filter*/
  practices?: string

  /** Farm name to filter by*/
  farm?: string
}

export const emptyProductFilters: ProductFilters = {
  categoryId: undefined,
  locationId: undefined,
  csaId: undefined,
  type: undefined,
  searchTerm: undefined,
  ebtOnly: undefined,
}
