import { useCartService } from '@/hooks/useCart'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { useDeviceSize } from '@/hooks/useLayout'
import { userSelector, wholesaleSelector } from '@/redux/selectors'
import { isWholesaleBuyer } from '@helpers/wholesale'
import { getFarmCartItems } from '@models/Cart'
import { useSelector } from 'react-redux'

/**
 * Hook that returns when specific components should show or not inside the `Header` component, based on the current screen, cart status and device size
 */
export function useVisibleComponents() {
  const { isExtraLargeDevice, isLargeDevice, isSmallDevice } = useDeviceSize()

  const user = useSelector(userSelector)
  const { cart } = useCartService()
  const { isWholesale } = useSelector(wholesaleSelector)

  /** This screen name variable should not be obtained from the global "routeName" redux state, because the goal is to identify the route of the current instance of the Header component, meanwhile the navRoute refers to the global currently focused route. Those are two different things. */
  const screenName = useComponentRoute()?.name

  const isExplore = screenName === 'ExploreScreen'
  const isCheckout = screenName === 'Checkout'
  const isSearchScreen = screenName === 'SearchScreen'
  const isShareCustomization = screenName === 'ShareCustomization'
  const hasCartItems = getFarmCartItems({ items: cart, isWholesale }).length > 0

  const exploreBtn = !isExplore && !isCheckout && !isShareCustomization && isExtraLargeDevice
  const consumerSearch = shouldShowConsumerSearch(isSmallDevice, screenName)

  const adminBtn = isLargeDevice && !hasCartItems && !isWholesaleBuyer(user)
  const checkoutBtn = !isCheckout && !isShareCustomization && hasCartItems && isLargeDevice
  const shoppingCartBtn = !isCheckout && !isShareCustomization && !checkoutBtn
  const searchScreenInput = isLargeDevice && isSearchScreen

  if (screenName === 'SearchScreen') {
    return {
      exploreBtn: false,
      consumerSearch: false,
      adminBtn: false,
      checkoutBtn: false,
      shoppingCartBtn: true,
      searchScreenInput: isLargeDevice,
    }
  }

  return {
    exploreBtn,
    consumerSearch,
    adminBtn,
    checkoutBtn,
    shoppingCartBtn,
    searchScreenInput,
  }
}

const shouldShowConsumerSearch = (isSmallDevice: boolean, screenName?: string) => {
  if (!screenName || isSmallDevice) return false

  return ![
    'HomeScreen',
    'ProductDetails',
    'FarmDetailScreen',
    'FarmShop',
    'FarmerOnboardScreen',
    'SearchScreen',
    'ShareCustomization',
    'MyCart',
    'Checkout',
  ].includes(screenName)
}
