import { canDistributionBeDeleted } from '@api/Distributions'
import { distrosCollection } from '@api/framework/ClientCollections'
import { MessageWithIcon, ToolTips } from '@components'
import {
  CheckBox,
  DateTimePickerForm,
  ErrorText,
  FormColorPicker,
  FormInput,
  FormPickerInput,
  LoadingView,
  Text,
  TextH3,
  Tooltip,
} from '@elements'
import { retry } from '@helpers/helpers'
import { Distribution } from '@models/Distribution'
import { isShipping } from '@models/Location'
import { Frequency, isSeasonalSchedule } from '@models/Schedule'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikProps } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { globalStyles } from '../../../../constants/Styles'
import DistributionCalendar from '../DistributionCalendar'
import { EditHeader } from '../components/EditHeader'
import { RadioButton } from '../components/RadioButton'
import { WeekdaySelector } from '../components/WeekdaySelector'

import { AdminView } from '@/admin/components/AdminView'
import InputLabel from '@/admin/components/InputLabel'
import { AdminDrawerParamList, DistributionSchedulesParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { useSnapshot } from '@/hooks/useApiFx'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { setAdminLocations } from '@/redux/actions/adminPersist'
import { setAdminNav } from '@/redux/actions/adminState'
import { adminFarmIdSelector, adminFarmSelector, adminLocsSelector, adminParamsSelector } from '@/redux/selectors'
import { snapshotLocationsByFarm } from '@api/Locations'
import { AccessRight, Permission } from '@helpers/Permission'
import { formatCatalog } from '@helpers/wholesale'
import { DefaultCatalog } from '@models/Product'
import {
  FormType,
  addException,
  createDummyDistro,
  frequencyOptions,
  getInitialAddValues,
  getInitialEditValues,
  validateLocationChange,
  validationSchema,
} from './DistributionDetailScreen.helpers'
import { ScheduleSideInfo } from './components/ScheduleSideInfo'
import { useSubmitDistribtion } from './hooks/useSubmitDistribution'

function DistributionDetailScreenComp() {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<DistributionSchedulesParamList, 'AddDistribution'>>()
  const { params } = useRoute<RouteProp<DistributionSchedulesParamList, 'AddDistribution' | 'EditDistribution'>>()
  const { distribution } = useSelector(adminParamsSelector)
  const farm = useSelector(adminFarmSelector)
  const farmId = useSelector(adminFarmIdSelector)
  const locations = useSelector(adminLocsSelector)
  const [error, setError] = useState<string>()
  const [showEndDatePicker, setShowEndDatePicker] = useState(true) //whether to show the end date selector
  const [canDelete, setCanDelete] = useState(false)
  const [closed, setClosed] = useState<Distribution['closed']>(false)

  const isEdit = !!params?.id

  // /** Syncs the farm distros into admin redux */
  const { loading } = useSnapshot(snapshotLocationsByFarm, [farmId!], !!farmId, {
    onStateChange: ({ data }) => dispatch(setAdminLocations(data ?? [])),
  })

  // Don't show hours when editing/adding a shipping distro
  const shouldShowHours = useCallback(
    (locationId: string) => {
      if (distribution) {
        return !isShipping(distribution.location)
      }
      const location = locations.find((loc) => loc.id === locationId)
      if (!location) {
        return true
      }
      return !isShipping(location)
    },
    [distribution, locations],
  )

  /** Loads the distro on edit, and manages loading state.
   * - This fx should be a "focus" effect because if the screen is out of focus and the farm id changes, we don't want to send the request.
   */
  useCancelableFocusFx(
    async (isCurrent) => {
      if (!farm?.id || farmId !== farm.id) return

      // If edit...
      if (isEdit) {
        let distroToEdit: Distribution
        try {
          distroToEdit = await retry(() => distrosCollection.fetch(params.id))
          if (!isCurrent) return
          if (!distroToEdit) throw new Error('Could not load the schedule')
          const canDelete = await canDistributionBeDeleted(farm.id, params.id)
          if (!isCurrent) return
          setCanDelete(canDelete)
          setShowEndDatePicker(isSeasonalSchedule(distroToEdit.schedule))
          setClosed(distroToEdit.closed)
          dispatch(setAdminNav({ distribution: distroToEdit }))
        } catch (err) {
          Logger.error(err)
          setError(
            'This distribution could not be loaded, please click the "X" in the upper right corner and go back and select a distribution from the list.',
          )
        }
      } else {
        dispatch(setAdminNav({ distribution: undefined }))
      }
    },
    [params?.id, isEdit, farm?.id, farmId, dispatch],
  )

  const { handleSubmitDistribution, submitting } = useSubmitDistribtion({
    locations,
    farm,
    isEdit,
    closed,
    distribution,
  })

  const initialValues = useMemo(() => {
    if (distribution) {
      return getInitialEditValues(distribution)
    }

    return getInitialAddValues(farm)
  }, [distribution, farm])

  const CustomHeader = useCallback(
    ({ handleSubmit }: { handleSubmit: () => void }) => {
      return (
        <EditHeader
          isLoading={error ? false : Object.values(loading).some((v) => v) || submitting}
          goBack={() => {
            if (params?.goBack === 'AddProduct') {
              navigation.setParams({ goBack: undefined })
              ;(navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
                screen: 'AddProduct',
              })
            } else {
              navigation.navigate('Schedules')
            }
          }}
          title={isEdit && !!distribution?.name ? `Edit Schedule - ${distribution.name}` : 'Add Schedule'}
          actionTitle={error ? 'Back' : 'Save'}
          onPress={() => {
            if (error) {
              if (params?.goBack === 'AddProduct') {
                navigation.setParams({ goBack: undefined })
                ;(navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
                  screen: 'AddProduct',
                })
              } else {
                navigation.navigate('Schedules')
              }
            } else {
              handleSubmit()
            }
          }}
        />
      )
    },
    [distribution?.name, error, isEdit, loading, navigation, params?.goBack, submitting],
  )

  return (
    <LoadingView
      loading={loading}
      switchMode //switchMode necessary for correct scroll from AdminView as container
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitDistribution}
        validationSchema={validationSchema}
        validateOnChange={false}
        enableReinitialize
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }: FormikProps<FormType>) => (
          <AdminView
            style={styles.addScheduleScreenContainer}
            customHeader={<CustomHeader handleSubmit={handleSubmit} />}
          >
            {error ? (
              <MessageWithIcon icon="truck-loading" title="Distribution not found">
                <Text>{error}</Text>
              </MessageWithIcon>
            ) : (
              <View style={styles.distributionSession}>
                {/* Location or Zone selector */}
                <FormPickerInput
                  label="Select a location or zone"
                  placeholder="Select a Location or Zone"
                  items={locations.map((loc) => ({
                    label: loc.name,
                    value: loc.id,
                  }))}
                  value={values.locationId}
                  onValueChange={(newLocId) =>
                    validateLocationChange(locations, newLocId, values.locationId, setFieldValue)
                  }
                  errorMessage={!touched.locationId ? undefined : errors.locationId}
                />

                {/* Catalog selection */}
                {farm.isWholesale ? (
                  <FormPickerInput
                    value={values.defaultCatalog}
                    items={Object.values(DefaultCatalog).map((v) => ({
                      label: formatCatalog(v),
                      value: v,
                    }))}
                    onValueChange={handleChange('defaultCatalog')}
                    label="Catalog"
                    containerStyle={styles.catalog}
                  />
                ) : null}

                {/* Schedule name */}
                <FormInput
                  label={
                    <InputLabel label="Schedule Name" tooltipTitle="Schedule Name" tooltipId={ToolTips.DIST_NAME} />
                  }
                  onChangeText={(text) => {
                    handleChange('name')(text)
                  }}
                  errorMessage={touched.name && !!errors.name ? `* ${errors.name}` : ''}
                  onBlur={handleBlur('name')}
                  value={values.name}
                  rightIcon={
                    touched.name && errors.name
                      ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                      : false
                  }
                />

                <View style={globalStyles.margin10} />
                {/* Schedule Color */}
                <FormColorPicker
                  onChange={(color) => setFieldValue('color', color)}
                  color={values.color}
                  containerStyle={styles.colorPickerTriggerContainer}
                  errorMessage={touched.color && !!errors.color ? `* ${errors.color}` : ''}
                  label="Schedule Color"
                  placeholder="Pick a color for the schedule"
                />
                <TextH3 color={Colors.shades[300]} style={styles.placeholder}>
                  An easy color for admins to identify the schedule. Pick one to check the color of the schedule on the
                  calendar
                </TextH3>

                {/* Notes */}
                <FormInput
                  label="Notes"
                  onChangeText={handleChange('notes')}
                  onBlur={handleBlur('notes')}
                  value={values.notes}
                  renderErrorMessage={!!touched.notes && !!errors.notes}
                  errorMessage={touched.notes ? errors.notes : undefined}
                  rightIcon={
                    touched.notes && errors.notes
                      ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                      : false
                  }
                />
                <TextH3 color={Colors.shades[300]} style={styles.placeholder}>
                  Notes to help people find you (optional)
                </TextH3>

                {/* Order cutoff window */}
                <FormInput
                  label={
                    <InputLabel
                      label="Order Cutoff Window"
                      tooltipTitle="Order Cutoff Window"
                      tooltipId={ToolTips.ORDER_CUTOFF_WINDOW}
                    />
                  }
                  inputMode="numeric"
                  onChangeText={handleChange('orderCutoffWindow')}
                  onBlur={handleBlur('orderCutoffWindow')}
                  renderErrorMessage={!!touched.orderCutoffWindow && !!errors.orderCutoffWindow}
                  errorMessage={
                    touched.orderCutoffWindow && !!errors.orderCutoffWindow ? `* ${errors.orderCutoffWindow}` : ''
                  }
                  value={values.orderCutoffWindow.toString()}
                  rightIcon={
                    touched.orderCutoffWindow && errors.orderCutoffWindow
                      ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                      : false
                  }
                />
                <TextH3 color={Colors.shades[300]} style={styles.placeholder}>
                  Minimum is 0 (until end of pickup)
                </TextH3>

                {/* Schedule Type */}
                <View style={styles.scheduleTypeContainer}>
                  <Text size={14} type="bold" style={styles.scheduleTypeLabel}>
                    Schedule type
                  </Text>
                  <View style={styles.radioButtonGroups}>
                    <RadioButton
                      value="Seasonal"
                      isSelected={values.scheduleType === 'Seasonal'}
                      disabled={isEdit}
                      onSelect={() => {
                        setFieldValue('scheduleType', 'Seasonal')
                        setShowEndDatePicker(true)
                      }}
                    />
                    <RadioButton
                      value="Year-round"
                      isSelected={values.scheduleType === 'Year Round'}
                      disabled={isEdit}
                      onSelect={() => {
                        setFieldValue('scheduleType', 'Year Round')
                        setShowEndDatePicker(false)
                      }}
                    />
                  </View>
                  {isEdit && (
                    <ErrorText style={styles.scheduleTypeError}>
                      * You cannot change the type of a schedule after it has been created. You must create a new
                      schedule to make changes to the type.
                    </ErrorText>
                  )}
                </View>
                <View style={globalStyles.margin10} />

                {/* Date Range */}
                <View style={styles.dateSelectorCont}>
                  <View>
                    <View style={styles.startDateLabelCont}>
                      <Text size={14} type="bold" style={styles.datePickerLabel}>
                        Start Date
                      </Text>
                      <Tooltip title="Distribution Schedule" id={ToolTips.DISTRIB_SCHEDULE} />
                    </View>
                    <DateTimePickerForm
                      containerStyle={styles.dateTimePickerContainer}
                      onChange={(item) => setFieldValue('startDate', item)}
                      value={values.startDate ? values.startDate : undefined}
                      timezone={farm.timezone}
                      maxDate={values.endDate}
                    />
                    {errors.startDate && touched.startDate && <ErrorText>{errors.startDate as string}</ErrorText>}
                  </View>

                  {showEndDatePicker && (
                    <View>
                      <Text type="bold" size={14} style={styles.datePickerLabel}>
                        End Date
                      </Text>
                      <DateTimePickerForm
                        containerStyle={styles.dateTimePickerContainer}
                        onChange={(item) => setFieldValue('endDate', item)}
                        value={values.endDate ? values.endDate : undefined}
                        timezone={farm.timezone}
                        minDate={values.startDate}
                      />
                      {!!errors.endDate && touched.endDate && <ErrorText>{errors.endDate}</ErrorText>}
                    </View>
                  )}
                </View>
                <View style={globalStyles.margin10} />

                {/* Time */}
                {shouldShowHours(values.locationId) && (
                  <View style={styles.dateSelectorCont}>
                    <View>
                      <Text size={14} type="bold" style={styles.datePickerLabel}>
                        Start Time
                      </Text>
                      <DateTimePickerForm
                        containerStyle={styles.dateTimePickerContainer}
                        mode="time"
                        onChange={(item) => setFieldValue('startTime', item)}
                        timezone={farm.timezone}
                        value={values.startTime}
                      />
                      {errors.startTime && touched.startTime && <ErrorText>{errors.startTime as string}</ErrorText>}
                    </View>
                    <View>
                      <Text type="bold" size={14} style={styles.datePickerLabel}>
                        End Time
                      </Text>
                      <DateTimePickerForm
                        containerStyle={styles.dateTimePickerContainer}
                        mode="time"
                        onChange={(item) => setFieldValue('endTime', item)}
                        timezone={farm.timezone}
                        value={values.endTime}
                      />
                      {errors.endTime && touched.endTime ? <ErrorText>{errors.endTime as string}</ErrorText> : null}
                    </View>
                  </View>
                )}

                <View style={globalStyles.margin10} />
                {/* Frequency */}
                <FormPickerInput
                  disabled={isEdit && !canDelete}
                  placeholder="Select frequency"
                  label={
                    <Text size={14} type="bold">
                      Frequency
                    </Text>
                  }
                  items={frequencyOptions}
                  value={values.frequency}
                  onValueChange={(item) => {
                    setFieldValue('frequency', item)
                    setFieldValue('exceptions', distribution?.schedule.exceptions || [])
                  }}
                  errorMessage={!touched.frequency ? undefined : errors.frequency}
                />
                {errors.frequency && touched.frequency ? (
                  <ErrorText style={styles.frequencyError}>* {errors.frequency}</ErrorText>
                ) : null}
                {isEdit && !canDelete && (
                  <ErrorText style={styles.frequencyNotAllowed}>
                    * You cannot change the frequency of a schedule after it has products or pickups associated with it.
                  </ErrorText>
                )}

                <View style={globalStyles.margin20} />

                <View style={globalStyles.flexRowCenter}>
                  <Text size={14} type="bold" style={styles.marginLeft}>
                    Closed for new orders
                  </Text>
                  <Tooltip id={ToolTips.CLOSED_FOR_NEW_ORDERS} />
                </View>
                <Text style={globalStyles.margin10}>
                  You can close this schedule temporarily to prevent customers from buying from it.
                </Text>
                <CheckBox
                  checked={closed ?? false}
                  onChange={(v) => setClosed(v)}
                  title="Close Schedule"
                  style={globalStyles.margin10}
                />

                <View style={globalStyles.margin20} />

                <View style={globalStyles.flexColumn}>
                  <View style={globalStyles.flexRowCenter}>
                    <Text size={14} type="bold" style={styles.marginLeft}>
                      Exception Days{' '}
                    </Text>
                    <Tooltip id={ToolTips.EXCEPTION_DAYS} />
                  </View>
                  {errors.exceptions ? (
                    <ErrorText style={styles.exceptionsError}>
                      {Array.isArray(errors.exceptions) ? errors.exceptions.join(', ') : errors.exceptions}
                    </ErrorText>
                  ) : null}
                </View>
                {values.frequency === Frequency.DAILY && (
                  <WeekdaySelector setFieldValue={setFieldValue} exceptions={values.exceptions} />
                )}
              </View>
            )}

            <View style={styles.exceptionsCalendarContainer}>
              <DistributionCalendar
                distro={createDummyDistro(values, farm)}
                onExceptionAdd={(e) => addException(setFieldValue, values.exceptions, e)}
                exceptions={values.exceptions}
              />
              <ScheduleSideInfo exceptions={values.exceptions} setFieldValue={setFieldValue} />
            </View>
          </AdminView>
        )}
      </Formik>
    </LoadingView>
  )
}

export const DistributionDetailScreen = withAdminAuth(
  DistributionDetailScreenComp,
  Permission.ProductSetup,
  AccessRight.Edit,
)

const styles = StyleSheet.create({
  addScheduleScreenContainer: {
    padding: 5,
  },
  distributionSession: {
    marginTop: 5,
    maxWidth: 800,
  },

  radioButtonGroups: {
    flexDirection: 'row',
    marginHorizontal: 10,
    width: 250,
    justifyContent: 'space-between',
  },

  placeholder: {
    marginTop: 2,
    margin: 10,
  },
  dateSelectorCont: {
    alignSelf: 'flex-start',
    gap: 20,
    marginLeft: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  colorPickerTriggerContainer: {
    marginLeft: 10,
    marginBottom: 0,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 2,
  },

  scheduleTypeContainer: { flexDirection: 'column', marginTop: 20 },
  scheduleTypeLabel: {
    margin: 10,
    color: Colors.black,
  },
  scheduleTypeError: { marginTop: 5, marginLeft: 15 },
  dateRangeContainer: { flexDirection: 'row', marginTop: 20 },
  dateTimePickerContainer: {
    width: 120,
  },
  datePickerLabel: {
    marginBottom: 2,
  },

  frequencyError: { marginTop: -5, marginLeft: 14 },
  frequencyNotAllowed: { marginTop: 5, marginLeft: 14 },
  marginLeft: {
    marginLeft: 10,
  },
  catalog: {
    marginTop: 10,
  },
  exceptionsError: { marginLeft: 10 },
  exceptionsCalendarContainer: {
    marginTop: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  startDateLabelCont: {
    gap: 3,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
