import { ShortState, getShortState } from '@/assets/data/states'
import { GoogleApiGeocodingAddressComponent } from '@api/Addresses'
import { bullet } from '@helpers/display'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { ValidateAddressOpts, getAddressErrors } from '@models/Address'
import { UserAddress } from '@models/UserAddress'
import { ErrorWithCode, isErrorWithCode } from '@shared/Errors'

/** A simplified address parsed from google places api. This is not a valid addres. It merely represents the parsing results */
interface ParsingResults {
  street_number?: string
  street_name?: string
  city?: string
  state?: string
  country?: string
  postal_code?: string
}

/** Builds an address from the google places GoogleApiGeocodingAddressComponent type */
export class GoogleAddressParser {
  private address: ParsingResults = {}

  constructor(private address_components: GoogleApiGeocodingAddressComponent[]) {
    if (!Array.isArray(this.address_components)) {
      throw Error('Address Components is not an array')
    }

    if (!this.address_components.length) {
      throw Error('Address Components is empty')
    }

    for (let i = 0; i < this.address_components.length; i++) {
      const component: GoogleApiGeocodingAddressComponent = this.address_components[i]

      if (GoogleAddressParser.isStreetNumber(component)) {
        this.address.street_number = component.long_name
      }

      if (GoogleAddressParser.isStreetName(component)) {
        this.address.street_name = component.long_name
      }

      if (!this.address.city && GoogleAddressParser.isCity(component)) {
        this.address.city = component.long_name
      }

      if (GoogleAddressParser.isCountry(component)) {
        this.address.country = component.long_name
      }

      if (GoogleAddressParser.isState(component)) {
        this.address.state = component.short_name || component.long_name
      }

      if (GoogleAddressParser.isPostalCode(component)) {
        this.address.postal_code = component.long_name
      }
    }
  }

  private static isStreetNumber(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('street_number')
  }

  private static isStreetName(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('route')
  }

  private static isCity(component: GoogleApiGeocodingAddressComponent): boolean {
    return (
      component.types.includes('locality') ||
      component.types.includes('administrative_area_level_3') ||
      component.types.includes('neighborhood')
    )
  }

  private static isState(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('administrative_area_level_1')
  }

  private static isCountry(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('country')
  }

  private static isPostalCode(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('postal_code')
  }

  /** returns a non-validated, simplified parsed address */
  result(): ParsingResults {
    return this.address
  }

  getCityState(): string | null {
    const { city, state } = this.address
    if (!nonEmptyString(city) || !state || !getShortState(state)) {
      return null
    }
    return `${this.address.city}, ${this.address.state}`
  }

  /** parses the google address into a validated address in our app's basic Address model */
  getAddress(validateOpts: ValidateAddressOpts = { allowPO: true }): Omit<UserAddress, 'coordinate' | 'id'> {
    const parsedAddress = this.address

    try {
      const street1 =
        parsedAddress.street_number && parsedAddress.street_name
          ? `${parsedAddress.street_number} ${parsedAddress.street_name}`
          : ''

      const stateCode = getShortState(parsedAddress.state || '') as ShortState // This will be validated below

      const address: Omit<UserAddress, 'coordinate' | 'id'> = {
        street1,
        city: parsedAddress.city || '',
        state: stateCode,
        zipcode: parsedAddress.postal_code || '',
      }

      const addrErrors = getAddressErrors(address, validateOpts)
      if (addrErrors) {
        // If there's validation errors, throw an error message that includes the errors with a formatted string
        const errStr = Object.entries(addrErrors)
          .map(([k, v]) => ` ${bullet} ${k}: ${v}.\n`)
          .join('')

        throw new ErrorWithCode({ code: 'AddressError', devMsg: errStr, data: parsedAddress })
      }
      return address
    } catch (error) {
      if (isErrorWithCode(error, 'AddressError')) {
        // If it's the 'AddressError' code, throw as-is, because it means the error was controlled and should be ready to be displayed
        throw error
      }

      // Otherwise it is an unexpected error, so something went wrong while parsing. Must manage it here
      throw new ErrorWithCode({
        code: 'ParsingError',
        devMsg: errorToString(error),
        uiMsg: 'The address data could not be parsed.',
        data: parsedAddress,
      })
    }
  }
}
