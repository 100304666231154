export enum LabelType {
  ROLL = 'roll',
  SHEET = 'sheet',
  CONTINUOUS_ROLL = 'continuousRoll',
}
export enum LabelSize {
  FOURxSIX = '4"x6"',
  FOURxTWO = '4"x2"',
  TWO_FOURxTHREE = '2.4"x3"',
}

export enum ItemsCount {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
}
