import { openUrl } from '@helpers/client'
import { DateRange, SummaryFilter } from '@models/Schedule'
import { generateReportingUrl } from './call'

/** openDailySummaryCSV opens the daily summary CSV file. */
export function openDailySummaryCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  const reportUrl = generateReportingUrl('dailySummaryCSV', { farmId, ...filter }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** openLocationSummaryCSV opens the location summary CSV file. */
export function openLocationSummaryCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  const reportUrl = generateReportingUrl('locationSummaryCSV', { farmId, ...filter }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** openSignInCSV opens the sign in CSV file. */
export function openSignInCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  const reportUrl = generateReportingUrl('signInCSV', { farmId, ...filter }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** openPackListCSV opens the packList CSV file. */
export function openPackListCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  const reportUrl = generateReportingUrl('packListCSV', { farmId, ...filter }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** openPackListPDF opens the pack list PDF in a browser or new tab when on the web platform. */
export function openSignInPDF(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  const reportUrl = generateReportingUrl('signInPDF', { farmId, ...filter }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** openPackListPDF opens the pack list PDF in a browser or new tab when on the web platform.*/
export function openPackListPDF(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  const reportUrl = generateReportingUrl('packListPDF', { farmId, ...filter }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** The function to generate coupon redemption csv  */
export function openCouponRedemptionCSV(farmId: string, dateRange: DateRange) {
  const reportUrl = generateReportingUrl('couponRedemptionCSV', { farmId }, dateRange)
  openUrl(reportUrl, { target: '_blank' })
}

/** This function format an invoice into PDF format.  */
export function openInvoiceReceiptPDF(invoiceId: string) {
  const reportUrl = generateReportingUrl('invoiceReceiptPDF', { invoiceId }, null)
  openUrl(reportUrl, { target: '_blank' })
}
