import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { farmsSelector } from '../../../redux/selectors'

import { useApiFx } from '@/hooks/useApiFx'
import { HomeParamList } from '@/navigation/types'
import { loadPopularCategories } from '@api/Categories'

export const useHomeScreenWholesaleData = () => {
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const fassocs = useSelector(farmsSelector)

  const categoriesFx = useApiFx(loadPopularCategories, [], undefined, {
    noRefocus: true,
    once: true,
  })

  const favorites = useMemo(() => fassocs.filter((f) => f.isFavorite).map((f) => f.farm!), [fassocs])

  const onFavoritesSeeAllPress = useCallback(
    () => navigation.navigate('Shopping', { screen: 'MyFarmsScreen' }),
    [navigation],
  )

  return {
    favorites,
    onFavoritesSeeAllPress,
    categories: categoriesFx.data,
  }
}

export type HomeScreenWholesaleType = ReturnType<typeof useHomeScreenWholesaleData>
