import { Button, Text, TextH1 } from '@elements'
import { memo } from 'react'
import { View } from 'react-native'

import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { getDraftOrderStatus } from '@helpers/orders-display'
import { DraftOrder } from '@models/DraftOrder'
import Colors from '../../../../constants/Colors'
import { globalStyles } from '../../../../constants/Styles'

type DraftOrderHeaderProps = {
  onPressContactFarm: () => void
  draftOrder: DraftOrder
}

export const DraftOrderHeader = memo(function DraftOrderHeader({
  onPressContactFarm,
  draftOrder,
}: DraftOrderHeaderProps): JSX.Element {
  const styles = useStyles()

  return (
    <View style={styles.headerContainer}>
      <View style={globalStyles.flexRowCenter}>
        <TextH1 size={18}>Order Details</TextH1>

        <Text size={12} style={styles.statusTag} type="bold">
          <Text color={Colors.white}>{getDraftOrderStatus(draftOrder)}</Text>
        </Text>
      </View>
      <View style={styles.buttonGroups}>
        <Button title="Contact Farm" style={styles.button} onPress={onPressContactFarm} outline small />
      </View>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    headerContainer: {
      flexDirection: isExtraSmallDevice ? 'column' : 'row',
      alignItems: isExtraSmallDevice ? 'flex-start' : 'center',
      justifyContent: 'space-between',
      gap: 4,
    },
    buttonGroups: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 8,
    },
    button: {
      paddingHorizontal: 20,
    },
    statusTag: {
      backgroundColor: Colors.red,
      color: Colors.white,
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 100,
      justifyContent: 'flex-start',
      marginLeft: 10,
    },
  }))
