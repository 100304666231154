import { Optional } from '@helpers/typescript'
import { FormikErrors } from 'formik'

import { isNum, isTruthy } from '@helpers/helpers'
import type { GeocodeResult } from 'use-places-autocomplete'
import { getState } from '../assets/data/states'
import { Coordinate } from './Coordinate'

export type Address = {
  coordinate: Coordinate
  street1: string
  street2?: string
  city: string
  state: string
  zipcode: string
  country?: string
}

/** Zip code 5-digit literal string type */
export type ShortZip = `${number}${number}${number}${number}${number}`

/** Will indicate whether an address is valid, without considering its coordinate */
export function isValidAddress(
  address?: Optional<Address, 'coordinate'>,
  opts?: ValidateAddressOpts,
): address is Address {
  return !!address && !getAddressErrors(address, opts)
}

export type ValidateAddressOpts = {
  /** Whether PO box addresses are allowed */
  allowPO?: boolean
}

/** Will validate an address except for its coordinate.
 * - If it returns void, means there were no errors.
 * - This validator does not geocode, so it doesn't validate that the address corresponds to a real place.
 */
export function getAddressErrors(
  address: Optional<Address, 'coordinate'>,
  { allowPO = false }: ValidateAddressOpts = { allowPO: false },
): FormikErrors<Omit<Address, 'coordinate'>> | void {
  const errors: FormikErrors<Omit<Address, 'coordinate'>> = {}

  if (address.street1.trim().length === 0) errors.street1 = 'Street1 is required'
  if (!allowPO && isPO(address)) errors.street1 = 'Cannot use a PO box'

  if (address.city.trim().length === 0) errors.city = 'City is required'
  if (address.state.trim().length < 2) errors.state = 'State must have at least 2 letters'
  else if (!getState(address.state)) errors.state = 'State is not a valid state'
  if (address.zipcode.trim().length !== 5) errors.zipcode = 'Zip code must have 5 characters'
  if (address.zipcode.split('').some((char) => !isNum(char))) errors.zipcode = 'Zip code must have only digits'
  if (Object.values(errors).some(isTruthy)) return errors
}

/** Identifies a po box address */
export const isPO = (address: Pick<Address, 'street1' | 'street2'>): boolean => {
  /** https://stackoverflow.com/a/25271289 */
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|num|number)? *\d+|(#|num|number) *\d+)/i,
  )

  return pattern.test(address.street1) || (!!address.street2 && pattern.test(address.street2))
}
/** This custom type is a simplified universal representation of a google places result item, which may be found while searching for anything in the google places api (cities, zipcodes, addresses, establishments, etc) */
export type GooglePlace = {
  coordinate: Coordinate
  address_components?: GeocodeResult['address_components']
  /** The name of the google place. It will be the city name when searching for cities, or the establishment name if searching for establishments, or it may be the address when searching for addresses, etc. */
  name?: string
}
