import { MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { useDeviceSize } from '@/hooks/useLayout'
import { HomeParamList } from '@/navigation/types'
import { sessionLocationSelector } from '@/redux/selectors'
import { Text } from '@elements'
import { getCoordString } from '@helpers/coordinate'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { getCategoryRefinementGroup } from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { memo, useMemo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

type CategoryListProps = {
  items: MenuItem[]
}

export const CategoryList = memo(function CategoryList({ items }: CategoryListProps) {
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const { isExtraSmallDevice, isLargeDevice } = useDeviceSize()
  const sessionLoc = useSelector(sessionLocationSelector)

  const buttons = useMemo(() => {
    const categories = getCategoryRefinementGroup(items)?.items
    if (!categories) return undefined

    const values = categories.slice(0, 5).map((el) => ({
      label: el.label,
      onPress: () =>
        navigation.navigate('SearchScreen', {
          categoryId: el.shouldClear ? undefined : el.value,
          locType: 'zip',
          isGlobal: true,
        }),
    }))

    const coordString = getCoordString(sessionLoc?.coordinate)
    if (coordString) {
      values.concat({
        label: 'Shop nearby farms',
        onPress: () => navigation.navigate('SearchScreen', { locType: 'coord', center: coordString }),
      })
    }

    return values
  }, [items, navigation, sessionLoc?.coordinate])

  if (!buttons) return null

  return (
    <View style={styles.main}>
      {buttons.map((el) => (
        <Pressable key={el.label} onPress={el.onPress}>
          <Text size={isExtraSmallDevice ? 12 : isLargeDevice ? 18 : 14}>{el.label}</Text>
        </Pressable>
      ))}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    gap: 20,
    marginVertical: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
})
