import Colors from '@/constants/Colors'
import { Spinner, Text } from '@elements'
import { StyleSheet, View } from 'react-native'

/** Header component used for conversation view*/
export function ConversationViewHeader({ isLoadingMore }: { isLoadingMore: boolean }) {
  return (
    <View style={styles.main}>
      {isLoadingMore && (
        <>
          <Spinner style={styles.spinner} size="small" />
          <Text color={Colors.shades['300']}>Loading older messages...</Text>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    gap: 10,
    height: 35,
  },
  spinner: {
    flex: undefined, // Override flex:1
  },
})
