import { registerWithEmailAndPassword } from '@api/Sessions'
import { loadUserByEmail } from '@api/Users'
import { Alert, FormButton, Modal, Text } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { errorToString } from '@helpers/helpers'
import { TermsAndConditions } from '@screens/UserScreen/TermsAndConditions'
import { Formik, FormikProps } from 'formik'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { globalStyles } from '../../../constants/Styles'
import { providerAlert } from '../LoginHelper'

type FormType = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

const registerValidationSchema = Yup.object<FormType>().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string().label('Email').email('Enter a valid email').required('Please enter a valid email'),
  password: Yup.string()
    .label('Password')
    .required()
    .matches(
      /^(?=.*([A-Z])+)(?=.*[0-9]+)(?=.*[a-z]+).{8,}$/,
      'Must Contain at least 8 Characters, One Uppercase, One Lowercase, and One Number',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Please confirm your password'),
})

type Props = {
  email: string
  setEmail(email: string): void
}

/** Form UI for registering a new account with email */
export function EmailRegister({ email, setEmail }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [isPassHidden, setIsPassHidden] = useState(true)
  const [isPassConfirmHidden, setIsPassConfirmHidden] = useState(true)
  const formikRef = useRef<FormikProps<FormType> | null>(null)

  // When the email prop changes it should set it to the form state via the helpers in the formik ref
  useEffect(() => {
    if (formikRef.current && email) {
      formikRef.current.setFieldValue('email', email)
    }
  }, [email])

  const onSubmitRegister = useCallback(async (values: FormType) => {
    const { email, password, firstName, lastName } = values

    setIsLoading(true)
    Keyboard.dismiss()

    // Try registering
    await registerWithEmailAndPassword(email, password, firstName, lastName)
      .catch(async (e) => {
        // If it fails check if the email was used with a different provider
        await loadUserByEmail(values.email)
          .then((user) => {
            providerAlert(user, 'Signup')
          })
          .catch(() => {
            Alert('Register Failed', `There was an error creating your account. (${errorToString(e)})`)
          })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const initialValues: FormType = {
    email: '',
    password: '',
    firstName: '',
    confirmPassword: '',
    lastName: '',
  }

  return (
    <View style={globalStyles.flex1}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={onSubmitRegister}
        validationSchema={registerValidationSchema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }: FormikProps<FormType>) => (
          <>
            <Input
              numberOfLines={1}
              value={values.firstName}
              placeholder="First Name"
              onChangeText={handleChange('firstName')}
              textContentType="givenName"
              autoCapitalize="words"
              onBlur={handleBlur('firstName')}
              leftIcon={{ type: 'font-awesome', name: 'user' }}
              leftIconContainerStyle={styles.leftIconContainer}
              errorMessage={touched.firstName ? errors.firstName : ''}
            />
            <Input
              numberOfLines={1}
              value={values.lastName}
              placeholder="Last Name"
              onChangeText={handleChange('lastName')}
              textContentType="familyName"
              autoCapitalize="words"
              onBlur={handleBlur('lastName')}
              leftIcon={{ type: 'font-awesome', name: 'user' }}
              leftIconContainerStyle={styles.leftIconContainer}
              errorMessage={touched.lastName ? errors.lastName : ''}
            />
            <Input
              numberOfLines={1}
              placeholder="Email"
              onChangeText={(e) => {
                setEmail(e)
                setFieldValue('email', e)
              }}
              value={values.email}
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
              onBlur={handleBlur('email')}
              leftIcon={{ type: 'font-awesome', name: 'envelope' }}
              leftIconContainerStyle={styles.leftIconContainer}
              errorMessage={touched.email ? errors.email : ''}
            />

            <Input
              numberOfLines={1}
              value={values.password}
              secureTextEntry={isPassHidden}
              placeholder="Password"
              onChangeText={handleChange('password')}
              autoCapitalize="none"
              autoComplete="password"
              onBlur={handleBlur('password')}
              leftIcon={{ type: 'font-awesome', name: 'lock' }}
              leftIconContainerStyle={styles.leftIconContainer}
              rightIcon={
                <FontAwesome5
                  name={isPassHidden ? 'eye' : 'eye-slash'}
                  size={16}
                  onPress={() => setIsPassHidden(!isPassHidden)}
                />
              }
              errorMessage={touched.password ? errors.password : ''}
            />

            <Input
              numberOfLines={1}
              value={values.confirmPassword}
              secureTextEntry={isPassConfirmHidden}
              placeholder="Confirm Password"
              onChangeText={handleChange('confirmPassword')}
              autoCapitalize="none"
              autoComplete="password"
              onBlur={handleBlur('confirmPassword')}
              leftIcon={{ type: 'font-awesome', name: 'lock' }}
              leftIconContainerStyle={styles.leftIconContainer}
              onSubmitEditing={() => handleSubmit()}
              returnKeyType="done"
              rightIcon={
                isPassConfirmHidden ? (
                  <FontAwesome5
                    name="eye"
                    size={16}
                    onPress={() => {
                      setIsPassConfirmHidden(!isPassConfirmHidden)
                    }}
                  />
                ) : (
                  <FontAwesome5
                    name="eye-slash"
                    size={16}
                    onPress={() => {
                      setIsPassConfirmHidden(!isPassConfirmHidden)
                    }}
                  />
                )
              }
              errorMessage={touched.confirmPassword ? errors.confirmPassword : ''}
            />
            <Text style={styles.marginHorizontal}>
              By signing up, you agree to GrownBy's{' '}
              <Text
                size={13}
                type="bold"
                onPress={() => Modal(<TermsAndConditions />, { title: 'Terms and Conditions' })}
              >
                terms and conditions.
              </Text>
            </Text>
            <FormButton title="Register" loading={isLoading} onPress={handleSubmit} style={styles.margin} />
          </>
        )}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({
  leftIconContainer: {
    marginRight: 8,
    opacity: 0.75,
  },
  margin: {
    margin: 8,
    marginVertical: 16,
  },
  marginHorizontal: { marginHorizontal: 10 },
})
