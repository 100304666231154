import { Standard } from '@models/Product'
import { Image, prodWidthSM, ResizedSuffix } from '@components'
import { View, StyleSheet } from 'react-native'
import { Text } from '@elements'
import { memo, useMemo } from 'react'
import { findPriceForAppMode, getUnits } from '@helpers/products'
import { formatMoney } from '@helpers/display'
import Colors from '../../../constants/Colors'
import { SHADOW_5 } from '../../../constants/Styles'

export const ShareBoxProdCard = memo(function ShareBoxProdCard({
  product,
  quantity,
}: {
  product: Standard
  quantity: number
}) {
  const prodPriceStr = useMemo(() => {
    const unit = getUnits(product, { isWholesale: false })[0]
    const price = findPriceForAppMode(unit.prices, false)?.amount
    return price ? `${formatMoney(price)}/${unit.name || 'unit'}` : 'N/A'
  }, [product])

  return (
    <View style={styles.container}>
      <View>
        <Image resizeSuffix={ResizedSuffix.THUMB} style={styles.image} source={{ uri: product?.images?.[0] }} />
        <View style={styles.quantityBadge}>
          <Text type="medium">{quantity}x</Text>
        </View>
      </View>
      <Text numberOfLines={2} style={styles.productName}>
        {product.name}
      </Text>
      <Text numberOfLines={2} size={10}>
        {prodPriceStr}
      </Text>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    width: prodWidthSM,
  },
  image: {
    width: prodWidthSM,
    aspectRatio: 1,
    resizeMode: 'cover',
    borderRadius: 10,
  },
  quantityBadge: {
    position: 'absolute',
    right: 2,
    bottom: 2,
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: 35,
    height: 35,
    borderRadius: 10,
    ...SHADOW_5,
  },
  productName: {
    marginTop: 10,
  },
})
