import { FILTERS, FarmStatusFilter, GeoDocTypeFilter } from '@models/Algolia'

/** Type for the FacetFilter that controls the map results */
export type MapFilters = [
  GeoDocTypeFilter[],
  FarmStatusFilter[],
  FILTERS.Ebt[],
  FILTERS.NotHidden,
  FILTERS.NotInactiveFarm,
  FILTERS.NotPrivateProd,
  FILTERS.Wholesale | FILTERS.Retail,
]

/** These are the filters for the map search. Their value will change later, as user interacts with map filters */
export function getInitialMapFilters(isWholesale: boolean): MapFilters {
  return [
    [FILTERS.Farm, FILTERS.Product, FILTERS.Distro],
    [FILTERS.Registered],
    [],
    FILTERS.NotHidden,
    FILTERS.NotInactiveFarm,
    FILTERS.NotPrivateProd,
    isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
  ]
}
