import { CoordString, getCoordString } from '@helpers/coordinate'
import { RouteProp, useRoute } from '@react-navigation/native'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { Configure, connectSearchBox } from 'react-instantsearch-native'
import { useSelector } from 'react-redux'

import { useCoords } from '@/hooks/useCoords/useCoords'
import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { mapFiltersSelector, searchLocationSelector } from '@/redux/selectors'

const HITS_PER_PAGE_MAP = 150

/** Map-specific virtual component that applies the MapFilters, coords, and radius to algolia Configure and performs searchbox refinement */
export const SearchConfigure = connectSearchBox(({ refine }: SearchBoxProvided) => {
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const searchLoc = useSelector(searchLocationSelector)
  const filters = useSelector(mapFiltersSelector)
  const coords = useCoords(params?.near as CoordString)

  //Applies the search refinement for farms or products based on the nav 'q' param
  useFocusFx(() => {
    refine(params?.q)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.q])

  return (
    <Configure
      aroundLatLng={getCoordString(coords)}
      aroundRadius={searchLoc?.radiusFromRegion}
      facetFilters={filters}
      hitsPerPage={HITS_PER_PAGE_MAP}
    />
  )
})
