import { AdminCard } from '@/admin/components/AdminCard'
import { closeOrderingWindow, openOrderingWindow, snapshotCustomShare } from '@api/CustomShares'
import { InfoRow } from '@components'
import { Alert, Button, Divider, Loader, LoadingView, Text, Toast } from '@elements'
import { formatPickupDate } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { formatDateRange, isBefore } from '@helpers/time'
import { CustomShare, CustomShareRun, CustomShareRunTypes } from '@models/CustomShare'
import { isErrorWithCode } from '@shared/Errors'
import React, { useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { AdminView } from '../../../admin/components/AdminView'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { useSnapshot } from '../../../hooks/useApiFx'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { PrimarySharesCard, RunCard } from './components'
import { formatRunTime, formatShareRunType, getNextShareRunTime } from '@helpers/custom_shares/display'
import { AccessRight, Permission } from '@helpers/Permission'
import { customSharesCollection } from '@api/framework/ClientCollections'
import { RouteProp, useRoute } from '@react-navigation/native'
import { AdminProductsParamList } from '../../../admin/navigation/types'
import { getNextShareCloseDateTime, getNextShareOpenDateTime } from '@helpers/custom_shares/share_window'
import { ProductRankingCard } from './components/ProductRankingCard'
import { ArrElement } from '@helpers/typescript'
import { arrayRemove, arrayUnion } from 'firebase/firestore'

//TODO: This is not used now but will be when this becomes an add and edit page
enum PageType {
  ADD = 'AddCustomShare',
  EDIT = 'EditCustomShare',
  VIEW = 'ViewCustomShare',
}

/** UI to render the farms custom share configuration. For right now we only support a single custom share at a time. */
function AdminCustomSharesComponent() {
  const { params, name: routeName } =
    useRoute<RouteProp<AdminProductsParamList, 'AddCustomShare' | 'EditCustomShare' | 'ViewCustomShare'>>()
  const customShareId = params?.shareId
  const shareApi = useSnapshot(snapshotCustomShare, [customShareId!], !!customShareId)
  const [isRunning, setIsRunning] = React.useState(false)
  const [testRuns, setTestRuns] = React.useState<CustomShareRun[]>([])

  const hasActiveRun = useMemo(() => shareApi.data?.runs.some((run) => run.status === 'running'), [shareApi.data])
  const shareRuns = useMemo(
    () => shareApi.data?.runs.sort((a, b) => b.date.toMillis() - a.date.toMillis()),
    [shareApi.data?.runs],
  )

  const toggleOrderingWindow = useCallback(
    async (share: CustomShare) => {
      setIsRunning(true)
      try {
        if (share.isOrderingOpen) {
          await closeOrderingWindow(share, false)
        } else {
          const nextOpenDate = getNextShareOpenDateTime(share)
          const nextCloseDate = getNextShareCloseDateTime(share)
          if (isBefore(nextOpenDate, nextCloseDate)) {
            // We are after the last close date and before the order window is starting so we should not allow opening the
            // ordering window. This is because the pickup window can't support this case, and it will be non-existent once
            // this is automated. We can still run pre-flight. The problem occurs when the order window is opened early and
            // someone tries to customize it will pick up the last window instead of current one.
            return Alert(
              "Can't open share window yet.",
              'It is not possible to open the ordering window early, please wait until the ordering window to allow orders.',
            )
          }
          await openOrderingWindow(share, false)
        }
        Toast('Run finished, see below for results')
      } catch (err) {
        const title = `Failed to ${share.isOrderingOpen ? 'close' : 'open'} ordering window`
        if (isErrorWithCode(err)) {
          Alert(title, errorToString(err))
        } else {
          Logger.error(err)
          Alert(title, 'See run details for more information or contact support.')
        }
      } finally {
        setIsRunning(false)
      }
    },
    [setIsRunning],
  )

  const testShareOrdering = useCallback(
    async (share: CustomShare) => {
      setIsRunning(true)
      try {
        if (share.isOrderingOpen) {
          const response = await closeOrderingWindow(share, true)
          setTestRuns((prev) => [...prev, response])
        } else {
          const response = await openOrderingWindow(share, true)
          setTestRuns((prev) => [...prev, response])
        }
        Toast('Run finished, see below for results')
      } catch (err) {
        Alert('Error testing share', errorToString(err))
      } finally {
        setIsRunning(false)
      }
    },
    [setIsRunning],
  )

  const updateRankedProduct = async (prod: ArrElement<CustomShare['rankedProducts']>, type: 'add' | 'remove') => {
    const share = shareApi.data
    if (!share) return
    if (hasActiveRun) {
      return Alert("Can't update products", 'Please wait until the current run has finished before updating products.')
    }

    // Update the products in our data, we target only the exact item for updating so there is no data contention.
    Loader(true)
    try {
      if (type === 'add') {
        await customSharesCollection.update({ id: share.id }, { rankedProducts: arrayUnion(prod) })
        Toast(`You have added ${prod.product.name} to your share rankings.`)
      } else if (type === 'remove') {
        await customSharesCollection.update({ id: share.id }, { rankedProducts: arrayRemove(prod) })
        Toast(`You have removed ${prod.product.name} from your share rankings.`)
      }
    } catch (err: unknown) {
      Logger.error(err)
      Alert('Error', 'There was an error saving this product please check your connection and try again.')
    } finally {
      Loader(false)
    }
  }

  return (
    <AdminView hideFooter style={styles.container}>
      <LoadingView loading={shareApi.loading} error={shareApi.error} success={shareApi.data}>
        {(share) => (
          <>
            <AdminCard>
              <View style={styles.statusContainer}>
                <Text
                  size={18}
                  type="bold"
                  color={share.isOrderingOpen ? Colors.green : Colors.red}
                  style={globalStyles.marginVertical10}
                >
                  Ordering is currently {share.isOrderingOpen ? 'OPEN' : 'CLOSED'}
                </Text>
                <View style={globalStyles.flexRow}>
                  <Button
                    loading={isRunning || hasActiveRun}
                    title={share.isOrderingOpen ? 'Test Closing' : 'Test Opening'}
                    onPress={() => testShareOrdering(share)}
                  />
                  <Button
                    outline
                    loading={isRunning || hasActiveRun}
                    title={share.isOrderingOpen ? 'Close Ordering' : 'Open Ordering'}
                    onPress={() => toggleOrderingWindow(share)}
                  />
                </View>
              </View>
              <Divider clear />
              <View>
                <Text size={16} type="bold" style={globalStyles.marginVertical10}>
                  Schedule Information
                </Text>
                <View>
                  <InfoRow
                    data={{
                      left: 'Season',
                      right: `${formatDateRange(share.season, 'MMM dd, yyyy')}`,
                    }}
                  />
                  <InfoRow
                    data={{
                      left: 'Orders Open',
                      right: formatRunTime(share, CustomShareRunTypes.OpenOrdering),
                    }}
                  />
                  <InfoRow
                    data={{
                      left: 'Orders Close',
                      right: formatRunTime(share, CustomShareRunTypes.CloseOrdering),
                    }}
                  />
                  <InfoRow
                    data={{
                      left: 'Next Scheduled Run',
                      right: `${formatShareRunType(share.nextRun.type)} on ${formatPickupDate(
                        share.nextRun.date,
                      )} at ${getNextShareRunTime(share)}`,
                    }}
                  />
                </View>
              </View>
            </AdminCard>
            <Divider clear />
            <PrimarySharesCard primaryShares={share.primaryShares} />
            <ProductRankingCard share={share} onUpdateRankedProduct={updateRankedProduct} />

            <View style={globalStyles.alignCenter}>
              <Divider clear />
              <Text size={18} type="bold" style={globalStyles.marginVertical10}>
                Run History
              </Text>
              {testRuns?.map((run) => (
                <RunCard key={run.id} run={run} isTestRun />
              ))}
              {shareRuns?.map((run) => (
                <RunCard key={run.id} run={run} />
              ))}
              {share.runs.length === 0 && testRuns.length === 0 && (
                <Text color={Colors.shades[400]}>No runs recorded yet</Text>
              )}
            </View>
          </>
        )}
      </LoadingView>
    </AdminView>
  )
}

export const AdminCustomShareDetailScreen = withAdminAuth(
  AdminCustomSharesComponent,
  Permission.ProductSetup,
  AccessRight.Edit,
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  statusContainer: {
    alignItems: 'center',
  },
})
