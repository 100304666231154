import { logGeneric } from '@api/FBAnalytics'
import { Alert } from '@elements'
import { isCartShare, SplitTenderPayment } from '@models/Order'
import { isEbtPayment } from '@models/PaymentMethod'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Logger } from '../../../config/logger'
import { setNavProps } from '../../../redux/actions/appState'
import { consumerCartInfoSelector, navPropsSelector, userSelector, wholesaleSelector } from '../../../redux/selectors'
import { validateEbtPin } from '../../PaymentMethods/validateEbtPin'

import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { ShoppingStackParamList } from '@/navigation/types'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { cartSubtotal } from '@helpers/order'
import { useCartService } from '../../../hooks/useCart'
import { CheckoutFormType } from './helpers'

type PlaceOrderType = {
  handleAddOrder: (tender: SplitTenderPayment, cartId: string, values: CheckoutFormType) => Promise<void>
  disableConfirmations: () => void
  splitTender?: SplitTenderPayment
}

/** placeOrder will check everything required for order placement is correct, and will run business logic on payment data to handle things like farm credit, ebt, split tender, and also will run cart validation */
export function usePlaceOrder({ handleAddOrder, disableConfirmations, splitTender }: PlaceOrderType) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const {
    params: { cartFarmId },
  } = useRoute<RouteProp<ShoppingStackParamList, 'Checkout'>>()
  const { farm } = useSelector(navPropsSelector)
  const { id: userId } = useSelector(userSelector)
  const dispatch = useDispatch()
  const { cartId } = useSelector(consumerCartInfoSelector)
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'Checkout'>>()
  const validateCartAlert = useValidateCartAlert({ cartServiceType: 'consumer', isWholesale })
  const { cart } = useCartService({
    cartServiceType: 'consumer',
    farmId: cartFarmId,
    isWholesale,
  })

  return useCallback(
    async (values: CheckoutFormType) => {
      logGeneric('placing_order')

      if (!farm) {
        Logger.warn('The farm for this order could not be loaded')
        return Alert('Farm data was not loaded')
      }
      if (!cartFarmId || !cartId) {
        return Alert('Order Error', 'Could not associate a cart with this order')
      }
      // Make sure payments are defined, this should never be false after the payment selector confirms it
      if (!splitTender || splitTender.length === 0) {
        return Alert('Order Error', 'No payment methods specified for this order')
      }

      /** onGoToShopPress will be called if the user cancels checkout on cart validation errors */
      function onGoToShopPress() {
        disableConfirmations()

        dispatch(setNavProps()) // This will clear the farm cache, so as to load the updated product data
        navigation.navigate('FarmShop', { farmSlug: cartFarmId })
      }
      const res = await validateCartAlert({ farmId: cartFarmId, onGoToShopPress })
      /** We will only proceed to adding the order if the user didn't cancel on any alert prompts notifying them of cart changes AND the remaining cart isn't empty */
      if (res.cancelCheckout || res.newCartLength === 0) return

      const orderMin = farm.orderMinimum?.[isWholesale ? 'wholesale' : 'retail']

      // If the cart has an EBT payment then we should not check the cart minimum. Otherwise, we should respect it,
      // NOTE: Right now we aren't allowing people paying with EBT to even get to this screen, however we are leaving this check so that if we loosen the strictness on the cart screen this will allow EBT members to ignore the cutoff
      if (!splitTender?.some((pay) => isEbtPayment(pay.paymentMethod)) && orderMin) {
        const standardItems = cart.filter((itm) => !isCartShare(itm))
        const subtotal = cartSubtotal(standardItems, farm.id, {
          isWholesale: !!isWholesale,
          excludeClosedDistros: true,
        })
        // If we have a non-share prod then the cart must have enough to reach the order minimum
        const hasNonShareProd = standardItems.length > 0
        if (hasNonShareProd && MoneyCalc.isLessThan(subtotal, orderMin)) {
          return Alert(
            'Minimum amount not met',
            `Please add additional products to the cart. This order does not meet the farms order minimum of ${formatMoney(
              orderMin,
            )}, which does not include the purchase of shares.`,
          )
        }
      }

      // If there is an ebt payment than check the pin and request it if necessary
      const ebtPayment = splitTender?.find((pmt) => isEbtPayment(pmt.paymentMethod))
      if (ebtPayment && isEbtPayment(ebtPayment.paymentMethod)) {
        const { pin } = await validateEbtPin(ebtPayment.paymentMethod, userId, true)
        ebtPayment.paymentMethod.pin = pin
      }

      return handleAddOrder(splitTender, cartId, values)
    },
    [
      userId,
      cartFarmId,
      dispatch,
      cartId,
      farm,
      handleAddOrder,
      navigation,
      disableConfirmations,
      splitTender,
      cart,
      validateCartAlert,
      isWholesale,
    ],
  )
}
