import { CachedCompute } from '@helpers/cachedCompute'
import { buildCustomShareBoxes } from '@helpers/custom_shares/algorithm'
import { getCustomSharePickupRange } from '@helpers/custom_shares/share_window'
import { makeHandle } from '@helpers/helpers'
import { isActive } from '@helpers/products'
import { pick } from '@helpers/typescript'
import { CustomShare, CustomShareRun } from '@models/CustomShare'
import { Money } from '@models/Money'
import { isPickupItemSkipped } from '@models/Order'
import { EbtEligibility, GlobalStandard, ProductType, Standard, isGlobalStandard } from '@models/Product'
import { ErrorWithCode } from '@shared/Errors'
import { CustomShareBoxContents, CustomShareBoxContentsExpanded } from '@shared/types/v2/customShares'
import { limit, where } from 'firebase/firestore'
import { unmarshalDateObject } from './encoding/Time'
import { customSharesCollection, pickupsCollection, productsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** Will snapshot all custom shares for a farm. */
export function snapshotCustomShareByFarm(
  callback: (customShare: CustomShare[]) => void,
  onError: (err: Error) => void,
  farmId: string,
): () => void {
  const q = customSharesCollection.query(where('farm.id', '==', farmId))
  return customSharesCollection.snapshotMany(
    q,
    (customShares) => {
      callback(customShares)
    },
    onError,
  )
}
/** Will snapshot a single custom share. */
export function snapshotCustomShare(
  callback: (customShare: CustomShare | undefined) => void,
  onError: (err: Error) => void,
  shareId: string,
): () => void {
  return customSharesCollection.snapshotDoc(shareId, (share) => callback(share), onError)
}

/** Will load a custom share for the user if one exists, this will only show one, if they have more than one they probably know how this all works and don't need guidance for each one. So it is ok to just show one and assume they will go from their email for others. */
export async function loadCustomShareByFarms(farmIds: string[]) {
  //TODO: For now we only allow one custom share, we can change this in the future
  const res = await customSharesCollection.fetchAll(where('farm.id', 'in', farmIds), limit(1))

  return res[0]
}

/** Will estimate if a user is a member of a custom share by checking if they have any pickups in the next pickup window for the share */
export async function estimateIsShareMember(customShare: CustomShare, userId: string) {
  // We will load pickups for the user in the next ordering window and see if any contain the share product and are not cancelled. If so we can estimate they are a share member
  const pickupWindow = getCustomSharePickupRange(customShare)

  const pickups = await pickupsCollection.fetchAll(
    where('user.id', '==', userId),
    where('date.utc', '>=', pickupWindow.startDate.toUTC().toISO()),
    where('date.utc', '<=', pickupWindow.endDate.toUTC().toISO()),
  )
  const primaryShareIds = customShare.primaryShares.map((ps) => ps.id)

  // Look for any pickups that have the share product and are not cancelled
  return pickups.some((p) =>
    p.items.filter((i) => !isPickupItemSkipped(i)).some((i) => primaryShareIds.includes(i.product.id)),
  )
}

/** Will call the server endpoint to handle opening the ordering window */
export async function openOrderingWindow(share: CustomShare, preflight: boolean) {
  if (preflight) {
    const res = await callEndpoint('v2.CustomShares.openOrderWindowPreflight', {
      shareId: share.id,
    })
    return unmarshalDateObject<CustomShareRun>(res)
  }
  const res = await callEndpoint('v2.CustomShares.openOrderWindow', { shareId: share.id })
  return unmarshalDateObject<CustomShareRun>(res)
}

/** Will call the server endpoint to handle closing the ordering window */
export async function closeOrderingWindow(share: CustomShare, preflight: boolean): Promise<CustomShareRun> {
  if (preflight) {
    const res = await callEndpoint('v2.CustomShares.closeOrderWindowPreflight', {
      shareId: share.id,
    })
    return unmarshalDateObject<CustomShareRun>(res)
  }
  const res = await callEndpoint('v2.CustomShares.closeOrderWindow', { shareId: share.id })
  return unmarshalDateObject<CustomShareRun>(res)
}

/**
 * Load all data for the custom share to allow the user to customize
 */
export async function loadShareCustomization(customShareId: string, userId: string, userPrefs?: string) {
  const customShare = await customSharesCollection.fetch(customShareId)

  if (!customShare.isOrderingOpen) {
    throw new ErrorWithCode({
      code: 'CLOSED_ORDERING',
      devMsg: 'Ordering window closed for this custom share.',
    })
  }

  const response = await callEndpoint('v2.CustomShares.loadShareCustomization', {
    customShareId,
    userId,
  })

  const customBox = await buildCustomShareBox({
    customShare,
    shareValue: response.product.value,
    // If the farmer didn't set a min number of items we default to 5 so that the share is still somewhat diverse
    minShareItems: response.product.minShareItems ?? 5,
    userPickup: { userId, locationId: response.pickup.locationId },
    numSharesToCustomize: response.totalNumSharesToCustomize,
    preferences: userPrefs,
  })
  return {
    ...response,
    customBox,
    customShare,
  }
}

type BuildCustomBoxProps = {
  customShare: CustomShare
  userPickup: {
    userId: string
    locationId: string
  }
  /** The number of shares that still need to be customized. This can be an estimate, it is used to fairly distribute products. */
  numSharesToCustomize: number
  /** The value of the custom shares */
  shareValue: Money
  /** The minimum number of items to include in the share. */
  minShareItems?: number
  /** Optional preferences for the customer that will influence share customization. */
  preferences?: { [productId: string]: number } | string
  /** Using the cache will prevent reloading all the available standard products, defaults to true*/
  useCache?: boolean
}

/** This holds a session cache for the loadCSStandardProds helper */
export const [getCSProdsCachedFn, setCSProdsCachedFn, isSetCSProdsCachedFn] =
  makeHandle<typeof loadCSStandardProds>('loadCSStandardProdsCache')

/** Will load products available for the Custom Share. */
async function loadCSStandardProds(farmId: string, csaId: string, useCache = true): Promise<GlobalStandard[]> {
  // If we can use the cache we avoid reloading all the standard products for the custom share. This allows recalling the box building
  // inexpensively
  if (useCache) {
    if (!isSetCSProdsCachedFn()) {
      setCSProdsCachedFn(CachedCompute(loadCSStandardProds).cachedFn)
    }
    // We can't load from cache here or we will be put in an infinite loop
    return getCSProdsCachedFn()(farmId, csaId, false)
  }
  const products = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('csa', 'array-contains', csaId),
    where('isHidden', '==', false),
    where('type', '==', ProductType.Standard),
  )

  const prods = (products.filter((p) => isGlobalStandard(p) && isActive(p)) as GlobalStandard[]).filter(
    (p) => p.ebtEligibility !== EbtEligibility.EbtOnly,
  )

  if (products.length === 0) {
    throw new ErrorWithCode({ code: 'NO-PRODUCTS-AVAILABLE', devMsg: 'No products are available to order' })
  }
  return prods
}

/** This function will build a custom share box for a customer taking into account their preferences. */
export async function buildCustomShareBox({
  numSharesToCustomize,
  userPickup,
  shareValue,
  minShareItems,
  customShare,
  useCache,
  preferences,
}: BuildCustomBoxProps): Promise<CustomShareBoxContentsExpanded> {
  const products = await loadCSStandardProds(customShare.farm.id, customShare.csa.id, useCache)
  const rankedProds = await buildRankingFromPreferences({ products, preferences })

  // We create dummy customers for the number of customers in the share which will help the algorithm evenly distribute products
  // to the customers and not give all the suggested products to the first customer
  const dummyCusts = Array.from({ length: numSharesToCustomize - 1 }, (_, i) => ({
    userId: i.toString(),
    // This is assuming that all products in the share have the same value, which is ok for now, we might want to make this more
    // accurate in the future to better split up items
    value: shareValue,
    minNumItems: minShareItems,
    // Since we don't know where the dummy customers are, we can't set a locationId for them
    locationId: undefined,
  }))

  const custs = buildCustomShareBoxes(customShare, products, [
    {
      userId: userPickup.userId,
      value: shareValue,
      preferences: rankedProds,
      minNumItems: minShareItems,
      locationId: userPickup.locationId,
    },
    ...dummyCusts,
  ])

  // We only care about the box created for this specific customer and not for dummy customers
  return custs[userPickup.userId].map((boxProd) => ({
    product: products.find((prod) => prod.id === boxProd.product.id)!,
    quantity: boxProd.quantity,
  }))
}

/** Build the cart for the custom share including items and the promo code */
export async function buildCustomShareCart(
  customShareId: string,
  cartId: string,
  userId: string,
  promoCode: string,
  products: CustomShareBoxContents,
) {
  // Since we add full product information to the CustomShareBoxContents, here we should make sure to clean that up so that it is just the data we need
  const cleanedProducts: CustomShareBoxContents = products.map(({ product, quantity }) => ({
    product: pick(product, 'id'),
    quantity,
  }))

  const response = await callEndpoint('v2.CustomShares.buildCustomShareCart', {
    customShareId,
    cartId,
    userId,
    promoCode,
    products: cleanedProducts,
  })

  return response.success
}

type BuildRankingFromPreferencesProps = {
  products: Standard[]
  preferences: { [productId: string]: number } | string | undefined
}

/** Takes in preferences as a string or ranked list and return the ranked product list*/
export async function buildRankingFromPreferences({ products, preferences }: BuildRankingFromPreferencesProps) {
  if (typeof preferences === 'string') {
    // This endpoint will rank all the original products based on the customer preferences
    const response = await callEndpoint('v2.CustomShares.getCustomerSharePreferences', {
      products: products.map((p) => pick(p, 'id', 'name')),
      message: preferences,
    })

    return response.rankedProducts
  }

  return preferences
}
