import { LoaderWithMessage } from '@components'
import { Text } from '@elements'
import { memo } from 'react'

type NotificationsScreenEmptyProps = {
  tabIndex: number
  loadingUnread: boolean
  loadingRead: boolean
}
export const NotificationsScreenEmpty = memo(function NotificationScreenEmpty({
  tabIndex,
  loadingUnread,
  loadingRead,
}: NotificationsScreenEmptyProps) {
  if (tabIndex === 0) {
    return (
      <LoaderWithMessage icon="bell-slash" loading={loadingUnread} title="No Notifications">
        <Text>You have no unread notifications! All your messages and reminders will show up here in the future.</Text>
      </LoaderWithMessage>
    )
  }

  if (tabIndex === 1) {
    return (
      <LoaderWithMessage loading={loadingRead} icon="bell-slash" title="No Notifications">
        <Text>You have no notifications! All your messages and reminders will show up here in the future.</Text>
      </LoaderWithMessage>
    )
  }

  return null
})
