import { Image } from '@/components'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { Icon, Text } from '@elements'
import { formatTime } from '@helpers/display'
import { getMessageText } from '@helpers/messaging'
import { ChatMessage } from '@models/Messaging'
import { userName } from '@models/User'
import { StyleSheet, View } from 'react-native'

type ChatBubbleProps = {
  /** Message data to show */
  item: ChatMessage
  /** Whether the message belongs to the current user*/
  isOwnChat: boolean
  /** The logo that will show next to the message */
  logo?: string
}

/** Component that shows the message content and message details */
export function ChatBubble({ item, isOwnChat, logo }: ChatBubbleProps) {
  return (
    <View style={[styles.mainCont, isOwnChat && styles.ownCont]}>
      {logo ? (
        <Image source={{ uri: logo }} style={styles.logo} />
      ) : (
        <View style={styles.userLogoCont}>
          <Icon noHover name="user-circle" solid size={45} />
        </View>
      )}

      <View style={styles.contentWrapper}>
        <View style={styles.usernameWrapper}>
          <Text size={14} type="medium">
            {isOwnChat ? 'You' : userName(item.sender)}
          </Text>
          <Text size={12} color={Colors.primaryGray}>
            {formatTime(item.sentAtUtc.toLocal())}
          </Text>
        </View>
        <Text>{getMessageText(item)}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  mainCont: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    borderRadius: 10,
    backgroundColor: Colors.semiGreen,
    margin: 10,
    padding: 10,
    gap: 10,
    maxWidth: '90%',
  },
  ownCont: {
    alignSelf: 'flex-end',
    backgroundColor: Colors.lightBlue,
    flexDirection: 'row-reverse',
  },
  usernameWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  inner: {
    flexDirection: 'row',
    gap: 10,
  },

  logo: {
    height: 50,
    width: 50,
    borderRadius: 50,
  },
  contentWrapper: isWeb
    ? {
        gap: 10,
        flexShrink: 1,
        flexGrow: 1,
      }
    : {
        gap: 10,
        maxWidth: '70%',
      },

  rowReverse: {
    flexDirection: 'row-reverse',
  },
  userLogoCont: {
    backgroundColor: Colors.white,
    borderRadius: 100,
    alignSelf: 'flex-start',
  },
})
