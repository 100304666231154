import { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import { loadReadNotes, markAllRead } from '../../../api/Notifications'
import { globalStyles } from '../../../constants/Styles'
import { withAuth } from '../../../hooks/withAuth'
import { Notification } from '../../../models/Notification'
import { notificationsListSelector, userSelector } from '../../../redux/selectors'
import { NotificationCard } from './components/NotificationCard'
import { NotificationsScreenEmpty } from './components/NotificationScreenEmpty'
import { NotificationsScreenHeader } from './components/NotificationsScreenHeader'

function NotificationsScreenComp() {
  const [tabIndex, setTabIndex] = useState(0)
  const [readNotifs, setReadNotifs] = useState<Notification[]>([])
  const [loadingUnread, setLoadingUnread] = useState(false)
  const [loadingRead, setLoadingRead] = useState(true)
  const notifications = useSelector(notificationsListSelector)
  const user = useSelector(userSelector)

  const unreadNotifs = useMemo(() => {
    return notifications.filter((el) => !el.isRead)
  }, [notifications])

  const onMarkAllRead = useCallback(async () => {
    // Delay subsequential notification listener updates
    // This will mark the entire set of notifications as read, but also it needs a delay in order to fetch the next set of notifications
    setLoadingUnread(true)

    await markAllRead(unreadNotifs)

    setTimeout(() => {
      setLoadingUnread(false)
    }, 3000)
  }, [unreadNotifs])

  const onSwitchTabs = useCallback(
    (idx: number) => {
      setTabIndex(idx)
      if (idx === 1 && readNotifs.length === 0) {
        loadReadNotes(user.id)
          .then((notes) => {
            setReadNotifs(notes)
          })
          .finally(() => setLoadingRead(false))
      }
    },
    [readNotifs.length, user.id],
  )

  const renderItem = useCallback(({ item }: ListRenderItemInfo<Notification>) => {
    return <NotificationCard item={item} />
  }, [])

  return (
    <View style={globalStyles.flex1}>
      <NotificationsScreenHeader
        tabIndex={tabIndex}
        hasUnreadNotifications={unreadNotifs.length > 0}
        onMarkAllRead={onMarkAllRead}
        onSwitchTab={onSwitchTabs}
      />
      <FlashList
        keyExtractor={(item) => item.id}
        data={tabIndex === 0 ? unreadNotifs : readNotifs}
        ListEmptyComponent={
          <NotificationsScreenEmpty tabIndex={tabIndex} loadingRead={loadingRead} loadingUnread={loadingUnread} />
        }
        renderItem={renderItem}
        estimatedItemSize={150}
      />
    </View>
  )
}

export const NotificationsScreen = withAuth(NotificationsScreenComp, { noSafeAreaInsets: true })
