import { farmsSelector } from '@/redux/selectors'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SearchScreenContext } from '../SearchScreen'

/**
 * Custom hook to manage favorite farms in the search layer.
 *
 * @returns hasFavoriteFarms - Indicates if there are favorite farms.
 * @returns isFilteredByFavoriteFarm - Indicates if the current filter is set to favorite farms.
 * @returns onToggle - Function to toggle the favorite farms filter.
 */
export function useSetOnlyFavoriteFarms() {
  const {
    searchLayer: { farmIdsFilter, setFarmIdFilter },
  } = useContext(SearchScreenContext)

  const farmAssociations = useSelector(farmsSelector)
  const favoriteFarmsIds = useMemo(
    () => farmAssociations.filter((farm) => farm.isFavorite).map((farm) => farm.id),
    [farmAssociations],
  )

  const onToggle = useCallback(() => {
    setFarmIdFilter(farmIdsFilter ? undefined : favoriteFarmsIds)
  }, [farmIdsFilter, favoriteFarmsIds, setFarmIdFilter])

  return {
    hasFavoriteFarms: favoriteFarmsIds.length > 0,
    isFilteredByFavoriteFarm: !!farmIdsFilter,
    onToggle,
  }
}
