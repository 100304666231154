import { Divider, LoadingView, Text, TextH1 } from '@elements'
import { format, fromJSDate } from '@helpers/time'
import { SignInProviders } from '@models/User'
import * as Updates from 'expo-updates'
import { useState } from 'react'
import { ScrollView, View } from 'react-native'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { nativeAppVersion, updatesAppVersion } from '../../config/Environment'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { AppleLogin } from './AppleLogin'
import { Email, EmailTypes } from './Email/Email'
import { GoogleLogin } from './GoogleLogin'
import LoginOptButton from './LoginOptButton'
import { PhoneNumber, PhoneSteps } from './Phone/PhoneNumber'

// Used to format the app update date from utc to local time
const debugDate = (date: Date | null) => (date ? format(fromJSDate(date, 'UTC'), 'MM/dd/yy HH:mm') : 'unknown')

export type LoginComponentProps = {
  provider?: SignInProviders
  loading?: boolean
  type?: 'signin' | 'signup'
  /** Whether the the component should not handle the safe area space by itself (i.e having it inside a screen with a header) */
  noSafeAreaInsets?: boolean
}

/**
 * Component that renders the UI that allows the user to authenticate
 */
export function LoginComponent({
  provider = SignInProviders.Phone,
  loading = false,
  type = 'signin',
  noSafeAreaInsets = false,
}: LoginComponentProps) {
  const [currProvider, setProvider] = useState<SignInProviders>(provider)
  const [showUpdateId, setShowUpdateId] = useState(false)
  const styles = useStyles()

  return (
    <LoadingView loading={loading} style={globalStyles.flex1}>
      <ScrollView style={!noSafeAreaInsets && styles.safeAreaTop} contentContainerStyle={styles.content}>
        <TextH1 style={styles.welcomeText}>Welcome!</TextH1>
        {currProvider === SignInProviders.Phone && <PhoneNumber step={typeToPhone[type]} />}
        {currProvider === SignInProviders.Email && <Email type={typeToEmail[type]} />}
        <View style={styles.lineDivider}>
          <Divider style={globalStyles.flex1} />
          <Text size={14} style={styles.orText}>
            or
          </Text>
          <Divider style={globalStyles.flex1} />
        </View>
        {currProvider !== SignInProviders.Email && (
          <LoginOptButton
            icon="envelope"
            title="Login with Email"
            color={Colors.blue}
            onPress={() => setProvider(SignInProviders.Email)}
            testID="login_with_email"
          />
        )}
        {currProvider !== SignInProviders.Phone && (
          <LoginOptButton
            icon="phone"
            title="Login with Phone"
            color={Colors.blue}
            onPress={() => setProvider(SignInProviders.Phone)}
          />
        )}
        <AppleLogin />
        <GoogleLogin />
        <Divider clear />
        <Text style={styles.versionText} onPress={() => setShowUpdateId((prev) => !prev)}>
          {showUpdateId
            ? `${debugDate(Updates.createdAt)} (${nativeAppVersion || 'unknown'})`
            : `Version: ${updatesAppVersion || nativeAppVersion}`}
        </Text>
        <Divider clear />
      </ScrollView>
    </LoadingView>
  )
}

const typeToEmail = {
  signin: EmailTypes.SignIn,
  signup: EmailTypes.Register,
}
const typeToPhone = {
  signin: PhoneSteps.SignIn,
  signup: PhoneSteps.Register,
}

const useStyles = () =>
  useLayoutFnStyles(({ top }) => ({
    safeAreaTop: {
      marginTop: top,
    },
    content: {
      alignSelf: 'center',
      padding: 10,
      maxWidth: 500,
      width: '100%',
    },
    lineDivider: {
      margin: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    versionText: { textAlign: 'center', color: Colors.semiTransparent },
    welcomeText: { marginVertical: 20, textAlign: 'center' },
    orText: { paddingHorizontal: 5 },
  }))
