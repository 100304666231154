import InputLabel from '@/admin/components/InputLabel'
import { adminParamsSelector } from '@/redux/selectors'
import { FormInput, FormPickerInput, Text, ToolTips } from '@elements'
import { formatCatalog } from '@helpers/wholesale'
import { DefaultCatalog, ProductType } from '@models/Product'
import { useFormikContext } from 'formik'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { AdvancedPricingForm } from '../../AdvancedPricing'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { BasicInfoForm } from '../BasicInformation.helpers'

/**  Component displays the basic information fields for a product share,
 * including the share name, product type, and catalog selection. */
export function ProductDescriptionShareHeader() {
  const { product } = useSelector(adminParamsSelector)

  const { handleChange, values, errors, handleBlur, touched, setFieldValue, setTouched } = useFormikContext<
    BasicInfoForm & AdvancedPricingForm & ProductTypeForm
  >()

  return (
    <>
      <FormInput
        value={values.name}
        placeholder="What are you selling?"
        label={<InputLabel label="Product Name" tooltipId={ToolTips.BASIC_NAME} required />}
        onChangeText={handleChange('name')}
        onBlur={handleBlur('name')}
        errorMessage={touched.name ? errors.name : ''}
      />

      <FormPickerInput
        onValueChange={(v) => {
          setFieldValue('type', v)
        }}
        // Do not allow type change on edit mode
        disabled={!!product?.id}
        onBlur={handleBlur('type')}
        label={<InputLabel label="Is this a Primary or Add-on share?" tooltipId={ToolTips.SHARE_TYPE} required />}
        value={values.type}
        items={prodTypes}
        errorMessage={touched.type ? errors.type : ''}
      />

      <FormPickerInput
        onValueChange={async (val) => {
          await setFieldValue('catalog', val)
          await setTouched({ ...touched, catalog: true })
        }}
        label={<InputLabel label="Catalog" />}
        value={values.catalog}
        disabled // Only retails is allowed on shares
        items={[DefaultCatalog.Retail].map((catalog) => ({
          label: formatCatalog(catalog),
          value: catalog,
        }))}
      />
      <View style={styles.descriptionWrapper}>
        <Text size={18} type="medium">
          Product description
        </Text>
        <Text>
          Describe your share to customers. You can use a template to market the same product to multiple CSAs or at
          different price points.
        </Text>
      </View>
    </>
  )
}

const prodTypes = [
  { value: ProductType.PrimaryShare, label: 'Primary Share' },
  { value: ProductType.AddonShare, label: 'Add-on Share' },
]

const styles = StyleSheet.create({
  descriptionWrapper: {
    margin: 10,
    marginTop: 20,
  },
})
