import { HomeParamList } from '@/navigation/types'
import { isNonNullish } from '@helpers/helpers'
import { ArrElement } from '@helpers/typescript'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { orderMinOptions, priceOptions } from '@screens/Shopping/FarmShop/Filtering/filterConstants'
import {
  ProductFilterGroup,
  getCategoryRefinementGroup,
  getFarmGroup,
  getPracticesRefinementGroup,
  getRangeRefinementGroupItems,
} from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { useCallback, useMemo, useState } from 'react'
import { RangeFilterProps } from '../RangeFilter/RangeFilter'
import { SidebarProps } from './Sidebar'

export const useSidebarData = ({ categories, practices, radius, priceFilter, orderMinFilter, farms }: SidebarProps) => {
  const [folded, setFolded] = useState<string[]>([])

  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'SearchScreen'>>()

  const sections: ProductFilterGroup[] = useMemo(() => {
    const values = [getCategoryRefinementGroup(categories), getPracticesRefinementGroup(practices), getFarmGroup(farms)]

    return values.filter(isNonNullish)
  }, [categories, farms, practices])

  const onButtonPress = useCallback(
    (item: ArrElement<ProductFilterGroup['items']>) => {
      // On toggle or `All` press, reset filter
      const value = item.shouldClear ? undefined : item.value

      navigation.setParams({ [item.filterKey]: value })
    },
    [navigation],
  )

  const toggleFolded = useCallback((id: string) => {
    setFolded((prev) => (prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id]))
  }, [])

  const onUpdatePriceRange: RangeFilterProps['onUpdate'] = useCallback(
    (range) => {
      navigation.setParams({
        priceLow: range.min,
        priceHigh: range.max,
      })
    },
    [navigation],
  )

  const onUpdateOrderMin: RangeFilterProps['onUpdate'] = useCallback(
    (range) => {
      navigation.setParams({
        orderMinLow: range.min,
        orderMinHigh: range.max,
      })
    },
    [navigation],
  )

  const priceRefinementGroup = useMemo(() => {
    return getRangeRefinementGroupItems(priceOptions, 'Any', priceFilter.limits, priceFilter.currentFilter)
  }, [priceFilter.currentFilter, priceFilter.limits])

  const orderMinRefinementGroup = useMemo(() => {
    return getRangeRefinementGroupItems(
      orderMinOptions,
      'Any minimum',
      orderMinFilter.limits,
      orderMinFilter.currentFilter,
    )
  }, [orderMinFilter.currentFilter, orderMinFilter.limits])

  const pickupRangeItem = useMemo(() => {
    const maxRadiusInMiles = 200
    const value = radius ? metersToMiles(radius) : undefined

    return {
      max: maxRadiusInMiles,
      value,
      maxValue: maxRadiusInMiles,
      title: 'Pickup range',
      sliderLabel: !value ? 'No pickup range' : `0 - ${value} mi`,
      onUpdate: (miles: number) => {
        if (miles === maxRadiusInMiles || miles === 0) {
          navigation.setParams({ radius: undefined })
        } else {
          navigation.setParams({ radius: milesToMeters(miles) })
        }
      },
    }
  }, [navigation, radius])

  return {
    folded,
    toggleFolded,
    sections,
    onButtonPress,
    onUpdateOrderMin,
    onUpdatePriceRange,
    pickupRangeItem,
    priceRefinementGroup,
    orderMinRefinementGroup,
  }
}

const milesToMeters = (miles: number) => {
  return Math.floor(miles * 1000 * 1.6)
}

const metersToMiles = (meters: number) => {
  return Math.floor(meters / 1000 / 1.6)
}
