import { Icon, Text, TextH2 } from '@elements'
import { getDayofWeekName } from '@helpers/display'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { Exception } from '@models/Schedule'
import { memo } from 'react'
import { removeException } from '../DistributionDetailScreen.helpers'

type ScheduleSideInfoProps = {
  exceptions: Exception[]
  setFieldValue: (field: string, newValue: Exception[]) => void
}
export const ScheduleSideInfo = memo(function ScheduleSideInfo({ exceptions, setFieldValue }: ScheduleSideInfoProps) {
  return (
    <View style={styles.main}>
      <View>
        <View style={styles.exceptionInfoRow}>
          <View style={styles.originalScheduledDayIconContainer}>
            <View style={styles.originalScheduledDayIcon} />
          </View>
          <Text>Original Scheduled day</Text>
        </View>

        <View>
          <View style={styles.exceptionInfoRow}>
            <View style={[styles.markerIcon, { backgroundColor: Colors.blue }]} />
            <Text>Skip day</Text>
          </View>

          <View style={styles.exceptionInfoRow}>
            <View style={[styles.markerIcon, { backgroundColor: Colors.red }]} />
            <Text>Exception day</Text>
          </View>

          <View style={styles.exceptionInfoRow}>
            <View style={styles.markerIcon} />
            <Text>Reschedule day</Text>
          </View>
        </View>
      </View>
      {exceptions.length > 0 && (
        <View>
          <TextH2 style={styles.exceptionsListLabel}>List of Exceptions</TextH2>
          {exceptions.map((e) => {
            return (
              <View key={e.sourceDate ? e.sourceDate.toISODate() : e.dayOfWeek} style={styles.exceptionContainer}>
                <Text style={styles.exceptionDate}>
                  {e.sourceDate ? e.sourceDate.toISODate() : getDayofWeekName(e.dayOfWeek)}
                </Text>
                <Icon name="chevron-right" />
                {e.targetDate ? (
                  <Text style={styles.exceptionDate}>{e.targetDate.toISODate()}</Text>
                ) : (
                  <Text>Skipped</Text>
                )}
                <Icon name="times" color={Colors.red} onPress={() => removeException(setFieldValue, exceptions, e)} />
              </View>
            )
          })}
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    marginTop: 40, // This is so the table is horizontally aligned  with this component
  },

  exceptionInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 4,
  },
  markerIcon: {
    width: 25,
    height: 25,
    borderRadius: 15,
    backgroundColor: Colors.green,
    marginRight: 10,
  },
  originalScheduledDayIconContainer: {
    width: 25,
    height: 25,
    borderRadius: 15,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  originalScheduledDayIcon: {
    width: 6,
    height: 6,
    borderRadius: 15,
    backgroundColor: Colors.green,
  },

  exceptionsListLabel: {
    marginTop: 20,
    marginBottom: 5,
  },
  exceptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
    gap: 20,
  },
  exceptionDate: {
    width: 80,
  },
})
