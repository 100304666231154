import { CachedCompute, encodeArgsBasic } from '@helpers/cachedCompute'
import { getPickups } from '@helpers/order'

import { removeObjDuplicates } from '@helpers/helpers'
import { Distribution, isDistroNonPickup } from '@models/Distribution'
import { CartItem, isCartPhysical } from '@models/Order'
import { Product, isAddon, isPhysical } from '@models/Product'
import { DataError } from '@shared/Errors'
import { DateTime } from 'luxon'
import {
  getAvailableSchedules,
  isScheduleAvailable,
} from '../../../components/AddToCartFlow-components/useAvailableSchedules'
import { SetLocationFlowOpts } from './useSetLocationFlow'

/** This should be used instead of 'getPickups' anywhere inside the addToCart flow, for efficient caching of results limited to this area. */
export const { cachedFn: getPickupsCacheAddtoCartFlow, clearCache: clearGetPickupsCacheAddtoCartFlow } = CachedCompute(
  getPickups,
  (sch, prod, opts) => sch.id + (!!prod && 'id' in prod ? prod.id : '') + encodeArgsBasic(opts),
  { seconds: 120 },
)

/** Will try to find cart schedules that match with a schedule id from the schedules array of a product.
 * - Used for determining whether a schedule should be selected automatically when adding a new product to cart
 * - Any nonPickup schedules returned are expected to have an address
 * - Not related to the calculation of addon availability
 */
export function getMatchingCartSchedules({
  cart,
  product,
  isAdmin = false,
  isWholesale,
}: {
  cart: CartItem[]
  product: Product
  isAdmin?: boolean
  /** If defined, will only consider the schedules for the given app mode */
  isWholesale: boolean
}): Distribution[] {
  if (!isPhysical(product)) return []

  // Get the unique schedules from the cart items
  const cartSchedules = removeObjDuplicates(cart.filter(isCartPhysical).map((ci) => ci.distribution))

  // Get the available schedules of the product
  const availSchedulesFromProd = getAvailableSchedules({
    cart,
    isAdmin,
    isWholesale,
    prod: product,
    schedulesIdsFilter: cartSchedules.map((sch) => sch.id),
    locsIdsFilter: undefined,
  })

  // Then get the cart schedules that match with the available schedules from the product
  // The final returned data must come from the cart schedules, to ensure any non-pickup schedules have their selected address assigned, which would otherwise be undefined if it came from the schedules of the product
  const matchingSchedulesFromCart = cartSchedules.filter(
    (cartSch) => !!availSchedulesFromProd.find((prodSch) => prodSch.id === cartSch.id),
  )

  if (matchingSchedulesFromCart.some((sch) => isDistroNonPickup(sch) && !sch.location.address)) {
    // This is why the matching schedules should be obtained from the cart, not from the product. Cart schedules, if delivery, will already have an address assigned. The product.distributions won't
    throw new Error('Matching cart schedules for delivery should have an address assigned')
  }

  return matchingSchedulesFromCart
}

/** Eligibility criteria for selecting a schedule for auto-add purposes */
const getCanAutoSelectSchedule = ({
  schedule,
  prod,
  isAdmin,
  isWholesale,
}: {
  schedule: Distribution | undefined
  prod: Product
  isAdmin: boolean
  isWholesale: boolean
}): boolean => {
  if (!schedule) return false
  if (!isScheduleAvailable({ isAdmin, isWholesale, prod, sch: schedule })) return false

  /** For non-pickup schedules to be auto-selected they must have an address. A schedule will have an address when it comes from the matching cart schedules (I.e. the schedules from items already in the cart, for which an address was selected earlier). Otherwise it can't be auto-selected */
  if (isDistroNonPickup(schedule) && !schedule.location.address) return false

  return true
}

/** Auto-selects a distribution based on the SetPickup opts */
export function getAutoSelectedSchedule({
  preselectedSchedule,
  matchingSchedulesAvailAddon,
  matchingSchedulesCart,
  prod,
  isAdmin,
  isWholesale,
}: Pick<
  SetLocationFlowOpts,
  'preselectedSchedule' | 'matchingSchedulesAvailAddon' | 'matchingSchedulesCart' | 'prod'
> & { isAdmin: boolean; isWholesale: boolean }): Distribution | undefined {
  // if defined by the end, means the distro will be auto-selected
  let autoSelectedSchedule: Distribution | undefined = getCanAutoSelectSchedule({
    schedule: preselectedSchedule,
    isAdmin,
    isWholesale,
    prod,
  })
    ? preselectedSchedule
    : undefined

  if (!autoSelectedSchedule) {
    // Try the other scenarios that would lead to a single option to auto-select

    if (isAddon(prod) && !isAdmin && matchingSchedulesAvailAddon) {
      // check if there's only one valid distro option, and assign it
      if (
        matchingSchedulesAvailAddon.length === 1 &&
        getCanAutoSelectSchedule({ schedule: matchingSchedulesAvailAddon[0], isAdmin, isWholesale, prod })
      ) {
        autoSelectedSchedule = matchingSchedulesAvailAddon[0]
      }
    } else if (
      matchingSchedulesCart.length === 1 &&
      getCanAutoSelectSchedule({ schedule: matchingSchedulesCart[0], isAdmin, isWholesale, prod })
    ) {
      // if there's only one valid distro option, auto-add it
      autoSelectedSchedule = matchingSchedulesCart[0]
    } else if (
      prod.distributions.length === 1 &&
      getCanAutoSelectSchedule({ schedule: prod.distributions[0], isAdmin, isWholesale, prod })
    ) {
      // if there's only one valid distro option, auto-add it
      autoSelectedSchedule = prod.distributions[0]
    }
  }

  if (!!autoSelectedSchedule && isDistroNonPickup(autoSelectedSchedule) && !autoSelectedSchedule.location.address) {
    throw new DataError(
      "A schedule shouldn't have been auto-selected if it's non-pickup and doesn't have an address.",
      { autoSelectedSchedule: autoSelectedSchedule.id, isAdmin, isWholesale },
    )
  }

  return autoSelectedSchedule
}

/** Fn in charge of auto selecting dates */
export function getAutoSelectedDates({
  isWholesale,
  matchingPickups,
  possiblePickups,
}: {
  possiblePickups: DateTime[]
  matchingPickups: DateTime[]
  isWholesale: boolean
}): DateTime[] {
  // These are the tentative pickups to auto-add to the cart
  let pickupsToAutoAdd: CartItem['pickups'] = []

  if (matchingPickups.length) {
    // If matching pickups have any length, we can use them for auto-add
    if (isWholesale) {
      // In wholesale we only allow one date per order so if we are auto-adding dates it must be only one
      pickupsToAutoAdd = [matchingPickups[0]]
    } else {
      // In retail the matching pickups are all auto-added
      pickupsToAutoAdd = matchingPickups
    }
  } else if (possiblePickups.length === 1 && !isWholesale) {
    // If there are no matching dates but the possible pickups are a single one we can auto-add it if we're in retail
    pickupsToAutoAdd = [possiblePickups[0]]
  }

  return pickupsToAutoAdd
}
