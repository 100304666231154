import { Image, MessageWithIcon } from '@components'
import { Button, Divider, Spinner, Text, TextH1, TextH2, WebLink } from '@elements'
import { CustomShareBoxContentsExpanded } from '@shared/types/v2/customShares'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { ShareBoxProdCard } from './CustomShareBoxProdCard'
import { isErrorWithCode } from '@shared/Errors'
import { errorToString } from '@helpers/helpers'
import { NEW_ZOHO_TICKET } from '@shared/BaseUrl'
import React, { useMemo } from 'react'
import { findPriceForAppMode, getUnits } from '@helpers/products'
import { MoneyCalc } from '@helpers/money'
import { Money, Zero } from '@models/Money'
import { formatMoney } from '@helpers/display'
import { useNavigation } from '@react-navigation/native'
import { ConsumerNavigatorParamList } from '../../../navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'

type CurrentShareProps = {
  customBox: CustomShareBoxContentsExpanded
  shareValue: Money
}

/** Will render the currently selected share box product list */
export function CurrentShareSection({ customBox, shareValue }: CurrentShareProps) {
  const currentShareValue = useMemo(() => {
    return customBox
      .map(({ product, quantity }) => {
        const unit = getUnits(product, { isWholesale: false })[0]
        const unitPrice = unit && findPriceForAppMode(unit.prices, false)?.amount
        if (!unitPrice) return Zero
        return MoneyCalc.multiply(unitPrice, quantity)
      })
      .reduce((acc, curr) => MoneyCalc.add(acc, curr), Zero)
  }, [customBox])

  const remainingValue = MoneyCalc.subtract(shareValue, currentShareValue)

  return (
    <View>
      <View style={styles.currentProdsWrapper}>
        {customBox.map(({ product, quantity }) => (
          <ShareBoxProdCard key={product.id} product={product} quantity={quantity} />
        ))}
      </View>
      <Text color={Colors.shades[300]} style={globalStyles.marginHorizontal10}>
        We have pre-selected the above items based on availability and preferences. You can adjust your preferences or
        choose exactly what items you would like to have included.
      </Text>
      <View style={styles.totalsContainer}>
        <TextH2>Order Total: {formatMoney(currentShareValue)}</TextH2>
        <TextH2>Remaining Value: {formatMoney(remainingValue)}</TextH2>
      </View>
    </View>
  )
}

/** This component will render a loading screen while the custom share is being generated. */
export function CustomShareLoading() {
  return (
    <View style={styles.loadingContainer}>
      <Image source={require('../../../assets/images/custom-share.png')} style={styles.loadingImage} />
      <Text size={30} style={globalStyles.margin10}>
        Customizing your share...
      </Text>
      <Divider clear large />
      <Spinner size="large" style={styles.clearFlex} />
    </View>
  )
}

/** Will render errors for share customization */
export function ErrorComponent({ error }: { error: unknown }) {
  const navigation = useNavigation<StackNavigationProp<ConsumerNavigatorParamList>>()

  if (!isErrorWithCode(error)) {
    return (
      <MessageWithIcon title="Error Loading Custom Share" icon="exclamation-triangle">
        <Text>
          We apologize, there was an unexpected error while trying to build your custom share.{' '}
          <WebLink url={NEW_ZOHO_TICKET}>Contact Support</WebLink> ({errorToString(error)})
        </Text>
      </MessageWithIcon>
    )
  }

  if (isErrorWithCode(error, 'CLOSED_ORDERING')) {
    return <MessageWithIcon title="This share's ordering window is currently closed." icon="store-alt-slash" />
  } else if (isErrorWithCode(error, 'SHARE_CUSTOMIZED')) {
    return (
      <View style={styles.loadingContainer}>
        <TextH1>Good job! You have already customized your share for this week.</TextH1>
        <Button
          title="View Orders"
          onPress={() =>
            navigation.navigate('OrdersNavigator', {
              screen: 'Orders',
            })
          }
        />
      </View>
    )
  } else if (isErrorWithCode(error, 'UNAUTHORIZED_USER')) {
    return (
      <MessageWithIcon title="Unmatched Account" icon="users-slash">
        <Text>
          Your account is not associated with this order. To customize, you must be logged in as the buyer of this
          share.
        </Text>
      </MessageWithIcon>
    )
  } else if (isErrorWithCode(error, 'NO-PRODUCTS-AVAILABLE')) {
    return (
      <MessageWithIcon title="No Products Available" icon="carrot">
        <Text>
          There are no products available to order. Please reach out to your farmer and request that they add products
          for ordering.
        </Text>
      </MessageWithIcon>
    )
  } else if (isErrorWithCode(error, 'COUPON_NOT_FOUND') || isErrorWithCode(error, 'PROMO_CODE_NOT_FOUND')) {
    return (
      <MessageWithIcon title="Error Loading Custom Share" icon="exclamation-triangle">
        <Text>
          The coupon code for this custom share is not available. Please reach out to customer support if you believe
          this is an error. <WebLink url={NEW_ZOHO_TICKET}>Contact Support</WebLink>
        </Text>
      </MessageWithIcon>
    )
  }
  return (
    <MessageWithIcon title="Error Loading Custom Share" icon="exclamation-triangle">
      <Text>
        We apologize, there was an unexpected error while trying to build your custom share.{' '}
        <WebLink url={NEW_ZOHO_TICKET}>Contact Support</WebLink> ({errorToString(error)})
      </Text>
    </MessageWithIcon>
  )
}

const styles = StyleSheet.create({
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  loadingContainer: {
    flex: 1,
    margin: 10,
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingImage: {
    width: 350,
    height: 238,
  },
  clearFlex: {
    flex: 0,
  },
  currentProdsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginVertical: 10,
    gap: 10,
  },
  totalsContainer: {
    alignItems: 'flex-end',
    gap: 5,
    marginVertical: 10,
  },
})
