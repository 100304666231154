import {
  EbtFilter,
  FarmStatusFilter,
  FILTERS,
  GeoDocTypeFilter,
  HiddenFilter,
  isEbtFilter,
  isStatusFilter,
} from '@models/Algolia'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialMapFilters, MapFilters } from './types'

import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'
import { HomeParamList } from '@/navigation/types'
import { setMapFilters } from '@/redux/actions/appState'
import { mapFiltersSelector, wholesaleSelector } from '@/redux/selectors'

/** The different kinds of filters that may be controlled by the user in the map UI */
type MapUIFilter = GeoDocTypeFilter | FarmStatusFilter | HiddenFilter | EbtFilter

/** Provides a callback used to handle the toggling of a filter */
export function useBuildMapFilters() {
  const { isWholesale } = useSelector(wholesaleSelector)
  const dispatch = useDispatch()
  const currFilters = useSelector(mapFiltersSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()

  /** Controls how map filters should change when toggled. Updates navigation params and dispatches new map filters */
  return useDeepCompareCallback(
    (toggledFilter: MapUIFilter) => (): void => {
      if (typeof isWholesale !== 'boolean') {
        return
      }

      const currTypeFilter = currFilters[0]
      const currStatusFilter = currFilters[1]
      const currEbtFilter = currFilters[2]

      let newTypeFilter = [...currFilters[0]]
      let newStatusFilter = [...currFilters[1]]
      let newEbtFilter = [...currFilters[2]]

      if (isStatusFilter(toggledFilter)) {
        //manage the status filter
        newStatusFilter = currStatusFilter.includes(toggledFilter)
          ? [] //if filter already exists, remove it. since this filter only has two values: reg/ non-reg, it's the same as reseting the filter
          : [toggledFilter] // if the opposite filter exists, or if no filter existed before, refine by the toggled status only

        //Updating docType filter as side-effect of toggling the status filter...
        newTypeFilter =
          newStatusFilter[0] === FILTERS.NotRegistered
            ? [FILTERS.Farm] //If refining by unregistered status, show only farms
            : (!newStatusFilter.length || newStatusFilter[0] === FILTERS.Registered) &&
              currStatusFilter[0] === FILTERS.NotRegistered
            ? getInitialMapFilters(isWholesale)[0] // Else if the previous refinement was notRegistered, and we're reseting the status, or refining by registered, reset doctypes because products and distros must have been hidden previously
            : currTypeFilter //Else, leave the docType filter as-is

        // if refining by notRegistered, reset any existing product category refinements. otherwise no results will be returned because this doesn't get removed automatically
        // this change gets handled by VirtualParamRefinement for category attribute
        if (newStatusFilter[0] === FILTERS.NotRegistered) navigation.setParams({ category: undefined })

        dispatch(
          setMapFilters([
            newTypeFilter,
            newStatusFilter,
            newEbtFilter,
            FILTERS.NotHidden,
            FILTERS.NotInactiveFarm,
            FILTERS.NotPrivateProd,
            isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
          ]),
        )
      } else if (isEbtFilter(toggledFilter)) {
        /** Info: For Ebt filter, we're assuming there's only two possible states: Either filter by ebt, or not filter by ebt (show all) */
        //#1. toggle the ebt filter
        newEbtFilter = !currEbtFilter.length ? [FILTERS.Ebt] : []
        //#2. if we're enabling the ebt filter, then make sure...
        if (!currEbtFilter.length) {
          //  #2.1. the new status filter isn't `NotRegistered`
          if (currFilters[1].includes(FILTERS.NotRegistered)) newStatusFilter = []
          //  #2.2. the type filter includes products
          if (!currTypeFilter.find((f) => f === FILTERS.Product)) newTypeFilter.push(FILTERS.Product)
        }
        dispatch(
          setMapFilters([
            newTypeFilter,
            newStatusFilter,
            newEbtFilter,
            FILTERS.NotHidden,
            FILTERS.NotInactiveFarm,
            FILTERS.NotPrivateProd,
            isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
          ]),
        )
      } else if (toggledFilter === FILTERS.Distro) {
        // Add or remove the distro filter
        newTypeFilter = currTypeFilter.includes(FILTERS.Distro)
          ? currTypeFilter.filter((f) => f !== FILTERS.Distro)
          : currTypeFilter.concat(FILTERS.Distro)
        dispatch(
          setMapFilters([
            newTypeFilter,
            newStatusFilter,
            newEbtFilter,
            FILTERS.NotHidden,
            FILTERS.NotInactiveFarm,
            FILTERS.NotPrivateProd,
            isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
          ]),
        )
      } else throw new Error(`buildFilters is not configured to handle this filter type: ${toggledFilter}`)
    },
    [currFilters, dispatch, navigation, isWholesale],
  )
}

/** Returns a helper that checks if a given filter is enabled in the current filters */
export function useFiltersIncludes() {
  const filters = useSelector(mapFiltersSelector)

  return useCallback(
    (filter: MapUIFilter) => {
      let isIncluded = false

      filters.forEach((f) => {
        if (isIncluded) return

        if (typeof f === 'string') {
          if (f === filter) isIncluded = true
        } else {
          f?.forEach((subf) => {
            if (subf === filter) isIncluded = true
          })
        }
      })
      return isIncluded
    },
    [filters],
  )
}

/** Must set the wholesale filter portion of the map filters based on the current app mode.
 * - This is necessary because the initial redux value cannot access the appMode value. Therefore the initial value will be wrong and needs to be updated at runtime
 */
export function useSetWholesaleMapFilter() {
  const { isWholesale } = useSelector(wholesaleSelector)
  const currFilters = useSelector(mapFiltersSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof isWholesale !== 'boolean') {
      return
    }

    const newFilters: MapFilters = [...currFilters]

    newFilters[6] = isWholesale ? FILTERS.Wholesale : FILTERS.Retail

    dispatch(setMapFilters(newFilters))
    // Disabled because this should run only when isWholesale changes
    // We don't want to run this whenever the current filters change because that would be useless
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWholesale])
}
