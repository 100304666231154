import Colors from '@/constants/Colors'
import { SHADOW_2 } from '@/constants/Styles'
import { Hoverable, Icon, Text } from '@elements'
import { getCardTrimmedMessage, getParticipantsCommonInfo, getRelativeTimeString } from '@helpers/messaging'
import { ChatSession } from '@models/Messaging'
import { User } from '@models/User'
import { memo, useMemo } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Image } from '../../Image'

type ConversationCardProps = {
  chat: ChatSession
  currUserId: User['id']
  onPress: (item: ChatSession) => void
  /** Whether the conversation is selected. Used when selecting a certain conversation in order to see messages */
  isSelected?: boolean
  /** Whether it has new (unread) messages */
  hasNewMessage?: boolean
}

/** Card that holds a reference for a chat session, showing the other's participant's info, latest message, etc */
export const ConversationCard = memo(function ConversationCard({
  chat,
  currUserId,
  isSelected,
  hasNewMessage = false,
  onPress,
}: ConversationCardProps) {
  const participants = useMemo(() => {
    return getParticipantsCommonInfo(chat.participants, currUserId)
  }, [chat.participants, currUserId])

  if (!participants.other || !participants.own) {
    return null
  }

  return (
    <Hoverable>
      {(isHovered) => (
        <TouchableOpacity onPress={() => onPress(chat)}>
          <View style={[styles.main, (isHovered || isSelected) && styles.hovered]}>
            <View style={styles.imageCont}>
              {participants.other?.logo ? (
                <Image source={{ uri: participants.other?.logo }} style={styles.otherImg} />
              ) : (
                <Icon noHover name="user-circle" solid size={60} />
              )}
              <View style={styles.currImgCont}>
                {participants.own?.logo ? (
                  <Image source={{ uri: participants.own?.logo }} style={styles.currImg} />
                ) : (
                  <Icon noHover name="user-circle" solid size={40} />
                )}
              </View>
            </View>
            <View style={styles.textCont}>
              <Text type={hasNewMessage ? 'medium' : 'regular'} size={16}>
                {participants.other?.name}
              </Text>
              <Text>{getCardTrimmedMessage(chat.lastMessage)}</Text>
              <Text color={Colors.shades['200']}>{getRelativeTimeString(chat.lastMessage.sentAtUtc.toLocal())}</Text>
            </View>
            {hasNewMessage && <View style={styles.newMessageDot} />}
          </View>
        </TouchableOpacity>
      )}
    </Hoverable>
  )
})

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    flexDirection: 'row',
    gap: 10,
  },
  hovered: {
    backgroundColor: Colors.shades['75'],
  },
  imageCont: {
    alignSelf: 'flex-start',
    height: 80,
    width: 80,
  },
  otherImg: {
    borderRadius: 10,
    height: 60,
    width: 60,
  },
  currImg: {
    borderRadius: 50,
    height: 40,
    width: 40,
  },
  currImgCont: {
    borderRadius: 50,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.white,
    position: 'absolute',
    ...SHADOW_2,
  },
  textCont: {
    gap: 8,
    flex: 1,
  },
  newMessageDot: {
    width: 15,
    height: 15,
    backgroundColor: Colors.green,
    borderRadius: 50,
  },
})
