import { ButtonClear, Text, TextH1 } from '@elements'
import { formatRelative } from 'date-fns'
import { memo, useCallback, useMemo } from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { SHADOW_2 } from '@/constants/Styles'
import { markRead } from '@api/Notifications'
import { formatNotification } from '@helpers/notification'
import { Notification } from '@models/Notification'
import { TemplateName } from '@shared/types/v2/messageTemplate'

export const NotificationCard = memo(function NotificationCard({ item }: { item: Notification }) {
  const formattedNotification = formatNotification(item)

  return (
    <View style={Platform.OS === 'web' ? styles.webContainer : styles.mobileContainer}>
      <TextH1 style={styles.marginBottom5}>{formattedNotification.title}</TextH1>

      <Text>{formatRelative(item.date.toJSDate(), new Date())}</Text>
      <Text>{formattedNotification.body}</Text>

      <ActionButton action={formattedNotification.action} item={item} />
    </View>
  )
})

type ActionButtonProps = {
  action: Notification['action']
  item: Notification
}

const parseInternalUrl = (url: string) => {
  // Use URL object to extract the part after the domain
  const parsedUrl = new URL(url)
  return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash
}

/**
 * Custom title for an action button based on the notification template.
 * customer_items_delivered, delivery_today for now doesn't have custom title because we could not distinguish delivery and shipping  */
const actionTitle: Partial<Record<TemplateName, string>> = {
  messaging: 'View message',
  custom_shares: 'Customize',
  credit_balancechange: 'View Farm Credit',
  debit_customer: 'View Order',
  draft_order: 'View Order',
  draft_order_manager: 'View Pending Order',
  inventory_low: 'View Product',
  inventory_out: 'View Product',
  invoice_overdue_consumer: 'Pay Now',
  invoice_overdue_farmer: 'Payment Link',
  manual_invoice: 'View Invoice',
  new_order: 'View Order',
  new_order_manager: 'View Order',
  offline_mark_paid: 'View Invoice',
  payout_followup: 'View Payout',
  pickup_tomorrow: 'View Pickups',
  refund_invoice: 'View Invoice',
  reschedule_all_consumer: 'View Orders',
  reschedule_all_farmer: 'View Customer',
  reschedule_pickup_consumer: 'View Orders',
  reschedule_pickup_farmer: 'View Customer',
  user_claimed_vacation: 'View Customer',
  withdrawal_followup: 'View Withdrawal',
}

/** Action button used on the notification card
 */
function ActionButton({ action, item }: ActionButtonProps) {
  const markAsRead = useCallback(() => {
    if (!item.isRead) {
      markRead(item.id)
    }
  }, [item.id, item.isRead])

  const url = useMemo(() => action?.url && parseInternalUrl(action.url), [action?.url])

  if (action) {
    return (
      <ButtonClear
        url={url}
        title={actionTitle[action.type] ?? 'Open'}
        style={styles.alignStart}
        onPress={markAsRead}
      />
    )
  }

  if (!item.isRead) {
    return <ButtonClear title="Mark as Read" style={styles.alignStart} onPress={markAsRead} />
  }
}

const styles = StyleSheet.create({
  webContainer: {
    ...SHADOW_2,
    margin: 10,
    borderRadius: 10,
    backgroundColor: Colors.white,
    padding: 10,
    paddingLeft: 20,
    alignSelf: 'center',
    minWidth: 350,
    width: '60%',
  },
  mobileContainer: {
    ...SHADOW_2,
    backgroundColor: Colors.white,
    marginHorizontal: 20,
    marginVertical: 5,
    borderRadius: 10,
    padding: 10,
    paddingLeft: 20,
  },

  marginBottom5: {
    marginBottom: 5,
  },
  alignStart: {
    alignSelf: 'flex-start',
  },
})
