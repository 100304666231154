import { Ionicons } from '@expo/vector-icons'
import { useContext } from 'react'
import { FlatList, StyleSheet, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'

import { Divider } from './elements/Divider'
import { Text } from './elements/Text'
import {
  GooglePlacesSearchContext,
  UseGooglePlacesSearchDataInput,
  useGooglePlacesSearchData,
} from './googlePlacesSearch.helper'

import Colors from '@/constants/Colors'
import { useFlatStyle } from '@/hooks/useMergeStyle'

/** These are received as props because in mobile they come from the 3rd party search library which has to sit already inside the main provider for efficiency */
type UiProps<ResultType extends { description: string }, DetailType extends object> = Pick<
  UseGooglePlacesSearchDataInput<ResultType, DetailType>,
  'searchResults' | 'inputValue' | 'setValue' | 'getResultDetails' | 'isSearching'
>

const hitSlop = { top: 4, right: 4, bottom: 4, left: 4 }

/** Common UI for the mobile and web versions of google places search.
 * This is meant to be identical for web and mobile, since the only difference necessary is in the data layer, which is where it uses different libraries in different platforms, but they use this same UI */
export function GooglePlacesSearchUi<ResultType extends { description: string }, DetailType extends object>({
  getResultDetails,
  inputValue,
  isSearching,
  searchResults,
  setValue,
}: UiProps<ResultType, DetailType>) {
  const {
    ref,
    customProps: {
      inline,
      onSelect,
      onSelectGooglePlace,
      hasClearBtn,
      onClearText: onClearTextProp,
      contStyle,
      initialValue,
      enableReinitialize,
      autoSelectFirstResultOnce,
      inputRef: inputRefProp,
    },
    textInputProps: {
      style,
      placeholder = 'Enter city',
      onChangeText: onChangeTextProp,
      onSubmitEditing: onSubmitEditingProp,
      onFocus: onFocusProp,
      onTouchStart: onTouchStartProp,
      ...textInputRest
    },
  } = useContext(GooglePlacesSearchContext)

  const {
    inputRef,
    hideInlineResults,
    onFocus,
    onBlur,
    onChangeText,
    onSubmit,
    onTouchStart,
    items,
    inlineOnPressItem,
    onClearText,
    autoCompleteOverlay,
  } = useGooglePlacesSearchData({
    ref,
    initialValue,
    getResultDetails,
    setValue,
    inline,
    onSelect,
    onSelectGooglePlace,
    searchResults,
    inputValue,
    isSearching,
    enableReinitialize,
    autoSelectFirstResultOnce,
    onChangeText: onChangeTextProp,
    onSubmitEditing: onSubmitEditingProp,
    onFocus: onFocusProp,
    onTouchStart: onTouchStartProp,
    inputRef: inputRefProp,
    onClearText: onClearTextProp,
  })

  return (
    <>
      {autoCompleteOverlay}
      <View style={contStyle}>
        <TextInput
          ref={inputRef}
          style={useFlatStyle([styles.input, hasClearBtn && !!inputValue && styles.paddingRight30, style])}
          value={inputValue}
          onFocus={onFocus}
          onBlur={onBlur}
          onChangeText={onChangeText}
          onSubmitEditing={onSubmit}
          onTouchStart={onTouchStart}
          placeholder={placeholder}
          placeholderTextColor={Colors.shades['400']}
          importantForAutofill="no"
          blurOnSubmit
          {...textInputRest}
        />

        {hasClearBtn && !!inputValue && (
          <View style={styles.iconCont}>
            <TouchableWithoutFeedback hitSlop={hitSlop} onPress={onClearText}>
              <Ionicons color={Colors.shades['200']} name="close" size={20} />
            </TouchableWithoutFeedback>
          </View>
        )}
      </View>

      {!hideInlineResults && (
        <FlatList
          data={items}
          renderItem={({ item, index }) => (
            <TouchableOpacity key={item.data.description} onPressIn={() => inlineOnPressItem(item.data)}>
              <Text style={styles.inlineItemText}>{item.text}</Text>
              {index < items.length - 1 && <Divider top={0} bottom={0} />}
            </TouchableOpacity>
          )}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  /** These styles must work for mobile and web */
  input: {
    // flex: 1, // Don't do it!. Made the input become invisible on android
    height: 50,
    fontSize: 14,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  inlineItemText: {
    padding: 10,
  },
  paddingRight30: {
    // If clear button is enabled, it has to have space in the view
    paddingRight: 30,
  },
  iconCont: {
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 5,
  },
})
