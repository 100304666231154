import { useFormikContext } from 'formik'
import { StyleSheet, View } from 'react-native'

import { adminFarmSelector, adminParamsSelector } from '@/redux/selectors'
import { hasUnits, isShare } from '@models/Product'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AdvancedPricingForm } from '../AdvancedPricing'
import { ProductFormikComponent } from '../helpers/ProductFormikComponent'
import { ProductTypeForm } from '../helpers/ProductTypeInfo'
import { ProductFeeSection } from '../ProductFeeSection'
import { BasicInfoForm, basicInfoSchema, fromFormik, toFormik } from './BasicInformation.helpers'
import { CsaSelection } from './components/CsaSelection'
import { PhotoSelection } from './components/PhotoSelection'
import { ProductDescriptionFields } from './components/ProductDescriptionFields'
import { ProductDescriptionShareHeader } from './components/ProductDescriptionShareHeader'
import { ProductShareDescriptionFields } from './components/ProductShareDescriptionFields'
import { TemplateSection } from './components/TemplateSection'

export const FormikBasicInformation = new ProductFormikComponent(basicInfoSchema, toFormik, fromFormik)

export const BasicInformation = memo(function BasicInformation() {
  const farm = useSelector(adminFarmSelector)
  const { values } = useFormikContext<BasicInfoForm & AdvancedPricingForm & ProductTypeForm>()

  const { product } = useSelector(adminParamsSelector)

  const FormContent = useMemo(() => {
    if (isShare(values.type)) {
      return (
        <>
          <ProductDescriptionShareHeader />
          <TemplateSection product={product} farmId={farm.id} />
          <ProductShareDescriptionFields />
          <CsaSelection farmId={farm.id} product={product} />
          <ProductFeeSection />
        </>
      )
    }

    return (
      <>
        <ProductDescriptionFields />
        {hasUnits({ type: values.type }) && <CsaSelection farmId={farm.id} product={product} />}
        <ProductFeeSection />
      </>
    )
  }, [farm.id, product, values.type])

  return (
    <View style={styles.main}>
      <View style={styles.fieldsCont}>{FormContent}</View>
      <View style={styles.photoSelectionWrapper}>
        <PhotoSelection />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 20,
    gap: 20,
  },
  fieldsCont: {
    gap: 10,
    flexGrow: 1,
    flexBasis: 500,
    flexShrink: 1,
  },
  photoSelectionWrapper: {
    flexBasis: 400,
    flexGrow: 1,
    flexShrink: 1,
    margin: 10,
  },
})
