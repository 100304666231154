import { getNearbyProducts } from '@api/Products'
import { validCoords } from '@helpers/coordinate'
import { removeDuplicates } from '@helpers/helpers'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'

import { isSmallDevice } from '@/constants/Layout'
import { wholesaleSelector } from '@/redux/selectors'
import { GooglePlace } from '@models/Address'
import { useSelector } from 'react-redux'
import { useProgressiveLoading } from './useProgressiveLoading'

/** Returns the number of farms expected of the results, for the current device */
export const getRequiredNFarms = () => (isSmallDevice() ? 2 : 3)

/** This is the number of products that will be fetched for each search to be done. If the number of farms is not met after reaching this number, it will continue loading more */
const getTargetLength = () => (isSmallDevice() ? 35 : 50)

const getPageLength = () => Math.ceil(getTargetLength() / (isSmallDevice() ? 2 : 3))

/** Hook that fetches the nearest products based on the current location  */
export const useGetNearbyProds = (place: GooglePlace) => {
  const { isWholesale } = useSelector(wholesaleSelector)

  return useProgressiveLoading<AlgoliaGeoDoc<AlgoliaGeoProduct>, number>({
    initialState: { loading: true },
    getPageData: async (cursor, pageLength) => {
      const hits = await getNearbyProducts(
        place,
        {
          isWholesale: isWholesale!,
        },
        {
          length: pageLength,
          offset: cursor === null ? 0 : cursor, // offset is the starting point for the next set of results
        },
      )

      return {
        data: hits,
        lastCursor: (cursor ?? 0) + hits.length,
      }
    },
    runCondition: !!place && validCoords(place.coordinate) && typeof isWholesale === 'boolean',
    targetLength: getTargetLength(),
    pageLength: getPageLength(), // There will be at least 3 iterations before reaching the target length
    shouldFetchMore: (results) => {
      /** If the number of unique farms is less than this number, should continue fetching more products.
       * If the target length was reached, but there are still less than the required N farms, it should continue
       * fetching more produts.
       */
      return removeDuplicates(results.map((res) => res.farm.id)).length < getRequiredNFarms()
    },
    maxCalls: 6, // If the required N farms isn't reached by the 6th call, it'll end regardless
    failedConditionMode: 'stop-loading',

    noRefocus: true,
    deps: [place, isWholesale],
  })
}
