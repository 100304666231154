import { snapshotInvoicesByUserAndFarm } from '@api/Invoices'
import { MessageWithIcon } from '@components'
import { LoadingView, Text, TextH4, Touchable } from '@elements'
import { openUrl } from '@helpers/client'
import { formatInvoiceNum, formatMiniDate, formatMoney } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Farm } from '@models/Farm'
import { Invoice, InvoiceStatus, amountDue, displayInvStatus, isDue } from '@models/Invoice'
import { Money, Zero } from '@models/Money'
import { User } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'

import { globalStyles } from '../../../../constants/Styles'
import { userSelector, wholesaleSelector } from '../../../../redux/selectors'
import { ActionsMenuComponent } from '../../../components/elements/ActionsMenuComponent'
import CopyInvoiceUrl from '../../Invoice/components/CopyInvoiceUrl'
import { invoiceActionGenerator } from '../../Invoice/helper/invoiceActionGenerator'
import { AdminCustomerHeader } from '../AdminCustomerOrdersSection/components/Header'
import { createInvoice } from './helpers/invoiceActions'

import { AdminCard } from '@/admin/components/AdminCard'
import { CustomerParamList } from '@/admin/navigation/types'
import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { useSnapshot } from '@/hooks/useApiFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'

import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { AccessRight, Permission } from '@helpers/Permission'
import { displayPaymentMethod } from '@helpers/paymentMethodDisplay'
import { ListItem } from 'react-native-elements'

type Props = {
  customerId: User['id']
  farm: Farm
  onAmountTotalChange: (amt: Money) => void
}

/** The Invoices Section Component inside AdminCustomerDetail Screen */
export function CustomerInvoices({ onAmountTotalChange, customerId, farm }: Props) {
  const { isExtraSmallDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<CustomerParamList>>()
  const farmer = useSelector(userSelector)
  const { isWholesale } = useSelector(wholesaleSelector)
  const invoicesSnap = useSnapshot(snapshotInvoicesByUserAndFarm, [customerId, farm.id])
  const [amtDue, setAmountDue] = useState(Zero)
  const [showVoid, setShowVoid] = useState(false)
  //limit is used to limit the number of invoices displayed at a time
  const [limit, setLimit] = useState(10)
  const [openSection, setOpenSection] = useState<boolean>(false)
  const [voidInvoiceExist, setVoidInvoiceExist] = useState<true | undefined>()
  const hasAccessEditInv = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  const styles = useStyles()

  const listItemAccordionOnPress = useCallback(() => {
    setOpenSection((value) => !value)
    // Reset limit
    setLimit(10)
  }, [])

  useEffect(() => {
    if (!invoicesSnap.data) {
      // if no invoice data, set amount due to 0
      setAmountDue(Zero)
      onAmountTotalChange(Zero)
      return
    }
    const newAmountDue = invoicesSnap.data
      .filter((inv) => isDue(inv))
      .reduce((tot, inv) => MoneyCalc.add(tot, amountDue(inv)), Zero)

    // Check if void invoice exists
    const voidInvoiceExist = invoicesSnap.data.some((inv) => inv.status === InvoiceStatus.Void) ? true : undefined
    setVoidInvoiceExist(voidInvoiceExist)

    // Will now only call onAmountTotalChanges when the total amount is different from the last

    if (!MoneyCalc.isEqual(newAmountDue, amtDue)) {
      setAmountDue(newAmountDue)
      onAmountTotalChange(newAmountDue)
    }
  }, [invoicesSnap.data, amtDue, onAmountTotalChange])

  const handleLoadMore = useCallback(() => {
    setLimit((prev) => prev + 10)
  }, [])

  return (
    <LoadingView loading={invoicesSnap.loading} success={{ invoices: invoicesSnap.data ?? [] }}>
      {({ invoices }) => {
        const displayInvoices = invoices.filter((inv) => showVoid || inv.status !== InvoiceStatus.Void)
        return (
          <AdminCard style={styles.adminCardContainer}>
            <ListItem.Accordion
              isExpanded={openSection}
              onPress={listItemAccordionOnPress}
              containerStyle={styles.listItemContainer}
              pad={isExtraSmallDevice ? 0 : 10}
              content={
                <AdminCustomerHeader
                  title={`Invoices (${displayInvoices.length})`}
                  containerStyle={styles.headerContainer}
                  btns={[
                    voidInvoiceExist && openSection
                      ? {
                          title: `${showVoid ? 'Hide' : 'Show'} Voided Invoices`,
                          clear: true,
                          onPress: () => setShowVoid((prev) => !prev),
                        }
                      : undefined,
                    hasAccessEditInv && openSection
                      ? {
                          title: 'New invoice',
                          onPress: () => {
                            createInvoice(customerId, farm)
                          },
                        }
                      : undefined,
                  ].filter(isNonNullish)}
                />
              }
            >
              {displayInvoices.length > 0 ? (
                <ScrollView bounces={false} horizontal contentContainerStyle={styles.scrollContentContainer}>
                  <View style={styles.scrollContainer}>
                    <View style={styles.containerRow}>
                      <TextH4 style={globalStyles.flex05}>Date</TextH4>
                      <TextH4 style={globalStyles.flex05}>Invoice #</TextH4>
                      <TextH4 style={globalStyles.flex05}>Order #</TextH4>
                      <TextH4 style={globalStyles.flex05}>Total</TextH4>
                      <TextH4 style={globalStyles.flex1}>Amount Due</TextH4>
                      <TextH4 style={globalStyles.flex1}>Method</TextH4>
                      <TextH4 style={globalStyles.flex1}>Status</TextH4>
                      <TextH4 style={globalStyles.flex1}>Action</TextH4>
                      <TextH4 style={globalStyles.flex1}>Link</TextH4>
                    </View>

                    {displayInvoices.slice(0, limit).map((inv: Invoice) => {
                      return (
                        <View style={styles.containerRow} key={inv.id}>
                          <Text style={globalStyles.flex05}>{formatMiniDate(inv.dueDate)}</Text>
                          <Touchable
                            style={globalStyles.flex05}
                            onPress={() =>
                              openUrl(
                                inv.payments.stripe_invoice?.paymentRef
                                  ? `https://dashboard.stripe.com/${farm.accountRef}/invoices/${inv.payments.stripe_invoice?.paymentRef}`
                                  : inv.pdf ?? `${grownbyWebsiteBaseUrl(isWholesale)}/external/invoice/${inv.id}`,
                              )
                            }
                          >
                            <Text style={{ color: Colors.blue }}>{formatInvoiceNum(inv.invoiceNum)}</Text>
                          </Touchable>
                          <Touchable
                            onPress={() => navigation.navigate('AdminOrderDetails', { orderId: inv.order.id })}
                            style={globalStyles.flex05}
                          >
                            <Text style={{ color: Colors.blue }}>{formatInvoiceNum(inv.order.orderNum)}</Text>
                          </Touchable>

                          <Text style={globalStyles.flex05}>{formatMoney(inv.amountTotal)}</Text>
                          <Text style={globalStyles.flex1}>{formatMoney(amountDue(inv))}</Text>
                          <Text style={globalStyles.flex1}>{displayPaymentMethod(inv)}</Text>
                          <Text style={globalStyles.flex1}>{displayInvStatus(inv)}</Text>
                          <ActionsMenuComponent
                            containerStyle={globalStyles.flex1}
                            data={inv}
                            buttons={invoiceActionGenerator(inv, farmer)}
                            disabled={!hasAccessEditInv}
                          />
                          <View style={globalStyles.flex1}>
                            <CopyInvoiceUrl invoice={inv} />
                          </View>
                        </View>
                      )
                    })}
                    {displayInvoices.length > limit && (
                      <Text style={styles.showMore} type="bold" size={16} color={Colors.green} onPress={handleLoadMore}>
                        Show More
                      </Text>
                    )}
                  </View>
                </ScrollView>
              ) : (
                <MessageWithIcon icon="file-invoice-dollar" title="Invoices">
                  <Text>No invoices yet. Check back later for more transactions.</Text>
                </MessageWithIcon>
              )}
            </ListItem.Accordion>
          </AdminCard>
        )
      }}
    </LoadingView>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isSmallDevice }) => ({
    sectionTitle: {
      marginBottom: 10,
    },
    containerRow: {
      justifyContent: 'space-between',
      marginHorizontal: 15,
      marginVertical: 5,
      flexDirection: 'row',
    },
    centerText: {
      alignSelf: 'center',
      marginBottom: 10,
    },
    scrollContainer: { flex: 1 },
    scrollContentContainer: { flex: 1, minWidth: 1000, marginHorizontal: 15, marginTop: 10 },
    showMore: {
      alignSelf: 'center',
      marginTop: 10,
    },
    adminCardContainer: {
      paddingVertical: isSmallDevice ? 10 : 20,
      paddingHorizontal: isExtraSmallDevice ? 10 : 20,
      marginTop: isSmallDevice ? 10 : 20,
    },
    headerContainer: {
      flex: 1,
      marginBottom: 0,
    },
    listItemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
      flex: 1,
    },
  }))
