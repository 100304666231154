import { Logger } from '@/config/logger'
import { changeUser, validatePhoneNumber } from '@api/Users'
import { auth } from '@api/db'
import { ConsumerScroll, FormBuilder, PhoneInput } from '@components'
import { Alert, Divider, FormButton, Toast, typography } from '@elements'
import { mainNumPatternForms, unmarshalPhoneNumber } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { User } from '@models/User'
import { HeaderBackButton } from '@react-navigation/elements'
import { StackScreenProps } from '@react-navigation/stack'
import { Formik, FormikProps, useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import Colors from '@/constants/Colors'
import { withAuth } from '@/hooks/withAuth'
import { globalStyles } from '../../constants/Styles'
import InputAutocomplete from '../../constants/inputAutocomplete'
import { UserStackParamList } from '../../navigation/types'
import { setUser } from '../../redux/actions/user'
import { RootState } from '../../redux/reducers/types'
import { userSelector } from '../../redux/selectors'

type PersonalInformationScreenProps = StackScreenProps<UserStackParamList, 'PersonalInformation'>

type FormType = {
  email: string
  email2?: string
  firstName: string
  lastName: string
  phoneNumber?: string
  pronouns?: string
}

//Virtual component. Renders null, but receives form data, and if form has changed, will make form state available outside formik context.
function OnChange({
  initialValues,
  callback,
}: {
  initialValues: object
  callback: (arg0: Partial<FormType> | undefined) => void
}) {
  const { values, errors } = useFormikContext()
  if (Object.keys(errors).length === 0 && JSON.stringify(values) !== JSON.stringify(initialValues)) {
    callback(values as { [key: string]: string })
  } else {
    callback(undefined)
  }
  return null
}

const registerValidationSchema = Yup.object<FormType>().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string().label('Email').email('Please enter a valid email').required('An email is required'),
  email2: Yup.string().label('Email 2').email('Please enter a valid email'),
  phoneNumber: Yup.string().matches(mainNumPatternForms, 'Please enter a valid phone number.').label('Phone Number'),
  pronouns: Yup.string().label('Preferred pronouns'),
})

// Will prompt the user asking if they want to go back and if so will send them back
const confirmBack = (goBack: () => void) => {
  Alert('Unsaved changes', 'Are you sure you want to leave this page?', [
    { text: 'No', style: 'cancel' },
    { text: 'Yes', onPress: goBack },
  ])
}

/** This is the page for Personal Information */
export function PersonalInformationScreenComp({ navigation, route }: PersonalInformationScreenProps) {
  const user = useSelector<RootState, User>(userSelector)
  const [data, setData] = useState<Partial<FormType>>()
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const authProvider = auth().getProvider()

  useEffect(() => {
    if (route.params?.updateEmail)
      Alert(
        'Please Update Email',
        'It looks like you chose to hide your email from Apple login. This makes it impossible for farmers to reach you. Please update your email so that you can receive messages.',
      )
  }, [route])

  const saveData = useCallback(async () => {
    setIsSaving(true)
    if (data === undefined) {
      setIsSaving(false)
      return Toast('Nothing to save')
    }
    //phone number must be marshalled and checked against duplicates
    let newPhoneNumber = data.phoneNumber || ''
    if (newPhoneNumber) {
      try {
        newPhoneNumber = await validatePhoneNumber(newPhoneNumber, user.id, false)
      } catch (err) {
        Toast(errorToString(err))
        return setIsSaving(false)
      }
    }

    const newUser: User = { ...user }

    newUser.name = {
      firstName: data.firstName!,
      lastName: data.lastName!,
    }
    newUser.email = data.email!
    newUser.phoneNumber = newPhoneNumber
    newUser.pronouns = data.pronouns || ''
    newUser.email2 = data.email2 || ''

    try {
      await changeUser(user.id, user, newUser)
      dispatch(setUser({ ...newUser }))
      Toast('Profile saved successfully.')
      setIsSaving(false)
      setData(undefined)
    } catch (err) {
      Logger.debug(err)
      setIsSaving(false)
      Toast(`Error saving your information. ${errorToString(err)}`)
    }
  }, [data, dispatch, user])

  useEffect(() => {
    if (data)
      navigation.setOptions({
        headerLeft: () => <HeaderBackButton onPress={() => confirmBack(navigation.goBack)} />,
      })
    else {
      navigation.setOptions({
        headerLeft: () => <HeaderBackButton onPress={navigation.goBack} />,
      })
    }
  }, [data, isSaving, navigation])

  const initialValues: FormType = useMemo(
    () => ({
      email: user.email,
      email2: user.email2,
      firstName: user.name.firstName,
      lastName: user.name.lastName,
      phoneNumber: user.phoneNumber ? unmarshalPhoneNumber(user.phoneNumber, false) : '',
      pronouns: user.pronouns || '',
    }),
    [user],
  )

  return (
    <KeyboardAvoidingView
      style={globalStyles.flex1}
      keyboardVerticalOffset={100}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <ConsumerScroll>
        <Formik
          initialValues={initialValues}
          onSubmit={saveData}
          validationSchema={registerValidationSchema}
          enableReinitialize
        >
          {({ handleChange, values, errors, touched, handleBlur, handleSubmit }: FormikProps<FormType>) => (
            <FormBuilder>
              <OnChange initialValues={initialValues} callback={setData} />

              <View style={globalStyles.margin10} />

              <Input
                labelStyle={styles.label}
                numberOfLines={1}
                value={values.firstName}
                placeholder="First Name"
                label="First Name"
                onChangeText={handleChange('firstName')}
                onBlur={handleBlur('firstName')}
                errorMessage={touched.firstName ? errors.firstName : ''}
                {...InputAutocomplete.FIRST_NAME}
              />
              <Input
                labelStyle={styles.label}
                numberOfLines={1}
                value={values.lastName}
                label="Last Name"
                onChangeText={handleChange('lastName')}
                onBlur={handleBlur('lastName')}
                errorMessage={touched.lastName ? errors.lastName : ''}
                {...InputAutocomplete.LAST_NAME}
              />
              <Input
                labelStyle={styles.label}
                numberOfLines={1}
                label="Pronoun"
                placeholder="Optional"
                onChangeText={handleChange('pronouns')}
                value={values.pronouns}
                onBlur={handleBlur('pronouns')}
              />
              <PhoneInput
                labelStyle={styles.phoneInputLabel}
                editable={authProvider !== 'phone'}
                value={values.phoneNumber}
                maskHandler={handleChange('phoneNumber')}
                onBlur={handleBlur('phoneNumber')}
                errorMessage={errors.phoneNumber}
                renderErrorMessage={touched.phoneNumber}
              />
              <Input
                numberOfLines={1}
                labelStyle={styles.label}
                label="Email"
                placeholder="Email"
                onChangeText={handleChange('email')}
                value={values.email}
                onBlur={handleBlur('email')}
                errorMessage={touched.email ? errors.email : ''}
                {...InputAutocomplete.EMAIL_ADDRESS}
              />
              <Input
                labelStyle={styles.label}
                numberOfLines={1}
                label="Email 2"
                onChangeText={handleChange('email2')}
                value={values.email2}
                placeholder="Optional"
                onBlur={handleBlur('email2')}
                errorMessage={touched.email2 ? errors.email2 : ''}
                {...InputAutocomplete.EMAIL_ADDRESS}
              />
              <Divider clear />
              <FormButton loading={isSaving} title="Save" onPress={handleSubmit} style={styles.buttonStyle} />
            </FormBuilder>
          )}
        </Formik>
      </ConsumerScroll>
    </KeyboardAvoidingView>
  )
}
export const PersonalInformationScreen = withAuth(PersonalInformationScreenComp, { noSafeAreaInsets: true })

const styles = StyleSheet.create({
  buttonStyle: {
    minWidth: 200,
  },
  borderBottom0: {
    borderBottomWidth: 0,
  },
  fullWidth: {
    width: '100%',
  },
  main: {
    borderWidth: 2,
  },
  label: {
    fontSize: 16,
    color: Colors.black,
    fontFamily: typography.body.medium,
  },
  // This is needed in order to have consistent UI between PhoneInput and FormBuilder's input styles
  phoneInputLabel: {
    fontSize: 16,
    color: Colors.black,
    fontFamily: typography.body.medium,
    marginBottom: 8,
  },
})
