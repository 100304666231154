import { AdminView } from '@/admin/components/AdminView'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { createUnitPrompt } from '@/admin/screens/Products/ProductForm/helpers/baseUnitAdd.helper'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmSelector } from '@/redux/selectors'
import { listLocalProducersAndCountAssociatedProducts } from '@api/Producers'
import { ToolTips } from '@components'
import { Button, HeaderText, Tooltip } from '@elements'
import { ProducerRow } from '@screens/Producers/components/ProducerRow'
import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

export const ProducersScreen = withAdminAuth(function ProducersScreen() {
  const farm = useSelector(adminFarmSelector)
  const { data, loading } = useApiFx(listLocalProducersAndCountAssociatedProducts, [farm.id, farm.localProducers])
  const { isSmallDevice } = useDeviceSize()
  const renderItem: NonNullable<OfflineTableProps<Record<string, number>>['renderItem']> = ({
    item: producerRecord,
    index,
  }) => {
    return <ProducerRow producerRecord={producerRecord} index={index} />
  }

  const styles = useStyles()

  return (
    <AdminView hideFooter style={styles.container}>
      <View style={styles.header}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={isSmallDevice ? 22 : 30}>Producers</HeaderText>
          <Tooltip size={15} id={ToolTips.PRODUCERS} />
        </View>
        <Button
          style={styles.addButton}
          small
          title="Add producer"
          onPress={() =>
            createUnitPrompt({
              type: 'producer',
              farmId: farm.id,
              inputProps: {
                placeholder: 'Enter producer name here',
                defaultValue: '',
              },
            })
          }
        />
      </View>
      <OfflineTable<Record<string, number>>
        data={data}
        isLoading={loading}
        headerColumns={[
          { title: 'Producer Name', widthFlex: 2 },
          { title: 'Product Count', widthFlex: 1.5 },
          { widthFlex: 0.5 },
          { widthFlex: 0.5 },
        ]}
        renderItem={renderItem}
        minWidth={600}
        /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem.
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
        scrollEnabled={isWeb || !isSmallDevice}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        containerStyle={styles.offlineTableContainer}
      />
    </AdminView>
  )
})

const useStyles = () => {
  return useLayoutFnStyles(({ isSmallDevice, height, bottom }) => ({
    container: {
      paddingHorizontal: isSmallDevice ? 10 : 30,
      paddingTop: isSmallDevice ? 10 : 30,
      // Adds safe area padding at the bottom, with a minimum of 10 if no safe area exists
      paddingBottom: bottom || 10,
    },
    offlineTableContainer: {
      /** If it is not smallDevice or not extraSmallDevice, it means templates table is enabled to have nested scroll, so the maxHeight should be set to height * 0.78 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
       * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
       */
      maxHeight: !isSmallDevice ? height * 0.78 : '100%',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    addButton: {
      marginRight: 20,
    },
    separator: {
      height: 1,
      backgroundColor: Colors.lightGray,
    },
  }))
}
