import Colors from '@/constants/Colors'
import { customShareSelector, userSelector, wholesaleSelector } from '@/redux/selectors'
import { Modal, Text, Touchable } from '@elements'
import { getNextShareCloseDateTime } from '@helpers/custom_shares/share_window'
import { CustomShare } from '@models/CustomShare'
import { memo, useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomShareModalData } from '../../redux/actions/appPersist'
import { CustomizationReminderModal } from './CustomizationReminderModal'
import { useCachedCustomShare } from './useCachedCustomShare'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { getTimeLeftBeforeOrderingOpens } from '@helpers/custom_shares/display'

type Props = {
  /** If the banner is rendered at the top of the screen we want it to add safe area padding so that the text isn't cut off. */
  includeSafeArea?: boolean
}

/** Displays a banner at the top of the shopping screens that show the user the status of the custom share ordering window.
 * This component will also show a modal once per share window for the customer. It is not shown in Wholesale mode. */
export function CustomShareShopBanner(props: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)

  if (isWholesale) return null

  return <CustomShareShopBannerComp {...props} />
}

const CustomShareShopBannerComp = memo(function CustomShareShopBanner({ includeSafeArea }: Props) {
  const insets = useSafeAreaInsets()
  const userId = useSelector(userSelector)?.id
  const customShareState = useSelector(customShareSelector)
  const dispatch = useDispatch()
  const { customShare, isShareMember } = useCachedCustomShare()
  const nextCloseDate = useMemo(
    () => (customShare ? getNextShareCloseDateTime(customShare).toMillis() : 0),
    [customShare],
  )

  useEffect(() => {
    // This effect is only to show the modal, and we don't show it if ordering is closed, or they aren't a share member
    if (!isShareMember || !customShare?.isOrderingOpen || !userId) return

    // If we have not shown the modal for this current open window we should show it
    if (!customShareState.lastSeenModalTimestamp || nextCloseDate > customShareState.lastSeenModalTimestamp) {
      Modal(<CustomizationReminderModal customShare={customShare} />, {
        title: 'Your share is ready to customize!',
        halfModal: true,
      })
      dispatch(setCustomShareModalData({ lastSeenModalTimestamp: nextCloseDate }))
    }
  }, [dispatch, customShareState.lastSeenModalTimestamp, customShare, isShareMember, nextCloseDate, userId])

  if (!customShare || !isShareMember || !userId) return null

  // Don't show the banner if the user has already customized for this window
  if (
    customShare.isOrderingOpen &&
    customShareState.lastCustomizedShareTimestamp &&
    customShareState.lastCustomizedShareTimestamp <= nextCloseDate
  )
    return null

  return (
    <Touchable
      disabled={!customShare.isOrderingOpen}
      style={[styles.container, includeSafeArea && { paddingTop: Math.max(insets.top, 20) }]}
      url={`/farms/${customShare.farm.id}/shop/csa/${customShare.csa.id}/share/${customShare.id}`}
    >
      {customShare.isOrderingOpen ? (
        <OrderingWindowOpenText customShare={customShare} />
      ) : (
        <OrderingWindowClosedText customShare={customShare} />
      )}
    </Touchable>
  )
})

function OrderingWindowOpenText({ customShare }: { customShare: CustomShare }) {
  const user = useSelector(userSelector)
  return (
    <Text color={Colors.white}>
      {user.name.firstName}, your ordering window is open for {customShare.farm.name}!{' '}
      <Text style={styles.underlineText} color={Colors.white}>
        Go to customization page
      </Text>
    </Text>
  )
}

function OrderingWindowClosedText({ customShare }: { customShare: CustomShare }) {
  const user = useSelector(userSelector)
  const timeLeft = getTimeLeftBeforeOrderingOpens(customShare)

  // If we don't return a time left then don't show this. This means that there is a lot of time left,
  // and we don't want to show the banner
  if (timeLeft === null) return null

  return (
    <Text color={Colors.white}>
      {user.name.firstName}, you can customize your share for {customShare.farm.name} {timeLeft}
    </Text>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.brightPurple,
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  underlineText: {
    textDecorationLine: 'underline',
  },
})
