import { memo, useCallback, useEffect, useState } from 'react'
import { View, ViewProps, ViewStyle } from 'react-native'

import { isWeb } from '@/constants/Layout'
import { useLayout } from '@/hooks/useLayout'
import useMeasure from '@/hooks/useMeasure'
import { useFlatStyle } from '@/hooks/useMergeStyle'

/** Creates a View with a maxHeight and maxWidth that tries to occupy as much of the screen as possible.
 * - maxHeight will subtract the height of any elements above it, such as a header
 * - maxWidth will subtract the width of any elements left, such as a sidebar
 * - noop on mobile unless prop requests it
 * - where this is needed:
 *     - ParallaxHeaderWeb,
 *     - Any screen with a FlatList/ FlashList that uses scrollToIndex() (CSADetails.tsx)
 *     - Any screen where a scrollable section needs a maxHeight as limit, for correct scrolling
 *     - FarmRegisterScreen and AddFarmScreen. The main screen container needs to subtract the header height because the app header is defined inside the navigator.
 * */
export const ScreenView = memo(function ScreenView({
  style,
  onLayout: onLayoutProp,
  webOnly = true,
  ...props
}: ViewProps & { webOnly?: boolean }) {
  const { height: screenHeight, screenWidth } = useLayout()
  const [maxBounds, setMaxBounds] = useState<ViewStyle>({ flex: 1, maxHeight: '100%', maxWidth: '100%' })

  const [measure, ref, refresh] = useMeasure()

  const onLayout = useCallback<NonNullable<ViewProps['onLayout']>>(
    (e) => {
      refresh()
      onLayoutProp?.(e)
    },
    [onLayoutProp, refresh],
  )

  useEffect(() => {
    if (!measure || (webOnly && !isWeb)) return
    const maxHeight = screenHeight - measure.pageY
    const maxWidth = screenWidth - measure.pageX
    const newStyle: typeof maxBounds = { maxHeight, maxWidth, flex: 1 }
    setMaxBounds(newStyle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenHeight, screenWidth, measure, webOnly])

  return <View collapsable={false} ref={ref} onLayout={onLayout} style={useFlatStyle(maxBounds, style)} {...props} />
})
