import { updateFarm } from '@api/Farms'
import { uploadImageAsync } from '@api/FirebaseStorage'
import { AddImages, Image, ImageSelect, PhoneInput, ResizedSuffix, ToolTips } from '@components'
import {
  Alert,
  ColorPicker,
  Divider,
  ErrorText,
  FormButton,
  FormInput,
  FormPickerInput,
  Text,
  TextH1,
  TextH2,
  TextH3,
  TextH4,
  Toast,
  Tooltip,
} from '@elements'
import {
  capitalize,
  formatZipcode,
  mainNumPatternForms,
  marshalPhoneNumber,
  unmarshalPhoneNumber,
  websitePattern,
} from '@helpers/display'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { getAddressErrors, isPO } from '@models/Address'
import { Media } from '@models/shared/Media'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { SaveFormat } from 'expo-image-manipulator'
import { Formik, FormikConfig, FormikProps } from 'formik'
import * as React from 'react'
import { useMemo } from 'react'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import sha1 from 'sha1'
import * as Yup from 'yup'

import { Permission } from '@helpers/Permission'
import { globalStyles } from '../../constants/Styles'

import { AdminView } from '@/admin/components/AdminView'
import { TagInput } from '@/admin/components/TagInput'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { getShortState, stateItemList } from '@/assets/data/states'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { Farm, OnboardSteps, Properties } from '@/models/Farm'
import { adminFarmSelector, certificationSelector } from '@/redux/selectors'
import { formatToSafeSlug, getNameChangeOnUrlSafeSlugWarningText } from '@helpers/urlSafeSlug'
import { isErrorWithCode } from '@shared/Errors'
import { AdminCard } from '../components/AdminCard'
import { AdminHalfCardsContainer } from '../components/AdminCard/AdminHalfCardsContainer'
import InputLabel from '../components/InputLabel'

type FormGettingStarted = {
  name: string
  website?: string
  email: string
  phoneNumber?: string
  about?: string
  street1: string
  street2?: string
  city: string
  state: string
  zipcode: string
  media: Media[]
}

type FormBranding = {
  brandColor: string
  accentColor: string
  logo: string
  properties?: Properties
  practices: string[]
}

type FarmSocial = {
  socialInstagram?: string
  socialFacebook?: string
  socialTwitter?: string
}

type FarmProfileType = FormGettingStarted & FormBranding & FarmSocial

const formatSocialHandle = (type: 'instagram' | 'twitter' | 'facebook', text: string) => {
  const prefix = `https://${type}.com/`
  const pattern = new RegExp(`(https?://)?([w.]*)${type}.com/?`, 'ig')
  const strippedText = text.replace(pattern, '')
  return prefix + strippedText
}

const stripSocialHandle = (type: 'instagram' | 'twitter' | 'facebook', text: string) => {
  const pattern = new RegExp(`(https?://)?([w.]*)${type}.com/?`, 'ig')
  const strippedText = text.replace(pattern, '')
  if (strippedText.length === 0) return ''
  return text
}

// Will check that each media item on the farm is valid and remove it if not
function getValidMedia(media: Media) {
  if (!media.hasOwnProperty('storageUrl')) return false
  if (!media.hasOwnProperty('type')) return false
  if (!media.storageUrl) return false
  if (media.storageUrl === '') return false
  return media.storageUrl.includes('http://') || media.storageUrl.includes('https://')
}

const validationFarmProfileSetUpSchema = Yup.object<FarmProfileType>().shape({
  name: Yup.string().trim().min(2, 'Name must be valid').trim().label('Farm Name').required(),
  website: Yup.string().trim().matches(websitePattern, 'Please enter a valid website.').label('Website'),
  email: Yup.string().label('Email').trim().email().required(),
  phoneNumber: Yup.string().matches(mainNumPatternForms, 'Please enter a valid phone number.').label('Phone Number'),
  about: Yup.string().trim().label('About'),
  street1: Yup.string()
    .trim()
    .min(2, 'Street Address must be valid')
    .label('Street Address 1')
    .required()
    .test(
      'no PO boxes',
      'PO Boxes are not allowed',
      (value: any): boolean => nonEmptyString(value) && !isPO({ street1: value }),
    ),
  street2: Yup.string()
    .trim()
    .label('Street Address 2')
    .test('no PO boxes', 'PO Boxes are not allowed', (value: any): boolean =>
      nonEmptyString(value) ? !isPO({ street1: '', street2: value }) : true,
    ),
  city: Yup.string().trim().label('City').required(),
  state: Yup.string()
    .trim()
    .min(2, 'Please select a state')
    .max(2, 'Please select a state')
    .label('State')
    .test(
      'validate-state',
      'Please select a valid state',
      (value: any): boolean => nonEmptyString(value) && !!getShortState(value),
    )
    .required(),
  zipcode: Yup.string()
    .label('Zip code')
    .required()
    .min(5, 'Zip code must be 5 digits')
    .max(5, 'Zip code must be 5 digits'),
  media: Yup.array<Media>().label('Images').min(1, 'Must upload at least one image').required(),
  brandColor: Yup.string()
    .matches(/#[a-fA-F0-9]{6}/, 'Please enter a valid primary color.')
    .label('Primary Color')
    .required(),
  accentColor: Yup.string()
    .matches(/#[a-fA-F0-9]{6}/, 'Please enter a valid accent color.')
    .label('Accent Color')
    .required(),
  logo: Yup.string().label('Logo').required(),
  properties: Yup.object(),
  practices: Yup.array().label('Practices').defined(),
  socialInstagram: Yup.string()
    .trim()
    .test('validate-handle', 'Please enter a valid instagram url', (value) =>
      Boolean(value && value.indexOf('https://instagram.com/') > -1),
    )
    .label('@Instagram_Username'),
  socialFacebook: Yup.string()
    .trim()
    .test('validate-handle', 'Please enter a valid facebook url', (value) =>
      Boolean(value && value.indexOf('https://facebook.com/') > -1),
    )
    .label('@Facebook_Username'),
  socialTwitter: Yup.string()
    .trim()
    .test('validate-handle', 'Please enter a valid twitter url', (value) =>
      Boolean(value && value.indexOf('https://twitter.com/') > -1),
    )
    .label('@Twitter_Username'),
})

const buildFarmFromFormik = (
  values: FarmProfileType,
  farmOnboardSteps: Farm['onboardSteps'],
): Partial<Omit<Farm, 'address'>> & { address: Omit<Farm['address'], 'coordinate'> } => {
  // Update farm profile step completed
  const onboardSteps: Partial<Record<OnboardSteps, boolean>> = {
    ...farmOnboardSteps,
    [OnboardSteps.CLAIM]: true,
    [OnboardSteps.FARM_PROFILE]: true,
  }

  return {
    about: values.about,
    practices: values.practices,
    email: values.email.trim(),
    phoneNumber: values.phoneNumber?.trim() || '',
    socialmedia: {
      instagram: stripSocialHandle('instagram', values.socialInstagram || ''),
      facebook: stripSocialHandle('facebook', values.socialFacebook || ''),
      twitter: stripSocialHandle('twitter', values.socialTwitter || ''),
    },
    name: values.name.trim(),
    nameUppercase: values.name.toUpperCase(),
    address: {
      street1: capitalize(values.street1),
      street2: capitalize(values.street2 || ''),
      city: capitalize(values.city),
      state: values.state,
      zipcode: formatZipcode(values.zipcode),
      country: 'USA',
    },
    onboardSteps,
    website: values.website?.trim(),
    media: values.media,
    properties: values.properties,
    logo: values.logo,
    branding: {
      brand: values.brandColor,
      accent: values.accentColor,
    },
  }
}

const mapFarmToFormik = (farm: Farm): FarmProfileType => {
  return {
    media: farm.media.filter(getValidMedia) || [],
    name: farm.name || '',
    email: farm.email || '',
    phoneNumber: farm.phoneNumber ? unmarshalPhoneNumber(farm.phoneNumber, false) : '',
    website: farm.website || '',
    about: farm.about || '',
    practices: farm.practices || [],
    properties: farm.properties || {},
    socialInstagram: formatSocialHandle('instagram', farm.socialmedia?.instagram || ''),
    socialFacebook: formatSocialHandle('facebook', farm.socialmedia?.facebook || ''),
    socialTwitter: formatSocialHandle('twitter', farm.socialmedia?.twitter || ''),
    street1: capitalize(farm.address.street1 || ''),
    street2: capitalize(farm.address.street2 || ''),
    city: capitalize(farm.address.city) || '',
    state: getShortState(farm.address.state),
    zipcode: formatZipcode(farm.address.zipcode) || '',
    logo: farm.logo || '',
    brandColor: farm.branding?.brand || Colors.green,
    accentColor: farm.branding?.accent || Colors.brown,
  }
}

function CreateFarmProfile() {
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()
  const { isMedDevice } = useDeviceSize()

  const farm = useSelector(adminFarmSelector)
  const practices = useSelector(certificationSelector)

  const initial = useMemo(() => mapFarmToFormik(farm), [farm])

  const onSubmit: FormikConfig<FarmProfileType>['onSubmit'] = async (values) => {
    if (values.phoneNumber) values.phoneNumber = marshalPhoneNumber(values.phoneNumber, false) || ''

    // This type allows all fields of a farm to be updated, but makes sure that coordinates are not passed to the update
    // function as those will be computed by the update function
    const newFarm: Partial<Omit<Farm, 'address'>> & { address: Omit<Farm['address'], 'coordinate'> } =
      buildFarmFromFormik(values, farm.onboardSteps)

    const addressErrs = getAddressErrors(newFarm.address!, { allowPO: false })
    if (addressErrs) {
      return Alert(
        'Need address',
        `Please add a valid farm address to proceed.\n\nErrors:\n\n${Object.values(addressErrs).join(' | ')}`,
      )
    }

    // Upload the logo
    if (newFarm.logo && !newFarm.logo.includes('http://') && !newFarm.logo.includes('https://')) {
      const res = await uploadImageAsync(`${farm.id}/logo.png`, { storageUrl: newFarm.logo, type: 'image' })
      newFarm.logo = res.storageUrl
    }
    // Upload all images
    if (newFarm.media) {
      newFarm.media = await Promise.all(
        newFarm.media.map((m: Media) => {
          // Check if image is already hosted
          if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return m
          else return uploadImageAsync(sha1(m.storageUrl), m)
        }),
      )
    }

    let proceed = true

    // Show a warning if the farm name has changed
    if (formatToSafeSlug(newFarm.name!) !== formatToSafeSlug(farm.name)) {
      proceed = await new Promise((resolve) =>
        Alert('Name Change warning', getNameChangeOnUrlSafeSlugWarningText('farms'), [
          { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
          { text: 'Continue', onPress: () => resolve(true) },
        ]),
      )
    }

    if (!proceed) return

    await updateFarm({ ...newFarm, id: farm.id })
      .then(() => {
        Logger.debug(farm.id + ' was updated')
        Toast('Farm profile updated successfully.')
        if (farm.onboardSteps && !farm.onboardSteps[OnboardSteps.PAYMENTS]) {
          navigation.navigate('Onboard')
        }
      })
      .catch((err) => {
        if (isErrorWithCode(err)) {
          Alert('Error updating farm details', errorToString(err))
        } else {
          Alert(
            'Error updating farm details',
            'There was an error updating your farms details, please check everything is correct and try again.',
          )
        }
        Logger.error('Error updating Farm details', err)
      })
  }

  return (
    <AdminView>
      <View style={styles.headerStyle}>
        <TextH1>
          Farm Profile <Tooltip title="Farm Profile" id={ToolTips.CREATE_FARM_PROFILE} />
        </TextH1>

        <Text center>Complete the steps below to get your farm active on GrownBy and start getting sales.</Text>
      </View>

      <Formik
        enableReinitialize
        initialValues={initial}
        onSubmit={onSubmit}
        validationSchema={validationFarmProfileSetUpSchema}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }: FormikProps<FarmProfileType>) => (
          <>
            <View style={globalStyles.alignCenter}>
              {/* Start section */}
              <AdminHalfCardsContainer>
                <View>
                  <TextH2>Basic Info</TextH2>
                  <FormInput
                    placeholder="Farm Name"
                    value={values.name}
                    onChangeText={handleChange('name')}
                    onBlur={handleBlur('name')}
                    errorMessage={touched.name ? errors.name : ''}
                    rightIcon={
                      touched.name && errors.name
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                  <FormInput
                    placeholder="Website"
                    value={values.website}
                    onChangeText={handleChange('website')}
                    onBlur={handleBlur('website')}
                    errorMessage={touched.website ? errors.website : ''}
                    rightIcon={
                      touched.website && errors.website
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                  <FormInput
                    placeholder="Email"
                    label={<InputLabel label="Email" tooltipId={ToolTips.EMAIL} tooltipTitle="Email" />}
                    value={values.email}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    errorMessage={touched.email ? errors.email : ''}
                    rightIcon={
                      touched.email && errors.email
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                  <PhoneInput
                    value={values.phoneNumber}
                    maskHandler={handleChange('phoneNumber')}
                    onBlur={handleBlur('phoneNumber')}
                    errorMessage={errors.phoneNumber}
                    renderErrorMessage={touched.phoneNumber}
                  />
                  <FormInput
                    multiline
                    numberOfLines={8}
                    placeholder="Farm Description"
                    errorMessage={touched.about ? errors.about : ''}
                    value={values.about}
                    onChangeText={handleChange('about')}
                    onBlur={handleBlur('about')}
                    rightIcon={
                      touched.about && errors.about
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                </View>
                <View>
                  <TextH2>Address</TextH2>

                  <FormInput
                    placeholder="Street Address 1"
                    label={<InputLabel label="Street Address 1" tooltipId={ToolTips.ADDRESS} tooltipTitle="Address" />}
                    value={values.street1}
                    onChangeText={handleChange('street1')}
                    onBlur={handleBlur('street1')}
                    errorMessage={touched.street1 ? errors.street1 : ''}
                    rightIcon={
                      touched.street1 && errors.street1
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                  <FormInput
                    placeholder="Street Address 2"
                    value={values.street2}
                    onChangeText={handleChange('street2')}
                    onBlur={handleBlur('street2')}
                    errorMessage={touched.street2 ? errors.street2 : ''}
                    rightIcon={
                      touched.street2 && errors.street2
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />

                  <FormInput
                    placeholder="City"
                    value={values.city}
                    onChangeText={handleChange('city')}
                    onBlur={handleBlur('city')}
                    errorMessage={touched.city ? errors.city : ''}
                    rightIcon={
                      touched.city && errors.city
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                  <FormPickerInput
                    label="State"
                    value={values.state}
                    placeholder="Select a State..."
                    items={stateItemList()}
                    onValueChange={handleChange('state')}
                  />
                  {touched.state && errors.state ? <ErrorText>{errors.state}</ErrorText> : <></>}
                  <FormInput
                    placeholder="Zip Code"
                    value={values.zipcode}
                    keyboardType="number-pad"
                    maxLength={5}
                    onChangeText={handleChange('zipcode')}
                    onBlur={handleBlur('zipcode')}
                    errorMessage={touched.zipcode ? errors.zipcode : ''}
                    rightIcon={
                      touched.zipcode && errors.zipcode
                        ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                        : false
                    }
                  />
                </View>
              </AdminHalfCardsContainer>

              {/* Certification section */}
              <AdminCard>
                <TextH2 style={styles.sectionHeader}>
                  Add a Certification Badge <Tooltip title="Certifications" id={ToolTips.CERTIFICATIONS} />
                </TextH2>
                <Text center>Select any third party certifications your farm has obtained.</Text>
                <View style={globalStyles.alignCenter}>
                  <View style={styles.practiceCont}>
                    {practices.length ? (
                      practices.map((practice) => (
                        <TouchableOpacity
                          key={practice.title}
                          style={styles.badgeCont}
                          onPress={() => {
                            const index = values.practices.indexOf(practice.id)
                            if (index > -1) values.practices.splice(index, 1)
                            else values.practices.push(practice.id)
                            setFieldValue('practices', values.practices)
                          }}
                        >
                          <Image
                            source={{ uri: practice.badge }}
                            resizeSuffix={ResizedSuffix.NONE}
                            style={[
                              styles.practiceBadge,
                              values.practices.indexOf(practice.id) !== -1 && styles.badgeSelected,
                            ]}
                          />

                          <TextH4 center>{practice.title}</TextH4>
                        </TouchableOpacity>
                      ))
                    ) : (
                      <TextH3>Loading Practices...</TextH3>
                    )}
                  </View>
                  {touched.practices && !!errors.practices && <ErrorText>{errors.practices}</ErrorText>}
                </View>
              </AdminCard>

              <Divider clear />

              {/* Images section */}
              <AdminCard>
                <TextH2>Photos {values.media.length + '/5'}</TextH2>
                <TextH4 color={Colors.shades['200']}>
                  Please upload between 1 and 5 images. Recommended Aspect Ratio is 3:2 (ex. 1200x800)
                </TextH4>
                <AddImages
                  media={values.media}
                  setMedia={(data) => {
                    setFieldValue('media', data)
                  }}
                  maxImages={5}
                />
                {touched.media && !!errors.media && <ErrorText>{errors.media as string}</ErrorText>}
              </AdminCard>
            </View>

            {/* Branding section */}
            <View style={globalStyles.alignCenter}>
              <AdminHalfCardsContainer>
                <>
                  <View style={globalStyles.flexRowCenter}>
                    <TextH2>Branding</TextH2>
                    <Tooltip id={ToolTips.BRANDING} title="Farm Branding" style={styles.marginLeft} />
                  </View>
                  <View style={brandingCont(isMedDevice)}>
                    <View style={brandingTextCon(isMedDevice)}>
                      <Text type="medium">Logo or Shop Image</Text>
                      <Text>Recommended Aspect Ratio is 1:1 (ex. 200x200).</Text>
                      <Text>Max size is 500x500.</Text>
                      {!!touched.logo && !!errors.logo && <ErrorText>{errors.logo}</ErrorText>}
                      <View style={styles.imgSelectContainer}>
                        <ImageSelect
                          imageType="logo"
                          defaultImage={values.logo}
                          imageStyle={styles.borderRadius}
                          editOptions={{
                            width: 200,
                            height: 200,
                            format: SaveFormat.PNG,
                            forceAspect: true,
                          }}
                          onChange={(image) => setFieldValue('logo', image)}
                        />
                      </View>
                    </View>

                    <View>
                      <Text type="medium">Brand Color</Text>
                      <ColorPicker
                        initial={values.brandColor}
                        onChange={(color) => setFieldValue('brandColor', color)}
                      />
                      {touched.brandColor && !!errors.brandColor && <ErrorText>{errors.brandColor}</ErrorText>}

                      <Divider clear />
                      <Text type="medium">Accent Color</Text>
                      <ColorPicker
                        initial={values.accentColor}
                        onChange={(color) => setFieldValue('accentColor', color)}
                      />
                      {touched.accentColor && !!errors.accentColor && <ErrorText>{errors.accentColor}</ErrorText>}
                    </View>
                  </View>
                </>
                <View>
                  <TagInput values={values.properties ?? {}} onUpdate={(tags) => setFieldValue('properties', tags)} />
                  {touched.properties && !!errors.properties && <ErrorText>{errors.properties}</ErrorText>}
                </View>
              </AdminHalfCardsContainer>
            </View>

            <Divider clear />

            {/* social media section */}
            <AdminCard>
              <TextH2 style={styles.sectionHeader}>Social media handles and feeds</TextH2>

              <FormInput
                label="Instagram"
                placeholder="Paste your Instagram URL Feed"
                errorStyle={{ color: Colors.red }}
                errorMessage={touched.socialInstagram ? errors.socialInstagram : ''}
                value={values.socialInstagram}
                onChangeText={(text) => setFieldValue('socialInstagram', formatSocialHandle('instagram', text))}
                onBlur={handleBlur('socialInstagram')}
              />
              <FormInput
                label="Facebook"
                placeholder="Paste your Facebook URL Feed"
                errorStyle={{ color: Colors.red }}
                errorMessage={touched.socialFacebook ? errors.socialFacebook : ''}
                value={values.socialFacebook}
                onChangeText={(text) => setFieldValue('socialFacebook', formatSocialHandle('facebook', text))}
                onBlur={handleBlur('socialFacebook')}
              />
              <FormInput
                label="Twitter"
                placeholder="Paste your Twitter URL Feed"
                errorStyle={{ color: Colors.red }}
                errorMessage={touched.socialTwitter ? errors.socialTwitter : ''}
                value={values.socialTwitter}
                onChangeText={(text) => setFieldValue('socialTwitter', formatSocialHandle('twitter', text))}
                onBlur={handleBlur('socialTwitter')}
              />
            </AdminCard>

            {/* Button container */}
            <View style={styles.containerRow}>
              {errors &&
                Object.keys(errors).length > 0 &&
                Object.values(errors).map((error, index) => <ErrorText key={index}>{error as string}</ErrorText>)}
              <FormButton
                style={styles.submitButton}
                title={isSubmitting ? 'Submitting' : 'Submit'}
                disabled={isSubmitting ? isSubmitting : false}
                onPress={handleSubmit}
              />
            </View>
          </>
        )}
      </Formik>
    </AdminView>
  )
}

const brandingCont = (isMedDevice: boolean): ViewStyle => ({
  flexDirection: isMedDevice ? 'column' : 'row',
  justifyContent: 'space-around',
  marginTop: 20,
  flexWrap: 'wrap',
})

const brandingTextCon = (isMedDevice: boolean): ViewStyle => ({
  maxWidth: isMedDevice ? 280 : 500,
})

export default withAdminAuth(CreateFarmProfile, Permission.AccountSetup)

const styles = StyleSheet.create({
  imgSelectContainer: {
    width: 100,
    height: 100,
  },
  headerStyle: {
    alignItems: 'center',
    margin: 20,
  },
  sectionHeader: { alignSelf: 'center', marginVertical: 20 },
  practiceCont: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  badgeCont: {
    borderRadius: 75,
    width: 150,
    margin: 10,
  },
  practiceBadge: {
    borderRadius: 75,
    width: 150,
    height: 150,
    marginBottom: 5,
  },
  badgeSelected: {
    borderColor: Colors.green,
    borderWidth: 4,
  },
  containerRow: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
  submitButton: {
    width: 200,
  },
  borderRadius: {
    borderRadius: 50,
  },
  marginLeft: {
    marginLeft: 10,
  },
})
