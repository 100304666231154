import { useCartService } from '@/hooks/useCart'
import { DraftOrder } from '@models/DraftOrder'
import { useCustomerData_reusable } from '../OrderCreatorScreen/useCustomerData-reusable'

/** Handles customer related state for order editing */
export function useCustomerData({ draft }: { draft: DraftOrder | undefined }) {
  const { cart } = useCartService({
    cartServiceType: 'orderEdit',
    farmId: draft?.farm.id || '',
    isWholesale: draft?.isWholesale,
  })

  return useCustomerData_reusable({
    cart,
    farmId: draft?.farm.id || '',
    cartServiceType: 'orderEdit',
    custId: draft?.user.id,
  })
}
