import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { InputProps } from 'react-native-elements'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip/Tooltip'
import { ToolTips } from '../Tooltip/ToolTips'

export type FormInputLabelProps = {
  labelContainerStyle?: StyleProp<ViewStyle>
  /** Will add a tooltip if specified */
  tooltipId?: ToolTips
  /** Indicates if the label is required. Will add an `*` after the label text */
  required?: boolean
} & Pick<InputProps, 'labelProps' | 'labelStyle' | 'label'>

/** FormInputLabel component renders a label with optional tooltip and required indicator. */
export function FormInputLabel({
  label,
  labelContainerStyle,
  labelProps,
  labelStyle,
  tooltipId,
  required,
}: FormInputLabelProps) {
  // Return the component if it's undefined or React Node
  if (typeof label !== 'string') return label

  return (
    <View style={[styles.main, labelContainerStyle]}>
      <View style={styles.innerCont}>
        <Text style={[styles.label, labelStyle]} {...labelProps}>
          {label}
          {required && '*'}
        </Text>
        {tooltipId && <Tooltip id={tooltipId} />}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    minHeight: 30,
    justifyContent: 'flex-end',
  },
  label: {
    fontSize: 16,
  },
  innerCont: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
})
