import { Button, LoadingView } from '@elements'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'

type ActionProps = {
  /** This will handle updating the customers cart and taking them to the appropriate screen. */
  onConfirmPress: (type: 'edit' | 'confirm') => Promise<void>
}

/** Renders buttons to allow the user to edit or confirm the share. */
export function SaveButtons({ onConfirmPress }: ActionProps) {
  const [isSavingShare, setIsSavingShare] = useState(false)

  const confirmCustomShare = async (type: 'edit' | 'confirm') => {
    // We handle the loading at this component level because this has all the actions so it can disable them correctly here
    setIsSavingShare(true)
    await onConfirmPress(type)
    setIsSavingShare(false)
  }

  return (
    <View style={styles.actionContainer}>
      <LoadingView loading={isSavingShare} style={styles.buttonContainer} noDefaultLoadingContainerStyle>
        <Button outline title="Add or Edit Products" onPress={() => confirmCustomShare('edit')} />
        <Button title="Looks Good to Me!" onPress={() => confirmCustomShare('confirm')} />
      </LoadingView>
    </View>
  )
}

const styles = StyleSheet.create({
  actionContainer: {
    alignItems: 'flex-end',
  },
  buttonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
})
