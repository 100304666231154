import { DateTime } from 'luxon'

/** cacheMinute means minute is rounded to 00 or 30 instead of its original minute.
 * @param isUsingCache - If true, it will round minute to 00 or 30, otherwise it will show the original minute. By default, it is false.
 */
export function getCacheMinute(dateTime: DateTime, isUsingCache = false): DateTime {
  let localDateTime: DateTime

  if (isUsingCache) {
    // Round minute to 00 or 30
    const minutes = dateTime.minute < 30 ? 0 : 30
    localDateTime = dateTime.set({ minute: minutes, second: 0, millisecond: 0 })
  } else {
    localDateTime = dateTime
  }

  return localDateTime
}
