import Colors from '@/constants/Colors'
import { useCartService } from '@/hooks/useCart'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { wholesaleSelector } from '@/redux/selectors'
import { ButtonClear, Text } from '@elements'
import { formatAddress, formatPickupDate, formatPickupTime, plural } from '@helpers/display'
import { formatDistributionType } from '@helpers/location'
import { Distribution } from '@models/Distribution'
import { isLocalPickup, isNonPickup } from '@models/Location'
import { CartPhysical, isCartItemNonPickup } from '@models/Order'
import { hasUnits, isShare } from '@models/Product'
import { useCallback } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import { ModifyDatesButton } from './ModifyDatesButton'

type ProductDistroDetailsProps = {
  item: CartPhysical
  setQuantity: (delta: number) => Promise<void>
  loading?: boolean | undefined
}

/** limits the amount of schedules that will show in the CartItem if the standard product has more than 3 selected schedules */
const showPickupDatesLimit = 3

/** Product Distribution  Section of Cart Item */
export function ProductDistroDetails({ item }: ProductDistroDetailsProps) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { cart } = useCartService({ cartServiceType: 'consumer', isWholesale, farmId: item.product.farm.id })
  const styles = useStyles()
  const { modifyDates, editDeliveryAddress } = useAddToCartFlow({ cartServiceType: 'consumer', isWholesale })

  const { distribution, pickups, product } = item

  const allPickupsDates = pickups ? pickups : []
  const limitedPickupDates = allPickupsDates.length > 0 ? allPickupsDates.slice(0, showPickupDatesLimit) : []
  const plusNumOfPickupsLeft = allPickupsDates.length - showPickupDatesLimit

  const displayLocalAddress = useCallback((distribution: Distribution): string => {
    if (isLocalPickup(distribution.location)) {
      return `${distribution.location.name} - ${formatAddress(distribution.location.address)}`
    }
    if (isNonPickup(distribution.location)) {
      return `${distribution.location.address ? formatAddress(distribution.location.address) : ''}`
    }
    return distribution.location.name
  }, [])

  return (
    <View style={styles.productDistroDetailsWrapper}>
      <View style={styles.detailContainer}>
        <Text type="medium" size={16}>
          {formatDistributionType(distribution.location, { capitalize: true, action: true })}{' '}
          {plural(allPickupsDates.length, 'Date')}
        </Text>
        {hasUnits(product) && limitedPickupDates.length > 0 ? (
          <>
            {limitedPickupDates.map((p) => (
              <Text key={item.id + p.toISODate()}>
                {`${formatPickupDate(p)} ${formatPickupTime(distribution.schedule.hours, distribution.location.type)}`}
              </Text>
            ))}
            {allPickupsDates.length > showPickupDatesLimit && <Text type="medium">+ {plusNumOfPickupsLeft} more</Text>}
          </>
        ) : isShare(product) ? (
          <Text color={Colors.black} style={styles.pickupAndLocationDetailsText}>
            {`Starting ${formatPickupDate(pickups[0])}`}
          </Text>
        ) : null}
        {/* If we're in wholesale we won't allow modifying dates unless there is a single item in the cart */}
        {(isWholesale === false || cart.length === 1) && <ModifyDatesButton item={item} modifyDates={modifyDates} />}
      </View>

      <View style={styles.detailContainer}>
        <Text type="medium" size={16}>
          {formatDistributionType(distribution.location, { capitalize: true, action: true })} Location
        </Text>
        <Text color={Colors.black} type="regular" style={styles.pickupAndLocationDetailsText}>
          {displayLocalAddress(distribution)}
        </Text>
        {isCartItemNonPickup(item) && (
          <ButtonClear
            style={styles.editDeliveryAddressButtonContainer}
            size={14}
            title="Edit"
            onPress={() => editDeliveryAddress(item)}
          />
        )}
      </View>
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    productDistroDetailsWrapper: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      paddingVertical: 18,
      paddingRight: 20,
    },
    detailContainer: {
      marginVertical: 5,
      flex: 1,
      flexDirection: 'column',
    },
    pickupAndLocationDetailsText: {
      marginTop: 4,
    },
    editDeliveryAddressButtonContainer: {
      padding: 0,
      margin: 0,
    },
  },
  {
    [maxSize(DEVICE_SIZES.XS)]: {
      productDistroDetailsWrapper: {
        marginHorizontal: 12,
        marginBottom: 12,
        paddingVertical: 0,
        paddingRight: 0,
      },
    },
  },
)
