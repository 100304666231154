import Colors from '@/constants/Colors'
import { ShoppingStackParamList } from '@/navigation/types'
import { Image } from '@components'
import { Button, ButtonClear, Text } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { cartSubtotal } from '@helpers/order'
import { Farm } from '@models/Farm'
import { isMoney } from '@models/Money'
import { CartItem, isCartShare } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useMemo } from 'react'
import { View, StyleSheet } from 'react-native'
import { ListItem } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../../../../../redux/selectors'
import { CartItemComponent } from '../CartItemComponent'
import { FarmMinimum } from '../components/FarmMinimum'
import { formatMoney } from '@helpers/display'
import { globalStyles } from '../../../../../constants/Styles'

type Props = {
  /** The farm to show */
  farm: Farm
  /** Cart items that belong to a farm*/
  items: CartItem[]
  /** Whether the section that contains the cart items is expanded or not
   * If null, the collapse / expand behaviour will not be present
   */
  isExpanded: boolean | null
  /** When the collapse / expand button is pressed */
  onToggleExpanded: (farmId: string) => void
  /** Checkout button press callback */
  onCheckoutPress: (farmId: string) => void
  /** Whether the checkout button should be on loading state */
  loading: boolean
  /** Whether there are multiple farms in the cart */
  multipleExistingFarms: boolean
}

/** Card compoennt that is showing farm info and expandable cart items related to that farm*/
export const FarmCartGroup = memo(function FarmCartGroup({
  farm,
  items,
  isExpanded,
  onToggleExpanded,
  onCheckoutPress,
  loading,
  multipleExistingFarms,
}: Props) {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'MyCart'>>()
  const { isWholesale } = useSelector(wholesaleSelector)

  const orderMin = useMemo(() => {
    const value = farm.orderMinimum?.[isWholesale ? 'wholesale' : 'retail']
    return isMoney(value) ? value : undefined
  }, [farm.orderMinimum, isWholesale])

  // Only include standard product in the calculation because shares can be purchased even if they don't meet the minimum
  const standardProdSubtotal = useMemo(() => {
    return cartSubtotal(
      items.filter((itm) => !isCartShare(itm)),
      farm.id,
      { isWholesale: !!isWholesale, excludeClosedDistros: true },
    )
  }, [items, farm.id, isWholesale])

  // Only include standard product in the calc
  const subtotal = useMemo(
    () => cartSubtotal(items, farm.id, { isWholesale: !!isWholesale, excludeClosedDistros: true }),
    [items, farm.id, isWholesale],
  )

  /** Should disable checkout button if we have an order minimum and standard products and they don't reach the minimum */
  const disableCheckout =
    orderMin && MoneyCalc.isGTZero(standardProdSubtotal) && MoneyCalc.isLessThan(standardProdSubtotal, orderMin)

  const onToggle = useCallback(() => {
    onToggleExpanded(farm.id)
  }, [farm.id, onToggleExpanded])

  const onCheckout = useCallback(() => {
    onCheckoutPress(farm.id)
  }, [farm.id, onCheckoutPress])

  const viewProduct = useCallback(
    (item: CartItem) => {
      navigation.navigate('ProductDetails', {
        productId: item.product.id,
        farmSlug: item.product.farm.id,
        csaId: item.csa?.id,
      })
    },
    [navigation],
  )

  const onContinueShoppingPress = useCallback(() => {
    navigation.navigate('FarmShop', { farmSlug: farm.urlSafeSlug })
  }, [farm.urlSafeSlug, navigation])

  return (
    <View style={styles.main}>
      <View style={styles.headerCont}>
        <View style={styles.farmDetailsWrapper}>
          <Image type="logo" source={{ uri: farm.logo }} style={styles.farmProfile} />
          <Text size={16} type="medium">
            {farm.name}
          </Text>
        </View>
        <View style={styles.amountContainer}>
          <Text type="medium" size={14} style={globalStyles.marginVertical10}>
            Order Subtotal: {formatMoney(subtotal)}
          </Text>
          {orderMin && (
            <FarmMinimum
              orderMin={orderMin}
              subtotal={standardProdSubtotal}
              hasNonStandard={!MoneyCalc.isEqual(subtotal, standardProdSubtotal)}
            />
          )}
        </View>
      </View>

      {/* If expanded is set to null then it means that we don't want accordion behaviour so will just show the items.
       * This should happen when it is a single cart group */}
      {isExpanded === null ? (
        <View style={styles.itemsWrapper}>
          {items.map((item) => (
            <CartItemComponent key={item.id} item={item} viewProduct={viewProduct} />
          ))}
        </View>
      ) : (
        <ListItem.Accordion
          isExpanded={isExpanded}
          onPress={onToggle}
          content={<Text>{`${isExpanded ? 'Collapse' : 'Expand'} ${items.length} items`}</Text>}
        >
          <View style={styles.itemsWrapper}>
            {items.map((item) => (
              <CartItemComponent key={item.id} item={item} viewProduct={viewProduct} />
            ))}
          </View>
        </ListItem.Accordion>
      )}

      <View style={styles.btnsCont}>
        <ButtonClear onPress={onContinueShoppingPress} title="Continue shopping" />
        <Button
          disabled={disableCheckout}
          loading={loading}
          onPress={onCheckout}
          title={multipleExistingFarms ? 'Checkout this farm' : 'Checkout'}
        />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 20,
    gap: 10,
  },
  headerCont: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  amountContainer: {
    alignItems: 'flex-end',
  },
  farmProfile: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  itemsWrapper: {
    gap: 10,
  },
  btnsCont: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  farmDetailsWrapper: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    flex: 1,
    minWidth: 250,
  },
})
