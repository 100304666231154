import { Logger } from '@/config/logger'
import { claimFarm, loadFarm, setFarmVerificationCode } from '@api/Farms'
import { TextInputBox } from '@components'
import { Button, Icon, LoadingView, SafeAreaView, TextH1, TextH4, TextLink, Toast } from '@elements'
import { openUrl } from '@helpers/client'
import { FarmAssociation, isFarmAdmin } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import { Formik, FormikProps } from 'formik'
import { useCallback, useState } from 'react'
import { BackHandler, ScrollView, StyleSheet, Text, View } from 'react-native'
import { SearchBarProps } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { adminCard } from '../../admin/constants/AdminCards'
import Colors from '../../constants/Colors'
import { isWeb } from '../../constants/Layout'
import { globalStyles } from '../../constants/Styles'
import { withAuth } from '../../hooks/withAuth'
import { ExternalLinkingParamList } from '../../navigation/types'
import { setCurrFarmAdmin } from '../../redux/actions/adminPersist'
import { RootState } from '../../redux/reducers/types'
import { adminFarmIdSelector, farmsSelector, userSelector } from '../../redux/selectors'

import { useApiFx } from '@/hooks/useApiFx'
import { useCancelableDeepCompareFx } from '@/hooks/useCancelablePromise'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useDeviceSize, useLayout } from '@/hooks/useLayout'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { SUPPORT_EMAIL } from '@shared/BaseUrl'
import { withBuyerBlock } from '../../hooks/withRetailCheck/withBuyerBlock'

type FormEmailCodeType = {
  code: string
}

const codeValidationSchema = Yup.object<FormEmailCodeType>().shape({
  code: Yup.string().required('This field is required'),
})

function FarmLandingScreenComp() {
  const dispatch = useDispatch()
  const {
    params: { farmSlug },
  } = useRoute<RouteProp<ExternalLinkingParamList, 'FarmLanding'>>()
  const navigation = useNavigation<StackNavigationProp<ExternalLinkingParamList, 'FarmLanding'>>()
  const user = useSelector(userSelector)
  const adminFarmId = useSelector(adminFarmIdSelector)
  const [screen, setScreen] = useState<'send' | 'verify'>('send')
  const [isLoading, setIsLoading] = useState(false)
  const farmAssociations = useSelector<RootState, FarmAssociation[]>(farmsSelector)
  const layout = useLayout()
  const { isSmallDevice, isLargeDevice } = useDeviceSize()

  const { data: farm, loading: loadingFarm, refresh: reloadFarm } = useApiFx(loadFarm, [farmSlug], !!farmSlug)

  useFocusFx(() => {
    // If we are on web then BackHandler is not supported and will throw an error. We should only add the listener on mobile.
    if (isWeb) return

    const onBackPress = () => {
      if (screen !== 'send') {
        setScreen('send')
        return true
      } else {
        return false
      }
    }

    BackHandler.addEventListener('hardwareBackPress', onBackPress)
    return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress)
  }, [screen])

  const goBack = useCallback(
    function () {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        const url = Linking.createURL('/explore')
        openUrl(url)
      }
    },
    [navigation],
  )

  /** Admin farm fx
   * When farm associations listens to changes, will check if the user is ALREADY an admin for the farm slug in params.
   * If so, it will dispatch it as the admin farm.
   *
   * This will dispatch the farm as the current admin farm in two cases.
   * 1. After claimFarm called successfully, which will update the farmAssociations through global listener
   * 2. The first time loading this screen, and the user is already an admin for this farm
   */
  useCancelableDeepCompareFx(async () => {
    if (!farm || !matchesIdOrSlug(farm, farmSlug)) return

    const fassoc = farmAssociations.find((fassoc) => isFarmAdmin(fassoc) && fassoc.id === farm.id)
    if (!fassoc) return

    Toast('Wait a moment while we redirect you to your farm...')

    //dispatching the admin farm will trigger the navigation fx
    dispatch(setCurrFarmAdmin(farm.id))
  }, [farmSlug, farm, farmAssociations, dispatch])

  /** Navigation fx
   * This will be triggered by dispatching the admin farm `dispatch(setCurrFarmAdmin(farm.id))`, which is handled by the Admin farm fx */
  useFocusFx(() => {
    if (matchesIdOrSlug(farm, farmSlug) && adminFarmId === farm?.id) {
      navigation.navigate('AdminDrawerNavigator', { screen: 'Onboard' })
    }
  }, [adminFarmId, farm, navigation, farmSlug])

  async function submitCode(values: { code: string }) {
    if (!farm || !matchesIdOrSlug(farm, farmSlug)) return

    const { code } = values
    setIsLoading(true)
    if (!farm.verificationCode || farm.verificationCode.code !== code) {
      Toast('Invalid verification code.')
    } else {
      await claimFarm(farm, user).catch(Logger.error)
    }
    setIsLoading(false)
  }

  async function generateVerificationCode() {
    setIsLoading(true)
    try {
      if (!farm) return

      await setFarmVerificationCode(farm?.id)
      setScreen('verify')
      reloadFarm()
    } catch (e: any) {
      Toast(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onFarmClaimedPress = () => {
    setScreen('verify')
  }

  return (
    <SafeAreaView style={globalStyles.flex1}>
      <Icon name="times" size={36} color={Colors.shades[500]} style={globalStyles.margin20} onPress={goBack} />
      <LoadingView loading={loadingFarm} success={farm} switchMode spinnerProps={{ size: 64 }}>
        {(farm) =>
          screen !== 'verify' ? (
            <ScrollView>
              <View style={[styles.container, adminCard(layout), { minHeight: 330 }]}>
                <View style={isLargeDevice ? { width: '45%', marginLeft: '5%' } : null}>
                  <TextH1 style={[styles.title, { marginTop: 30 }]}>{`Welcome ${farm.name}!`}</TextH1>
                  <Text style={styles.subtitle}>Thanks for joining our community of farmers!</Text>
                  <Text style={styles.subtitle}>In order to start selling on GrownBy, please verify your email.</Text>
                  <Button
                    loading={isLoading}
                    title="Send Verification Email"
                    onPress={generateVerificationCode}
                    style={{ maxWidth: 315, marginLeft: 0 }}
                  />
                  <TextLink onPress={onFarmClaimedPress}>Already have Verification code</TextLink>
                </View>
              </View>
              <View style={{ marginTop: 20, justifyContent: 'center', alignSelf: 'center' }}>
                <View style={{ flexDirection: isSmallDevice ? 'column' : 'row' }}>
                  <TextH4 style={[styles.helpText, { fontWeight: 'bold' }]}>Need help? </TextH4>
                  <View style={globalStyles.flexRow}>
                    <TextH4 style={styles.helpText}>Contact </TextH4>
                    <TextH4 style={[styles.helpText, { color: Colors.green, textDecorationLine: 'underline' }]}>
                      {SUPPORT_EMAIL}.
                    </TextH4>
                  </View>
                </View>
              </View>
            </ScrollView>
          ) : (
            <ScrollView>
              <View style={[styles.container, adminCard(layout), { minHeight: 300, justifyContent: 'center' }]}>
                <View style={{ marginHorizontal: 32 }}>
                  <Text style={styles.title}>Enter your verification code below.</Text>
                  <Formik initialValues={{ code: '' }} onSubmit={submitCode} validationSchema={codeValidationSchema}>
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched,
                    }: FormikProps<FormEmailCodeType>) => (
                      <>
                        <TextInputBox
                          numberOfLines={1}
                          onBlur={handleBlur('code') as SearchBarProps['onBlur']}
                          value={values.code}
                          containerStyle={{ maxWidth: 372, paddingHorizontal: 0 }}
                          placeholder="6-digit code"
                          keyboardType="number-pad"
                          onChangeText={handleChange('code')}
                        />
                        <Text style={{ color: Colors.red }}>{touched.code ? errors.code : ''}</Text>
                        <Button
                          loading={isLoading}
                          title="Submit"
                          onPress={() => handleSubmit()}
                          style={{ maxWidth: 150, marginBottom: 16, marginLeft: 0 }}
                        />
                      </>
                    )}
                  </Formik>

                  <View style={{ flexDirection: isLargeDevice ? 'row' : 'column' }}>
                    <Text style={styles.instruction}>A verification code was sent to {user?.email}. </Text>
                    <TextH4
                      style={[
                        styles.helpText,
                        {
                          color: Colors.green,
                          textDecorationLine: 'underline',
                          marginVertical: 8,
                          lineHeight: 20,
                        },
                      ]}
                      onPress={() => setScreen('send')}
                    >
                      Send again
                    </TextH4>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 20, justifyContent: 'center', flexDirection: 'row' }}>
                <View style={{ flexDirection: isSmallDevice ? 'column' : 'row' }}>
                  <TextH4 style={[styles.helpText, { fontWeight: 'bold' }]}>Need help? </TextH4>
                  <View style={globalStyles.flexRow}>
                    <TextH4 style={styles.helpText}>Contact </TextH4>
                    <TextH4 style={[styles.helpText, { color: Colors.green, textDecorationLine: 'underline' }]}>
                      {SUPPORT_EMAIL}.
                    </TextH4>
                  </View>
                </View>
              </View>
            </ScrollView>
          )
        }
      </LoadingView>
    </SafeAreaView>
  )
}

export const FarmLandingScreen = withBuyerBlock(withAuth(FarmLandingScreenComp), { blockWholesaleAuthorizedUser: true })

const styles = StyleSheet.create({
  container: { alignSelf: 'center' },
  title: { fontSize: 24, marginBottom: 16, fontWeight: 'bold' },
  subtitle: { fontSize: 18, fontStyle: 'normal', fontWeight: 'normal', lineHeight: 29, marginBottom: 26 },
  instruction: {
    marginVertical: 8,
    lineHeight: 20,
  },
  helpText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 17,
  },
  codeInput: {
    maxWidth: 372,
    height: 50,
    borderWidth: 1,
    borderColor: '#ABABAB',
    borderRadius: 10,
    marginBottom: 16,
  },
})
