import { ButtonClear } from '@elements'
import { useMessages } from '@screens/MessagesScreen/MessagesScreen.context'
import { memo } from 'react'

export const ConversationListFooter = memo(function ConversationListFooter({ hasItems }: { hasItems: boolean }) {
  const { canLoadMoreConversations, onFetchAllConversations, onlyUnread } = useMessages()

  if (!hasItems) return null

  /** Show load more for all messages tab only */
  const showLoadMore = !onlyUnread && canLoadMoreConversations

  if (!showLoadMore) return null
  return <ButtonClear title="Load more" onPress={onFetchAllConversations} />
})
