import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles, SHADOW_5 } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { ShoppingStackParamList } from '@/navigation/types'
import { FarmBadges, FavoriteFarm, Image, ResizedSuffix } from '@components'
import { BreadCrumbs, ButtonClear, HeaderText, ReadMore, Text } from '@elements'
import { hyperlinkOpenUrl } from '@helpers/links'
import shareContent from '@helpers/sharing'
import { Farm } from '@models/Farm'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { GROWNBY_APP_URL } from '@shared/BaseUrl'
import { memo, useCallback } from 'react'
import { View } from 'react-native'
import HyperLink from 'react-native-hyperlink'
import { FarmHeaderSnippet } from './components'
import { MAX_SHOP_WIDTH } from './helpers'
import { ShopHeaderSkeleton } from './ShopHeaderSkeleton'

type Props = {
  farm?: Farm
  onBackPress: () => void
}
export const FarmShopHeader = memo(function FarmShopHeader({ farm, onBackPress }: Props) {
  const { isLargeDevice, isSmallDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()

  const styles = useStyles()

  if (!farm) {
    return <ShopHeaderSkeleton />
  }

  const goToFarmDetail = () => {
    navigation.navigate('FarmDetailScreen', { farmSlug: farm.urlSafeSlug })
  }

  const images = isSmallDevice
    ? farm.media.slice(0, 1)
    : !isLargeDevice
    ? farm.media.slice(0, 2)
    : farm.media.slice(0, 3)
  return (
    <View
      /** This is not a useless View wrapper. It is necessary for providing a background to the container behind the main View, which has the max width */
      style={styles.bgGold}
    >
      <View style={styles.boundedContainer}>
        {(isWeb || isLargeDevice) && <BreadCrumbs goBack={onBackPress} />}
        <View style={styles.imgWrapper}>
          {images.map((media) => (
            <View style={styles.imgCont} key={media.storageUrl}>
              <Image
                type="farm"
                resizeSuffix={ResizedSuffix.LARGE}
                style={globalStyles.flex1}
                source={{ uri: media.storageUrl }}
              />
            </View>
          ))}
          {!isLargeDevice && (
            <View style={styles.farmLogoContSmall}>
              <View style={[styles.farmLogoSmall, { ...SHADOW_5 }]}>
                <Image type="logo" style={globalStyles.flex1} source={{ uri: farm.logo }} />
              </View>
            </View>
          )}
        </View>
        {!isLargeDevice ? (
          <View style={styles.smallContentContainer}>
            <HeaderText center size={24} numberOfLines={2}>
              {farm.name}
            </HeaderText>
            <View style={styles.smallInfoCont}>
              <Text>
                {farm.address.city}, {farm.address.state}
              </Text>
              <View style={globalStyles.margin10} />
              <FavoriteFarm farm={farm} />
            </View>
            <FarmBadges practices={farm.practices} />
            <ButtonClear url={`/farms/${farm?.id}`} title="About the farm" />
          </View>
        ) : (
          <View style={styles.contentContainer}>
            <FarmHeaderSnippet farm={farm} onPress={goToFarmDetail} />
            <CertificatesSection farm={farm} />
            <AboutSection farm={farm} onPress={goToFarmDetail} />
          </View>
        )}
      </View>
    </View>
  )
})

type CertificateProps = { farm: Farm }

function CertificatesSection({ farm }: CertificateProps) {
  return (
    <View style={globalStyles.flex1}>
      <Text numberOfLines={1} size={14} type="medium">
        {farm.practices.length ? 'Certifications & Reviews' : 'Reviews'}
      </Text>
      <FarmBadges practices={farm.practices} size={40} />
      <FavoriteFarm farm={farm} />
    </View>
  )
}

type AboutProps = {
  farm: Farm
  onPress: () => void
}

function AboutSection({ farm, onPress }: AboutProps) {
  const onSharePress = useCallback(() => {
    shareContent({
      url: `${GROWNBY_APP_URL}/farms/${farm.id}`,
      title: `${farm.name} on GrownBy: ${GROWNBY_APP_URL}/farms/${farm.id}`,
    })
  }, [farm.id, farm.name])

  return (
    <View style={globalStyles.flex1}>
      <Text size={14} type="medium">
        About
      </Text>
      <HyperLink linkStyle={{ color: Colors.blueLink }} onPress={hyperlinkOpenUrl}>
        <ReadMore numLines={3}>{farm.about.replace('\\n', '\n\n')}</ReadMore>
      </HyperLink>

      {!!farm.email && (
        <View style={globalStyles.flexRowCenter}>
          <ButtonClear icon="envelope" title="Contact" onPress={onPress} size={14} />
          <ButtonClear icon="share" title="Share" onPress={onSharePress} size={14} />
        </View>
      )}
    </View>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isSmallDevice, isExtraSmallDevice }) => ({
    bgGold: {
      backgroundColor: Colors.shadeGold,
    },
    boundedContainer: {
      paddingHorizontal: !isLargeDevice ? 0 : 10,
      paddingVertical: !isLargeDevice ? 0 : 20,
      maxWidth: MAX_SHOP_WIDTH,
      alignSelf: 'center',
      width: '100%',
    },
    imgCont: {
      flex: 1,
      borderRadius: isSmallDevice ? 0 : 10,
      overflow: 'hidden',
    },
    imgWrapper: {
      flexDirection: 'row',
      height: isExtraSmallDevice ? 200 : 300,
      justifyContent: 'space-between',
      gap: 10,
    },
    contentContainer: {
      marginTop: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 10,
    },
    smallContentContainer: {
      alignItems: 'center',
      marginVertical: 10,
      paddingHorizontal: 10,
    },
    farmLogoSmall: {
      width: 60,
      height: 60,
      borderRadius: 6,
      overflow: 'hidden',
      ...SHADOW_5,
      backgroundColor: Colors.white,
    },
    farmLogoContSmall: {
      position: 'absolute',
      bottom: 10,
      left: 0,
      right: 0,
      alignItems: 'center',
    },
    backButtonText: {
      fontSize: 14,
    },
    backButton: {
      alignSelf: 'flex-start',
    },
    smallInfoCont: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  }))
