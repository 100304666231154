import { loadProduct } from '@api/Products'
import { MessageWithIcon, ProductCardSquare, ToolTips } from '@components'
import { HeaderText, RowWrapper, SafeAreaView, Text, Toast, Tooltip } from '@elements'
import { getUnits, shouldShow } from '@helpers/products'
import { sortByIncluded, sortByName } from '@helpers/sorting'
import { Share, isPhysical, isShare } from '@models/Product'
import { Media } from '@models/shared/Media'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { AddCartBtn } from '../../../components/AddCartBtn'
import { PickupLocation } from '../../../components/AddToCartFlow-components/PickupLocation'
import { Certifications } from '../../../components/Certifications'
import { FooterBtn } from '../../../components/FooterBtn'
import ParallaxHeader, { ParallaxButtons } from '../../../components/ParallaxHeader/index'
import Colors from '../../../constants/Colors'
import { useProductHitTrack } from '../../../hooks/useHitTrack'
import { ShoppingStackParamList } from '../../../navigation/types'
import { addNavProp } from '../../../redux/actions/appState'
import { navPropsSelector, wholesaleSelector } from '../../../redux/selectors'

import { globalStyles } from '@/constants/Styles'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useFarmData } from '@/hooks/useFarmData'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSize } from '@/hooks/useLayout'
import { withWholesaleAuth } from '@/hooks/withWholesaleAuth'
import { getIdFromSlug } from '@helpers/urlSafeSlug'
import { Details } from './Details'
import { Sticky } from './Sticky'
import { getPickupOptsHeaderText } from './helpers'

interface Props {
  route: RouteProp<ShoppingStackParamList, 'ProductDetails'>
  navigation: StackNavigationProp<ShoppingStackParamList, 'ProductDetails'>
}

/** Shopping screen dedicated to a specific product */
export function ProductDetailsComp({
  route: {
    params: { farmSlug, productId, csaId, goBack },
  },
  navigation,
}: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const {
    farm: { data: farm },
    csas: { data: farmCsas, loading: loadingCsas, err: errorCsas },
    prods: { data: farmProds, addons: farmAddons, loading: loadingProds },
  } = useFarmData(farmSlug, { fetchCsas: true, fetchProds: isWholesale === false })

  /** farmId will be an empty string until the data matches the slug */
  const farmId = useMemo(() => getIdFromSlug(farmSlug, farm), [farm, farmSlug])

  // Provide analytics data for the product view action.
  useProductHitTrack(farmId, productId)

  const { product, csa } = useSelector(navPropsSelector)
  const [loading, setLoading] = useKeyedState({ prod: true, csa: true })
  const [invalid, setInvalid] = useKeyedState({ prod: false, csa: false })

  const [addons, setAddons] = useState<Share[]>([]) //the addons to show below the main product
  const { availAddonsIds } = useAvailAddons() //availAddons in this screen are only used to determine which addons get the green addCartBtn, but all of them get shown

  const [unitId, setUnitId] = useState<string>('')
  const { isSmallDevice, isExtraSmallDevice } = useDeviceSize()
  const dispatch = useDispatch()

  /** Get the product from farmProds data, or load it directly if not yet available in the cache */
  useCancelableFocusFx(
    async (isActive) => {
      if (productId === product?.id) {
        setLoading('prod', false)
        return
      }
      setLoading('prod', true)
      let prod = farmProds?.find(({ id }) => id === productId) //If already match, not need to set

      if (!prod) {
        // If it's not in the products list on the first try, we may as well fetch it, since the progressive loading in useFarmData might not have gotten to it yet
        // Also useFarmData might never fetch it if this product is hidden. In that case we will still load it and set it here. But another effect below will handle the situation if it's hidden or somehow invalid.
        prod = await loadProduct(productId)
        if (!isActive) return
      }

      dispatch(addNavProp({ product: prod }))
      setLoading('prod', false)
    },
    [farmProds, productId, product, dispatch, setLoading],
  )

  /** Get the csa from farmCsas data. Also checks for invalid parameters */
  useFocusFx(() => {
    if (loadingCsas || !farmCsas) {
      return setLoading('csa', true) //wait
    }
    setLoading('csa', true)

    // get the csa from either the current nav prop or the farmCsas list
    let csaProp = csa
    if (csaId) {
      if (csaId !== csaProp?.id) {
        csaProp = farmCsas.find(({ id }) => id === csaId) // get csa from farm csas list
        dispatch(addNavProp({ csa: csaProp })) // this is intended to re-trigger this fx
        return
      }
    } else if (csaProp) {
      // Should clear the csa nav prop if the csaId is not defined. This prevents trying to add a product to the cart with a csa unrelated to the product
      dispatch(addNavProp({ csa: undefined })) // this is intended to re-trigger this fx
      return
    }

    // check data is correct
    // this is necessary because the csaId is just a url parameter so there's no guarantee the csaId is good for the product
    const needsCsaId = !!product && isShare(product) && !csaId
    const notBelongCsa = !!product && !!csaId && !product.csa?.includes(csaId)
    const notFoundCsa = !!product && !!csaId && !csaProp && !errorCsas && !notBelongCsa
    const invalidProduct = !!product && !shouldShow(product, { isWholesale })

    if (needsCsaId || notBelongCsa || notFoundCsa) {
      // If it's not a share, just remove the invalid csa id
      if (!isShare(product)) {
        navigation.setParams({ csaId: undefined }) // this is intended to re-trigger this fx
        return
      } else {
        // If the share has any other useable csa, set it. Otherwise accept this as being invalid
        const newCsaId = product.csa.find((id) => farmCsas.find((csa) => csa.id === id))
        if (newCsaId) {
          navigation.setParams({ csaId: newCsaId }) // this is intended to re-trigger this fx
          return
        } else {
          setInvalid('csa', true)
          Toast('The specified CSA is not available')
        }
      }
    }
    if (invalidProduct) {
      setInvalid('prod', true)
      Toast('This product is not being sold at this time')
    }
    setLoading('csa', false)
  }, [csaId, csa, loadingCsas, farmCsas, dispatch, product, errorCsas, setInvalid, setLoading, navigation, isWholesale])

  /** Gets related addons for product, based on the csaId */
  useFocusFx(() => {
    if (loadingProds || loading.csa || !csaId || !farmAddons) return

    // Get the farm addons for the current csa.
    const csaAddons = farmAddons.filter((p) => p.csa.includes(csaId))

    if (csaAddons.length) {
      /// Sort by name before setting the state. Available for addToCart should go first
      csaAddons.sort(sortByName).sort(sortByIncluded(availAddonsIds))
      setAddons(csaAddons)
    }
  }, [csaId, loading.csa, loadingProds, farmAddons, availAddonsIds])

  const onGoBack = useCallback(() => {
    if (goBack === 'home') {
      return navigation.navigate('Consumer', { screen: 'Home' })
    }
    if (navigation.canGoBack()) navigation.goBack()
    else if (csaId && csaId === csa?.id && !loading.csa && !invalid.csa)
      navigation.navigate('CSADetails', { csaId, farmSlug: csa.farm.urlSafeSlug })
    else if (product) navigation.navigate('FarmShop', { farmSlug: product.farm.urlSafeSlug })
  }, [goBack, navigation, product, csa, invalid, csaId, loading])

  const buttons: ParallaxButtons = useMemo(
    () => ({
      left: {
        icon: 'arrow-left',
        onPress: onGoBack,
      },
    }),
    [onGoBack],
  )

  const webElements = useDeepCompareMemo(
    () => ({
      content: <Details product={product} farm={farm} />,
      sticky: <Sticky product={product} unitId={unitId} setUnitId={setUnitId} />,
    }),
    [product, unitId, farm],
  )

  const parallaxMedia: Media[] = useMemo(
    () =>
      product?.images
        ? product.images.map((image: string) => ({
            storageUrl: image,
            type: 'image',
          }))
        : [],
    [product?.images],
  )

  const headerText = useMemo(
    () => (product ? getPickupOptsHeaderText(product, isWholesale) : ''),
    [product, isWholesale],
  )

  return (
    <SafeAreaView style={globalStyles.flex1}>
      <ParallaxHeader
        title={product?.name ?? 'Product Details'}
        media={parallaxMedia}
        webElements={webElements}
        buttons={buttons}
        back={onGoBack}
        loading={Object.values(loading).some((v) => !!v)} // children won't be shown while loading
      >
        {/* This part doesn't need to check for loading state because the loading state is passed into the parallax header, and while loading is true, these "children" won't be shown yet. */}
        {product && !invalid.prod && !invalid.csa ? (
          <>
            {isPhysical(product) && (
              <>
                <Certifications certs={product.certification} />
                {!!headerText && (
                  <View style={styles.title}>
                    <HeaderText>{headerText}</HeaderText>
                    <Tooltip title="Delivery/Pickup Options" id={ToolTips.PICKUP_OPTIONS} style={styles.marginLeft10} />
                  </View>
                )}
                <View style={styles.alignContCenter}>
                  <PickupLocation product={product} isWholesale={isWholesale} />
                </View>
              </>
            )}
            {!!csaId && !!addons.length && (
              <View style={styles.marginV15}>
                <HeaderText>Add-ons</HeaderText>
                <RowWrapper horizontalSpacing={20} verticalSpacing={20}>
                  {addons.map((p) => (
                    <ProductCardSquare
                      key={p.id}
                      product={p}
                      csa={csa}
                      small={isExtraSmallDevice}
                      cardAction="addcart"
                      onPressMobileNavigate={(p) =>
                        navigation.navigate('ProductDetails', { farmSlug, productId: p.id, csaId })
                      }
                    />
                  ))}
                </RowWrapper>
              </View>
            )}
          </>
        ) : (
          <>
            <MessageWithIcon
              icon="exclamation-triangle"
              title={!invalid.csa && !invalid.prod ? 'Load error' : 'Invalid Link!'}
            >
              <Text>
                {!invalid.csa && !invalid.prod
                  ? 'There was an error loading this product.'
                  : 'This URL is invalid, please check your link and try again.'}
              </Text>
            </MessageWithIcon>
          </>
        )}
      </ParallaxHeader>
      {isSmallDevice && product ? (
        <FooterBtn withTabs style={styles.footerBtn}>
          <AddCartBtn
            /**Disable stepper if the product has multiple buying options, because otherwise they won't be able to add a different BO */
            disableStepper={(getUnits(product, { isWholesale })?.length ?? 0) > 1}
            product={product}
            csa={csa}
            isWholesale={isWholesale}
          />
        </FooterBtn>
      ) : null}
    </SafeAreaView>
  )
}

export const ProductDetails = withWholesaleAuth(ProductDetailsComp)

export const styles = StyleSheet.create({
  flex1: { flex: 1 },
  unitContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  unitItem: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades['200'],
    marginVertical: 10,
    marginRight: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  unitItemSelected: {
    borderColor: Colors.green,
    backgroundColor: Colors.lightGreen,
  },
  unitItemDisabled: {
    borderColor: Colors.shades['200'],
    backgroundColor: Colors.shades['200'],
    color: Colors.shades['300'],
    opacity: 0.4,
  },
  about: {
    paddingTop: 10,
  },
  unitPrice: {
    flex: 1,
    alignItems: 'flex-end',
  },
  arrows: {
    top: 0,
    bottom: 0,
    position: 'absolute',
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginVertical: 32,
  },
  detailItm: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  btnBelowAddCart: { alignSelf: 'center', padding: 5 },
  footerBtn: { position: 'absolute', bottom: 0, paddingBottom: 5 },
  cartItemInfo: { marginVertical: 10, flexDirection: 'row', justifyContent: 'center' },
  marginRight: { marginRight: 15 },
  title: { flexDirection: 'row', alignItems: 'center' },
  marginLeft10: { marginLeft: 10 },
  alignContCenter: { alignContent: 'center' },
  marginV15: { marginVertical: 15 },
  width150: { width: 150 },
  unit: { flexDirection: 'row', alignItems: 'center', marginVertical: 10 },
  marginV40: { marginVertical: 40 },
  marginBtm5: { marginBottom: 5 },
  marginV20: { marginVertical: 20 },
  sharePriceText: { marginVertical: 20 },
  row: { flexDirection: 'row' },
  width180: { width: 180 },
  blue: { color: Colors.blueLink },
})
