import { auth } from '@api/db'
import { ApplicationVerifier, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { useCallback, useEffect, useRef } from 'react'
import { View } from 'react-native'

const RECAPTCHA_CONTAINER = 'recaptcha-container'

/** This hook is responsible for initiating the login process which would mean showing the reCAPTCHA if necessary,
 * sending an OTP to the phone number specified or automatically logging in/registering the user if they have autoVerify
 * setup on Android */
export const useVerifyPhone = (onSuccess: (verification: string) => void) => {
  const recaptchaVerifierRef = useRef<ApplicationVerifier | null>(null)

  useEffect(() => {
    recaptchaVerifierRef.current = new RecaptchaVerifier(RECAPTCHA_CONTAINER, { size: 'invisible' }, auth().getAuth())
  }, [])

  return useCallback(
    async (phone: string) => {
      const res = await signInWithPhoneNumber(auth().getAuth(), phone.replace(' ', ''), recaptchaVerifierRef.current!)
      onSuccess(res.verificationId)
    },
    [onSuccess],
  )
}

/** The RecaptchaContainer renders an empty div that will be used as a place that the recaptcha will be inserted into.
 * - This is for web only and is noop in mobile.
 * - Collapsable must be false or react-native-web will try and remove this while rendering since it is an empty div */
export function RecaptchaContainer() {
  return <View nativeID={RECAPTCHA_CONTAINER} collapsable={false} />
}
