import { Logger } from '@/config/logger'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useCoords } from '@/hooks/useCoords/useCoords'
import { HomeParamList, SearchScreenParams } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { getParsedAddressFromCoords } from '@api/Addresses'
import { formatAddress } from '@helpers/display'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { LocType } from '../searchScreen-helpers'

/** Will set a default region to params once if the locType is NonPickup and no region is defined */
export function useSetInitialRegion(
  locType: LocType | undefined,
  regionParam: SearchScreenParams['region'] | undefined,
  isGlobal: boolean | undefined,
) {
  const navigation = useNavigation<NavigationProp<HomeParamList, 'SearchScreen'>>()
  const dispatch = useDispatch()

  /** useCoords here should not receive the argument for coords because in this hook its job is to give us a set of initial coordinates in the scenario where there is no navigation parameter for coordinates */
  const coords = useCoords()

  useCancelableFocusFx(
    async (isCurrent) => {
      if ((locType !== 'state' && locType !== 'zip') || isGlobal) {
        // The job of this hook is to set an initial region for nonPickup location types, so if the locType is none of the nonPickup types then there's nothing to do
        return
      }

      if (regionParam) {
        // If there's a region param then there's nothing to do because the job of this hook is to set an initial value in case none is defined
        return
      }

      if (!coords) {
        // If the useCoords could not produce a useful set of initial/ default coordinates then there's nothing we can do to help. This is unlikely
        return
      }

      try {
        // Try to get the region and full address from the initial coords
        const parser = await getParsedAddressFromCoords(coords)
        if (!isCurrent) return

        const addr = parser.getAddress()

        // get the appropriate region for the locType
        const region = locType === 'state' ? addr.state : locType === 'zip' ? addr.zipcode : undefined
        if (!region) return

        // When setting an initial region we will also set the coords as the current search location
        dispatch(setSearchLocation({ coordinate: coords, name: formatAddress(addr) }))
        navigation.setParams({ region })
      } catch (error) {
        // This could mean the coords have no US address, or the address is not a valid US address
        Logger.error(error)
      }
    },
    [locType, isGlobal, regionParam, coords, dispatch, navigation],
    { noRefocus: true, once: true },
  )
}
