import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { ScrollView, View } from 'react-native'

import { SharedScreenHeader, sharedStyles } from '../components'
import { useSignInSheetContext } from '../useSignInSheetData'

import { globalStyles } from '@/constants/Styles'
import { SignInSheetParamList } from '@/navigation/types'
import { withDataAndAuth } from '../withDataAndAuth'
import { InvoiceSection } from './components/InvoiceSection'
import { OrderSection } from './components/OrdersSection'
import { UserDetails } from './components/UserDetails'

type Props = StackScreenProps<SignInSheetParamList, 'PickupSummary'>

function PickupSummaryScreenComp({ route }: Props) {
  const { items } = useSignInSheetContext()
  const item = items.find((itm) => itm.user.id === route.params.userId)

  return (
    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={sharedStyles.wrapperNoAlign}>
      <SharedScreenHeader title="Customer details" />
      {item && <UserDetails item={item} />}
      <View style={globalStyles.margin10} />
      <InvoiceSection userId={route.params.userId} />
      <View style={globalStyles.margin10} />
      {item && <OrderSection item={item} />}
    </ScrollView>
  )
}

export const PickupSummaryScreen = withDataAndAuth(PickupSummaryScreenComp)
