import { searchLocationSelector, sessionLocationSelector, userSelector } from '@/redux/selectors'
import { CoordString } from '@helpers/coordinate'
import { Coordinate } from '@models/Coordinate'
import { getDefaultCoords } from '@screens/Explore/helpers'
import { useSelector } from 'react-redux'

/** Returns the most relevant coordinates that can be used for search purposes in various places that may need an initial/default value */
export function useCoords(coords?: CoordString | Coordinate): Coordinate | undefined {
  const user = useSelector(userSelector)
  const searchLoc = useSelector(searchLocationSelector)
  const sessionLoc = useSelector(sessionLocationSelector)

  return getDefaultCoords({ userAddress: user.address, searchLoc, sessionLoc, center: coords })
}
