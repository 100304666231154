import { AddImages } from '@components'
import { Text, TextH3 } from '@elements'
import { Media } from '@models/shared/Media'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../../../../constants/Colors'

type PhotoSectionProps = {
  onMediaChange: (value: Media[]) => void
  value: Media[]
  disabled?: boolean
}

/** Component for displaying and add images  */
export const PhotoSection = memo(function PhotoSection({ onMediaChange, value, disabled = false }: PhotoSectionProps) {
  return (
    <View style={styles.container}>
      <View style={styles.photosHeader}>
        <TextH3 size={18} color={Colors.black}>
          Photos*
        </TextH3>
        <TextH3 size={18} color={Colors.black}>
          {value?.length}/5
        </TextH3>
      </View>
      <View>
        <Text color={Colors.shades[200]}>Horizontally oriented images work best.</Text>
        <Text color={Colors.shades[200]}>Recommended Aspect Ratio is 4:3 (1280x960)</Text>
      </View>

      <AddImages media={value} setMedia={onMediaChange} maxImages={5} disabled={disabled} />
    </View>
  )
})

const styles = StyleSheet.create({
  photosHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    gap: 10,
  },
})
