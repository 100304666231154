import uuid from '@helpers/uuid'
import { DateTime, Duration } from 'luxon'
import { DeepPartial } from 'redux'

import { DualMessageError } from '@shared/Errors'
import { makeAbortable } from './abortablePromise'
import mergeDeep from './mergeDeep'
import { isLuxonDateTime } from './time'
import { AsyncFn, Falsy, Primitive, Resolved, isImmutable, keys } from './typescript'

/**
 * Splits an array into groups for batch processing
 * @param values - the array to perform chunking on
 * @param size - the number of values in each sub array
 */
export function splitToGroups<T>(values: T[], size: number): T[][] {
  const groups = []
  let subGroup: T[] = []
  values.forEach((val: T) => {
    subGroup.push(val)
    if (subGroup.length === size) {
      groups.push(subGroup)
      subGroup = []
    }
  })
  if (subGroup.length > 0) groups.push(subGroup)
  return groups
}

/**
 * Splits an array into groups of equal size
 * @param values - the array to perform chunking on
 * @param numberOfGroups - the number of groups to create
 */
export function splitEvenlyToGroups<T>(values: T[], numberOfGroups: number): T[][] {
  const groupSize = Math.ceil(values.length / numberOfGroups)
  return splitToGroups(values, groupSize)
}

/** Will run an async function once with each argument in the args array, and return the results of all resolved promises.
 * - The async function must have only one parameter */
export async function loadAllDocs<T, S>(args: S[], loadFunc: (arg: S) => Promise<T>): Promise<T[]> {
  const requests: Promise<T>[] = args.map((arg) => loadFunc(arg))
  return await Promise.all(requests)
}

/** groupBy returns an array of arrays where each subarray contains the elements from the supplied collection that
 * share the same key generated by keyGenerator. */
export function groupBy<TKey, TElement>(
  collection: TElement[],
  keyGenerator: (element: TElement) => TKey,
): TElement[][] {
  const map = new Map<TKey, TElement[]>()
  for (const element of collection) {
    const key = keyGenerator(element)
    const value = map.get(key)
    if (value) {
      value.push(element)
      map.set(key, value)
    } else {
      map.set(key, [element])
    }
  }
  return Array.from(map.values())
}

/** Returns an object where keys are generated via the keyGenerator arg, and values are arrays of those elements in collection which returned the given key */
export function groupByObj<TKey, TElement>(
  collection: TElement[],
  keyGenerator: (element: TElement) => TKey,
): Record<string, TElement[]> {
  const map = new Map<TKey, TElement[]>()
  for (const element of collection) {
    const key = keyGenerator(element)
    const value = map.get(key)
    if (value) {
      value.push(element)
      map.set(key, value)
    } else {
      map.set(key, [element])
    }
  }
  return Object.fromEntries(map)
}

/** Removes duplicates in a primitive array */
export function removeDuplicates<T extends Primitive>(arr: T[]) {
  return [...new Set(arr)]
}

/** Removes object duplicates by id, or a custom field */
export function removeObjDuplicates<T extends Record<any, any>>(
  array: T[],
  idGetter: (obj: T) => string = (obj) => obj['id'],
): T[] {
  const uniqueIds = new Set<string>()
  const result: T[] = []

  for (const obj of array) {
    const id = idGetter(obj)
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id)
      result.push(obj)
    }
  }

  return result
}

/** Removes duplicates from an array of objects based on a custom comparison function */
export function removeComplexDuplicates<T extends Record<any, any>>(
  array: T[],
  isDuplicate: (obj1: T, obj2: T) => boolean,
): T[] {
  const result: T[] = []

  for (const obj of array) {
    const dupe = result.find((objRes) => isDuplicate(objRes, obj))

    if (!dupe) {
      result.push(obj)
    }
  }

  return result
}

/** Will return if an array has any duplicates in it */
export function hasDuplicates(arr: Primitive[]) {
  return new Set(arr).size !== arr.length
}

/** Detects a value is a non-array plain object, or Record. Will be false for class instances and null */
export const isObject = (obj: any): obj is Record<any, any> =>
  obj !== undefined &&
  obj !== null &&
  typeof obj === 'object' &&
  !Array.isArray(obj) &&
  Object.getPrototypeOf(obj) === Object.getPrototypeOf({})

/** If a value is an array */
export function isArray<T>(arg: any): arg is T & T[] {
  return Array.isArray(arg)
}

/** Detects when an object is an instance of any class. Will be false for a plain JS object or array */
export const isInstance = (obj: any): obj is object => {
  return (
    obj !== undefined &&
    obj !== null &&
    typeof obj === 'object' &&
    !Array.isArray(obj) &&
    Object.getPrototypeOf(obj) !== Object.getPrototypeOf({})
  )
}

/** deepMerge merges the properties of the supplied sources into the target.
 * - Target is mutated.
 * - Arrays are replaced without any kind of merging.
 * - Objects are only checked for 'object' type, which will include null, functions and any other type which extends the base JS 'object'
 * - Doesn't preclude class instances from getting merged, with the exception of luxon date time.
 */
export function deepMerge(target: object, ...sources: object[]): object {
  for (const source of sources) {
    const keys = Object.keys(source) as (keyof typeof source)[]
    for (const key of keys) {
      if (Array.isArray(target[key])) {
        target[key] = source[key]
      } else if (typeof target[key] === 'object' && !isLuxonDateTime(target[key])) {
        deepMerge(target[key], source[key])
      } else {
        target[key] = source[key]
      }
    }
  }
  return target
}

/** Deep merges two arrays.
 *
 * For each pair of elements of the same index in both arrays:
 * - Immutables get replaced.
 * - Arrays start a recursion
 * - Objects get deep merged. */
export const mergeArray = <T>(prev: T[], obj: T[]): T & any[] => {
  return obj.map((_: unknown, ix: number) => {
    const prevVal = prev[ix]
    const objVal = obj[ix]
    if (isImmutable(objVal) || isInstance(objVal)) return objVal
    else if (isArray(prevVal) && isArray(objVal)) return mergeArray(prevVal, objVal)
    else if (isObject(prevVal) && isObject(objVal)) return mergeDeep(prevVal, objVal)
    else return objVal
  }) as T & any[]
}

/** A useful helper to tell typescript something is not undefined. Used in filters to remove undefined values. */
export const isNonNullish = <T>(value: T | undefined | null): value is NonNullable<T> =>
  value !== undefined && value !== null

export const isTruthy = <T>(value: T | Falsy): value is T => !!value

/** Checks that a value is a non-empty string, and isn't only whitespace */
export const nonEmptyString = (value: any): value is string => typeof value === 'string' && value.trim() !== ''

/**
 * Will remove deeply nested undefined values from an object
 * @param obj the object to parse
 */
export const removeUndefined = <T extends { [key: string]: any }>(obj: T): T => {
  const newObj: { [key: string]: any } = {}
  // If it is not an object then don't recurse through the fields
  if (!isObject(obj)) {
    return obj
  }
  Object.keys(obj).forEach((key) => {
    // If we encounter an array then map over the fields and recursively call the function
    if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map(removeUndefined)
      // If we encounter an object
    } else if (isObject(obj[key])) {
      // If it is not a date then loop through it to find nested undefined values
      newObj[key] = removeUndefined(obj[key])
    } else if (obj[key] !== undefined) newObj[key] = obj[key]
  })
  return newObj as T
}

/** Recursively checks whether a value has any meaningful data. If the value does not contain any non-nullish data, will return true */
export const isEmptyValue = (value: any): boolean =>
  value === undefined ||
  value === null ||
  (isArray(value) && (!value.length || value.every((itm) => isEmptyValue(itm)))) ||
  (isObject(value) && (!Object.entries(value).length || Object.values(value).every((v) => isEmptyValue(v))))

/** Recursively removes fields from an object if they have no meaningful data */
export const removeEmptyFields = <T extends Record<any, any>>(obj: T): DeepPartial<T> | T => {
  const keys = Object.keys(obj) as (keyof T)[]
  for (const key of keys) {
    if (isEmptyValue(obj[key])) delete obj[key]
    else if (isObject(obj[key])) obj[key] = removeEmptyFields(obj[key]) as T[keyof T]
    else if (isArray<T[keyof T]>(obj[key])) obj[key] = removeEmptyFieldsArray<T[keyof T]>(obj[key])
  }
  return obj
}

const removeEmptyFieldsArray = <T>(arr: any[]): T & any[] => {
  return arr
    .filter((itm) => !isEmptyValue(itm))
    .map((itm) => {
      if (isImmutable(itm) || isInstance(itm)) return itm
      else if (isArray(itm)) return removeEmptyFieldsArray(itm)
      else if (isObject(itm)) return removeEmptyFields(itm)
      else return itm
    }) as T & any[]
}

/** errorToString attempts to return the error value to a string representation. */
export function errorToString(err: unknown): string {
  if (typeof err === 'string') {
    return err
  }
  if (typeof err === 'number') {
    return err.toString()
  }
  if (err instanceof Error) {
    return err.message
  }
  if (hasOwnProperty(err, 'message') && typeof err.message === 'string') {
    return err.message
  }
  if (typeof err === 'object' && err) {
    if ('toString' in err) {
      return err.toString()
    }
  }
  return 'Unknown error'
}

/** Extends the message of a caught error */
/** WARNING: this will modify the original error.message in place **/
export const extendErr = (err: unknown, msg: string): Error => {
  // If the error extends DualMessageError we should add the message to both the uiMsg message and the devMsg message
  if (err instanceof DualMessageError) {
    const uiMsg = `${msg} ${err.uiMsg}`
    err.uiMsg = uiMsg
    err.message = uiMsg

    err.devMsg = `${msg} ${err.devMsg}`

    return err
  }

  const newErr = err as Error
  newErr.message = `${msg} ${errorToString(newErr)}`
  return newErr
}

/** performs a type assertion on the supplied object containing the supplied property. */
export function hasOwnProperty<T, U extends PropertyKey>(object: T, property: U): object is T & Record<U, unknown> {
  return !!object && Object.prototype.hasOwnProperty.call(object, property)
}

// Will generate a unique ID that can be used across the application
export const createId = () => uuid()

/** Returns a deep copy of an object, by copying every primitive value to a new one, mimicking the original structure.
 * - works on arrays and nested arrays as well
 * - won't really clone functions, and instead copy their reference, but could be extended. https://stackoverflow.com/a/11230005
 */
export const deepClone = <T extends any | any[]>(obj: T): T => {
  if (isImmutable(obj) || isInstance(obj)) return obj
  else if (isArray(obj)) return obj.map(deepClone) as T
  else {
    const newObj: Record<any, any> = {}
    for (const key in obj) {
      newObj[key] = deepClone(obj[key])
    }
    return newObj as T
  }
}

type HandleHelpers<T> = [() => T, (arg?: T) => void, () => boolean]

/** Creates a getter and setter for a value handle to be reused by other files.
 * - The setter can also be used to clear the current value by calling with no arguments
 */
export const makeHandle = <T>(handleName: string): HandleHelpers<T> => {
  let handle: T | undefined
  const get = () => {
    if (!handle) throw new Error(`${handleName} handle not configured.`)
    return handle
  }
  const set = (newHandle?: T) => (handle = newHandle)
  const isSet = () => handle !== undefined
  return [get, set, isSet]
}

type HandleHelpersObj<T> = { get: () => T; set: (arg?: T) => void; isSet: () => boolean }

/** Creates a getter and setter for a value handle to be reused by other files.
 * - The setter can also be used to clear the current value by calling with no arguments
 */
export const makeHandleObj = <T>(handleName: string): HandleHelpersObj<T> => {
  const [get, set, isSet] = makeHandle<T>(handleName)

  return { get, set, isSet }
}

export const isFunction = (obj: any): obj is Function => typeof obj === 'function'

/** whether the value can be parsed into a valid number (not NaN).
 * - Intended for strings, but any value could be checked
 * https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
 */
export const isNum = (v: any): boolean => {
  return !isNaN(v as number) && !isNaN(parseFloat(v)) && isFinite(parseFloat(v))
}

/** helper that takes a retryable async function. It will catch and retry up to the specified n times, pausing for the given interval(miliseconds) before each try, and if the max retry is reached will reject with the last error */
export async function retry<T>(fn: () => Promise<T>, maxTries = 3, interval = 500): Promise<T> {
  for (let tryIx = 1; tryIx <= maxTries; tryIx++) {
    try {
      const result = await fn()
      return result
    } catch (error) {
      if (tryIx < maxTries) {
        await wait(interval)
        continue
      } else {
        throw error
      }
    }
  }
  throw new Error('Unexpected error with retry')
}

/** Returns a promise that auto-resolves in a given number of milliseconds */
export const wait = (milliseconds: number) => new Promise<void>((resolve) => setTimeout(() => resolve(), milliseconds))

/** Calls an async fn and aborts it after a given wait time */
export function withTimeout<T extends Promise<any>>(prom: T, maxWait = 5000): T {
  const { abortable, controller } = makeAbortable(prom, 'The function took too long to execute')

  wait(maxWait).then(() => controller.abort())

  return abortable as T
}

/** Wrapper for Promise.allSettled which maps the settled results to provide only resolved or handled values. Rejected promises will be handled as undefined */
export async function settleUnwrap<T extends unknown[]>(
  proms: T,
): Promise<{ [P in keyof T]: Awaited<T[P]> | undefined }> {
  const settled = await Promise.allSettled(proms)

  return settled.map((res) => (res.status === 'rejected' ? undefined : res.value)) as {
    [P in keyof T]: Awaited<T[P]> | undefined
  }
}

/** Determines if two arrays contain the same elements, without regard for sorting order */
export function haveSameItems(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort and then compare every element
  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  return sortedArr1.every((value, idx) => value === sortedArr2[idx])
}

/** Will call an async function and return how long it took to run */
export const getTiming = async <T>(
  asyncFn: AsyncFn<T>,
): Promise<{ result: Resolved<typeof asyncFn>; duration: Duration }> => {
  const timeBefore = DateTime.now()
  const result = await asyncFn()
  const timeAfter = DateTime.now()
  const duration = timeAfter.diff(timeBefore)
  return { duration, result }
}

/**
 * Will convert an array of type T into an object with values of type T
 * @param array The array to convert
 * @param keyExtractor A function to extract the keys for the object
 */
export function arrayToObject<T extends object>(array: T[], keyExtractor: (obj: T) => string): Record<string, T> {
  return array.reduce(
    (returnObj, arrItem) => ({
      ...returnObj,
      [keyExtractor(arrItem)]: arrItem,
    }),
    {},
  )
}

/** Calculates a rough estimate of the size of an object in bytes
 * - based on https://stackoverflow.com/a/29387295 and https://stackoverflow.com/a/63805778
 */
export function getObjectBytes(val: any): number {
  const typeKey = typeof val

  const typeSizes: Record<typeof typeKey, (val?: any) => number> = {
    undefined: () => 0,
    boolean: () => 4,
    number: () => 8,
    string: (val: string) => 2 * val.length,
    object: (val: object) =>
      !val ? 0 : keys(val).reduce((total, key) => getObjectBytes(key) + getObjectBytes(val[key]) + total, 0),
    bigint: () => 8,
    symbol: () => 4,
    function: (val: Function) => encodeURI(JSON.stringify(val)).split(/%..|./).length - 1,
  }
  return typeSizes[typeKey](val)
}
