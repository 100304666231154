import { MediaCarousel } from '@components'
import { Media } from '@models/shared/Media'
import { memo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

export const LogisticsSelectionCarousel = memo(function LogisticsSelectionCarousel() {
  const [carouselWidth, setCarouselWidth] = useState(400)

  return (
    <View
      onLayout={(e) => {
        const width = e.nativeEvent.layout.width
        if (width > 0) {
          setCarouselWidth(e.nativeEvent.layout.width)
        }
      }}
      style={styles.carouselCont}
    >
      <MediaCarousel width={carouselWidth} media={wholesaleBanner} height={carouselWidth * 0.6} />
    </View>
  )
})

const styles = StyleSheet.create({
  carouselCont: {
    flexBasis: 400,
    flex: 1,
  },
})

const wholesaleBanner: Media[] = [
  {
    storageUrl: require('../../../../../assets/images/home_wholesale/banner.png'),
    type: 'image',
  },
]
