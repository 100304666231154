import { FormInput, Text } from '@elements'
import { DefaultCatalog, hasUnits, isShare, Product } from '@models/Product'
import { useFormikContext } from 'formik'
import { useCallback } from 'react'
import { View } from 'react-native'

import InputLabel from '@/admin/components/InputLabel'
import { globalStyles } from '@/constants/Styles'
import { CSA } from '@models/CSA'
import { AdvancedPricingForm } from '../../AdvancedPricing'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { BasicInfoForm } from '../BasicInformation.helpers'
import { CsaSection } from './CsaSection'

type CsaSelectionProps = {
  product: Product | undefined
  farmId: string
}

/** Wrapper around CSA inputs that interacts with the form */
export function CsaSelection({ product, farmId }: CsaSelectionProps) {
  const { handleChange, values, errors, handleBlur, touched, setFieldValue, setTouched } = useFormikContext<
    BasicInfoForm & AdvancedPricingForm & ProductTypeForm
  >()

  //CSA checkboxes handler
  const checkboxOnClick = useCallback(
    (item: CSA) => {
      if (values.csas?.includes(item.id)) {
        setFieldValue(
          'csas',
          values.csas?.filter((id) => id !== item.id),
        )
      } else {
        setFieldValue('csas', [...(values.csas || []), item.id])
      }
    },
    [setFieldValue, values.csas],
  )

  // CSA dropdown picker handler
  const csaOnChange = useCallback(
    async (value: string) => {
      if (!value) {
        return await setFieldValue('csas', [] as string[])
      }
      await setFieldValue('csas', [value] as string[])
      await setTouched({ ...touched, csas: true })
    },
    [setFieldValue, setTouched, touched],
  )

  const type = values.type

  const isShareProd = isShare({ type })

  return (
    <>
      <View style={globalStyles.marginHorizontal10}>
        <Text size={18} type="medium">
          {isShareProd ? 'CSA Group and Vacation Weeks' : 'CSA Group'}
        </Text>
        <Text>
          {isShareProd
            ? `With vacation weeks, customers can receive farm credit when they claim a given vacation week in GrownBy.`
            : ''}{' '}
          CSA groups support member management and customization.
        </Text>
      </View>
      {isShareProd && (
        <FormInput
          value={values.vacationWeeks?.toString() || ''}
          label={<InputLabel label="Vacation Weeks" required />}
          placeholder="ex. 4"
          onChangeText={handleChange('vacationWeeks')}
          onBlur={handleBlur('vacationWeeks')}
          errorMessage={touched.vacationWeeks ? errors.vacationWeeks : ''}
        />
      )}

      {(hasUnits({ type }) || isShare({ type })) && values.catalog !== DefaultCatalog.Wholesale && (
        <CsaSection checkboxOnClick={checkboxOnClick} csaOnChange={csaOnChange} farmId={farmId} product={product} />
      )}
    </>
  )
}
