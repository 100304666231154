import { entries } from '@helpers/typescript'
import { DataVersion } from '@models/Meta'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'
import { marshalUserAddress, unmarshalUserAddress } from './Address'
import { marshalCSA, unmarshalCSA } from './CSA'
import { marshalCart, unmarshalCart } from './Cart'
import { marshalCommodity, unmarshalCommodity } from './Commodity'
import { marshalCoupon, marshalPromoCode, unmarshalCoupon, unmarshalPromoCode } from './Coupons'
import { marshalDistribution, unmarshalDistribution } from './Distribution'
import { marshalEvent, unmarshalEvent } from './Event'
import { marshalExternalLink, unmarshalExternalLink } from './ExternalLinks'
import { marshalFarm, unmarshalFarm } from './Farm'
import { marshalFarmAssociation, unmarshalFarmAssociation } from './FarmAssociation'
import { marshalFarmBalances, unmarshalFarmBalances } from './FarmBalances'
import { marshalSupportTicket, unmarshalSupportTicket } from './Feedback'
import { marshalHits, unmarshalHits } from './Hits'
import { marshalInvoice, unmarshalInvoice } from './Invoice'
import { marshalLocation, unmarshalLocation } from './Location'
import { marshalEmail, unmarshalEmail } from './Mail'
import { marshalNotification, unmarshalNotification } from './Notification'
import { marshalOrder, unmarshalOrder } from './Order'
import { marshalPaymentMethod, unmarshalPaymentMethod } from './PaymentMethods'
import { marshalPayout, unmarshalPayout } from './Payout'
import { marshalPickup, unmarshalPickup } from './Pickup'
import { marshalProduct, unmarshalProduct } from './Product'
import { marshalProductFee, unmarshalProductFee } from './ProductFees'
import { marshalReview, unmarshalReview } from './Review'
import { marshalSignInSummary, unmarshalSignInSummary } from './SignInSummary'
import { marshalTemplateProduct, unmarshalTemplateProduct } from './TemplateProduct'
import { marshalUser, unmarshalUser } from './User'
import { marshallModel, unmarshalModel } from './encoding'
import { DraftOrder } from '@models/DraftOrder'
import { ChatMessage, ChatSession } from '@models/Messaging'
import { CustomShare } from '@models/CustomShare'

type Marshaller<T> = (object: Partial<T>, isNested?: boolean) => DocumentData
type Unmarshaller<T> = (
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
) => T

type CreateCollectionParams<DataType> = [
  name: string,
  marshal: Marshaller<DataType>,
  unmarshal: Unmarshaller<DataType>,
  version: DataVersion,
]

const collectionData = <DataType>(
  name: string,
  marshal: Marshaller<DataType>,
  unmarshal: Unmarshaller<DataType>,
  version: DataVersion,
) => [name, marshal, unmarshal, version] as CreateCollectionParams<DataType>

export const collectionMap = {
  users: collectionData('users', marshalUser, unmarshalUser, '1.0.0'),
  farms: collectionData('farms', marshalFarm, unmarshalFarm, '1.0.0'),
  external_links: collectionData('external_links', marshalExternalLink, unmarshalExternalLink, '1.0.0'),
  pickups: collectionData('pickups', marshalPickup, unmarshalPickup, '1.0.0'),
  signin_summaries: collectionData('signin_summaries', marshalSignInSummary, unmarshalSignInSummary, '1.0.0'),
  reviews: collectionData('reviews', marshalReview, unmarshalReview, '1.0.0'),
  notifications: collectionData('notifications', marshalNotification, unmarshalNotification, '1.0.0'),
  locations: collectionData('locations', marshalLocation, unmarshalLocation, '1.0.0'),
  invoices: collectionData('invoices', marshalInvoice, unmarshalInvoice, '1.0.0'),
  orders: collectionData('orders', marshalOrder, unmarshalOrder, '1.0.0'),
  csa: collectionData('csa', marshalCSA, unmarshalCSA, '1.0.0'),
  products: collectionData('products', marshalProduct, unmarshalProduct, '1.0.0'),
  distributions: collectionData('distributions', marshalDistribution, unmarshalDistribution, '1.0.0'),
  events: collectionData('events', marshalEvent, unmarshalEvent, '1.0.0'),
  feedback: collectionData('feedback', marshalSupportTicket, unmarshalSupportTicket, '1.0.0'),
  template_products: collectionData('template_products', marshalTemplateProduct, unmarshalTemplateProduct, '1.0.0'),
  custom_shares: collectionData<CustomShare>('custom_shares', marshallModel, unmarshalModel, '1.0.0'),
  carts: collectionData('carts', marshalCart, unmarshalCart, '1.0.0'),
  draft_orders: collectionData<DraftOrder>('draft_orders', marshallModel, unmarshalModel, '1.0.0'),
  mail: collectionData('mail', marshalEmail, unmarshalEmail, '1.0.0'),
  commodities: collectionData('commodities', marshalCommodity, unmarshalCommodity, '1.0.0'),
  payouts: collectionData('payouts', marshalPayout, unmarshalPayout, '1.0.0'),
  hits: collectionData('hits', marshalHits, unmarshalHits, '1.0.0'),
  chat_sessions: collectionData<ChatSession>('chat_sessions', marshallModel, unmarshalModel, '1.0.0'),

  // Child collections
  chat_messages: collectionData<ChatMessage>('chat_messages', marshallModel, unmarshalModel, '1.0.0'),
  addresses: collectionData('addresses', marshalUserAddress, unmarshalUserAddress, '1.0.0'),
  farmAssociations: collectionData('farmAssociations', marshalFarmAssociation, unmarshalFarmAssociation, '1.0.0'),
  farmBalances: collectionData('farmBalances', marshalFarmBalances, unmarshalFarmBalances, '1.0.0'),
  payment_methods: collectionData('payment_methods', marshalPaymentMethod, unmarshalPaymentMethod, '1.0.0'),
  coupons: collectionData('coupons', marshalCoupon, unmarshalCoupon, '1.0.0'),
  promo_codes: collectionData('promo_codes', marshalPromoCode, unmarshalPromoCode, '1.0.0'),
  product_fees: collectionData('product_fees', marshalProductFee, unmarshalProductFee, '1.0.0'),
}

/** This will build a map similar to above except it will remove the marshal and unmarshal so that it can be passed as params for connection */
export const connectionMap = entries(collectionMap)
  .map(([_, params]) => [params[0], params[3]])
  .reduce((a, b) => ({ ...a, [b[0]]: b }), {}) as Record<keyof typeof collectionMap, [string, DataVersion]>
