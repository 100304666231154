import { addCSA, canCSABeDeleted, deleteCSA, updateCSA } from '@api/CSAs'
import { Image } from '@components'
import { Alert, Icon, Text, Toast } from '@elements'
import { CSA } from '@models/CSA'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as Clipboard from 'expo-clipboard'
import { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { setAdminCSAsAction } from '../../../../redux/actions/adminState'
import { RootState } from '../../../../redux/reducers/types'
import { adminCsasSelector, wholesaleSelector } from '../../../../redux/selectors'
import LinkButton from '../../../components/LinkButton'
import { ActionBtn, ActionsMenuComponent } from '../../../components/elements/ActionsMenuComponent'
import { AdminDrawerParamList } from '../../../navigation/types'

import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { errorToString } from '@helpers/helpers'

type CSACardAdminProps = {
  /** If isLastCSA is true, the current CSA is listed as last one in all CSAs list in parent table */
  isLastCSA: boolean
  /** Current passed CSA */
  csa: CSA
  /** The helper props to perform navigation functionality to CSADetailScreen for each CSA */
  csaDetail(csa: CSA): void
  /** The index for Current passed CSA */
  index: number
}

/** UI to show the csa in admin list view */
export function CSACardAdmin({ csa, csaDetail, isLastCSA, index }: CSACardAdminProps) {
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()
  const dispatch = useDispatch()
  const { isWholesale } = useSelector(wholesaleSelector)
  const adminAllCSAs = useSelector<RootState, CSA[]>(adminCsasSelector)
  const styles = useStyles(!!csa.isHidden, isLastCSA)

  const getUniqueName = useCallback(
    (originalName: string): string => {
      const nameSet = new Set<string>()
      let uniqueName = originalName + ' - Copy'
      adminAllCSAs.forEach((csa) => {
        nameSet.add(csa.name)
      })

      let i = 1
      while (nameSet.has(uniqueName)) {
        uniqueName = originalName + ' - Copy (' + i.toString() + ')'
        i++
      }

      return uniqueName
    },
    [adminAllCSAs],
  )

  /** Generate links for Orders and Customers */
  const generateLinks = (csaName: string, linkTitle: 'Customers' | 'Orders') => {
    let onPress = () => {}
    if (linkTitle === 'Customers') {
      onPress = () => navigation.navigate('Customer', { screen: 'CustomerList', params: { csaName } })
    } else if (linkTitle === 'Orders') {
      onPress = () => navigation.navigate('Orders', { screen: 'AdminOrderList', params: { csaName } })
    }
    return <LinkButton key={`${csaName}_${linkTitle}`} title={linkTitle} onPress={onPress} />
  }

  /** Action to hide/show CSA */
  const handleHideCSA = useCallback(async () => {
    const newCSAs = adminAllCSAs.map((c) => (c.id === csa.id ? { ...csa, isHidden: !csa.isHidden } : c))

    // FIXME: Should remove every setAdminCSAsAction() once implement csa snapshot listener
    dispatch(setAdminCSAsAction(newCSAs))

    try {
      await updateCSA({ ...csa, isHidden: !csa.isHidden })
    } catch (err) {
      Alert('Error hiding CSA:', errorToString(err))
      dispatch(setAdminCSAsAction(adminAllCSAs))
    }
  }, [adminAllCSAs, csa, dispatch])

  /** Action to copy CSA */
  const handleCopyCSA = useCallback(async () => {
    try {
      const copyOfCsa = { ...csa }
      copyOfCsa.name = getUniqueName(csa.name)
      const { id } = await addCSA(copyOfCsa)
      copyOfCsa.id = id
      dispatch(setAdminCSAsAction([copyOfCsa, ...adminAllCSAs]))
    } catch (err) {
      Alert('Error copying CSA:', errorToString(err))
      dispatch(setAdminCSAsAction(adminAllCSAs))
    }
  }, [getUniqueName, adminAllCSAs, csa, dispatch])

  /** Inner helper for handleDeletePress to delete an CSA */
  const delCSA = useCallback(async () => {
    const newCSAs = adminAllCSAs.filter((c) => c.id !== csa.id)
    dispatch(setAdminCSAsAction(newCSAs))

    try {
      await deleteCSA(csa.id)
    } catch (err) {
      Alert('Error deleting CSA:', errorToString(err))
      dispatch(setAdminCSAsAction(adminAllCSAs))
    }
  }, [adminAllCSAs, csa, dispatch])

  /** Action to delete CSA */
  const handleDeletePress = useCallback(() => {
    canCSABeDeleted(csa.farm.id, csa.id).then((result) => {
      if (result) {
        Alert('Delete CSA', 'Are you sure you want to delete this CSA?', [
          {
            text: 'Delete',
            onPress: delCSA,
          },
          {
            text: 'Cancel',
            style: 'cancel',
          },
        ])
      } else {
        Alert(
          'Permission Denied',
          'This CSA cannot be deleted because it either has a pickup or product associated with it.',
          [
            {
              text: 'Ok',
              style: 'cancel',
            },
          ],
        )
      }
    })
  }, [csa, delCSA])

  /** Action to edit CSA */
  const handleEditPress = useCallback(() => csaDetail(csa), [csa, csaDetail])

  /**  Action to private CSA */
  const handlePrivatePress = useCallback(async () => {
    const newCSAs = adminAllCSAs.map((c) => (c.id === csa.id ? { ...csa, isPrivate: !csa.isPrivate } : c))

    dispatch(setAdminCSAsAction(newCSAs))

    try {
      await updateCSA({ ...csa, isPrivate: !csa.isPrivate })
    } catch (err) {
      Alert('Error making private CSA:', errorToString(err))
      dispatch(setAdminCSAsAction(adminAllCSAs))
    }
  }, [adminAllCSAs, csa, dispatch])

  /** Action to get CSA Link */
  const handleGetLinkPress = useCallback(async () => {
    const link = `${grownbyWebsiteBaseUrl(isWholesale)}/farms/${csa.farm.id}/shop/csa/${csa.id}`
    await Clipboard.setStringAsync(link)
    Toast('Link successfully copied to clipboard!')
  }, [csa.farm.id, csa.id, isWholesale])

  /** Generate action buttons for CSA */
  const actionButtons = useMemo((): ActionBtn[] => {
    return [
      { title: 'Edit', onPress: handleEditPress },
      { title: 'Copy', onPress: handleCopyCSA },
      { title: csa.isHidden ? 'Unhide' : 'Hide', onPress: handleHideCSA },
      { title: csa.isPrivate ? 'Make Public' : 'Make Private', onPress: handlePrivatePress },
      ...(csa.isPrivate ? [{ title: 'Get Link', onPress: handleGetLinkPress }] : []),
      { title: 'Delete', onPress: handleDeletePress },
    ]
  }, [
    handleCopyCSA,
    handleDeletePress,
    handleEditPress,
    handleGetLinkPress,
    handleHideCSA,
    handlePrivatePress,
    csa.isHidden,
    csa.isPrivate,
  ])

  return (
    <ExpandableRow<CSA>
      item={csa}
      index={index}
      key={`csaRow${csa.id}`}
      rowContainerStyle={styles.rowContainerStyle}
      onRowPress={handleEditPress}
      columns={[
        {
          process: (csa: CSA) => (
            <View style={styles.imageCont}>
              <Image style={styles.csaImage} type="product" source={{ uri: csa.images[0].storageUrl }} />
              {csa.isPrivate && (
                <View style={styles.privateCsa}>
                  <Icon name="lock" color={Colors.white} />
                </View>
              )}
            </View>
          ),
          widthFlex: 0.5,
        },
        { process: (csa: CSA) => csa.name, widthFlex: 2 },
        { process: (csa: CSA) => <Text numberOfLines={10}>{csa.shortDescription}</Text>, widthFlex: 3 },
        { process: () => generateLinks(csa.name, 'Orders') },
        { process: () => generateLinks(csa.name, 'Customers') },
        {
          process: (csa: CSA) => (
            <View style={styles.actionSpace}>
              <ActionsMenuComponent<CSA> data={csa} buttons={actionButtons} />
            </View>
          ),
        },
      ]}
    />
  )
}

const useStyles = (isHidden: boolean, isLastOne: boolean) =>
  useSizeFnStyles(
    (_, isHidden: boolean, isLastOne) => ({
      rowContainerStyle: {
        backgroundColor: isHidden ? Colors.lightGray : Colors.transparent,
        borderBottomColor: isLastOne ? Colors.transparent : Colors.shades['100'],
        borderBottomWidth: isLastOne ? 0 : 1,
        alignItems: 'center',
      },
      imageCont: {
        height: 50,
        width: 50,
        overflow: 'hidden',
        borderRadius: 5,
      },
      csaImage: {
        width: '100%',
        height: '100%',
      },
      privateCsa: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.semiTransparent,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        zIndex: 10,
      },
      actionSpace: {
        paddingLeft: 15,
      },
    }),
    isHidden,
    isLastOne,
  )
