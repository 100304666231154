import React from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { SignInSummaryOrder } from '@screens/SignInSheet/components'
import { GroupedItemType } from '@screens/SignInSheet/helpers'

/** Order summary list*/
export function OrderSection({ item }: { item: GroupedItemType }) {
  return (
    <View style={styles.marginV5}>
      {item.orders.map((orderData) => (
        <SignInSummaryOrder key={orderData.orderInfo.id} orderData={orderData} showOrderInfo />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  marginV5: {
    borderRadius: 8,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
    overflow: 'hidden',
  },
})
