import { AlgoliaDocType, FILTERS } from '@models/Algolia'
import { getInitialMapFilters } from '@screens/Explore/types'
import * as React from 'react'
import { InstantSearch as InstantSearchHooks } from 'react-instantsearch'
import { Configure, InstantSearch } from 'react-instantsearch-native'
import { useSelector } from 'react-redux'

import { indexNameEnvName, searchClient } from '../config/Algolia'
import { adminFarmSelector, wholesaleSelector } from '../redux/selectors'
import { useNeedsSearchRefresh } from './useSearchIndexRefresh'

/** Similar to witAdminIndex, but enables the algolia hooks api. <Configure /> is best done inside the component, with `useConfigure({})` for flexible control of filters while avoiding creating two Configure's. */
export const withAdminIndexHooks = <T extends object>(Component: React.ComponentType<T>) => {
  return function WithAdminIndexHooks(props: T): JSX.Element {
    return (
      <InstantSearchHooks
        searchClient={searchClient}
        indexName={indexNameEnvName.admin}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Component {...props} />
      </InstantSearchHooks>
    )
  }
}

/** Used for easy setup of AdminTable with algolia provider and configure */
export const withAdminIndex = <T extends object>(Component: React.ComponentType<T>, docType: AlgoliaDocType) => {
  return function WithAdminIndex(props: T): JSX.Element {
    const farm = useSelector(adminFarmSelector)
    const needsRefresh = useNeedsSearchRefresh(farm.id, docType)

    return (
      <InstantSearch searchClient={searchClient} indexName={indexNameEnvName.admin} refresh={needsRefresh}>
        <Configure filters={`farmId:${farm.id} AND docType:${docType}`} />
        <Component {...props} />
      </InstantSearch>
    )
  }
}

/** Allows using the consumer index with hooks api */
export const withConsumerIndexHooks = <T extends object>(Component: React.ComponentType<T>) => {
  return function WithConsumerIndexHooks(props: T): JSX.Element {
    return (
      <InstantSearchHooks
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        searchClient={searchClient}
        indexName={indexNameEnvName.consumer}
      >
        <Component {...props} />
      </InstantSearchHooks>
    )
  }
}

/** Enables instant search with default configure for the geosearch index
 * @param Component the component to be connected to algolia
 * @param isAutoComplete if true, will work as a farm search. if false will work as a product geolocation search
 */
export const withConsumerIndex = <T extends object>(Component: React.ComponentType<T>, isAutoComplete = false) => {
  return function WithConsumerIndex(props: T): JSX.Element {
    const { isWholesale = false } = useSelector(wholesaleSelector)
    return (
      <InstantSearch searchClient={searchClient} indexName={indexNameEnvName.consumer}>
        {isAutoComplete ? (
          <Configure
            hitsPerPage={5}
            facetFilters={[
              FILTERS.Farm,
              FILTERS.NotInactiveFarm,
              // This must search for only wholesale farms when in wholesale mode.
              // In retail mode we search all farms
              isWholesale ? FILTERS.WholesaleFarm : '',
            ]}
          />
        ) : (
          <Configure facetFilters={getInitialMapFilters(isWholesale)} />
        )}
        <Component {...props} />
      </InstantSearch>
    )
  }
}
