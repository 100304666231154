import Colors from '@/constants/Colors'
import { ButtonClear, typography } from '@elements'
import { memo } from 'react'
import { StyleSheet } from 'react-native'
import { ButtonGroup } from 'react-native-elements'

type NotificationsScreenHeaderProps = {
  tabIndex: number
  onSwitchTab: (idx: number) => void
  onMarkAllRead: () => void
  hasUnreadNotifications: boolean
}

export const NotificationsScreenHeader = memo(function NotificationScreenHeader({
  tabIndex,
  onSwitchTab,
  onMarkAllRead,
  hasUnreadNotifications,
}: NotificationsScreenHeaderProps) {
  return (
    <>
      <ButtonGroup
        textStyle={styles.btnGroupText}
        onPress={onSwitchTab}
        selectedIndex={tabIndex}
        buttons={['Unread', 'Read']}
        containerStyle={styles.btnGroupCont}
        selectedButtonStyle={styles.selectedBtn}
      />
      {tabIndex === 0 && hasUnreadNotifications && (
        <ButtonClear title="Mark all as read" style={styles.markAllAsReadBtn} onPress={onMarkAllRead} />
      )}
    </>
  )
})
const styles = StyleSheet.create({
  btnGroupCont: {
    maxWidth: 500,
    width: '90%',
    alignSelf: 'center',
  },
  selectedBtn: {
    backgroundColor: Colors.green,
  },
  btnGroupText: {
    fontFamily: typography.body.regular,
  },
  markAllAsReadBtn: {
    alignSelf: 'center',
  },
})
