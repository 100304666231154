import { removeUndefined } from '@helpers/helpers'
import { pick } from '@helpers/typescript'
import { Farm } from './Farm'
import { isLocalPickupDistLocation, isNonPickupDistLocation, LocalPickup, Location, NonPickup } from './Location'
import { DefaultCatalog } from './Product'
import { DateRange, Frequency, Schedule } from './Schedule'

/** Location keys included in the Distribution['location'] field.
 *
 * - This is equivalent to Omit<Location, 'farm'>, bu it should not be expressed that way because if a new field is ever added tot he Location model, this would automatically include it inadvertently, although the new field would not exist in the database unless we consciously do a migration to include it. So, the lession for the kind of situation is: When a field is a subset of another larger type, the keys included should be expressed individually because that means the type is completely stable (It would not change unless we manually add a new key). Otherwise if you use the Omit<> method, it will not be stable because it would automatically include any new keys not being omitted from the source type */
export type DistLocationKeys = keyof Pick<
  Location,
  'id' | 'address' | 'cost' | 'feeWaiveOption' | 'name' | 'abbreviation' | 'regions' | 'timezone' | 'type'
>

/** This type describes the Distribution.location field.
 * - Although the dynamic type seems redundant because each ternary case picks the same fields, simplifying it with a single Pick<Loc, 'xxx' | 'xxx' > causes type errors in Denormalizers, which stop recognizing the Distribution['location'] field as a type that "extends" the Location model.
 */
export type DistroLocation<L extends Location = Location> = L extends NonPickup
  ? Pick<L, DistLocationKeys>
  : L extends LocalPickup
  ? Pick<LocalPickup, DistLocationKeys>
  : Pick<Location, DistLocationKeys>

/** A Distribution specifies product schedule information specific to a location. This includes location-specific pricing,taxes, and distribution schedules.
 * It is commonly referred to as just "Schedule", from a user perspective. Although in the code the Schedule model is only a part of the Distribution model.
 */
export type Distribution<L extends Location = Location, S extends Schedule = Schedule> = {
  id: string

  name: string

  /** The farm who setup the distribution */
  farm: Pick<Farm, 'id' | 'name' | 'status' | 'timezone'>

  /** The location of the distribution. */
  location: DistroLocation<L>

  /** The distribution schedule. */
  schedule: S

  /** The number of days before a distribution that it can still be purchased. Defaults to 1 day before */
  orderCutoffWindow: number

  notes: string

  isHidden?: boolean

  /** When true, should make this schedule not be available for placing new orders: Shouldn't appear as option for addToCart, cartItem validator should not allow it, order service should not allow orders placed for a paused schedule. Admin should work all the same */
  closed?: boolean

  /** This color will be assigned to the schedule for the farmer dashboard UI, to help manage schedules */
  color?: `#${string}`

  /** Associates the distro with a pricing catalog.
   * - If undefined, it is considered retail only.
   */
  priceGroup?:
    | {
        type: 'default-catalog'
        /**
         * - Wholesale distributions can only be purchased in the wholesale app.
         * - Retail distributions can only be purchased on the retail app.
         * - Wholesale-retail distributions can be purchased in both apps. */
        catalog: DefaultCatalog
      }
    | {
        type: 'custom-catalog'
        /** The id of a custom catalog for the distribution. (Needs future specification) */
        catalogId: string
      }
}

/** A constraint that narrows the distributions of a schedule */
export type DistributionConstraint = {
  /** To which document is this constraint applicable to */
  id: Distribution['id']
  /** If defined, expected to be a narrower frequency than the schedule frequency */
  frequency?: Frequency
  /** If defined, expected to be a narrower date range than the schedule date range */
  dateRange?: DateRange
}

/** Will select the required fields based on the location type to build the distribution.location */
export function getDistLocationFields<T extends Location>(location: T): DistroLocation<T> {
  const distroLoc = pick<T, DistLocationKeys>(
    location,
    'id',
    'name',
    'timezone',
    'abbreviation',
    'address',
    'type',
    'cost',
    'regions',
    'feeWaiveOption',
  )
  /** This 'as' is required because this can't know if the T is actually a LocalPickup or NonPickup, but this is correct for both */
  return removeUndefined(distroLoc) as DistroLocation<T>
}

export const getDistFarmFields = (farm: Farm): Distribution['farm'] =>
  pick(farm, 'id', 'name', 'status', 'timezone', 'managers')

export const isDistroNonPickup = (d: Distribution): d is Distribution<NonPickup> => isNonPickupDistLocation(d.location)

export const isDistroLocalPickup = (d: Distribution): d is Distribution<LocalPickup> =>
  isLocalPickupDistLocation(d.location)

export type EditableFieldsSchedule = Extract<
  keyof Distribution,
  'closed' | 'isHidden' | 'location' | 'name' | 'notes' | 'orderCutoffWindow' | 'schedule' | 'color' | 'priceGroup'
>

/** Schedule fields which can be edited. These may help in comparing change objects for deep equality */
export const editableFieldsSchedule: EditableFieldsSchedule[] = [
  'closed',
  'isHidden',
  'location',
  'name',
  'notes',
  'orderCutoffWindow',
  'schedule',
  'color',
  'priceGroup',
]

/** Identifies a schedule compatible with wholesale */
export function isWholesaleSchedule(schedule: Distribution) {
  return (
    schedule.priceGroup?.type === 'default-catalog' &&
    (schedule.priceGroup.catalog === DefaultCatalog.Wholesale ||
      schedule.priceGroup.catalog === DefaultCatalog.WholesaleRetail)
  )
}

/** Identifies a schedule compatible with retail */
export function isRetailSchedule(schedule: Distribution) {
  return (
    !schedule.priceGroup ||
    (schedule.priceGroup.type === 'default-catalog' &&
      (schedule.priceGroup.catalog === DefaultCatalog.Retail ||
        schedule.priceGroup.catalog === DefaultCatalog.WholesaleRetail))
  )
}
