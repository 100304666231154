import Colors from '@/constants/Colors'
import { FontAwesome5IconName, Icon, Text, Touchable, TouchableProps } from '@elements'
import { memo } from 'react'
import { StyleSheet } from 'react-native'

type UniversalTagProps = Omit<TouchableProps, 'children'> & {
  /** An optional icon to display on the left side of the tag */
  icon?: FontAwesome5IconName
  loading?: boolean
  label: string
}

/** This component should be used on any placed that we do have tags like multiple select or autocomplete, so we can make most tags consistent except special request. */
export const UniversalTag = memo(function UniversalTag({
  icon,
  label,
  onPress,
  disabled,
  loading = false,
  ...rest
}: UniversalTagProps) {
  return (
    <Touchable style={styles.main} disabled={disabled || loading} onPress={onPress} {...rest}>
      {icon && <Icon color={Colors.black} size={14} name={icon} />}
      <Text style={styles.tagText}>{label}</Text>
      {!disabled && <Icon color={Colors.black} size={14} name="times" />}
    </Touchable>
  )
})

const styles = StyleSheet.create({
  main: {
    borderWidth: 1,
    borderColor: Colors.shades['300'],
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
    borderRadius: 15,
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 5,
  },
  tagText: {
    fontSize: 14,
    marginHorizontal: 5,
    color: Colors.black,
  },
})
