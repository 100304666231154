import { Logger } from '@/config/logger'
import { userSelector } from '@/redux/selectors'
import { sendChatMessage, setConversationAsRead } from '@api/Messaging'
import { Toast } from '@elements'
import { createParticipantsData, getUpdatedUnreadIds } from '@helpers/messaging'
import { DateTime } from 'luxon'
import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ConversationViewProps } from '../ConversationView'

type UseMessagesApiProps = Pick<ConversationViewProps, 'chatSession'>

/** Data layer that interacts with the backend functions*/
export function useMessagesApi({ chatSession }: UseMessagesApiProps) {
  const user = useSelector(userSelector)
  const lastCall = useRef<number | null>()

  /** Action which will create the participants data and will send a message via function*/
  const onSendMessage = useCallback(
    async (message: string) => {
      if (!chatSession) return
      const data = createParticipantsData(chatSession, user.id, user)

      if (!data) {
        Toast('Unable to send message, please contact support.')
        return
      }

      try {
        await sendChatMessage({
          to: data.to,
          from: data.from,
          chat: chatSession,
          message: { type: 'text', content: message },
        })
      } catch (err) {
        Logger.error(err)
      }
    },
    [chatSession, user],
  )

  /** Will set the conversation flag as read for the current user */
  const onSetMessageAsRead = useCallback(async () => {
    if (!chatSession) return

    const unreadIds = getUpdatedUnreadIds(chatSession, user.id)

    if (!unreadIds) {
      return
    }

    // Do not proceed if the last call was made under 5 seconds ago
    const now = DateTime.now().toMillis()
    if (lastCall.current && now - lastCall.current < 5 * 1000) {
      return
    }

    lastCall.current = now

    try {
      await setConversationAsRead({ chatSessionId: chatSession.id, unreadIds })
    } catch (err) {
      Logger.error('onSetMessageAsRead error:', err)
    }
  }, [chatSession, user.id])

  return { onSendMessage, onSetMessageAsRead }
}
