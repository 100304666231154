import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { RootState } from './reducers/types'

import { FarmCachedData, getEmptyFarmCachedData } from '@/constants/types/navProps'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { useMemo } from 'react'

/** A typed use selector which expects a function of the root state. The fn must return the selected slice */
export const useSelectorRoot: TypedUseSelectorHook<RootState> = useSelector

// Admin Selectors

export const adminFarmSelector = (state: RootState) => state.adminPersist.farm
export const adminLocsSelector = (state: RootState) => state.adminPersist.locations
export const adminSchedulesSelector = (state: RootState) => state.adminPersist.distributions
export const isAdminSelector = (state: RootState) => state.adminPersist.isAdmin
export const adminCsasSelector = (state: RootState) => state.adminState.CSAs
export const adminParamsSelector = (state: RootState) => state.adminState.navProps
export const adminFarmIdSelector = (state: RootState) => state.adminPersist.adminFarmId
export const adminPermissionsSelector = (state: RootState) => state.adminPersist.permissions
export const certificationSelector = (state: RootState) => state.adminPersist.certifications
export const categorySelector = (state: RootState) => state.adminPersist.categories
export const adminProductsSelector = (state: RootState) => state.adminPersist.products
export const queueAlgoliaProducts = (state: RootState) => state.adminState.queueAlgoliaProducts
export const adminFeaturesAvailableSelector = (state: RootState) => state.adminState.featuresAvailable
export const adminCartInfoSelector = (state: RootState) => state.adminPersist.cartInfo
/** Selects the order creator cart service */
export const cartServiceOrderCreatorSelector = (state: RootState) => state.adminState.cartService
export const isWholesaleOrderCreatorSelector = (state: RootState) => state.adminPersist.isWholesaleOrderCreator
/** Selects the order edit cart service */
export const cartServiceOrderEditSelector = (state: RootState) => state.adminState.cartServiceOrderEdit

// User Selectors
/** User selector returns the logged in user */
export const userSelector = (state: RootState) => state.user
export const notificationsSelector = (state: RootState) => state.user.notifications
export const promotionsSelector = (state: RootState) => state.user.promotions
export const unsubscribersSelector = (state: RootState) => state.appState.unsubscribers
export const isInstitutionAccountSelector = (state: RootState) =>
  state.user.institution?.accountType === 'wholesale-buyer'
export const unreadMessagesNoSelector = (state: RootState) => state.appState.unreadMessagesNo

// AppPersist Selectors
export const farmsSelector = (state: RootState) => state.appPersist.farmsAssoc
export const sessionLocationSelector = (state: RootState) => state.appPersist.sessionLocation
export const consumerCartInfoSelector = (state: RootState) => state.appPersist.cartInfo
export const addonsPurchasesSelector = (state: RootState) => state.appPersist.availAddonsPurchases
export const wholesaleSelector = (state: RootState) => state.appPersist.wholesale

// AppState Selectors
export const searchLocationSelector = (state: RootState) => state.appState.searchLocation
export const currentHoverSelector = (state: RootState) => state.appState.currentHover
export const mapFiltersSelector = (state: RootState) => state.appState.mapFilters
export const isAdminOpenSelector = (state: RootState) => state.appState.isAdminOpen
export const layoutSelector = (state: RootState) => state.appState.layout
export const layoutSizeSelector = (state: RootState) => state.appState.layout.size
/** Accesses the data passed between screens, which is stored in the navProps state */
export const navPropsSelector = (state: RootState) => state.appState.navProps
/** Selects the screen name currently in focus. This value will change whenever the user navigates between screens */
export const navRouteSelector = (state: RootState) => state.appState.navRoute
export const hasListenersSelector = (state: RootState) => state.appState.hasListeners
export const unreadCountSelector = (state: RootState) => state.appPersist.unreadCount
export const notificationsListSelector = (state: RootState) => state.appPersist.notifications
export const customShareSelector = (state: RootState) => state.appPersist.customShare
/** Selects the consumer cart service */
export const cartServiceConsumerSelector = (state: RootState) => state.appState.cartService
export const featuresAvailableSelector = (state: RootState) => state.appState.navProps.featuresAvailable

/** Gets the farm data from cache for the farm currently in the navigation prop state */
export const currentFarmCacheSelector = ({
  appState: {
    navProps: { farm, farmCache },
  },
}: RootState): FarmCachedData => {
  // if farm exists, return the farmCache[farm.urlSafeSlug] or farmCache[farm.id] or emptyFarmCachedData
  return farm ? farmCache[farm.urlSafeSlug] || farmCache[farm.id] || getEmptyFarmCachedData() : getEmptyFarmCachedData()
}

/** Gets any cached farm data available for the specified farmSlug or id */
export const useFarmDataFromCache = (farmSlug: string): FarmCachedData => {
  const { farmCache } = useSelector(navPropsSelector)
  const currentFarmData = useSelector(currentFarmCacheSelector)

  return useMemo(() => {
    // First try getting the cached data for the slug provided (slug or id)
    const cachedDataForSlug = farmCache[farmSlug]

    // If it is in the cache, done
    if (cachedDataForSlug) return cachedDataForSlug

    // If it's not found under that slug, perhaps it is under the other ref as key (id or slug)
    if (currentFarmData.farm && matchesIdOrSlug(currentFarmData.farm, farmSlug)) return currentFarmData

    return getEmptyFarmCachedData()
  }, [farmCache, farmSlug, currentFarmData])
}
