import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { useFarmProp } from '@/hooks/useFarmProp'
import { useFocusFx } from '@/hooks/useFocusFx'
import { ShoppingStackParamList } from '@/navigation/types'
import { addNavProp } from '@/redux/actions/appState'
import { RootState } from '@/redux/reducers/types'
import { farmsSelector, navPropsSelector, userSelector, wholesaleSelector } from '@/redux/selectors'
import { toggleFarmAssociationFavorite } from '@api/FarmAssociations'
import { loadFeaturedByFarm } from '@api/Products'
import { loadReviewsByFarmAndUser, updateReview } from '@api/Reviews'
import { ProductCardProps } from '@components'
import { Modal, Toast } from '@elements'
import shareContent from '@helpers/sharing'
import { PartialExcept } from '@helpers/typescript'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { FarmStatus } from '@models/Farm'
import { Review } from '@models/Review'
import { FarmAssociation, Role } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { calculateReviewChange } from '@shared/Reviews'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InviteFarm } from './InviteFarm'
import { GROWNBY_APP_URL } from '@shared/BaseUrl'

/** Data layer for the FarmDetailScreen */
export function useFarmDetailData() {
  const { params } = useRoute<RouteProp<ShoppingStackParamList, 'FarmDetailScreen'>>()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmDetailScreen'>>()

  const { farmSlug, goBack } = params
  const [isStarred, setIsStarred] = useState<boolean>()
  const [numFavorites, setNumFavorites] = useState(0)
  const [isFavoriteCallbackOpen, setIsFavoriteCallbackOpen] = useState(false)
  const myFarms = useSelector<RootState, FarmAssociation[]>(farmsSelector)
  const user = useSelector(userSelector)
  const { isWholesale } = useSelector(wholesaleSelector)
  const { farm: farmNavProp } = useSelector(navPropsSelector)

  const dispatch = useDispatch()

  const { loading: loadingFarm, err: errorFarm, data: farm } = useFarmProp({ farmSlug })

  const reviewsApiFx = useApiFx(loadReviewsByFarmAndUser, [farm?.id, user?.id], !!farm?.id, {
    failedConditionMode: 'keep-loading',
  })

  const { loading: loadingProds, data: featuredProds } = useApiFx(
    loadFeaturedByFarm,
    [farmSlug, isWholesale],
    !!farmSlug,
    { noRefocus: true },
  )

  // Update numFavorites
  useFocusFx(() => {
    setNumFavorites(farm?.numFavorites ?? 0)
  }, [farm?.numFavorites])

  //Check if user has farm in favorites
  useFocusFx(() => {
    // we must check if the farm.urlSafeSlug is equal to farmSlug since the former can be derived from
    // a previous redux state and therefore might be different from the url based one.
    if (!farmSlug || !farm || !matchesIdOrSlug(farm, farmSlug)) return
    if (!user?.id) {
      setIsStarred(false)
      return
    }
    //have to get current farmAssociation not all farmAssociation(myFarms) before checking if it's starred
    const curFarmAssociation = myFarms.find((f) => f.id === farm.id)
    //it will be starred only when isFavorite is true
    if (curFarmAssociation?.isFavorite) {
      setIsStarred(true)
    } else {
      setIsStarred(false)
    }
  }, [user?.id, farmSlug, myFarms, farm])

  const onGoBackPress = useCallback(() => {
    if (goBack === 'explore') navigation.navigate('Home', { screen: 'ExploreScreen' })
    if (goBack === 'home') navigation.navigate('Home', { screen: 'HomeScreen' })
    if (goBack === 'orders') navigation.navigate('OrdersNavigator', { screen: 'Orders' })
    else navigation.navigate('MyFarmsScreen')
  }, [goBack, navigation])

  const onShareFarm = useCallback(async () => {
    if (!farm || !matchesIdOrSlug(farm, farmSlug)) return
    await shareContent({
      url: `${GROWNBY_APP_URL}/farms/${farm.urlSafeSlug}`,
      title: `${farm.name} on GrownBy: ${GROWNBY_APP_URL}/farms/${farm.urlSafeSlug}`,
    })
  }, [farmSlug, farm])

  /** Is meant to be called ON press, meaning this must handle both the db update and the state update */
  const onToggleFavorite = useCallback(() => {
    if (!farm?.id) return
    setIsFavoriteCallbackOpen(false)

    //farmAssociation would check current user's role and its ifFavorite status
    //if user is manager or admin, they can't unfavorite their own farm (they can only favorite it)
    if (
      myFarms.find((f) => f.farmId === farm?.id && (f.role === Role.Admin || f.role === Role.Manager) && f.isFavorite)
    ) {
      return Toast('You can not unfavorite your own farm')
    }
    //This will make sure in the UI that number never goes below 0 if someone click button too fast. However, the data in the firestore should be correct. After refreshing the page, the number should be correct.
    setNumFavorites(isStarred ? (numFavorites <= 1 ? 0 : numFavorites - 1) : numFavorites + 1)
    setIsStarred((prev) => !prev)
    toggleFarmAssociationFavorite(farm.id, !!isStarred).then(() => {
      Toast(isStarred ? 'Farm removed from favorites' : 'Farm added to favorites')
    })
  }, [farm?.id, myFarms, isStarred, numFavorites])

  const onFooterPress = useCallback(() => {
    if (farm?.status === FarmStatus.Registered) {
      navigation.navigate('FarmShop', { farmSlug })
    } else {
      // We should not be able to get here until the farm is loaded, but if we do show a message.
      if (!farm) {
        Toast('Please wait until the farm is loaded.')
      } else {
        Modal(<InviteFarm farm={farm} />, { title: 'Invite to GrownBy' })
      }
    }
  }, [farm, navigation, farmSlug])

  const onPressCsaNavigate = useCallback<NonNullable<ProductCardProps['onPressCsaNavigate']>>(
    (prod, csa) => {
      if (!csa) return Toast('No CSA Groups available in this Product')

      navigation.navigate('CSADetails', { csaId: csa.id, farmSlug: prod.farm.urlSafeSlug })
    },
    [navigation],
  )

  const onPressMobileNavigate = useCallback<NonNullable<ProductCardProps['onPressMobileNavigate']>>(
    (p) => {
      navigation.navigate('ProductDetails', { farmSlug: p.farm.urlSafeSlug, productId: p.id })
    },
    [navigation],
  )

  /** Is meant to be called ON press, meaning this must handle both the db update and the state update */
  const onFlagPress = useCallback(
    async (item: Review) => {
      if (!reviewsApiFx.data) return

      let update: PartialExcept<Review, 'id'>

      // If it has been flagged by the user, remove Flag for the user
      if (item.flaggedBy && item.flaggedBy[user.id]) {
        delete item.flaggedBy[user.id]
        update = {
          id: item.id,
          flaggedBy: item.flaggedBy,
          flaggedCount: (item.flaggedCount -= 1),
        }
      } else {
        // Add Flag
        update = {
          id: item.id,
          flaggedBy: { ...item.flaggedBy, [user.id]: true },
          flaggedCount: (item.flaggedCount += 1),
        }
      }
      const currData = { ...reviewsApiFx.data }

      // Optimistic UI state change
      reviewsApiFx.setState((prev) => ({
        ...prev,
        data: {
          myReview: item.id === currData.myReview?.id ? { ...currData.myReview, ...update } : currData.myReview,
          reviews: currData.reviews.map((rev) => (rev.id === update.id ? { ...rev, ...update } : rev)),
        },
      }))

      try {
        await updateReview(update)
      } catch (error) {
        Logger.error(error)
        Toast('Something went wrong while updating this review')

        // Revert the state on error
        reviewsApiFx.setState((prev) => ({ ...prev, data: currData }))
      }
    },
    [reviewsApiFx, user.id],
  )

  /** Will be called AFTER a review is deleted, meaning this must only handle the state change */
  const onDeleteReview = useCallback(() => {
    if (farm) {
      const farmReviews = calculateReviewChange(farm, reviewsApiFx.data!.myReview, undefined)
      dispatch(addNavProp({ farm: { ...farm, reviews: farmReviews } }))
    }
    reviewsApiFx.setState((prev) => ({
      ...prev,
      data: !prev.data
        ? undefined
        : {
            myReview: undefined,
            reviews: prev.data.reviews.slice(1),
          },
    }))
  }, [dispatch, farm, reviewsApiFx])

  /** This will be called AFTER update OR creation, meaning this must only handle the state change */
  const onUpdateReview = useCallback(
    (item: Review) => {
      // This will be called on update OR create

      // Update the review value on local farm
      if (farm && farmNavProp && farm.id === farmNavProp.id) {
        const farmReviews = calculateReviewChange(farm, reviewsApiFx.data?.myReview, item)
        dispatch(addNavProp({ farm: { ...farm, reviews: farmReviews } }))
      }

      reviewsApiFx.setState((prev) => ({
        ...prev,
        data: !prev.data
          ? undefined
          : {
              myReview: item,

              reviews: prev.data.reviews.find((r) => r.id === item.id)
                ? // If it exists, replace it.
                  prev.data.reviews.map((r) => (r.id === item.id ? item : r))
                : // If it's new, add it to the array
                  [...prev.data.reviews, item],
            },
      }))
    },
    [dispatch, farm, farmNavProp, reviewsApiFx],
  )

  return {
    ...params,
    onFooterPress,
    onToggleFavorite,
    onShareFarm,
    onGoBackPress,
    farmSlug,
    isStarred,
    numFavorites,
    isFavoriteCallbackOpen,
    featuredProds,
    loadingProds,
    setIsFavoriteCallbackOpen,
    farm,
    loadingFarm,
    errorFarm,
    onPressCsaNavigate,
    onPressMobileNavigate,
    onUpdateReview,
    onDeleteReview,
    onFlagPress,
    reviewsApiFx,
  }
}

export type FarmDetailDataType = ReturnType<typeof useFarmDetailData>
