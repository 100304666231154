import { LoadingView, TextH1, Toast } from '@elements'
import { PaymentCard } from '@screens/Orders/PaymentCard'
import React, { useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { ListItem } from 'react-native-elements'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { useApiFx } from '@/hooks/useApiFx'
import { wholesaleSelector } from '@/redux/selectors'
import { openUrl } from '@helpers/client'
import { useSelector } from 'react-redux'
import { useSignInSheetContext } from '../../useSignInSheetData'

/** Section of invoices that can be expanded */
export function InvoiceSection({ userId }: { userId?: string }) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { getUserInvoices } = useSignInSheetContext()

  const [invoicesExpanded, setInvoicesExpanded] = useState(false)

  const invoices = useApiFx(getUserInvoices, [userId], !!userId, {
    onError: () => Toast('Error fetching invoices'),
  })

  return (
    <LoadingView success={invoices.data} loading={invoices.loading} style={styles.card}>
      {(data) => (
        <ListItem.Accordion
          content={
            <>
              <ListItem.Content>
                <TextH1 size={18}>{`Invoices (${data.length})`}</TextH1>
              </ListItem.Content>
            </>
          }
          isExpanded={invoicesExpanded}
          onPress={() => setInvoicesExpanded((c) => !c)}
        >
          <FlatList
            data={data}
            scrollEnabled={isWeb}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item) => item.id}
            renderItem={({ item: inv }) => (
              <PaymentCard
                invoice={inv}
                onPress={() => openUrl(`${grownbyWebsiteBaseUrl(isWholesale)}/external/invoice/${inv.id}`)}
              />
            )}
          />
        </ListItem.Accordion>
      )}
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
    overflow: 'hidden',
  },
})
