import { Header, useCreateSearchParams } from '@components'
import { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'

import { ConnectedResults } from '../../components/Search/ConnectedResults'
import { isMobile, isWeb } from '../../constants/Layout'
import { useLocation } from '../../hooks/useSessionLocation'
import { withConsumerIndex } from '../../hooks/withAlgoliaIndex'
import { ConnectedGeoSearch } from './ConnectedGeoSearch'
import AllFiltersModal from './Filters'
import { MobileExploreInput } from './MobileExploreInput'
import { SearchConfigure } from './SearchConfigure'
import { VirtualParamRefinement } from './VirtualParamRefinement'
import { ExploreContext, ExploreContextType } from './components/ExploreContext'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { withWholesaleAuth } from '@/hooks/withWholesaleAuth'
import { HomeParamList } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { SafeAreaView } from '@elements'
import { Coordinate } from '@models/Coordinate'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useDispatch } from 'react-redux'
import { useAskLocationModal } from './components/AskLocationModal'

function ExploreScreenComp() {
  useLocation()
  useAskLocationModal()
  const { isLargeDevice, isSmallDevice } = useDeviceSize()
  const [condensedView, setCondensedView] = useState<'list' | 'map'>('list')
  const [showModal, setShowModal] = useState(false)
  const [showCarousel, setShowCarousel] = useState(false)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()
  const createSearchParams = useCreateSearchParams()

  const dispatch = useDispatch()

  const redoSearch = useCallback(
    (coords: Coordinate | undefined) => {
      if (!coords) return
      dispatch(setSearchLocation({ coordinate: coords, name: '' }))
      navigation.setParams(createSearchParams({ coords }))
    },
    [createSearchParams, dispatch, navigation],
  )

  const contextData = useMemo<ExploreContextType>(() => {
    return {
      showModal,
      setShowModal,
      condensedView,
      setCondensedView,
      showCarousel,
      setShowCarousel,
      redoSearch,
    }
  }, [showModal, setShowModal, condensedView, setCondensedView, showCarousel, setShowCarousel, redoSearch])

  const styles = useStyles()
  return (
    <ExploreContext.Provider value={contextData}>
      <SafeAreaView style={styles.screen}>
        <SearchConfigure />
        <VirtualParamRefinement attribute="category" paramKey="category" />
        <VirtualParamRefinement attribute="farm.practices" paramKey="certifications" />
        <VirtualParamRefinement attribute="farm.tags" paramKey="tags" />
        <AllFiltersModal />
        {/* On all web devices, we want to show this header since there is no bottom navigation */}
        {isWeb && <Header noSafeareaMargin />}
        {/* On all mobile and on small screen web, we want to show this input since there will be no header input */}
        {(isMobile || isSmallDevice) && <MobileExploreInput />}
        <View style={styles.container}>
          {(isLargeDevice || condensedView === 'list') && (
            <View style={styles.left}>
              <ConnectedResults />
            </View>
          )}
          {(isLargeDevice || condensedView === 'map') && (
            <View style={styles.right}>
              <ConnectedGeoSearch />
            </View>
          )}
        </View>
      </SafeAreaView>
    </ExploreContext.Provider>
  )
}

export const ExploreScreen = withWholesaleAuth(withConsumerIndex(ExploreScreenComp, false))

const useStyles = () =>
  useLayoutFnStyles(({ height }) => ({
    screen: {
      flex: 1,
      // This prevents the map component from exceeding the screen height, which makes the entire screen scrollable
      maxHeight: height,
    },
    container: {
      flex: 1,
      flexDirection: 'row',
    },
    left: {
      flex: 3,
      maxWidth: 600,
    },
    right: {
      flex: 2,
    },
  }))
