import { useCartService } from '@/hooks/useCart'
import { useFocusFx } from '@/hooks/useFocusFx'
import { KeyedState } from '@/hooks/useKeyedState'
import { DraftOrder } from '@models/DraftOrder'
import { isCartStandard } from '@models/Order'
import { Product } from '@models/Product'
import { useRef } from 'react'
import { OrderCreatorStateProductsFilters } from '../OrderCreatorScreen/OrderCreatorScreen.helper'
import { useSchedulesAndDatesReusable } from '../OrderCreatorScreen/useSchedulesAndDates-reusable'

/** Data layer for any state related to schedules and dates in the order edit screen */
export function useSchedulesAndDates({
  draft,
  keyedState,
  ...opts
}: {
  draft: DraftOrder | undefined
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
  isLoadingProducts: boolean
  dbProds: Product[] | undefined
  isAdmin: boolean
}) {
  const { cart, isCartInit } = useCartService({
    cartServiceType: 'orderEdit',
    farmId: draft?.farm.id || '',
    isWholesale: draft?.isWholesale,
  })

  const data = useSchedulesAndDatesReusable({
    ...opts,
    keyedState,
    orderType: 'standard',
    farmId: draft?.farm.id || '',
    isWholesale: draft?.isWholesale,
  })

  /** This stores the id of the last draft order that was initialized with the schedule and date */
  const draftIdInitialized = useRef('')
  // This fx should auto-select a schedule and date on mount based on the cart items of the draft order
  useFocusFx(
    () => {
      if (!draft || !isCartInit || (!!draftIdInitialized.current && draftIdInitialized.current === draft.id)) {
        /** Should wait for:
         * - The draft order being edited
         * - The cart for the draft order must be initialized
         * - The last draftId initialized must not be the same as the current draft id
         */
        return
      }

      // Find the first cart standard item in the cart
      const cartItem = cart.find(isCartStandard)

      if (cartItem) {
        // If found use its schedule and pickup date as initial values
        const [, , setState] = keyedState
        setState((prev) => ({ ...prev, schedule: cartItem.distribution, pickupDate: cartItem.pickups[0] }))
        draftIdInitialized.current = draft.id
      }
    },
    [cart, isCartInit, keyedState, draft],
    { noRefocus: true },
  )

  return data
}
