import InputLabel from '@/admin/components/InputLabel'
import { AdminDrawerParamList } from '@/admin/navigation/types'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { loadCSAsByFarm } from '@api/CSAs'
import {
  ButtonClear,
  CheckBox,
  ErrorText,
  FormPickerInput,
  LoadingView,
  Text,
  ToggleButton,
  Tooltip,
  ToolTips,
} from '@elements'
import { CSA } from '@models/CSA'
import { hasUnits, Product } from '@models/Product'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useFormikContext } from 'formik'
import { memo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { AdvancedPricingForm } from '../../AdvancedPricing'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { BasicInfoForm } from '../BasicInformation.helpers'

type CsaSectionProps = {
  farmId: string
  product: Product | undefined
  checkboxOnClick: (csa: CSA) => void
  csaOnChange: (value: string, idx: number) => void
}
export const CsaSection = memo(function CsaSection({ farmId, product, checkboxOnClick, csaOnChange }: CsaSectionProps) {
  /** CSAs should be re-fetched on every re-focus to ensure this alwasy has the latest csa data. This should not filter by isHidden, because a product associated with a hidden csa should still show the selected one */

  const csasFx = useApiFx(loadCSAsByFarm, [farmId])
  const { values, errors, touched, setFieldValue, setTouched } = useFormikContext<
    BasicInfoForm & AdvancedPricingForm & ProductTypeForm
  >()

  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()

  const type = values.type

  return (
    <LoadingView loading={csasFx.loading} success={csasFx.data} error={csasFx.err}>
      {(csas) =>
        csas.length >= 1 ? (
          /** TODO: For multiple currently selected CSAs (more than one) in existing product, we still show checkboxes and allow farmer to uncheck them because
           * we will limit one product only has one CSA for new products. This is a temporary solution and gradual migration for now. Right now, farmers can still keep current multiple CSAs like before or uncheck them, but future products will only have one CSA. This code could be removed in the future when enough products have become single-csa over time.
           */

          // If we're editing an existing product, and it has multiple csa assignments, show multiple checkboxes.
          !!product?.csa && product.csa?.length > 1 ? (
            <View style={globalStyles.padding10}>
              <View style={hasUnits({ type }) ? styles.standardCSAWrapper : styles.shareCSAWrapper}>
                <Text style={styles.subSectionTitle}>Select a CSA {hasUnits({ type }) ? '(Optional)' : '*'}</Text>
                <Tooltip
                  id={hasUnits({ type }) ? ToolTips.SELECT_CSA_STANDARD : ToolTips.SELECT_CSA}
                  title="CSA"
                  style={{ marginLeft: 10 }}
                />

                <View style={{ width: 30 }} />
                <ToggleButton
                  title="Only buy on CSA pages"
                  onChange={(checked) => setFieldValue('hideFromShop', checked)}
                  value={!!values.hideFromShop}
                />
                {!!errors.hideFromShop && <ErrorText>{errors.hideFromShop}</ErrorText>}
              </View>
              <FlatList
                columnWrapperStyle={styles.csaContainer}
                data={csas}
                renderItem={({ item: csa }) => (
                  <CheckBox
                    style={{ marginLeft: 10 }}
                    title={`${csa.name}${csa.isHidden ? ' (Hidden)' : ''}`}
                    key={csa.id}
                    onChange={() => {
                      checkboxOnClick(csa)
                      setTouched({ ...touched, csas: true })
                    }}
                    checked={!!(values.csas && values.csas.find((csaId) => csaId === csa.id))}
                  />
                )}
                keyExtractor={(item) => item.id}
                numColumns={3}
              />
              {!!touched.csas && !!errors.csas && <ErrorText style={globalStyles.padding10}>{errors.csas}</ErrorText>}
              <ErrorText style={globalStyles.padding10}>
                * Products can only be associated with one CSA going forward. You can remove the additional CSAs now or
                in the future when your pickups are complete.
              </ErrorText>
            </View>
          ) : (
            // In 'add' mode or 'edit' mode with only one CSA, the form will show single-select dropdown
            <View style={styles.csaOption}>
              <View style={styles.pickerCont}>
                <FormPickerInput
                  label={
                    <InputLabel
                      label={`Select a CSA ${hasUnits({ type }) ? '(Optional)' : '*'}`}
                      tooltipId={hasUnits({ type }) ? ToolTips.SELECT_CSA_STANDARD : ToolTips.SELECT_CSA}
                    />
                  }
                  onValueChange={csaOnChange}
                  value={values.csas?.[0]}
                  items={csas.map((csa) => ({
                    label: `${csa.name}${csa.isHidden ? ' (Hidden)' : ''}`,
                    value: csa.id,
                  }))}
                  errorMessage={touched.csas ? errors.csas : ''}
                />
                {!!errors.hideFromShop && <ErrorText style={styles.csaError}>{errors.hideFromShop}</ErrorText>}
              </View>
              <View style={styles.onlyShowOnCsaContainer}>
                <ToggleButton
                  title="Only buy on CSA pages"
                  onChange={(checked) => setFieldValue('hideFromShop', checked)}
                  value={!!values.hideFromShop}
                />
              </View>
            </View>
          )
        ) : (
          // In case the farm has no CSA groups, we show a link to the csa creation page
          <View>
            <InputLabel
              label={`Select a CSA ${hasUnits({ type }) ? '(Optional)' : '*'}`}
              tooltipId={hasUnits({ type }) ? ToolTips.SELECT_CSA_STANDARD : ToolTips.SELECT_CSA}
              style={globalStyles.padding10}
            />
            <ButtonClear
              textStyle={{ textDecorationLine: 'underline' }}
              title="Create a CSA Group"
              onPress={() =>
                (navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('CSAStackNav', {
                  screen: 'CSADetail',
                  params: {
                    goBack: 'AddProduct',
                  },
                })
              }
            />
            <ErrorText style={globalStyles.padding10}>
              Please click this link and go to CSA page to create a CSA Group, then you may add it to this product.
            </ErrorText>
          </View>
        )
      }
    </LoadingView>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingBottom: 10,
  },

  subSectionTitle: {
    color: Colors.black,
    marginLeft: -5,
    fontSize: 16,
  },
  standardCSAWrapper: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  shareCSAWrapper: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  csaContainer: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  csaOption: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  onlyShowOnCsaContainer: {
    justifyContent: 'flex-end',
    paddingBottom: 10,
    marginLeft: 10,
  },
  pickerCont: {
    width: 300,
  },
  csaError: {
    marginLeft: 10,
  },
})
