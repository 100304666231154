import { useApiFx } from '@/hooks/useApiFx'
import { HomeParamList } from '@/navigation/types'
import { loadCategoryImages } from '@api/Categories'
import { Image } from '@components'
import { LoadingView, Text } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo } from 'react'
import { Pressable, ScrollView, StyleSheet } from 'react-native'

/** List of static categories with their image */
export const CategoryCards = memo(function CategoryCards() {
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const categoriesFx = useApiFx(loadCategoryImages, [], undefined, { noRefocus: true })

  return (
    <LoadingView switchMode loading={categoriesFx.loading}>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        style={styles.main}
        horizontal
        contentContainerStyle={styles.contentContainer}
      >
        {categoriesFx.data?.map((el) => (
          <Pressable
            // Will navigate to search screen with the category search values concatenated
            onPress={() =>
              navigation.navigate('SearchScreen', { q: el.searchValues.join(', '), isGlobal: true, locType: 'zip' })
            }
            key={el.title}
            style={styles.item}
          >
            <Image source={el.imageURL} style={styles.image} />
            <Text style={styles.title} numberOfLines={2} size={14} type="medium">
              {el.title}
            </Text>
          </Pressable>
        ))}
      </ScrollView>
    </LoadingView>
  )
})

const styles = StyleSheet.create({
  main: {
    width: '100%',
  },
  contentContainer: {
    gap: 20,
    justifyContent: 'center',
    flexGrow: 1,
  },
  item: {
    alignItems: 'center',
    gap: 10,
    flexBasis: 177,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 100,
    maxWidth: 250,
    aspectRatio: 0.5,
  },
  image: {
    width: '100%',
    flex: 1,
    borderRadius: 16,
    overflow: 'hidden',
  },
  title: {
    // ensures that the image height is not altered when texts is showing on 2 lines
    height: 50,
  },
})
