import InputLabel from '@/admin/components/InputLabel'
import { useApiFx } from '@/hooks/useApiFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminFarmSelector } from '@/redux/selectors'
import { loadFarmCategories } from '@api/Categories'
import { AddNewDropdownItem, FormInput, FormPickerInput, ToolTips } from '@elements'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { AdvancedPricingForm } from '../../AdvancedPricing'
import { createUnitOrSetValue } from '../../helpers/baseUnitAdd.helper'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { BasicInfoForm } from '../BasicInformation.helpers'

/** Fields used on Share Product Form */
export function ProductShareDescriptionFields() {
  const farm = useSelector(adminFarmSelector)
  const { isLargeDevice } = useDeviceSize()

  const { handleChange, values, errors, handleBlur, touched, setFieldValue, setTouched } = useFormikContext<
    BasicInfoForm & AdvancedPricingForm & ProductTypeForm
  >()
  const canEdit = !!values.templateProductId

  const categoriesFx = useApiFx(loadFarmCategories, [farm.localCategories], undefined, { once: true })
  const producers = useMemo(() => [...(farm.localProducers ?? [])].sort(), [farm.localProducers])

  const categoryOnChange = useCallback(
    (value: string) => {
      createUnitOrSetValue(value, 'category', farm.id, (v) =>
        setFieldValue('category', v).then(() => setTouched({ ...touched, category: true })),
      )
    },
    [farm.id, setFieldValue, setTouched, touched],
  )

  // Producer dropdown picker handler
  const producerOnChange = useCallback(
    (value: string) => {
      createUnitOrSetValue(value, 'producer', farm.id, (v) =>
        setFieldValue('producer', v).then(() => setTouched({ ...touched, producer: true })),
      )
    },
    [farm.id, setFieldValue, setTouched, touched],
  )

  return (
    <>
      <FormInput
        disabled={canEdit}
        value={values.shareSku}
        label={<InputLabel label="SKU" tooltipId={ToolTips.OPTION_SKU} />}
        placeholder="SKU"
        onChangeText={handleChange('shareSku')}
        onBlur={handleBlur('shareSku')}
        errorMessage={touched.shareSku ? errors.shareSku : ''}
      />
      <FormInput
        disabled={canEdit}
        value={values.shortDescription}
        label={<InputLabel label="Short Description" tooltipId={ToolTips.SHORTDESCRIPTION} required />}
        placeholder="Short Description"
        onChangeText={handleChange('shortDescription')}
        onBlur={handleBlur('shortDescription')}
        errorMessage={touched.shortDescription ? errors.shortDescription : ''}
      />
      <FormInput
        disabled={canEdit}
        value={values.longDescription}
        label={<InputLabel label="Long Description" tooltipId={ToolTips.LONGDESCRIPTION} required />}
        placeholder="A detailed description of the product"
        onChangeText={handleChange('longDescription')}
        onBlur={handleBlur('longDescription')}
        errorMessage={touched.longDescription ? errors.longDescription : ''}
        numberOfLines={isLargeDevice ? 4 : 2}
        multiline
        style={styles.longDescriptionCont}
      />
      <FormInput
        disabled={canEdit}
        value={values.productionMethod?.toString() || ''}
        label="Production Method"
        placeholder="Organic, Conventional, Grassfed, etc"
        onChangeText={handleChange('productionMethod')}
        onBlur={handleBlur('productionMethod')}
        errorMessage={touched.productionMethod ? errors.productionMethod : ''}
      />

      <FormPickerInput
        loading={categoriesFx.loading}
        disabled={canEdit}
        onValueChange={categoryOnChange}
        label={<InputLabel label="Category" tooltipId={ToolTips.BASIC_CATEGORY} required />}
        value={values.category}
        items={(categoriesFx.data ?? [])
          .map((category) => ({ label: category.name, value: category.name }))
          .concat([AddNewDropdownItem])}
        errorMessage={touched.category ? errors.category : ''}
      />

      <FormPickerInput
        disabled={canEdit}
        onValueChange={producerOnChange}
        label={<InputLabel label="Producer" tooltipId={ToolTips.BASIC_PRODUCER} />}
        errorMessage={errors.producer}
        value={values.producer}
        items={
          producers.length
            ? producers.map((producer) => ({ label: producer, value: producer })).concat([AddNewDropdownItem])
            : [AddNewDropdownItem]
        }
      />

      <View style={styles.sizeContainer}>
        <FormInput
          disabled={canEdit}
          containerStyle={styles.sizeInput}
          value={values.shareInfo?.suggestedFamilySize?.toString()}
          label={<InputLabel label="Suggested family size" />}
          placeholder="ex. 4"
          onChangeText={handleChange('shareInfo.suggestedFamilySize')}
          onBlur={handleBlur('shareInfo.suggestedFamilySize')}
          //errors.shareInfo will expect shareInfo as a string, not an object
          //@ts-ignore
          errorMessage={touched.shareInfo?.suggestedFamilySize ? errors.shareInfo?.suggestedFamilySize : ''}
        />
        <FormInput
          disabled={canEdit}
          containerStyle={styles.sizeInput}
          value={values.shareInfo?.minimumNumberOfItems?.toString()}
          label={<InputLabel label="Minimum items" />}
          placeholder="ex. 8"
          onChangeText={handleChange('shareInfo.minimumNumberOfItems')}
          onBlur={handleBlur('shareInfo.minimumNumberOfItems')}
          //@ts-ignore
          errorMessage={touched.shareInfo?.minimumNumberOfItems ? errors.shareInfo?.minimumNumberOfItems : ''}
        />
        <FormInput
          disabled={canEdit}
          containerStyle={styles.sizeInput}
          value={values.shareInfo?.maximumNumberOfItems?.toString()}
          label={<InputLabel label="Maximum items" />}
          placeholder="ex. 8"
          onChangeText={handleChange('shareInfo.maximumNumberOfItems')}
          onBlur={handleBlur('shareInfo.maximumNumberOfItems')}
          //@ts-ignore
          errorMessage={touched.shareInfo?.maximumNumberOfItems ? errors.shareInfo?.maximumNumberOfItems : ''}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  sizeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  sizeInput: {
    flex: 1,
    flexBasis: 150,
  },
  longDescriptionCont: {
    height: 100,
  },
})
