import { useDispatch, useSelector } from 'react-redux'
import { farmsSelector, userSelector } from '../../redux/selectors'
import { Role } from '@models/User'
import { isBefore } from '@helpers/time'
import { dateTimeInZone } from '@models/Timezone'
import { useApiFx } from '../../hooks/useApiFx'
import { estimateIsShareMember, loadCustomShareByFarms } from '@api/CustomShares'
import { setCustomShareModalData } from '../../redux/actions/appPersist'
import { AppPersist, RootState } from '../../redux/reducers/types'

// TODO: For the initial launch we will make the cache only for one minute, but we should set it to an hour once custom
//  shares are all automated
// const ONE_HOUR = 1000 * 60 * 60
const ONE_MINUTE = 1000 * 60

const getIsExpiredCache = (customShareState: AppPersist['customShare']) => {
  return (
    !customShareState.lastLoadedTimestamp ||
    Date.now() - ONE_MINUTE > customShareState.lastLoadedTimestamp ||
    // If there was a closing or opening of the window we should clear the cache
    (customShareState.customShare &&
      isBefore(customShareState.customShare.nextRun.date, dateTimeInZone(customShareState.customShare.farm.timezone)))
  )
}

// A simple wrapper around the customShare selector to add the isExpiredCache option
const cachedCustomShareSelector = (state: RootState) => {
  return { ...state.appPersist.customShare, isExpiredCache: getIsExpiredCache(state.appPersist.customShare) }
}

/** This will cache the custom share requests so that it isn't loaded more than once per hour. */
export const useCachedCustomShare = () => {
  const userId = useSelector(userSelector)?.id
  const dispatch = useDispatch()
  const farmIds = useSelector(farmsSelector)
    // Prospect means they haven't purchased so don't include those farms
    .filter((fa) => fa.role !== Role.Prospect)
    .map((f) => f.farmId)
  const customShareState = useSelector(cachedCustomShareSelector)

  const shouldFetchCS = !customShareState.customShare || customShareState.isExpiredCache
  const shouldFetchCSMember = !customShareState.customShare || customShareState.isExpiredCache
  const { data: customShare } = useApiFx(
    loadCustomShareByFarms,
    [farmIds],
    farmIds.length > 0 && !!userId && shouldFetchCS,
    {
      onStateSet: (state) => {
        if (!state.data) return
        dispatch(setCustomShareModalData({ customShare: state.data, lastLoadedTimestamp: Date.now() }))
      },
    },
  )
  const { data: isShareMember } = useApiFx(
    estimateIsShareMember,
    [customShare, userId],
    !!customShare && !!userId && shouldFetchCSMember,
    {
      onStateSet: (state) => {
        if (!state.data) return
        dispatch(setCustomShareModalData({ isMember: state.data, lastLoadedTimestamp: Date.now() }))
      },
    },
  )

  return {
    customShare: customShare ?? customShareState.customShare,
    isShareMember: isShareMember ?? customShareState.isMember,
  }
}
