import { callEndpoint } from '@api/v2'
import { sortByEarliest } from '@helpers/sorting'
import { Pickup } from '@models/Order'
import { CancelPickupRequest, RescheduleRequest } from '@models/OrderChanges'
import { QueryConstraint, limit, orderBy, where, onSnapshot } from 'firebase/firestore'
import { DateTime } from 'luxon'

import { marshalCancelPickupRequest, marshalRescheduleRequest } from './encoding/OrderChanges'
import { pickupsCollection } from './framework/ClientCollections'
import { unmarshalPickup } from './encoding/Pickup'
import { unmarshalMeta } from './encoding/Meta'
import { Meta } from '@models/Meta'

export const INITIAL_LIMIT_N_PICKUPS = 10

/** Invokes a snapshot handler for the supplied user within the set date range and a result limit.
The snapshot will be passed to the callback function as data changes.*/
export function snapshotFuturePickupsByUser(
  userId: string,
  callback: (pickups: Pickup[]) => void,
  /** `limitN`: The max number of documents to load. If null, there will be no limit. If undefined, will have the default limit */
  limitN: number | null = INITIAL_LIMIT_N_PICKUPS,
): () => void {
  const today = DateTime.now().startOf('day').toISO()
  const qFilters: QueryConstraint[] = [
    where('user.id', '==', userId),
    where('date.utc', '>=', today),
    orderBy('date.utc', 'asc'),
  ]
  // firestore has a 10k max limit. This prevents misuse of this helper
  if (limitN !== null && limitN < 10000) qFilters.push(limit(limitN))
  const query = pickupsCollection.query(...qFilters)
  return pickupsCollection.snapshotMany(query, callback)
}

/** Will load the next set of pickups for the user.
 * @param limit should be a practical number for the max pickups of an average user */
export async function loadFuturePickupsByUser(userId: string, limitN = 200): Promise<Pickup[]> {
  if (!userId) return Promise.resolve([])
  const today = DateTime.now().startOf('day').toUTC().toISO()
  const qFilters: QueryConstraint[] = [
    where('user.id', '==', userId),
    where('date.utc', '>=', today),
    orderBy('date.utc', 'asc'),
  ]
  // firestore has a 10k max limit. This prevents misuse of this helper
  if (limitN !== null && limitN < 10000) qFilters.push(limit(limitN))
  return pickupsCollection.fetchAll(...qFilters)
}

/** Loads the last pickup that has changed for a farm, useful to see if there have been any updates that require re-querying. */
export function snapshotLastChangedPickupByFarm(
  callback: (pickups?: Pickup & { meta: Meta | undefined }) => void,
  onError: (err: Error) => void,
  farmId: string,
) {
  const q = pickupsCollection.query(where('farm.id', '==', farmId), orderBy('meta.updatedAt.utc', 'desc'), limit(1))

  // We use the default firebase snapshot because we want to do a custom unmarshal to keep the metadata
  return onSnapshot(
    q,
    (snapshot) => {
      if (snapshot.empty) return callback(undefined)
      const data = snapshot.docs.filter((d) => d.exists())[0]
      if (data) {
        callback({ ...unmarshalPickup(data), meta: unmarshalMeta(data.data().meta) })
      } else {
        callback(undefined)
      }
    },
    onError,
  )
}

export async function loadFuturePickupsByUserAndFarm(userId: string, farmId: string) {
  if (!userId || !farmId) return Promise.resolve([])

  const today = DateTime.now().startOf('day').toUTC().toISO()
  const pickups = await pickupsCollection.fetchAll(
    where('user.id', '==', userId),
    where('farm.id', '==', farmId),
    where('date.utc', '>=', today),
  )
  return pickups.sort(sortByEarliest('date'))
}

export async function loadPickupsByUserAndFarm(userId: string, farmId: string) {
  const pickups = await pickupsCollection.fetchAll(where('user.id', '==', userId), where('farm.id', '==', farmId))
  return pickups.sort(sortByEarliest('date'))
}

// this cloud function will be used for reschedule pickup

export async function reschedulePickup(req: RescheduleRequest) {
  return await callEndpoint('v2.Pickup.reschedulePickupService', marshalRescheduleRequest(req))
}

// Handle vacation claiming (claimVacationWeek) or cancelPickup server side for share product
// Handle cancelPickup server side for standard product

export async function cancelPickup(req: CancelPickupRequest) {
  return await callEndpoint('v2.Pickup.cancelPickupService', marshalCancelPickupRequest(req))
}
