import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import useKeyedState from '@/hooks/useKeyedState'
import { HomeParamList } from '@/navigation/types'
import { GooglePlacesInputImperative, GooglePlacesSearch } from '@components'
import { Alert, Button, DropdownMenu, DropdownMenuBtn, FormInput, Icon, Text, TextH1 } from '@elements'
import { entries, values } from '@helpers/typescript'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import {
  LocType,
  LocTypeDisplay,
  locTypeToDisplayMap,
  submitLogistics,
} from '@screens/SearchScreen/searchScreen-helpers'
import { useInitialAddress } from '@screens/SearchScreen/useInitialAddress'
import { memo, useCallback, useMemo, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { LogisticsSelectionCarousel } from './LogisticsSelectionCarousel'

function LogisticsSelectionComp() {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<HomeParamList, 'HomeScreen'>>()
  const initialAddressFx = useInitialAddress()
  const [{ locTypeDisplay, query }, , , setters] = useKeyedState({
    locTypeDisplay: 'Delivery' as LocTypeDisplay,
    query: '',
  })
  const ref = useRef<GooglePlacesInputImperative>(null)

  const locTypeButtons = useMemo<DropdownMenuBtn[]>(
    () => values(locTypeToDisplayMap).map((v) => ({ title: v, onPress: () => setters.locTypeDisplay(v) })),
    [setters],
  )

  /** This is the locType param derived from the location type display, which will be used as a parameter for the search screen */
  const locTypeParam = useMemo<LocType>(() => {
    if (!locTypeDisplay) {
      throw new Error('There should be an initial value for the location type display')
    }
    const locTypeParam = entries(locTypeToDisplayMap).find(
      ([_, locTypeDisplayInMap]) => locTypeDisplay === locTypeDisplayInMap,
    )?.[0]

    if (!locTypeParam) {
      throw new Error('The display location should always match a location type parameter.')
    }
    return locTypeParam
  }, [locTypeDisplay])

  const onSubmitLogistics = useCallback(async () => {
    const { status } = await submitLogistics({
      ref,
      locType: locTypeParam,
      dispatch,
      query,
      handleParams: (params) => navigation.navigate('SearchScreen', params),
    })
    if (status === 'empty') {
      Alert(
        'Invalid Address',
        'The address entered is not producing valid results. Try writing your address in a different way, or try with a different address',
      )
    }
  }, [locTypeParam, query, dispatch, navigation])
  return (
    <View style={styles.main}>
      <LogisticsSelectionCarousel />
      <View style={styles.selectionCont}>
        <TextH1 size={24}>What are you looking for today?</TextH1>

        <FormInput
          containerStyle={styles.noHorizPadding}
          placeholder="Search for products or farms"
          label={null}
          onChangeText={setters.query}
          value={query}
        />
        <View style={styles.addressInputWrapper}>
          <DropdownMenu contentViewStyle={styles.dropdownContent} buttons={locTypeButtons}>
            <Text children={locTypeDisplay} />
            <Icon size={16} color={Colors.shades['500']} name="chevron-down" />
          </DropdownMenu>
          <View style={globalStyles.flex1}>
            <GooglePlacesSearch
              ref={ref}
              // This input should use "geocode" because it allows searching by either zip code, city or address
              types="geocode"
              placeholder={initialAddressFx.data || 'Enter your location'}
              initialValue={initialAddressFx.data}
              enableReinitialize
              editable={!initialAddressFx.loading}
              autoComplete="postal-address"
              hasClearBtn
              style={styles.addressInput}
              onSubmitEditing={onSubmitLogistics}
            />
          </View>
        </View>
        <Button
          style={styles.button}
          title="Find food"
          loading={initialAddressFx.loading}
          onPress={onSubmitLogistics}
        />
      </View>
    </View>
  )
}

export const LogisticsSelection = memo(LogisticsSelectionComp)

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    margin: 15,
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20,
  },
  button: {
    alignSelf: 'flex-start',
    marginLeft: 0,
  },
  addressInputWrapper: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  noHorizPadding: {
    paddingHorizontal: 0,
  },
  addressInput: {
    borderColor: Colors.shades['100'],
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
  },
  dropdownContent: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 8,
    height: 40,
    paddingHorizontal: 10,
  },

  selectionCont: {
    flexBasis: 400,
    flex: 1,
    gap: 10,
  },
})
