import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { Address } from '@models/Address'
import { Farm } from '@models/Farm'
import { Invoice, InvoiceItemTypes } from '@models/Invoice'
import { LocationTypes, isNonPickup } from '@models/Location'
import {
  CartItem,
  CartPhysical,
  Order,
  Pickup,
  isCartStandard,
  isCartStandardMulti,
  LocationFeeMapping,
} from '@models/Order'
import { isPayInFull } from '@models/Product'
import { User } from '@models/User'
import { UserAddress } from '@models/UserAddress'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import { getProratedAmount } from '../order'
import { isSameDay } from '../time'
import { Optional, pick } from '../typescript'
import { addressSchema } from './AddressBuilder'
import { Builder } from './Builder'
import { pickupItemSchema } from './PickupItemBuilder'
import { userAddressSchema } from './UserAddressSchema'
import { pickupItemBuilder } from './index'
import { YupValidationError, isYupValidationError, validateFromSchema } from './validators/helpers'
import { Money } from '@models/Money'
import { MoneyCalc } from '../money'

export const pickupSchema: Yup.ObjectSchema<Pickup> = Yup.object().shape({
  id: Yup.string().required(),
  user: Yup.object()
    .shape({
      id: Yup.string().required(),
    })
    .required(),
  farm: Yup.object()
    .shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
      logo: Yup.string().optional(),
      email: Yup.string().optional(),
      phoneNumber: Yup.string().optional(),
    })
    .required(),
  date: Yup.mixed().isDateTime().required(),
  oldDistribution: Yup.object()
    .shape({
      id: Yup.string().required(),
    })
    .default(undefined),
  locationFee: Yup.object()
    .shape({
      amount: YUP_MONEY_REQUIRED('location fee', { allowZero: true }),
      type: Yup.string().oneOf([InvoiceItemTypes.SHIPPING_FEE, InvoiceItemTypes.DELIVERY_FEE]).required(),
      invoice: Yup.object()
        .shape({
          id: Yup.string().required(),
        })
        .required(),
    })
    .default(undefined),
  items: Yup.array().of(pickupItemSchema.required()).required().min(1, 'should have at least one item'),
  distribution: Yup.object()
    .shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
      locationType: Yup.mixed<LocationTypes>().oneOf(Object.values(LocationTypes)).required(),
      locationName: Yup.string().required(),
      locationAbbreviation: Yup.string().optional(),
      notes: Yup.string().optional(),
      // Address if it's a local pickup or UserAddress if it's a non-pickup (delivery or shipment)
      address: Yup.mixed<Address | UserAddress>()
        .when('locationType', {
          is: (type: LocationTypes) => isNonPickup(type),
          then: (_) => userAddressSchema.required(),
          otherwise: (_) => addressSchema.required(),
        })
        .required(),
      hours: Yup.object()
        .shape({
          startTime: Yup.string().required(),
          endTime: Yup.string().required(),
        })
        .required(),
    })
    .required(),
  signInSummary: Yup.object()
    .shape({
      id: Yup.string().required(),
    })
    .default(undefined),
  draftOrderId: Yup.string().optional(),
})

type BuildPickupOpts = {
  pickupDate: DateTime
  item: CartPhysical
  isAdmin?: boolean
  order: Pick<Order, 'id' | 'orderNum' | 'date'>
  user: Pick<User, 'id'>
  farm: Farm
} & (
  | {
      isDraft: false
      invoices: Optional<Invoice, 'invoiceNum'>[]
    }
  | {
      isDraft: true
    }
)

const buildBasePickup = ({ pickupDate, item, order, user, farm }: BuildPickupOpts): Omit<Pickup, 'id'> => {
  return {
    items: [pickupItemBuilder.build(item, order)],
    user: { id: user.id },
    farm: pick(farm, 'id', 'name', 'managers', 'logo', 'email', 'phoneNumber'),
    distribution: {
      id: item.distribution.id,
      name: item.distribution.name,
      notes: item.distribution.notes,
      locationType: item.distribution.location.type,
      locationName: item.distribution.location.name,
      locationAbbreviation: item.distribution.location.abbreviation,
      address: item.distribution.location.address!,
      hours: item.distribution.schedule.hours,
    },
    date: pickupDate,
  }
}

/** Given the invoices for an order, finds the invoice id for a cartItem's pickup date.
 * - Assumes each invoice has been assigned a document id.
 * - Assumes the cartItem belongs to the cart from which the invoices were generated.
 * - This logic is based on `createInvoices()` and must be updated accordingly.
 */
const mapInvoiceToPickupItem = (
  invoices: Optional<Invoice, 'invoiceNum'>[],
  item: CartItem,
  date: DateTime,
  isAdmin = false,
): string | undefined => {
  // We currently only map invoice ids to pickupItems for standard products,
  // because only standard pickups are fully refundable
  if (!isCartStandard(item)) return undefined

  const { upfrontPmt } = getProratedAmount(item, {
    excludeClosedDistros: !isAdmin,
    ignoreOrderCutoffWindow: isAdmin,
  })

  if (isPayInFull(item.paymentSchedule) || upfrontPmt) {
    // If either they're paying in full, or there's an upfront payment for this item,
    // the invoice due date will be on checkout
    return invoices[0]?.id
  } else if (isCartStandardMulti(item)) {
    // In a per-pickup multiDate standard, each pickup date will become an invoice due-date except the first one, which becomes an invoice due on checkout
    const dateIx = item.pickups.findIndex((d) => isSameDay(d, date))
    if (dateIx === -1) return undefined

    if (dateIx === 0) return invoices[0]?.id

    return invoices.find((inv) => {
      // If the date isn't the first pickup date, the invoice due date should be on the same date,
      // and the invoice should have an item with the same id as the cartItem

      return isSameDay(inv.dueDate, date) && inv.items.find((invItm) => invItm.id === item.id)
    })?.id
  }
  return undefined
}

/** Will get the locationFee for a given pickup if it has a shipping or delivery fee */
export function getPickupDeliveryFee(
  locationType: LocationTypes,
  locationCost: Money | undefined,
  feeInvoice: Pick<Invoice, 'id'>,
): LocationFeeMapping | undefined {
  if (!isNonPickup(locationType)) return undefined
  if (!locationCost || !MoneyCalc.isGTZero(locationCost)) return undefined

  const feeType =
    locationType === LocationTypes.Delivery ? InvoiceItemTypes.DELIVERY_FEE : InvoiceItemTypes.SHIPPING_FEE
  return {
    amount: locationCost,
    invoice: pick(feeInvoice, 'id'),
    type: feeType,
  }
}

/**
 * This builder creates a Pickup object */
export class PickupBuilder extends Builder<Pickup> {
  constructor() {
    super('Pickup')
  }

  build(opts: BuildPickupOpts): Omit<Pickup, 'id'> {
    if (opts.isDraft) {
      const tempPickup = buildBasePickup(opts)

      // Since we are creating these for a draft order it will only be associated with a single order, so we track that here
      tempPickup.draftOrderId = opts.order.id

      return this.validateWithoutId(tempPickup)
    } else {
      const tempPickup = buildBasePickup(opts)

      if (isNonPickup(opts.item.distribution.location)) {
        // The issue is we aren't adding location fee so we can't tell if the pickup needs one
        tempPickup.locationFee = getPickupDeliveryFee(
          opts.item.distribution.location.type,
          opts.item.distribution.location.cost,
          // It is ok to not use the actual invoiceId as we are overwriting this value with the actual ID once we have
          // the true fee invoiceId
          { id: 'TEMP_INVOICE_ID' },
        )
      }

      tempPickup.items = tempPickup.items.map((itm) => ({
        ...itm,
        invoiceId: isCartStandard(opts.item)
          ? mapInvoiceToPickupItem(opts.invoices, opts.item, opts.pickupDate, opts.isAdmin)
          : undefined,
      }))

      return this.validateWithoutId(tempPickup)
    }
  }

  /** This function validates pickup object
   * @param pickup the pickup object to validate */
  validate(pickup: Partial<Pickup>): Pickup {
    try {
      return validateFromSchema(pickupSchema, pickup)
    } catch (error) {
      if (isYupValidationError(error)) {
        throw new YupValidationError({ path: 'pickup.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
