import { useCoords } from '@/hooks/useCoords/useCoords'
import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { CoordString, getCoordString } from '@helpers/coordinate'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { LocType } from '../searchScreen-helpers'

/** Will set a default coord to params once if none is defined */
export function useSetInitialCenter(
  locType: LocType | undefined,
  coordParam: CoordString | undefined,
  isGlobal: boolean | undefined,
) {
  const coordsDefault = useCoords()
  const navigation = useNavigation<NavigationProp<HomeParamList, 'SearchScreen'>>()
  const dispatch = useDispatch()

  useFocusFx(
    () => {
      if (locType !== 'coord' || !!coordParam || !coordsDefault || isGlobal) return

      const center = getCoordString(coordsDefault)
      if (!center) return

      // When an initial center is set we will also set that as the current search location
      dispatch(setSearchLocation({ coordinate: coordsDefault }))
      navigation.setParams({ center })
    },
    [locType, coordParam, coordsDefault, isGlobal, dispatch, navigation],
    { noRefocus: true, once: true },
  )
}
