import { MessagesParamList } from '@/navigation/types'
import { userSelector } from '@/redux/selectors'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { useConversationsApi } from './useConversationsApi'
import { useMessagesApi } from './useMessagesApi'

/** Data layer hook that handles the business logic for the Messages screen */
export function useMessagesScreenData(adminFarmId?: string) {
  const user = useSelector(userSelector)
  const route = useRoute<RouteProp<MessagesParamList, 'Messages'>>()
  const selectedChatId = route.params?.id

  const {
    canLoadMoreConversations,
    chatsSnap,
    onFetchAllConversations,
    onShowOnlyUnread,
    onlyUnread,
    unreadChatsSnap,
    setCanLoadData,
    canLoadData,
  } = useConversationsApi({ userId: user.id })

  const { messagesSnap, onIncreaseMessageLimit } = useMessagesApi({
    chatSessionId: selectedChatId,
    canLoadData,
  })

  return {
    user,
    selectedChatId,
    unreadChatsSnap,
    messagesSnap,
    adminFarmId,
    onShowOnlyUnread,
    onlyUnread,
    onIncreaseMessageLimit,
    canLoadMoreConversations,
    chatsSnap,
    onFetchAllConversations,
    setCanLoadData,
  }
}
