import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { MessagesParamList } from '@/navigation/types'
import { Text } from '@elements'
import { filterChatsBySearchValue } from '@helpers/messaging'
import { ChatSession } from '@models/Messaging'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useMessages } from '@screens/MessagesScreen/MessagesScreen.context'
import { memo, useCallback, useMemo, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { ConversationCard } from '../ConversationCard/ConversationCard'
import { ConversationListFooter } from './components/ConversationListFooter'
import { ConversationsListHeader } from './components/ConversationListHeader'

/** List of conversations between the logged in user and other participants */
export const ConversationsList = memo(function ConversationsList() {
  const {
    selectedChatId,
    chatsSnap,
    adminFarmId,
    user,
    onShowOnlyUnread,
    onlyUnread,
    unreadChatsSnap,
    canLoadMoreConversations,
    onFetchAllConversations,
  } = useMessages()
  const { isSmallDevice } = useDeviceSize()
  const [searchValue, setSearchValue] = useState('')
  const navigation = useNavigation<StackNavigationProp<MessagesParamList, 'Chat'>>()

  const onCardPress = useCallback(
    (item: ChatSession) => {
      //On small devices, we are not selecting the chat as it should navigate to the chat screen
      if (isSmallDevice) {
        navigation.navigate('Chat', { id: item.id })
      } else {
        navigation.setParams({ id: item.id })
      }
    },
    [isSmallDevice, navigation],
  )
  const onTabPress = useCallback(
    (tabIdx: number) => {
      if (tabIdx === 0) {
        onShowOnlyUnread(false)
      } else {
        onShowOnlyUnread(true)
      }
    },
    [onShowOnlyUnread],
  )

  const filteredData = useMemo(() => {
    const data = onlyUnread ? unreadChatsSnap.data : chatsSnap.data
    if (!data) return undefined

    return (
      data
        // TODO: fetch only adminFarmId's conversations if defined
        .filter((chat) => (adminFarmId ? !!chat.participants[adminFarmId] : true))
        .filter((chat) => filterChatsBySearchValue(chat, searchValue))
    )
  }, [adminFarmId, chatsSnap.data, onlyUnread, searchValue, unreadChatsSnap.data])

  /** When text is changed, load all the conversations in order to allow search over all the conversations */
  const onTextChange = useCallback(
    (value: string) => {
      if (canLoadMoreConversations) {
        onFetchAllConversations()
      }
      setSearchValue(value)
    },
    [canLoadMoreConversations, onFetchAllConversations],
  )

  return (
    <FlatList
      ListHeaderComponent={
        <ConversationsListHeader
          searchValue={searchValue}
          onTabPress={onTabPress}
          onTextChange={onTextChange}
          tabIdx={!onlyUnread ? 0 : 1}
        />
      }
      data={filteredData}
      renderItem={({ item }) => (
        <ConversationCard
          isSelected={selectedChatId === item.id}
          chat={item}
          currUserId={user.id}
          onPress={onCardPress}
          hasNewMessage={item.unreadIds.includes(user.id)}
        />
      )}
      keyExtractor={(item) => item.id}
      ListEmptyComponent={() => (
        <View style={styles.emptyContainer}>
          <Text center>No conversations</Text>
        </View>
      )}
      ListFooterComponent={<ConversationListFooter hasItems={!!filteredData?.length} />}
      extraData={[selectedChatId, adminFarmId, user.id]}
    />
  )
})

const styles = StyleSheet.create({
  emptyContainer: {
    paddingVertical: 30,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: Colors.shades['200'],
  },
})
