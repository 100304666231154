import { LocationTypes } from '@models/Location'
import { DefaultCatalog, ProductType } from '@models/Product'

import { AppStackParamList, MessagesParamList } from '@/navigation/types'
import { PickupItemStatus } from '@models/Order'

/** The types for the routes of the drawer navigator. Any type here should also exist in the AdminDrawerNavigator's actual screens, otherwise should be removed */
export type AdminDrawerParamList = AppStackParamList & {
  Onboard: undefined
  Dashboard: undefined
  Sales: undefined
  Analytics: undefined
  Customer?: {
    screen: keyof CustomerParamList
    params?: CustomerParamList[keyof CustomerParamList]
  }
  Orders?: {
    screen: keyof AdminOrdersParamList
    params?: AdminOrdersParamList[keyof AdminOrdersParamList]
  }
  Invoices?: { pastDue?: boolean }
  //Locations And Zones
  LocationsAndZonesNavigator?: {
    screen: keyof LocationsAndZonesParamList
    params?: LocationsAndZonesParamList[keyof LocationsAndZonesParamList]
  }
  //DistributionSchedules
  DistributionSchedulesNavigator?: {
    screen: keyof DistributionSchedulesParamList
    params?: DistributionSchedulesParamList[keyof DistributionSchedulesParamList]
  }
  DistributionSummaryStack?: {
    screen: keyof DistributionSummaryParamList
    params?: DistributionSummaryParamList[keyof DistributionSummaryParamList]
  }
  CSAStackNav?: { screen: keyof CSAParamList; params?: CSAParamList[keyof CSAParamList] }
  Products?: {
    screen: keyof AdminProductsParamList
    initial?: boolean
    params?: AdminProductsParamList[keyof AdminProductsParamList]
  }
  AdminCoupons?: {
    screen: keyof CouponsParamList
  }
  FarmSetup: { tab?: FarmSetupTab; stripeRedirect?: string }
  FarmProfileSetUp: undefined
  Integrations: undefined
  MessagesStack: {
    screen: keyof MessagesParamList
    params?: MessagesParamList[keyof MessagesParamList]
  }
}

/** Whether the template details screen should be in edit or add (new) mode */
export enum TemplatePageType {
  ADD = 'AddTemplate',
  EDIT = 'EditTemplate',
}

export type AdminProductsParamList = {
  ProductList: undefined
  AddProduct: {
    /** The type for the new product */
    type: ProductType
    prodId?: undefined
    /** The initial value for the catalog price selector in wholesale farms */
    catalog?: DefaultCatalog
  }
  EditProduct: { type?: undefined; prodId: string }
  ViewProduct: { type?: undefined; prodId: string }
  AddTemplate?: { goBack?: keyof AdminProductsParamList }
  EditTemplate: { templateId: string; prodId?: string; goBack?: keyof AdminProductsParamList }
  ShareTemplates: undefined
  Categories: undefined
  Producers: undefined
  CustomSharesList: undefined
  ViewCustomShare: { shareId: string }
  EditCustomShare: { shareId: string }
  AddCustomShare: undefined
}

/** The screen names/ routes that share the product details screen component */
export type ProductDetailsRoutes = keyof Pick<AdminProductsParamList, 'AddProduct' | 'EditProduct'>

export type CSAParamList = {
  CSAGroup: undefined
  CSADetail?: {
    csaId?: string
    goBack?: 'AddProduct'
  }
}

export type CouponsParamList = {
  CouponList: undefined
  CouponDetails: {
    id: string
    /** If true, when the screen is loaded, the AddPromo modal will automatically show */
    addPromo?: boolean
  }
}

//For Locations & Zones

export type LocationsAndZonesParamList = {
  Locations: undefined
  AddLocation: undefined
  EditLocation: { id: string }
  AddDeliveryShipping: { id?: string; type: LocationTypes.Shipping | LocationTypes.Delivery }
  EditDeliveryShipping: { id: string; type: LocationTypes.Shipping | LocationTypes.Delivery }
}

//For Schedules

export type DistributionSchedulesParamList = {
  Schedules: undefined
  AddDistribution?: { id?: undefined; goBack?: 'AddProduct' }
  EditDistribution: { id: string; goBack?: 'AddProduct' }
}

/** The product type filter for the product table in the order creator */
export type OrderProductType = 'standard' | 'share' | 'digital'

export type CustomerParamList = {
  CustomerList?: SearchAttributes
  CustomerDetails: {
    custId: string
    orderNum?: number // used in OrderDetailsScreen
    productId?: string // used in OrderDetailsScreen
  }
  AdminOrderDetails?: { orderId: string }
}

/** Initial params for the order creator */
export type OrderCreatorScreenParams = {
  custId?: string
  orderType?: OrderProductType
  goBack?: 'customers' | 'orders' | 'customerDetails'
}

export type FarmSetupTab = 'payments' | 'memberships' | 'payouts' | 'members' | 'productFees' | 'wholesale'

export type AdminOrdersParamList = {
  AdminOrderList?: SearchAttributes
  AdminOrderDetails: { orderId: string }
  AdminDraftOrderDetails: { draftOrderId: string }
  OrderCreator?: OrderCreatorScreenParams
  OrderEdit: { draftOrderId: string }
}

/** The parameters in this type are used by connected components of the admin table, so they won't be used by the screen-specific code, but it may be used in their sub-components */
export type SearchAttributes = { csaName?: string; draftOnly?: boolean; customerName?: string }

export type DistributionSummaryParamList = {
  DistributionSummary?: {
    locationId?: string
    tab?: 'daily' | 'location' | 'signIn' | 'packList'
    /**fromDate and endDate will have formate (YYYY-MM-DD) */
    fromDate?: string
    endDate?: string
    pickupItemStatus?: PickupItemStatus
  }
  LocationSummary: {
    locId: string
    // distributionDate will have formate (YYYY-MM-DD)
    date: string
  }
  SignInSheetSelector?: {
    locId?: string
    // distributionDate will have formate (YYYY-MM-DD)
    date?: string
  }
}
