import { Logger } from '@/config/logger'
import { errorToString, extendErr } from '@helpers/helpers'
import { AsyncFn, PartialArgs, Resolved } from '@helpers/typescript'
import { DependencyList, Dispatch, SetStateAction, useRef, useState } from 'react'

import { dequal } from '@helpers/customDequal'
import { CancelableCallback, useCancelableDeepFocusCallback } from './useCancelablePromise'
import { useDeepCompareCallback, useDeepCompareMemo } from './useDeepEqualEffect'
import { useDeepCompareFocusFx } from './useFocusFx'
import useKeyedState from './useKeyedState'

export type ApiFxState<F extends AsyncFn> = {
  loading: boolean
  data: Resolved<F> | undefined
  err?: string
}

/** Must extend any ApiFxState */
export const initialApiFxState = {
  loading: true,
  data: undefined,
  err: undefined,
}

export type UseApiFxReturn<F extends AsyncFn> = ApiFxState<F> & {
  /** Refresh
   *
   * Memoized callback that invokes the original `fn` with up-to-date `args` and `condition`. Can be used anywhere that `useApiFx` is used, to force a re-run. param `condition` still applies.*/
  refresh: () => void

  setState: Dispatch<SetStateAction<ApiFxState<F>>>
}

export const createInitialApiFxReturn = <T>(initialData: T | undefined): UseApiFxReturn<() => Promise<T>> => ({
  data: initialData,
  loading: true,
  refresh: () => {},
  setState: () => {},
})

export type ApiFxOptions<F extends AsyncFn> = {
  /** Optional function that will be called on error and can perform custom error handling. This callback will be memoized with no updates, to allow inline fn for convenience. */
  onError?: (e: unknown) => void

  retryOnFailure?: boolean

  retryInterval?: number

  maxRetries?: number
  /** external state setter. will be called with the new state, whenever internal state is set */
  onStateSet?: (newState: ApiFxState<F>) => void
  /** dependencies for onStateSet handler */
  depsOnStateSet?: DependencyList
  /** if getFromCache returns a non nullish value, it will not call the api again */
  getFromCache?: () => Resolved<F> | undefined
  /** processes the data before returning it */
  transform?: (data: Resolved<F>) => Resolved<F>
  /** When the run condition fails, this will determine whether the state contines to wait, with loading:true, or whether a failed run condition constitutes a reason why we should stop loading and considered things done. This varies a lot in the use-case */
  failedConditionMode?: 'stop-loading' | 'keep-loading'
  /** deps will re-run the api. fx uses deep comparison */
  deps?: DependencyList
  /** The ApiFX state will be initialized with this value */
  initialState?: Partial<ApiFxState<F>>
  /** If true, the effect will not run on navigation re-focus, only on dependency change while focused. This is meant to prevent calling again the api when the screen comes into focus for subsequent times. */
  noRefocus?: boolean
  /** If true, will not re-run the api when condition changes after the first load */
  ignoreConditionAfterFirstLoad?: boolean
  /** Will run the api only once */
  once?: boolean
}

const defaultRetryInterval = 2500
const defaultMaxRetries = 3

/** useEffect for calling an api when a screen is focused
 *
 * @param fn a function to be run by useEffect. Will be memoized with no updates, so it doesn't need to be put inside a useCallback.
 - If `fn` is a class method (i.e `ordersCollection.fetchAll`), the class must be bound to the `fn` (`ordersCollection.fetchAll.bind(ordersCollection)`)
 * @param args the partial args of fn. They will be registered as useEffect dependencies, so changes in any member of args will trigger a re-run of `fn` with the new arguments. (and thus, a re-render of the component where it is used).
 * @param condition Only if true, `fn` will run. serves as a way to limit under what circumstances `fn` should run. also an effect dependency. For example, here you can make sure it will only run if certain arguments are defined. This allows you to safely pass them in a partial state.
 * @param opts a set of options that can be passed to the useApiFx hook see type above
 * @returns an object of `loading`: boolean, `data`: Return type of `fn`, and `refresh`: a function to force a re-run of `fn`. (`condition` still applies in `refresh`)
 */
export function useApiFx<F extends AsyncFn = AsyncFn>(
  fn: F,
  args: PartialArgs<Parameters<F>>,
  condition?: boolean,
  { deps = [], initialState = initialApiFxState, noRefocus, once, ...opts }: ApiFxOptions<F> = {},
): UseApiFxReturn<F> {
  const [state, , setState, setter] = useKeyedState<ApiFxState<F>>(
    { ...initialApiFxState, ...initialState },
    {
      onStateSet: opts?.onStateSet,
      deps: opts?.depsOnStateSet,
    },
  )
  const retriesLeft = useRef(opts?.maxRetries ?? defaultMaxRetries)

  const hasRunRef = useRef(false) // Whether the api has run at least once

  const apiCallback = useDeepCompareCallback<CancelableCallback>(
    async (isCurrent) => {
      // If condition is false return
      if (condition === false || (once === true && hasRunRef.current === true)) {
        if (!opts?.failedConditionMode || opts.failedConditionMode === 'stop-loading') {
          setter.loading(false)
        }
        return
      }

      /** This should set loading true before trying to get data from cache, because even though redux access is non-async, I'm suspicious there is some time involved in practice. This would be useful if deps change after initial load, which triggers the api again */
      setter.loading(true)

      if (opts?.getFromCache) {
        const cache = opts.getFromCache()
        if (cache) return setState({ loading: false, data: cache })
      }

      try {
        const res = await fn(...(args as Parameters<F>))
        if (!isCurrent) return // this means the fx was re-triggered and a new promise started, so we also abort this run

        const data = opts?.transform ? opts.transform(res) : res

        hasRunRef.current = true

        setState({ data, loading: false })
      } catch (err) {
        setState({ loading: false, err: errorToString(err), data: undefined })

        /** Will call itself again if there's retries left */
        if (opts?.retryOnFailure && retriesLeft.current >= 1) {
          retriesLeft.current -= 1
          setTimeout(apiCallback, opts?.retryInterval ?? defaultRetryInterval)
        }

        Logger.error(extendErr(err, `${fn.name || 'Anonymous'}: Api call failed.`))

        // If there is an error handler, pass the error obj
        opts?.onError?.(err)
      }
      /**
       * - It's only intended to trigger on changes to 'args' or 'condition'
       * - `fn` and options are intentionally not in the deps array, because those should never change
       * in the normal use of this hook. They meant to be static options that may be passed inline.
       * - If opts has an external dependency, such as the cache in `getFromCache`, it should update next time there was a change in args or condition
       */
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps.concat(args, condition),
    (deps1, deps2) => {
      // If the api hasn't run yet, OR the option to ignore condition after first load is disabled, updates on deep comparison of deps, args, and condition
      if (!hasRunRef.current || opts?.ignoreConditionAfterFirstLoad === false) return dequal(deps1, deps2)
      // If it has run, and the option is enabled or undefined, after the first load the deep comparison should ignore the condition
      // This code is ignoring the condition by removing the last element in the array, because the last element is the condition
      return dequal(deps1.slice(0, -1), deps2.slice(0, -1))
    },
  )

  const refresh = useCancelableDeepFocusCallback(apiCallback, { noRefocus })

  // doesn't need to update on refresh or setState, only on state change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useDeepCompareMemo((): UseApiFxReturn<F> => ({ ...state, setState, refresh }), [state])
}

///////////////////////////////////////////////////
// ---UseSnapshot---

type UseSnapshotOpts<Return> = {
  /** Will be called with the new state on change */
  onStateChange?: (state: UseSnapshotReturn<Return>) => void
}

type Unsubscribe = () => void
type SnapshotFunction<Args extends Parameters<any>, Return> = (
  handler: (data?: Return) => void,
  onErr: (err: unknown) => void,
  ...args: Args
) => Unsubscribe
type UseSnapshotReturn<Return> = { loading: boolean; error?: string; data: Return | undefined }

/** Handles using a snapshot listener as an effect, along with loading and error states. */
export function useSnapshot<Args extends Parameters<any>, Return>(
  snapshotFn: SnapshotFunction<Args, Return>,
  args: Args,
  condition?: boolean,
  { onStateChange }: UseSnapshotOpts<Return> = {},
): UseSnapshotReturn<Return> {
  const [state, , setState] = useKeyedState<UseSnapshotReturn<Return>>(
    {
      loading: true,
      data: undefined,
      error: undefined,
    },
    {
      onStateSet: onStateChange,
    },
  )

  const [signal, setSignal] = useState(0) //`signal` will trigger a retry of the listener effect

  useDeepCompareFocusFx(() => {
    // If condition is defined and false don't start listening
    if (condition !== undefined && !condition) return

    setState((s) => ({ ...s, loading: true, error: undefined })) //reset error and start loading state before listening

    let unsubscribe = () => {}

    // If there is an error then log it and set the error. We will let the caller determine how to handle the error
    const onError = (e: unknown) => {
      Logger.error(e)
      setState({ loading: false, data: undefined, error: errorToString(e) })
      if (signal < 4) setTimeout(() => setSignal(signal + 1), 2500) //induce retry
    }

    const timeout = setTimeout(() => {
      // This timeout sets a time limit for the listener to return data. Listener callback will prevent this timeout error
      onError(new Error(`Snapshot timed out for ${snapshotFn.name}`))
    }, 7000)

    // Setup data handler that receives data everytime it changes
    const handler = (data?: Return) => {
      clearTimeout(timeout)
      setState({ loading: false, error: undefined, data })
    }

    try {
      // Start snapshot listener and  unsubscribe on cleanup
      unsubscribe = snapshotFn(handler, onError, ...args)
    } catch (e) {
      onError(e)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
      unsubscribe()
    }

    // Deps needed: args, condition, signal. Not needed: fn.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args, condition, signal])

  return state
}
