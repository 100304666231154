import Colors from '@/constants/Colors'
import { SHADOW_2 } from '@/constants/Styles'
import { Text } from '@elements'
import { isProductEbtOnly } from '@helpers/products'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoProduct } from '@models/Algolia'
import { Product } from '@models/Product'
import { StyleSheet, View } from 'react-native'
import { EbtIcon } from '../EbtIcon'

type ProductCardTagsProps = {
  product?: AlgoliaGeoDoc<AlgoliaGeoProduct> | Product
}

/** Component that displays tags for a product based on its features. */
export function ProductCardTags({ product }: ProductCardTagsProps) {
  if (!product) return null

  const isEbtOnly = isGeoProduct(product) ? product.isEbtOnly : isProductEbtOnly(product)
  if (!product.isFeatured && !isEbtOnly) return null

  return (
    <View style={styles.main}>
      {product.isFeatured && (
        <View style={styles.tag}>
          <Text>Featured</Text>
        </View>
      )}
      {isEbtOnly && (
        <View style={styles.tag}>
          <Text>
            <EbtIcon product={product} />
            SNAP Only
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    position: 'absolute',
    zIndex: 5,
    left: 5,
    top: 5,
    gap: 5,
    alignItems: 'flex-start',
  },
  tag: {
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...SHADOW_2,
  },
})
