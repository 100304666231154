import { INITIAL_LIMIT_N_INVOICES, snapshotInvoicesByUserAndDueDate } from '@api/Invoices'
import { LoaderWithMessage } from '@components'
import { Spinner, Text, typography } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { sortByLatest } from '@helpers/sorting'
import { Invoice, InvoiceStatus } from '@models/Invoice'
import { dateTimeInZone } from '@models/Timezone'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import { useCallback, useContext, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { Button } from '../../components/elements/Button'
import { globalStyles } from '../../constants/Styles'
import { InvoiceCard } from './InvoiceCard'
import { MaxLimit } from './PickupTab'

import { useFocusFx } from '@/hooks/useFocusFx'
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll'
import { useDeviceSize } from '@/hooks/useLayout'
import { userSelector, wholesaleSelector } from '@/redux/selectors'
import { OrdersScreenContext } from './OrdersScreen'

export type InvoicesByFarm = { [farmId: string]: Invoice[] }

export function InvoiceTab() {
  const [{ invoices, tab, highlightedInvoices }, set] = useContext(OrdersScreenContext)
  const visible = tab === 'invoices'
  const styles = useStyles()
  const user = useSelector(userSelector)
  const { isWholesale } = useSelector(wholesaleSelector)

  const { isSmallDevice } = useDeviceSize()
  const [futureEnabled, setFutureEnabled] = useState(false)
  const { increaseLimit, limit, loading, setLoading, forceIncreaseLimit } = useInfiniteScroll(
    INITIAL_LIMIT_N_INVOICES,
    invoices.length,
  )

  const invoicesToShow = useMemo(
    () =>
      invoices
        .filter(isValidInvoice)
        .filter((inv) => (futureEnabled || isWholesale ? true : !isFutureInvoice(inv)))
        .sort(sortByLatest('dueDate')),
    [futureEnabled, invoices, isWholesale],
  )

  const hasFutureInvoices = useMemo(() => invoices.filter(isValidInvoice).some(isFutureInvoice), [invoices])

  useFocusFx(() => {
    setLoading(true)
    return snapshotInvoicesByUserAndDueDate(
      (newInvoices: Invoice[]) => {
        if (shouldFetchMoreInvoices(newInvoices, limit)) {
          // If threshold is not met, increase limit
          forceIncreaseLimit(INITIAL_LIMIT_N_INVOICES)
          return
        }
        set('invoices', newInvoices)
        disableLoading()
      },
      undefined,
      user.id,
      limit > MaxLimit ? null : limit,
    )
    // No other more dependencies should be passed, in order to avoid unintented snapshot calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, limit])

  /** This delay ensures that the dispatch has finished */
  const disableLoading = () =>
    setTimeout(() => {
      setLoading(false)
    }, 150)

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<Invoice>) => {
      return <InvoiceCard invoice={item} highlighted={highlightedInvoices.includes(item.id)} />
    },
    [highlightedInvoices],
  )
  const ListEndLoader = useCallback(() => {
    if (loading) {
      return <Spinner />
    }
    return <View />
  }, [loading])

  const toggleFuture = useCallback(() => {
    setFutureEnabled((curr) => !curr)
  }, [])

  const Header = useCallback(() => {
    return (
      <View style={styles.top}>
        <Text style={styles.title}>Pay by this date</Text>
        <Text style={styles.title}>Invoice number</Text>
        <Text style={styles.title}>Amount</Text>
        <Text style={styles.title}>Status</Text>
        <View style={globalStyles.flex05} />
      </View>
    )
  }, [styles.title, styles.top])

  if (!visible) {
    return <View />
  }
  return (
    <>
      {hasFutureInvoices && !isWholesale && (
        <Button outline title={`${futureEnabled ? '- Hide' : '+ Show'} Future Invoices`} onPress={toggleFuture} />
      )}

      <FlashList
        ListHeaderComponent={Header}
        data={invoicesToShow}
        ListFooterComponent={ListEndLoader}
        renderItem={renderItem}
        estimatedItemSize={isSmallDevice ? 50 : 150}
        ListEmptyComponent={
          <LoaderWithMessage loading={loading} icon="money-bill-wave" title="No Invoices!">
            <Text>After you make a purchase on GrownBy, you can find your invoices here.</Text>
          </LoaderWithMessage>
        }
        onEndReachedThreshold={0.9}
        onEndReached={increaseLimit}
      />
    </>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    top: {
      display: 'flex',
      flexDirection: 'row',
      paddingHorizontal: 60,
      marginTop: 30,
    },
    title: {
      fontFamily: typography.body.regular,
      flex: 1,
      marginHorizontal: 5,
      fontSize: 16,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      top: {
        paddingHorizontal: 20,
      },

      title: {
        fontSize: 13,
      },
    },
  },
)
const isFutureInvoice = (invoice: Invoice) => {
  const now = dateTimeInZone(invoice.farm.timezone)
  return invoice.dueDate > now
}

/** If viewable past invoices are less than a certain threshold, we should fetch more until the threshold is met */
const shouldFetchMoreInvoices = (invoices: Invoice[], limit: number) => {
  // If total invoices is less than limit, fetching more won't help
  if (invoices.length < limit) return false

  const visiblePastInv = invoices
    .filter((inv) => inv.dueDate <= dateTimeInZone(inv.farm.timezone))
    .filter((inv) => inv.status !== InvoiceStatus.Void && MoneyCalc.isGTZero(inv.amountTotal))
    .filter((inv) => inv.items.filter((itm) => !itm.cancelled).length > 0)

  // If past invoices are less than 10, fetch more
  return visiblePastInv.length < 10
}

/** Whether the invoice should be visible */
const isValidInvoice = (inv: Invoice): boolean => {
  return MoneyCalc.isGTZero(inv.amountTotal) && inv.items.filter((itm) => !itm.cancelled).length > 0
}
