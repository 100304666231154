import { DateTime } from 'luxon'
import { Farm } from './Farm'
import { Money } from './Money'
import { Product } from './Product'
import { DateRange, DayOfWeek } from './Schedule'
import { CSA } from './CSA'

export enum CustomShareRunTypes {
  OpenOrdering = 'open-ordering',
  CloseOrdering = 'close-ordering',
}

export enum CustomShareLogTypes {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
}

export type CustomShare = {
  id: string
  name: string
  farm: Pick<Farm, 'id' | 'timezone' | 'name'>
  /** This will hold the id value and csa because those values cannot be changed while the share is active. So we don't want to rely on live data.
   * These values can be changed while the share window is closed. */
  primaryShares: { id: string; name: string; value: Money; minShareItems?: number }[]
  /** The CSA associated with the primary shares */
  csa: Pick<CSA, 'id'>
  /** This will hold a list of products that will be prioritized or deprioritized by the farmer, this will be used as a default
   * preference list, so if the product is not included in customer preferences we will use farmer preferences */
  rankedProducts: { product: Pick<Product, 'id' | 'name'>; score: number }[]

  /** If the ordering window is currently open or closed*/
  isOrderingOpen: boolean

  /** A list of the past runs and messages about them*/
  runs: CustomShareRun[]

  /** The dates between which we will run this for */
  season: DateRange

  /** Configuration for handling the opening of order windows */
  openWindow: {
    dayOfWeek: DayOfWeek
    /** In 24-Hour format with leading 0, Ex. 13:20 or 09:20 */
    time: string
  }

  /** Configuration for handling the closing of order windows */
  closeWindow: {
    dayOfWeek: DayOfWeek
    /** In 24-Hour format with leading 0, Ex. 13:20 or 09:20 */
    time: string
  }

  /** This will be the next action that will be run for this custom share. It will be used for the cronjob to know what to call. */
  nextRun: {
    date: DateTime
    type: CustomShareRunTypes
  }

  /** Custom shares can be marked as deleted and will not show in the UI */
  isDeleted: boolean
}

export type CustomShareRun = {
  /** The run id, usually the idempotency key*/
  id: string
  type: CustomShareRunTypes
  /** Whether the run was successful or failed.
   * - It will be status warn if it has only warnings
   * - While it is running it will be status running */
  status: 'success' | 'warning' | 'error' | 'running'
  /** The date of the run */
  date: DateTime
  /** A list of messages from the run, including success messages, warnings or any failures */
  messages: { type: CustomShareLogTypes; message: string }[]
}
