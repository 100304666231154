import { CurrentLocation } from '@/constants/types'
import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { searchLocationSelector, sessionLocationSelector, userSelector, wholesaleSelector } from '@/redux/selectors'
import { useCreateSearchParams } from '@components'
import { LoadingView } from '@elements'
import { validCoords } from '@helpers/coordinate'
import { nonEmptyString } from '@helpers/helpers'
import { GooglePlace } from '@models/Address'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { NearbyProdsInput } from './NearbyProductsInput'
import { NearbyProductsResults } from './NearbyProductsResults' /** Contains everything related to the nearby products search in the homescreen */
export const NearbyProducts = memo(function NearbyProducts() {
  const { isWholesale } = useSelector(wholesaleSelector)
  const user = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const searchLoc = useSelector(searchLocationSelector)
  const sessionLoc = useSelector(sessionLocationSelector)
  const createSearchParams = useCreateSearchParams()

  const [selectedPlace, setSelectedPlace] = useState<GooglePlace | undefined>()

  // This will set the session location as the initial selected place if not defined
  useFocusFx(
    () => {
      // Update only if there is no value, so we won't make unnecessary calls.
      if (selectedPlace) return

      setSelectedPlace(getInitialLocation(searchLoc, sessionLoc))
    },
    [searchLoc, selectedPlace, sessionLoc],
    { noRefocus: true },
  )

  const onExplorePress = useCallback(() => {
    navigation.navigate('ExploreScreen', createSearchParams({ coords: selectedPlace?.coordinate }))
  }, [createSearchParams, navigation, selectedPlace?.coordinate])

  return (
    // This is intended to wait until the user location is set. In case it can't be obtained it is expected to be null. Undefined means it hasn't been set so it's loading
    <LoadingView loading={sessionLoc === undefined} style={styles.container}>
      {(!isWholesale || nonEmptyString(user.id)) && (
        <NearbyProdsInput
          initialValue={selectedPlace?.name}
          onSelectCity={setSelectedPlace}
          onExplorePress={onExplorePress}
        />
      )}
      <View style={styles.spacing10} />
      {selectedPlace && <NearbyProductsResults place={selectedPlace} />}
    </LoadingView>
  )
})

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  spacing10: {
    height: 10,
  },
})

/** Decides which location to use based on available locations */
const getInitialLocation = (searchLoc?: CurrentLocation, sessionLoc?: CurrentLocation | null) => {
  // If we have searched for a location that's preferred
  if (searchLoc?.coordinate && validCoords(searchLoc.coordinate)) return searchLoc
  // If not we use current location
  return sessionLoc && validCoords(sessionLoc.coordinate) ? sessionLoc : undefined
}
