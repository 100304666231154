import {
  BaseProduct,
  DefaultCatalog,
  EbtEligibility,
  isPhysical,
  isShare,
  isStandard,
  Product,
  ProductType,
  Share,
} from '@models/Product'
import { Media } from '@models/shared/Media'
import sha1 from 'sha1'

import * as Yup from 'yup'

import { Logger } from '@/config/logger'
import { uploadImageAsync } from '@api/FirebaseStorage'
import { Alert } from '@elements'
import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import { productFeeSchema } from '@helpers/builders/ProductFeeBuilder'
import { getReadableEbtEligibility } from '@helpers/products-display'
import { PartialPick } from '@helpers/typescript'
import { YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { FeeType, ProductFee } from '@models/ProductFee'
import { PickerItemProps } from '@react-native-picker/picker'
import { AdvancedPricingForm } from '../AdvancedPricing'
import { ProductTypeForm } from '../helpers/ProductTypeInfo'

export type BasicInfoForm = {
  name?: string
  /** This sku field is only intended to be used for shares */
  shareSku?: Share['sku']
  shortDescription?: string
  longDescription?: string
  category?: string
  producer?: string
  media?: Media[]
  csas?: string[]
  ebtEligibility?: EbtEligibility
  isDiscountEligible?: boolean
  shareInfo?: {
    minimumNumberOfItems?: number
    maximumNumberOfItems?: number
    suggestedFamilySize?: number
  }
  isChild?: boolean
  templateProductId?: string
  templateProductName?: string
  hasTemplate?: boolean
  vacationWeeks?: number
  productionMethod?: string
  hideFromShop?: BaseProduct['hideFromShop']
  isTaxExempt?: BaseProduct['taxesAndFees']['isTaxExempt']
  fees?: BaseProduct['taxesAndFees']['fees']
}

export const isNotTemplateChild = (isChild: boolean, type: ProductType, templateProductId: string) =>
  !(isChild && templateProductId && isShare({ type }))

export const basicInfoSchema: Yup.ObjectSchema<BasicInfoForm, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>().shape({
    name: Yup.string()
      .trim()
      .label('Name')
      .min(3, 'Name must be at least 3 characters')
      .test('is-valid-name', 'Name must contain at least three consecutive letters', (value) => {
        if (!value) return false
        // Require at least 3 consecutive characters
        return !!value.match(/[a-zA-Z]{3,}/)
      })
      .required(),
    shortDescription: Yup.string()
      .trim()
      .label('Short Description')
      .when(['isChild', 'type', 'templateProductId'], {
        is: isNotTemplateChild,
        then: (schema) => schema.required(),
      }),
    longDescription: Yup.string()
      .trim()
      .label('Long Description')
      .when(['isChild', 'type', 'templateProductId'], {
        is: isNotTemplateChild,
        then: (schema) => schema.required(),
      }),
    category: Yup.string()
      .trim()
      .label('Category')
      .when(['isChild', 'type', 'templateProductId'], {
        is: isNotTemplateChild,
        then: (schema) => schema.required(),
      }),
    producer: Yup.string().trim().label('Producer'),
    media: Yup.array<Media>()
      .label('Images')
      .when(['isChild', 'type', 'templateProductId'], {
        is: isNotTemplateChild,
        then: (schema) => schema.required().min(1, 'Must upload at least one image'),
      }),
    csas: Yup.array<ProductSchemaContext>()
      .label('CSAs')
      .when('type', {
        is: (type: ProductType) => isShare({ type }),
        then: (schema) =>
          schema.test(
            'is-csa-array-non-empty',
            'Must select at least one CSA that this share is available at.',
            function (csas) {
              const { defaultCatalog } = this.options.context ?? {}
              if (defaultCatalog === DefaultCatalog.Wholesale) {
                // no csa validation for wholesale-only products
                return true
              }
              return !!csas && csas.length > 0
            },
          ),
      }),
    vacationWeeks: Yup.number()
      .label('Vacation Weeks')
      .when('type', {
        is: (type: ProductType) => isShare({ type }),
        then: () => YUP_WHOLE_NUMBER_REAL('Vacation Weeks', { allowZero: true }),
      }),
    ebtEligibility: Yup.mixed<EbtEligibility>().label('SNAP/EBT Eligibility').oneOf(Object.values(EbtEligibility)),
    isDiscountEligible: Yup.boolean().label('Discount Eligible'),
    shareInfo: Yup.object({
      minimumNumberOfItems: Yup.number()
        .min(0, `Can not be negative`)
        .typeError('Must be a number and can not left empty.'),
      maximumNumberOfItems: Yup.number()
        .min(0, `Can not be negative`)
        .typeError('Must be a number and can not left empty.'),
      suggestedFamilySize: Yup.number()
        .min(0, `Can not be negative`)
        .typeError('Must be a number and can not left empty.'),
    }),
    isChild: Yup.boolean()
      .label('Child Product')
      .when('templateProductId', {
        is: (templateProductId: string) => !templateProductId,
        then: (schema) => schema.equals([false], 'You have to choose a template'),
      }),
    templateProductId: Yup.string().label('Template Product'),
    templateProductName: Yup.string().label('Template Product Name'),
    shareSku: Yup.string().label('SKU'),
    productionMethod: Yup.string().label('Production Method'),
    hideFromShop: Yup.boolean<boolean, ProductSchemaContext>().test(
      'csa-required-when-hideFromShop',
      'If "Only buy on CSA" is active, you must select at least one CSA',
      function (value) {
        const { defaultCatalog } = this.options.context ?? {}
        if (defaultCatalog === DefaultCatalog.Wholesale) {
          // don't validate for wholesale-only because this option is hidden in wholesale-only
          return true
        }

        const { csas } = this.parent as BasicInfoForm

        // If hideFromShop is true, ensure at least one CSA is selected
        if (value) {
          return csas && csas.length > 0
        }

        // If hideFromShop is false, the validation automatically passes
        return true
      },
    ),
    isTaxExempt: Yup.boolean()
      .label('Tax Exempt')
      .test('Validate Tax Exempt', 'Tax Exempt products cannot have taxes', function (isTaxExempt) {
        const { fees } = this.parent
        if (isTaxExempt && fees) {
          return !fees.some((fee: ProductFee) => fee.type === FeeType.Tax)
        }
        return true
      }),
    fees: Yup.array().label('Product Fees').of(productFeeSchema),
    hasTemplate: Yup.boolean().optional(),
  })

export async function fromFormik(
  values: BasicInfoForm & ProductTypeForm & AdvancedPricingForm,
): Promise<Partial<Product>> {
  const base: PartialPick<Product, 'type'> = {
    type: values.type,
    name: values.name,
    description: values.shortDescription,
    longDescription: values.longDescription,
    category: values.category,
    // If it's wholesale-only there is no csa assignment, so we save an empty array
    csa: values.catalog !== DefaultCatalog.Wholesale ? values.csas || [] : [],
    taxesAndFees: {
      isTaxExempt: values.isTaxExempt!,
      fees: values.fees,
    },
  }

  if (isPhysical(base)) {
    base.producer = values.producer
    base.productionMethod = values.productionMethod
  }

  if (isStandard(base)) {
    if (values.catalog !== DefaultCatalog.Wholesale) {
      // If it's standard, this assumes the isEbtEligible field is defined
      base.ebtEligibility = values.ebtEligibility ?? EbtEligibility.NotEbtEligible
    } else {
      // if the product is wholesale-only we force this to false, because the UI gets hidden and isn't validated
      base.ebtEligibility = EbtEligibility.NotEbtEligible
    }
  }

  if (values.catalog !== DefaultCatalog.Wholesale) {
    // if the product is wholesale-only we don't include this value because the UI gets hidden and isn't validated
    base.hideFromShop = values.hideFromShop
  }

  if (isShare(base)) {
    base.shareInfo = Object.fromEntries(
      Object.entries(values.shareInfo ?? {}).filter(([_, value]) => Number(value) !== 0),
    )
    base.isChild = values.isChild
    /** Cast it as number because Input value is string */
    base.vacationWeeks = Number(values.vacationWeeks!)
    base.sku = values.shareSku // the sku here is for share
    base.templateProduct = { id: '', name: '' }
  }

  // If product applies a template, get the template id and template name and set them to the product
  if (values.templateProductId && values.isChild && isShare(base)) {
    base.templateProduct = { id: values.templateProductId, name: values.templateProductName || '' }
  }

  if (values.media) {
    try {
      values.media = await Promise.all(
        values.media.map((m: Media) => {
          // Check if image is already hosted
          if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return m
          else return uploadImageAsync(sha1(m.storageUrl), m)
        }),
      )
      base.images = values.media.map((m) => m.storageUrl)
    } catch (err) {
      Alert('Error saving images', 'There was an error saving your images. Please try again.')
      Logger.error('Error saving images', err)
    }
  }

  return base as Partial<Product>
}

export const toFormik = (product: PartialPick<Product, 'type'>): BasicInfoForm => {
  const base: BasicInfoForm = {
    name: product.name || '',
    shortDescription: product.description || '',
    longDescription: product.longDescription || '',
    category: product.category || '',
    producer: product.producer || '',
    productionMethod: product.productionMethod || '',
    media:
      product.images?.map((img) => ({
        storageUrl: img,
        type: 'image',
      })) || ([] as Media[]),
    csas: product.csa || [],
    hideFromShop: product.hideFromShop,
    /** Note: isTaxExempt by default should be true for now, so all products are now isTaxExempts until further changes.  */
    isTaxExempt: product.taxesAndFees?.isTaxExempt ?? true,
    fees: product.taxesAndFees?.fees || [],
  }

  if (isStandard(product)) {
    base.ebtEligibility = product.ebtEligibility
  }

  if (isShare(product)) {
    base.shareInfo = {
      minimumNumberOfItems: Number(product.shareInfo?.minimumNumberOfItems) || 0,
      maximumNumberOfItems: Number(product.shareInfo?.maximumNumberOfItems) || 0,
      suggestedFamilySize: Number(product.shareInfo?.suggestedFamilySize) || 0,
    }
    base.isChild = product.isChild || false
    base.templateProductId = product.templateProduct?.id || ''
    base.templateProductName = product.templateProduct?.name || ''
    base.vacationWeeks = product.vacationWeeks
    base.shareSku = product.sku || ''
  }

  return base
}

/** Array of picker items for EBT eligibility. This array contains objects with `label` and `value` properties representing different EBT eligibility options. */
export const ebtOptsPickerItems: PickerItemProps<EbtEligibility>[] = Object.values(EbtEligibility).map((value) => ({
  label: getReadableEbtEligibility(value),
  value,
}))
