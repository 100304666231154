import { DateTime } from 'luxon'

import { Farm } from './Farm'
import { Money } from './Money'
import { User } from './User'

export enum CouponType {
  Fixed = 'fixed',
  Percent = 'percent',
}

/** A coupon represents a collection a grouping of discounts with specific rules */
export type Coupon<Type = CouponType> = {
  /** The document identifier from Firestore */
  id: string

  /** The farm this coupon is for */
  farm: Pick<Farm, 'id'>

  /** The identifier for this coupon, ex. $10 OFF SPRING SALE */
  name: string

  /** The type of the coupon, either fixed or percent */
  type: Type

  /** Will be the value of the coupon, either money amount or percent amount.
   * - If money, type will be of type Money.
   * - If percent, type will be a decimal number, from zero to one. For example, 0.2, 0.5, 1. Zero is invalid */
  value: Type extends CouponType.Fixed ? Money : Type extends CouponType.Percent ? number : never

  /** The number of times the coupon has been redeemed */
  timesRedeemed: number

  /** If the coupon is archived then all promo codes under it are also archived */
  archived: boolean

  /** The below are options that allow specific requirements to be met for the coupon to work. EBT only means that this coupon can only be used with ebt payment methods. This is only an option for percentage coupons*/
  ebtOnly?: Type extends CouponType.Percent ? boolean : never

  /** A list of categories that this coupon can be applied to */
  categories?: string[]

  /** A list of producers that this coupon can be applied to */
  producers?: string[]

  /** If this coupon should be limited to only certain CSA groups */
  csaGroups?: string[]

  /** A system coupon is one that we use internally in GrownBy and cannot be modified by the farmer. It is used currently
   * for custom shares. */
  isSystemCoupon?: boolean
}

/** A promo code is a customer facing code for a coupon that can have additional requirements */
export type PromoCode<Type = CouponType> = {
  /** The document identifier from Firestore. The id is the same as the promo code */
  id: string

  /** The coupon this promo code is associated with */
  coupon: Pick<Coupon<Type>, 'id' | 'farm'>

  /** The code assigned by the farmer, must be unique for that farm */
  code: string

  /** The below are options that allow specific requirements to be met for the promo code to work **/

  /** The customers for which this coupon is valid for */
  customers?: Pick<User, 'id' | 'email'>[]

  /** The minimum amount of the order for the promo code. This includes all upfront and future payments. */
  orderMinimum?: Money

  /** The maximum number of times this promo code can be redeemed */
  maxRedemptions?: number

  /** The number of times the promo code has been redeemed */
  timesRedeemed: number

  /** The expiration date of this promo code */
  expiration?: DateTime

  /** Will only allow this code to be used once per customer [DEFAULT=true]
   * This will not include any coupons that the admin apply
   */
  oncePerCustomer: boolean
}

export function isFixedCoupon(coupon: Pick<Coupon, 'type'>): coupon is Coupon<CouponType.Fixed> {
  return coupon.type === CouponType.Fixed
}

export function isPercentCoupon(coupon: Pick<Coupon, 'type'>): coupon is Coupon<CouponType.Percent> {
  return coupon.type === CouponType.Percent
}

/** A combination of promo and coupon which may be added to a cart for the items of a given farm. It is assumed both promo and coupon belong to the same farm.
 * - It can also be used to pay an invoice.
 */
export type Discount = {
  /**
   * In consumer carts, promo will always be defined because regular users must access coupons through promos.
   * In admin carts promos are optional but valid, though discouraged because promos are intended to impose customer-oriented restrictions on coupons
   *
   * In invoices, the same rules will apply. The intention is promo will always be defined for consumers because consumers can only add coupons through promo codes, while it might be undefined if an admin paid the invoice because admins could add coupons directly without promo codes.
   *  */
  promo?: PromoCode
  /** Coupon is always going to be defined in a Discount because it is the source data for the discount */
  coupon: Coupon
}
