import { createStackNavigator } from '@react-navigation/stack'
import { InvoiceExternal } from '@screens/InvoiceDetails/InvoiceExternal'
import * as Device from 'expo-device'
import { Platform } from 'react-native'

import { ExternalLinkingParamList } from '../../navigation/types'
import { InAppBrowserReturn, StripeAuthorizationCallback } from '../PaymentMethods/AddPaymentMethod/BrowserCallback'
import { AddPaymentExternal } from './AddPaymentExternal'
import FarmInvitation from './FarmInvitation'
import { FarmLandingScreen } from './FarmLanding'
import ResetPassword from './ResetPassword'
import SavingPassword from './SavingPassword'
import { VerifyEmailComp } from './VerifyEmail'

const ExternalLinkingStack = createStackNavigator<ExternalLinkingParamList>()

/** This is ExternalLinkNavigator for handle externalLink pages stacks */
export function ExternalLinkingNavigator() {
  return (
    <ExternalLinkingStack.Navigator
      screenOptions={{
        headerShown: false,
        //This is currently the trick to prevent android app to crash during navigation https://github.com/react-navigation/react-navigation/issues/10290
        animationEnabled: Platform.OS === 'android' && parseInt(Device.osVersion || '0', 10) > 9,
      }}
    >
      <ExternalLinkingStack.Screen name="FarmInvitation" component={FarmInvitation} />
      <ExternalLinkingStack.Screen name="FarmLanding" component={FarmLandingScreen} />
      <ExternalLinkingStack.Screen name="ResetPassword" component={ResetPassword} />
      <ExternalLinkingStack.Screen name="SavingPassword" component={SavingPassword} />
      <ExternalLinkingStack.Screen name="InvoiceExternal" component={InvoiceExternal} />
      <ExternalLinkingStack.Screen name="StripeAuthorizationCallback" component={StripeAuthorizationCallback} />
      <ExternalLinkingStack.Screen name="InAppBrowserReturn" component={InAppBrowserReturn} />
      <ExternalLinkingStack.Screen name="VerifyEmail" component={VerifyEmailComp} />
      <ExternalLinkingStack.Screen name="AddPaymentExternal" component={AddPaymentExternal} />
    </ExternalLinkingStack.Navigator>
  )
}
