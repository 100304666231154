import { FormikErrors } from 'formik'
import { ProductDetailsForm } from './formHelpers'

/** This maps a field from the product form to a user-friendly string */
export const errorsMap: Record<keyof FormikErrors<ProductDetailsForm>, string> = {
  productionMethod: 'Production method',
  producer: 'Producer',
  pricePerUnit: 'Pricing',
  ebtEligibility: 'SNAP/EBT Eligibility',
  minPickups: 'Minimum pickups',
  baseUnit: 'Unit',
  unitStock: 'Unit Stock',
  shareQuantity: 'Quantity',
  hideFromShop: 'Always show on CSA',
  disableBuyInFuture: 'Buy in future',
  name: 'Name',
  type: 'Type',
  hasTemplate: '',
  longDescription: 'Long Description',
  category: 'Category',
  isChild: '',
  templateProductId: '',
  templateProductName: '',
  shareSku: 'SKU',
  shareInfo: '',
  vacationWeeks: 'Vacation Weeks',
  media: 'Please upload at least one image',
  payInFull: 'Pay in full',
  payMonthly: 'Monthly Payment',
  payWeekly: 'Weekly Payment',
  shortDescription: 'Short Description',
  csas: 'CSAs',
  isDiscountEligible: '',
  formSchedules: 'Schedules',
  buyingOptions: 'Buying Options',
  costPerUnit: 'Cost per Unit',
  maxPerOrder: 'Max per Order',
  showCostPerUnit: '',
  globalQuantity: 'Quantity',
  numberOfPickups: 'Number of Pickups',
  catalog: 'Catalog',
  unitSkuPrefix: 'Unit SKU Prefix',
  isTaxExempt: 'Tax Exemption',
  fees: 'Taxes & Fees',
}
