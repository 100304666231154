import { Column, ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { Text, Touchable } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import React, { memo, useCallback } from 'react'
import { StyleSheet } from 'react-native'
import { CustomShare } from '@models/CustomShare'
import { formatTime, getDayofWeekName } from '@helpers/display'

type RowProps = {
  customShare: CustomShare
  index: number
}

/** This component renders a list row item for each custom share passed in. It is shown in an offline table */
export const CustomShareRow = memo(function CustomShareRow({ customShare, index }: RowProps) {
  const navigation = useNavigation<DrawerNavigationProp<AdminDrawerParamList, 'Products'>>()
  const handleDetailPress = useCallback(
    (type: 'view' | 'edit') => {
      const screen = type === 'view' ? 'ViewCustomShare' : 'EditCustomShare'
      navigation.navigate('Products', {
        screen,
        params: {
          shareId: customShare.id,
          goBack: 'CustomSharesList',
        },
      })
    },
    [customShare, navigation],
  )

  const columns: Column<CustomShare>[] = [
    {
      process: (cs) => <Text numberOfLines={2}>{cs.primaryShares.map((p) => p.name).join(', ')}</Text>,
      widthFlex: 2,
    },
    {
      process: (cs) => getDayofWeekName(cs.openWindow.dayOfWeek) + ' @ ' + formatTime(cs.openWindow.time),
      widthFlex: 1,
    },
    {
      process: (cs) => getDayofWeekName(cs.closeWindow.dayOfWeek) + ' @ ' + formatTime(cs.closeWindow.time),
      widthFlex: 1,
    },
    {
      process: (cs) => (cs.isOrderingOpen ? 'Open' : 'Closed'),
      widthFlex: 1,
    },
    {
      process: () => (
        <Touchable onPress={() => handleDetailPress('view')} style={styles.rowBtnCont}>
          <Text type="medium" color={Colors.green}>
            View
          </Text>
        </Touchable>
      ),
      widthFlex: 0.5,
    },
    {
      process: () => (
        <Touchable onPress={() => handleDetailPress('edit')} style={styles.rowBtnCont}>
          <Text type="medium" color={Colors.green}>
            Edit
          </Text>
        </Touchable>
      ),
      widthFlex: 0.5,
    },
  ]

  return (
    <ExpandableRow<CustomShare>
      item={customShare}
      index={index}
      columns={columns}
      rowContainerStyle={styles.row}
      onRowPress={() => handleDetailPress('view')}
    />
  )
}, propsAreDeepEqual)

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  rowBtnCont: {
    alignItems: 'center',
    minWidth: 18,
  },
})
