import { ButtonGroup, SearchInputBar } from '@elements'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

type ConversationHeaderProps = {
  tabIdx: number
  onTabPress: (idx: number) => void
  onTextChange: (value: string) => void
  searchValue: string
}

/** Component that renders a search input and a tab selector, used for filtering conversations */
export const ConversationsListHeader = memo(function ConversationsListHeader({
  tabIdx,
  onTabPress,
  onTextChange,
  searchValue,
}: ConversationHeaderProps) {
  return (
    <View style={styles.header}>
      <SearchInputBar value={searchValue} onChangeText={onTextChange} />
      <ButtonGroup onSelect={onTabPress} selectedIndex={tabIdx} small buttons={['All', 'Unread']} />
    </View>
  )
})

const styles = StyleSheet.create({
  header: {
    gap: 10,
    padding: 10,
  },
})
