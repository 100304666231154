import { globalStyles } from '@/constants/Styles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { LoadingView } from '@elements'
import { MessagesWithDate, flagMessagesWithDates, getMessageLogo } from '@helpers/messaging'
import { ChatMessage, ChatSession } from '@models/Messaging'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
  FlatList,
  KeyboardAvoidingView,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  View,
} from 'react-native'
import { ChatBubble } from '../ChatBubble/ChatBubble'
import { ConversationInput } from './components/ConversationInput'
import { ConversationViewEmpty } from './components/ConversationViewEmpty'
import { ConversationViewHeader } from './components/ConversationViewHeader'
import { DateSeparator } from './components/DateSeparator'
import { useMessagesApi } from './hooks/useMessagesApi'

export type ConversationViewProps = {
  /** Messages that are showing*/
  data?: ChatMessage[]
  /** Current user's id, used for showing different UI for the user's own messages */
  currUserId: string
  loading: boolean
  error?: string
  chatSession?: ChatSession
  adminFarmId?: string
  onLoadMore: () => void
}

/** Pixels from bottom to consider "at bottom" */
const BOTTOM_THRESHOLD = 20
/** Pixels from top to trigger load more */
const TOP_THRESHOLD = 100

/** Component that shows the messages from a conversation */
export function ConversationView({
  data,
  currUserId,
  chatSession,
  loading,
  error,
  adminFarmId,
  onLoadMore,
}: ConversationViewProps) {
  const [isNearBottom, setIsNearBottom] = useState(true)
  const [autoScrollDone, setAutoScrollDone] = useState(false)
  const latestYOffset = useRef(0)
  const { isSmallDevice } = useDeviceSize()

  const items = useMemo(() => {
    if (!data) return undefined

    return flagMessagesWithDates(data)
  }, [data])

  const isLoadingMoreItems = loading && !!items
  const scrollRef = useRef<FlatList | null>(null)

  const { onSendMessage, onSetMessageAsRead } = useMessagesApi({ chatSession })

  /* Scroll to bottom when new messages arrive and user is at bottom */
  useFocusFx(() => {
    if (!items?.length) return

    // If we are near the bottom of the conversation, we should scroll to end so the new message is viewed.
    if (isNearBottom) {
      setTimeout(() => {
        scrollRef.current?.scrollToEnd({ animated: true })
      }, 500)
    }

    // Automatic scroll should run only when the data is changed (a new message is received)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const onEndReached = useCallback(() => {
    if (!items?.length) {
      return
    }
    setAutoScrollDone(true)

    onSetMessageAsRead()
  }, [items?.length, onSetMessageAsRead])

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent

      const isCloseToBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - BOTTOM_THRESHOLD
      setIsNearBottom(isCloseToBottom)

      const isScrollingUp = contentOffset.y < latestYOffset.current - 10
      latestYOffset.current = contentOffset.y
      if (!isScrollingUp) return

      const isCloseToTop = contentOffset.y <= TOP_THRESHOLD

      if (!isLoadingMoreItems && autoScrollDone && isCloseToTop) {
        onLoadMore()
      }
    },
    [autoScrollDone, isLoadingMoreItems, onLoadMore],
  )

  return (
    <LoadingView error={error} style={styles.main} loading={false}>
      <KeyboardAvoidingView
        keyboardVerticalOffset={getKeyboardVerticalOffset(isSmallDevice, !!adminFarmId)}
        behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
        style={globalStyles.flex1}
        contentContainerStyle={globalStyles.flex1}
      >
        <FlatList<MessagesWithDate>
          data={items}
          ref={scrollRef}
          keyExtractor={(itm) => itm.id}
          contentContainerStyle={styles.contentContainer}
          onScroll={onScroll}
          // Android needs smaller throttle in order to correctly detect scroll events
          scrollEventThrottle={Platform.OS === 'android' ? 50 : 250}
          ListHeaderComponent={<ConversationViewHeader isLoadingMore={isLoadingMoreItems} />}
          ListEmptyComponent={<ConversationViewEmpty isLoading={loading} />}
          onEndReachedThreshold={0.2}
          onEndReached={onEndReached}
          renderItem={({ item }) => (
            <View>
              {item.shouldShowDate && <DateSeparator item={item} />}
              <ChatBubble
                isOwnChat={item.sender.id === currUserId}
                logo={getMessageLogo(chatSession, item, currUserId)}
                item={item}
              />
            </View>
          )}
          extraData={currUserId}
        />

        {!!items && <ConversationInput onSendMessagePress={onSendMessage} />}
      </KeyboardAvoidingView>
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end', // This is a temporary fix until a better solution is found for #9875
  },
  main: {
    overflow: 'hidden',
    flex: 1,
  },
})

/** Indicates which offset keyboardAvoidingView uses.
 * - When the keyboard is opened, it makes sure that the input is above it.
 * - It varies based on the platform, device size, and whether the screen is on the admin side (tabBar is present).*/
const getKeyboardVerticalOffset = (isSmallDevice: boolean, isAdminSide: boolean) => {
  if (Platform.OS === 'android') {
    return -125
  }
  if (Platform.OS === 'ios') {
    if (isAdminSide) {
      return 200
    }
    if (isSmallDevice) {
      return 100
    }
    return 145
  }
}
