import { useFocusFx } from '@/hooks/useFocusFx'
import { withAuth } from '@/hooks/withAuth'
import { MessagesParamList } from '@/navigation/types'
import { ConsumerView, ConversationView } from '@components'
import { getParticipantsCommonInfo } from '@helpers/messaging'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleSheet } from 'react-native'
import { useChatScreenData } from './hooks/useChatScreenData'

/** Shows user conversation messages. This component is used in both the consumer and admin chat screens so it must remain compatible with both */
export function ChatScreenComp_Reusable({ adminFarmId }: { adminFarmId?: string }) {
  const { messages, currUserId, loading, error, chatSession, onIncreaseMessageLimit } = useChatScreenData()
  const navigation = useNavigation<StackNavigationProp<MessagesParamList, 'Chat'>>()

  /** This will set the screen title with the other's participant name*/
  useFocusFx(
    () => {
      if (!chatSession?.participants || !currUserId) return

      const othersName = getParticipantsCommonInfo(chatSession.participants, currUserId).other?.name
      if (!othersName) return

      navigation.setOptions({ title: othersName })
    },
    [chatSession?.participants, currUserId, navigation],
    { noRefocus: true },
  )

  return (
    <ConversationView
      onLoadMore={onIncreaseMessageLimit}
      adminFarmId={adminFarmId}
      chatSession={chatSession}
      error={error}
      currUserId={currUserId}
      data={messages}
      loading={loading}
    />
  )
}

function ConsumerChatScreenComp() {
  return (
    <ConsumerView style={styles.main}>
      <ChatScreenComp_Reusable />
    </ConsumerView>
  )
}

const styles = StyleSheet.create({
  main: {
    paddingHorizontal: 0,
  },
})

export const ConsumerChatScreen = withAuth(ConsumerChatScreenComp)
