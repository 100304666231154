import { AdminDrawerParamList, DistributionSchedulesParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { setNavProps } from '@/redux/actions/appState'
import { addDistribution } from '@api/Distributions'
import { distrosCollection } from '@api/framework/ClientCollections'
import { Alert, Toast } from '@elements'
import { getDistributionPickups } from '@helpers/order'
import { getDayOfWeek, getWeekOfMonth } from '@helpers/time'
import { pick } from '@helpers/typescript'
import { Distribution, getDistLocationFields } from '@models/Distribution'
import { Farm } from '@models/Farm'
import { Location } from '@models/Location'
import {
  Frequency,
  isPatternException,
  isSkipException,
  isWiderFreq,
  SeasonalSchedule,
  YearRoundSchedule,
} from '@models/Schedule'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { validateDistributionEdit } from '../../helpers/editValidation'
import { ReturnStates } from '../../helpers/types'
import { invalidPickupSkippedPrompt } from '../../helpers/validationPrompts'
import { FormType } from '../DistributionDetailScreen.helpers'

type UseSubmitDistribtionProps = {
  farm: Farm
  locations: Location[]
  distribution: Distribution | undefined
  isEdit: boolean
  closed: Distribution['closed']
}

/** Handels submit distribution business logic */
export function useSubmitDistribtion({ locations, farm, distribution, isEdit, closed }: UseSubmitDistribtionProps) {
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const { params } = useRoute<RouteProp<DistributionSchedulesParamList, 'AddDistribution' | 'EditDistribution'>>()

  const navigation = useNavigation<StackNavigationProp<DistributionSchedulesParamList, 'AddDistribution'>>()

  const handleSubmitDistribution = useCallback(
    async (values: FormType) => {
      const location = locations.find((loc) => loc.id === values.locationId)
      if (!location) {
        Alert(
          'Error Loading Locations',
          'We were unable to load your locations, please refresh this page and try again.',
        )
        return
      }

      const distLocation = getDistLocationFields(location)

      let data: Distribution

      if (values.scheduleType === 'Seasonal') {
        const seasonalData: Distribution<Location, SeasonalSchedule> = {
          id: '',
          name: values.name,
          farm: pick(farm, 'id', 'name', 'status', 'timezone'),
          location: distLocation,
          schedule: {
            dayOfWeek: getDayOfWeek(values.startDate),
            frequency: values.frequency as Frequency,
            hours: {
              startTime: values.startTime.toFormat('HH:mm'),
              endTime: values.endTime.toFormat('HH:mm'),
            },
            season: {
              startDate: values.startDate,
              endDate: values.endDate!,
            },
            exceptions: values.exceptions.length ? values.exceptions : undefined,
          },
          notes: values.notes || '',
          orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
          closed,
          color: values.color,
          priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
        }
        data = seasonalData
      } else {
        const yearlyData: Distribution<Location, YearRoundSchedule> = {
          id: '',
          name: values.name,
          farm: pick(farm, 'id', 'name', 'status', 'timezone'),
          location: distLocation,
          schedule: {
            dayOfWeek: getDayOfWeek(values.startDate),
            frequency: values.frequency as Frequency,
            hours: {
              startTime: values.startTime.toFormat('HH:mm'),
              endTime: values.endTime.toFormat('HH:mm'),
            },
            pickupStart: values.startDate,
            exceptions: values.exceptions.length ? values.exceptions : undefined,
          },
          notes: values.notes || '',
          orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
          closed,
          color: values.color,
          priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
        }
        data = yearlyData
      }

      // If the frequency is bi-weekly or monthly we need to set the week of month we start on to calculate pickups
      if (isWiderFreq(values.frequency, Frequency.WEEKLY)) {
        data.schedule.week = getWeekOfMonth(values.startDate) - 1
      }

      // Need to remove exceptions so getDistributionPickups includes skipped pickups
      const dataWithoutExceptions = { ...data, schedule: { ...data.schedule, exceptions: [] } }
      const pickups = getDistributionPickups(dataWithoutExceptions)
      const isFirstPickupSkipped = data.schedule.exceptions?.some((ex) => {
        const firstPickup = pickups[0]

        if (!firstPickup) return false

        if (isPatternException(ex)) {
          return ex.dayOfWeek === firstPickup.weekday
        }

        if (isSkipException(ex)) {
          return ex.sourceDate.toISODate() === firstPickup.toISODate()
        }

        return false
      })
      const isLastPickupSkipped = data.schedule.exceptions?.some((ex) => {
        const lastPickup = pickups[pickups.length - 1]

        if (!lastPickup) return false

        if (isPatternException(ex)) {
          return ex.dayOfWeek === lastPickup.weekday
        }

        if (isSkipException(ex)) {
          return ex.sourceDate.toISODate() === lastPickup.toISODate()
        }

        return false
      })

      if (isFirstPickupSkipped) {
        return invalidPickupSkippedPrompt('first')
      }

      if (isLastPickupSkipped) {
        return invalidPickupSkippedPrompt('last')
      }

      // Update or save changes
      if (isEdit && distribution) {
        // Check that we are actually updating anything and warn the farmer of any important changes
        try {
          setSubmitting(true)

          const newDist = { ...data, id: distribution.id }
          const result = await validateDistributionEdit(distribution, newDist)

          // Since there is nothing to update display toast and proceed with going back to distribution list
          if (result.status === ReturnStates.NO_CHANGE) Toast('Nothing to update, going back')

          // return here, so we don't go back if something requires more attention or a popup was cancelled
          if (result.status === ReturnStates.DO_NOTHING) {
            Toast('Not saved')
            return setSubmitting(false)
          }

          // If the farmer agrees and there are changes then proceed to update
          if (result.status === ReturnStates.SAVE) {
            await distrosCollection.update(result.data)
            if (result.linkedProducts.length > 0) {
              //If there's linked products, clear the farm cache
              dispatch(setNavProps())
            }
            Toast('Schedule was updated successfully')
          }
        } catch (e) {
          Logger.error(e)
          return Alert(
            'Error saving distribution',
            'There was an error saving this distribution, please contact support if this issue persists.',
          )
        } finally {
          setSubmitting(false)
        }
      } else {
        setSubmitting(true)
        await addDistribution(data, farm)
        Toast('New Schedule is added successfully')
        setSubmitting(false)
      }
      if (params?.goBack === 'AddProduct') {
        navigation.setParams({ goBack: undefined })
        return (navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
          screen: 'AddProduct',
        })
      }
      navigation.navigate('Schedules')
    },
    [locations, distribution, farm, closed, isEdit, dispatch, navigation, params?.goBack],
  )

  return { handleSubmitDistribution, submitting }
}
