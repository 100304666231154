import { getState } from '@/assets/data/states'
import env from '@/config/Environment'
import { Logger } from '@/config/logger'
import { GoogleAddressParser } from '@/constants/GoogleAddressParser'
import { HomeParamList, SearchScreenParams } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { geocodeZipcode, getParsedAddressFromCoords } from '@api/Addresses'
import { GooglePlacesInputImperative } from '@components'
import { Toast } from '@elements'
import { ShortZipSchema } from '@helpers/builders/validators/sharedValidation'
import { CoordString, getCoordString, validCoords } from '@helpers/coordinate'
import { formatAddress } from '@helpers/display'
import { GooglePlace } from '@models/Address'
import { StackNavigationProp } from '@react-navigation/stack'
import { RefObject } from 'react'
import { Dispatch } from 'redux'

export type LocTypeDisplay = 'Delivery' | 'Pickup' | 'Shipping'

export type LocType = 'zip' | 'state' | 'coord'

export const locTypeToDisplayMap: Record<LocType, LocTypeDisplay> = {
  coord: 'Pickup',
  state: 'Shipping',
  zip: 'Delivery',
}
type SwitchLocTypeOpts = {
  inputValue: string
  locType: LocType
  newLocType: LocType
  searchRef: RefObject<GooglePlacesInputImperative>
  navigation: StackNavigationProp<HomeParamList, 'SearchScreen'>
  dispatch: Dispatch
}
/** Handles switching location type in the search screen */
export async function switchLocType({
  inputValue,
  locType,
  navigation,
  newLocType,
  searchRef,
  dispatch,
}: SwitchLocTypeOpts) {
  if (locType === 'state' && !!getState(inputValue)) {
    // If the current location type is state and the value in the input is only a state name string we can't use the value in the input for any other location type search, so we must unset the region param and simply change the location type with no region
    searchRef.current?.onClearText()
    return navigation.setParams({
      locType: newLocType,
      region: undefined,
      center: undefined,
      radius: undefined,
    })
  } else if (locType === 'zip' && ShortZipSchema.isValidSync(inputValue)) {
    // If the current location type is zip and the value in the input is a short zip code we can try to geocode the zipcode and generate an address to fill the input and set the params with the needed data
    try {
      const { coordinate, state } = await geocodeZipcode(inputValue)
      if (newLocType === 'state') {
        return navigation.setParams({
          locType: newLocType,
          region: state,
        })
      } else if (newLocType === 'coord') {
        // Try to get an address
        const center = getCoordString(coordinate)
        if (!center) throw new Error('The coordinates obtained for the zip code were invalid')
        const parser = await getParsedAddressFromCoords(coordinate)
        const addr = formatAddress(parser.getAddress())
        searchRef.current?.setText(addr)
        return navigation.setParams({
          locType: newLocType,
          region: undefined,
          center,
        })
      }
    } catch (error) {
      Logger.error(error)
      Toast('Error geocoding zipcode')
      return navigation.setParams({
        locType: newLocType,
        region: undefined,
        center: undefined,
        radius: undefined,
      })
    }
    return
  } else if (!!getState(inputValue) || ShortZipSchema.isValidSync(inputValue)) {
    // If for any other unforeseeable scenario the input happens to include only a bare zip code or state, don't submit that as a new search because it will definitely not produce the expected results
    searchRef.current?.onClearText()
    return navigation.setParams({
      locType: newLocType,
      region: undefined,
      center: undefined,
      radius: undefined,
    })
  }
  return submitLogistics({
    ref: searchRef,
    locType: newLocType,
    dispatch,
    handleParams: navigation.setParams,
    onPlaceNotFound: 'handleParams',
  })
}

type SubmitLogisticsReturn = Promise<{ status: 'error' | 'done' | 'empty' }>

/** Handles submitting logistics options for the current logistics type, the current address in the google places search input, and the current query.
 * - This should be called when we want to submit all the current values, whether the establishment is known or not.
 */
export async function submitLogistics({
  ref,
  locType,
  query,
  dispatch,
  handleParams,
  onPlaceNotFound = 'doNothing',
}: {
  ref: RefObject<GooglePlacesInputImperative>
  locType: LocType
  query?: string
  dispatch: Dispatch<any>
  handleParams: (params: SearchScreenParams) => void
  /** If the input is empty or the address was invalid there will be no google place found. If the value is 'handleParams' it will run the handleParams handler. That's what we want in the search screen because updates should happen immediately on change. We don't want that in the homescreen because it has no params and instead it navigates to the search screen when logistics are all filled in */
  onPlaceNotFound?: 'handleParams' | 'doNothing'
}): SubmitLogisticsReturn {
  if (!ref.current) {
    // This is never expected to happen because the ref will receive the handler on mount
    Logger.error(new Error('The submitCurrentAddress ref is undefined'))
    return { status: 'error' }
  }

  // This ensures the first autocomplete item is selected whenever the user presses the submit button on homescreen logistics UI or when they switch the logistics type in the search screen, without them having to manually select a suggestion from the autocomplete
  const place = await ref.current.submitCurrentValue().catch((err) => {
    Logger.error(err)
    return undefined
  })

  if (!place) {
    // This might mean perhaps the current text did not produce any results, or the request for the details item inside google places search might've failed. Either of those things isn't meant to be handled at this level because that's not the responsibility of this function
    if (onPlaceNotFound === 'handleParams') {
      // In this case we want to update the params
      // It should only set the locType param, and reset the other params related to logistics because it would mean the user simply wants to switch the type while the input is empty
      handleParams({ locType, region: undefined, center: undefined, radius: undefined })
    }

    return { status: 'empty' }
  }

  return submitEstablishment({ place, locType, query, dispatch, handleParams, onPlaceNotFound })
}

/** Handles setting the logistics options with a supplied establishment.
 * - This should be used once the establishment is known
 * - It needs to be a separate function from submitLogistics because this is called only when the user selects an item from the autocomplete, whereas the submitLogistics is called without an item selected
 */
export async function submitEstablishment({
  place,
  locType,
  query,
  dispatch,
  handleParams,
  onPlaceNotFound,
}: {
  place: GooglePlace
  locType: LocType
  query?: string
  dispatch: Dispatch<any>
  handleParams: (params: SearchScreenParams) => void
  onPlaceNotFound?: 'handleParams' | 'doNothing'
}): SubmitLogisticsReturn {
  // Get the necessary data from the place
  let error = ''
  let center: CoordString | undefined = undefined
  let region: string | undefined = undefined

  switch (locType) {
    case 'coord': {
      const coords = place.coordinate
      const coordString = getCoordString(coords)
      if (!validCoords(coords) || !coordString) {
        error = 'No valid coordinates could be obtained from the selected location'
      } else {
        center = coordString
      }
      break
    }
    case 'state': {
      if (!place.address_components) {
        error = 'Could not obtain the data for this address'
      } else {
        try {
          const parser = new GoogleAddressParser(place.address_components)

          region = parser.result().state
        } catch (err) {
          Logger.error(err)
          error = 'Something went wrong while parsing the address'
        }
      }

      break
    }
    case 'zip': {
      if (!place.address_components) {
        error = 'Could not obtain the data for this address'
      } else {
        try {
          const parser = new GoogleAddressParser(place.address_components)

          region = parser.result().postal_code
        } catch (err) {
          Logger.error(err)
          error = 'Something went wrong while parsing the address'
        }
      }
      break
    }
  }

  // Validate the data
  if (center && region && env.APP_ENV === 'dev') {
    throw new Error('Only the center or the region should be defined. Not both.')
  }
  if (error && (center || region) && env.APP_ENV === 'dev') {
    throw new Error("If there's an error the params should be undefined")
  }

  if (error || (!center && !region)) {
    // This means the preconditions are not met to perform a search

    if (error) {
      // If there was an error above, we're done
      Toast(error)
      return { status: 'error' }
    }

    // If there was no error try to get the missing data about the place. This might happen when they select a place that includes no zip code. For example when they search by only a city name, sometimes the address_components do not include the data for the zip code. But it can stil be obtained via the coords
    try {
      const parser = await getParsedAddressFromCoords(place.coordinate)
      if (locType === 'zip') {
        region = parser.result().postal_code
      } else if (locType === 'state') {
        region = parser.result().state
      }
    } catch (error) {
      Logger.error(error)
    }

    // check again to see if we got the data
    if (!center && !region) {
      Toast(
        `The ${
          locType === 'zip'
            ? 'zip code'
            : locType === 'state'
            ? 'state'
            : locType === 'coord'
            ? 'coordinates'
            : 'necessary data'
        } could not be obtained. Please try a different address${
          locType === 'zip' ? ' that includes zip code.' : locType === 'state' ? ' that includes state.' : '.'
        }`,
      )
      if (onPlaceNotFound === 'handleParams')
        handleParams({ locType, region: undefined, center: undefined, radius: undefined, isGlobal: undefined })
      return { status: 'error' }
    }
  }

  // Set the selected location as the most recent search location
  dispatch(setSearchLocation({ coordinate: place.coordinate, name: place.name }))

  const params: SearchScreenParams = { locType, center, region }
  if (query) params.q = query
  if (locType !== 'coord') {
    // The goal here is to reset any current value that may be in the radius param state
    // because radius only applies to searching by coordinates.
    params.radius = undefined
  }
  params.isGlobal = undefined
  handleParams(params)

  return { status: 'done' }
}
