import { PortalSetter, Spinner } from '@elements'
import { PortalProvider } from '@gorhom/portal'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { AdminDrawerNavigator } from '../admin/navigation/AdminDrawerNavigator'
import AdminOnboardStripeConnect from '../admin/screens/AdminOnboardStripeConnect'
import { ExternalLinkingNavigator } from '../screens/ExternalLinking'
import NotFoundScreen from '../screens/NotFoundScreen'
import ConsumerNavigator from './ConsumerNavigator'
import FarmGenAdminNavigator from './FarmGenAdminNavigator'
import { FarmerWalkthroughNavigator } from './FarmWalkthroughNavigator'
import { LinkingConfiguration } from './LinkingConfiguration'
import { SignInSheetNavigator } from './SignInSheetNavigator'
import {
  documentTitle,
  getScreenListeners,
  navigationTheme,
  onNavReady,
  onNavStateChange,
  stackScreenOptions,
} from './helpers'
import { navRef } from './navigationRef'
import { AppStackParamList } from './types'

import { useAppInitialization } from '@/constants/appInitialization/initializationHooks'
import { EnvironmentBanner } from '../components/EnvironmentBanner'

const AppStack = createStackNavigator<AppStackParamList>()

/** Main Navigation */
export function Navigation() {
  const dispatch = useDispatch()
  const routeNameRef = useRef<string>()

  const onReady = useCallback(() => onNavReady(navRef, routeNameRef, dispatch), [dispatch])

  const onStateChange = useCallback(() => onNavStateChange(navRef, routeNameRef, dispatch), [dispatch])

  const screenListeners = useMemo(() => getScreenListeners(dispatch), [dispatch])

  const isAppInit = useAppInitialization()

  if (!isAppInit) return <Spinner />

  return (
    <NavigationContainer<AppStackParamList>
      ref={navRef}
      linking={LinkingConfiguration}
      theme={navigationTheme}
      onReady={onReady}
      onStateChange={onStateChange}
      documentTitle={documentTitle}
    >
      {/* Having the portal provider at this level allows components in the portal to have access to react navigation context. For example, access to useLinkProps() for url navigation in useAutocomplete, so Touchables in the Autocomplete can navigate with urls directly. Outside of the navigation container this failed. */}
      <PortalProvider>
        <EnvironmentBanner />

        <PortalSetter>
          <AppStack.Navigator
            screenOptions={stackScreenOptions}
            screenListeners={screenListeners}
            initialRouteName="Consumer"
          >
            <AppStack.Screen name="Consumer" component={ConsumerNavigator} />
            <AppStack.Screen name="AdminDrawerNavigator" component={AdminDrawerNavigator} />
            <AppStack.Screen name="FarmerWalkthroughNavigator" component={FarmerWalkthroughNavigator} />
            <AppStack.Screen name="ExternalLinking" component={ExternalLinkingNavigator} />
            <AppStack.Screen name="SignInSheet" component={SignInSheetNavigator} />
            <AppStack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
            <AppStack.Screen name="FarmGenAdminNavigator" component={FarmGenAdminNavigator} />
            <AppStack.Screen name="OnboardStripeConnect" component={AdminOnboardStripeConnect} />
          </AppStack.Navigator>
        </PortalSetter>
      </PortalProvider>
    </NavigationContainer>
  )
}
