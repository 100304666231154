import { MultiSelectAutocompleteComp } from '@/admin/components/MultiSelectAutocomplete'
import { useApiFx } from '@/hooks/useApiFx'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { adminFarmSelector } from '@/redux/selectors'
import { loadCSAsByFarm } from '@api/CSAs'
import { Toast } from '@elements'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CouponFormType } from '../AddEditCoupon'
import { isNonNullish } from '@helpers/helpers'

/** MultipleSelect CSAGroups Autocomplete component for AddEditCoupon Form  */
export function CSAGroupSelection() {
  const { values, setFieldValue } = useFormikContext<CouponFormType>()
  const farm = useSelector(adminFarmSelector)
  const { data: farmCSAs } = useApiFx(loadCSAsByFarm, [farm.id])

  const onCSAGroupSelect = useCallback(
    (csaGroup: AutoCompleteItem<string>) => {
      if (values.csaGroups?.includes(csaGroup.data)) {
        Toast(`${csaGroup.text} is already selected`)
        return
      }

      setFieldValue('csaGroups', [...(values.csaGroups ?? []), csaGroup.data])
    },
    [setFieldValue, values.csaGroups],
  )

  const onRemoveCSAGroup = useCallback(
    (csaGroup: AutoCompleteItem<string>) => {
      setFieldValue(
        'csaGroups',
        values.csaGroups!.filter((item) => item !== csaGroup.data),
      )
    },
    [setFieldValue, values.csaGroups],
  )

  const items = useMemo(() => {
    if (!farmCSAs) return []

    const filtered = farmCSAs.filter((csaGroup) => !values.csaGroups?.includes(csaGroup.name))

    return filtered.map((csaGroup) => ({ text: csaGroup.name, data: csaGroup.id }))
  }, [farmCSAs, values.csaGroups])

  const selectedItems = useMemo(() => {
    return (
      values.csaGroups
        ?.map((csaGroupId) => {
          const csa = farmCSAs?.find((fcsa) => fcsa.id === csaGroupId)
          if (!csa) return undefined
          return { text: csa.name, data: csa.id }
        })
        .filter(isNonNullish) ?? []
    )
  }, [values.csaGroups, farmCSAs])

  return (
    <MultiSelectAutocompleteComp
      inline
      onSelect={(item) => onCSAGroupSelect(item)}
      onRemove={onRemoveCSAGroup}
      items={items}
      autoCompleteId="csaGroupSearch"
      placeholder="Search for CSA Groups"
      selectedItems={selectedItems}
    />
  )
}
