import { Invoice, InvoiceItem, InvoiceProductFeeId, invoiceItemTotal } from '@models/Invoice'
import { Money, Zero } from '@models/Money'
import { ProductFee, isTaxProductFee } from '@models/ProductFee'
import { MoneyCalc } from './money'

/**
 * This will
 * concatenate the invoice item description with the product description if it exists.
 *
 * Notes1: Previously, invoice item description includes the product description, but now it is separated, so we need to concatenate them because some places need only invoice item description and some places want combination from invoice item description and product description.
 *
 * Notes2: Previous invoice item description included product description and we keep it, but all future invoice item should have product description separated, so we will have two options.
 * 1. One to use the invoice item description only
 * 2. One to use the invoice item description and product description combined
 */
export function getInvItemDescription(itm: InvoiceItem, withProductDescription = true): string {
  if (itm.product && withProductDescription) {
    return `${itm.description}, ${itm.product.description}`
  } else {
    return itm.description
  }
}

/** Will format the product fee as an InvoiceItem['id'] */
export function createProductFeeId(productFee: ProductFee): InvoiceProductFeeId {
  if (isTaxProductFee(productFee)) {
    return `tax_${productFee.id}`
  }
  return `fee_${productFee.id}`
}

type InvoiceTaxesAndFeesTotal = {
  taxes: Money
  fees: Money
}

/** Calculate total tax And fees amounts for an invoice. By default, it will calculate total taxes */
export function calculateTotalTaxAndFeeAmounts(invoice: Invoice): InvoiceTaxesAndFeesTotal {
  const result = { taxes: Zero, fees: Zero }

  for (const item of invoice.items) {
    if (item.id.startsWith('tax_')) {
      result.taxes = MoneyCalc.add(result.taxes, invoiceItemTotal(item))
    } else if (item.id.startsWith('fee_')) {
      result.fees = MoneyCalc.add(result.fees, invoiceItemTotal(item))
    }
  }

  return result
}
