import { globalStyles } from '@/constants/Styles'
import { ToolTips } from '@components'
import { Text, Tooltip } from '@elements'
import { memo } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

type InputLabelProps = {
  label: string
  tooltipTitle?: string
  tooltipId?: ToolTips
  required?: boolean
  style?: StyleProp<ViewStyle>
}

/** Custom label used alongside Tooltips
 * @deprecated FormInputLabel component should be used instead
 */
const InputLabel = memo(function InputLabel({ label, tooltipId, tooltipTitle, required, style }: InputLabelProps) {
  return (
    <View style={[styles.main, style]}>
      <View style={globalStyles.flexRowCenter}>
        <Text numberOfLines={1} style={styles.label}>
          {label}
          {required && '*'}
        </Text>
        {!!tooltipId && <Tooltip style={styles.tooltip} title={tooltipTitle ?? label} id={tooltipId} />}
      </View>
    </View>
  )
})
export default InputLabel

const styles = StyleSheet.create({
  main: {
    justifyContent: 'flex-end',
    minHeight: 30, // Must be the same value as the label on FormInput so it has the same spacing
  },
  label: {
    fontSize: 16,
  },
  tooltip: {
    marginLeft: 5,
  },
})
