import { ConsumerScroll } from '@components'

import { useLayout } from '@/hooks/useLayout'
import { withAuth } from '@/hooks/withAuth'
import { UserScreenFooter } from './components/UserScreenFooter'
import { UserScreenHeader } from './components/UserScreenHeader'
import {
  AccountSection,
  FarmerSection,
  InvitationsSection,
  LegalSection,
  SupportSection,
} from './components/UserScreenSections'

function UserScreenComp() {
  const { top } = useLayout()

  return (
    <ConsumerScroll style={{ marginTop: top }}>
      <UserScreenHeader />
      <AccountSection />
      <FarmerSection />
      <InvitationsSection />
      <SupportSection />
      <LegalSection />
      <UserScreenFooter />
    </ConsumerScroll>
  )
}

export const UserScreen = withAuth(UserScreenComp)
