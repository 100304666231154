// WARNING:  polyfills.js MUST be the first import in the app, it handles polyfilling based on the Platform
import './polyfills'
// This must be included so that YupCustomMethods are added to the Yup global object
import './shared/YupCustomMethods'

/**
 * This file may need to be redesigned/ or removed, since it makes no sense to have. We created it out of necessity when custom entry points no longer worked for a past expo version. Now they work again. https://docs.expo.dev/versions/latest/sdk/register-root-component/#what-if-i-want-to-name-my-main-app-file-something-other-than-appjs and https://docs.expo.dev/build-reference/migrating/#custom--main--entry-point-in . The OLD reference is no longer applicable (https://docs.expo.dev/versions/latest/sdk/register-root-component/)
 */
import { registerRootComponent } from 'expo'

/**
 * Our solution for app switching is implemented inside the AppSwitcher app; So this layer doesn't have any important or necessary logic or code; which is why this file may be best removed unless we later find a way to handle the app swithing here somehow. There is no obvious benefit to that though.
 * - Unfortunately env variables don't work here. So you can't registerRootComponent(CompNavigator) only if env.IS_COMP === true; this is why we no longer handle the app switching at this layer.
 * - You also can't console.log here, so you can't debug any value here at runtime.
 */
import { AppSwitcher } from './App'

registerRootComponent(AppSwitcher)

// For simple testing purposes, you may enable registering a specific component instead of the default one
// import CompNavigator from './components/ComponentNavigator/App'
// registerRootComponent(CompNavigator)
