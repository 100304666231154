import { ALL_CATEGORIES, ALL_TYPES, MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { capitalizeFirst, formatAddress } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { sortByProperty } from '@helpers/sorting'
import { isValidAddress } from '@models/Address'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, AlgoliaShareCategory } from '@models/Algolia'
import { CSA } from '@models/CSA'
import { ProductFilters } from '@models/Filters'
import { Location, isDelivery, isLocalPickup } from '@models/Location'
import { AddonShare, ProductType } from '@models/Product'
import { RangeItem } from '@screens/SearchScreen/components/RangeFilter/RangeFilter'

export type ProcessedMenuItem = MenuItem & { filterKey: keyof ProductFilters; shouldClear?: boolean; subtitle?: string }

/** Each group is a section of buttons for the sidebar */
export type ProductFilterGroup = {
  title: string
  filterKey: keyof ProductFilters
  items: ProcessedMenuItem[]
}

/** This creates an artificial sidebar group of algolia menu items from the csas
 * Csas without products are not displayed
 */
export const getCsaRefinementGroup = (
  csas: CSA[] | undefined,
  products: (AlgoliaGeoDoc<AlgoliaGeoProduct> | AddonShare)[],
): ProductFilterGroup | undefined => {
  // CSAs that are present in visible products
  const prodCsas = products.flatMap((el) => el.csa)

  const filtered = csas?.filter((csa) => prodCsas.includes(csa.id) && !csa.isPrivate)

  if (filtered?.length)
    return {
      title: 'Shares',
      filterKey: 'csaId',
      items: filtered.map((csa) => ({
        label: csa.name,
        value: csa.id,
        count: 1,
        isRefined: false,
        filterKey: 'csaId',
      })),
    }

  return undefined
}

/** Prepares data for the shop sidebar from type refinement options
 * - For the moment will allow only primary and addons
 */
export const getTypeRefinementGroup = (refinementTypes: MenuItem[]): ProductFilterGroup | undefined => {
  const items: ProductFilterGroup['items'] = [...refinementTypes]
    // Show only primary and addons if provided
    .filter((type) => type.value === ProductType.PrimaryShare || type.value === ProductType.AddonShare)
    .sort(sortByProperty([ProductType.PrimaryShare, ProductType.AddonShare], (item) => item.value))
    .map((type) => ({
      ...type,
      label: type.value === ProductType.PrimaryShare ? 'Shares' : 'Add-ons',
      filterKey: 'type',
    }))

  if (!items.length) return undefined

  items.unshift({
    value: ALL_TYPES,
    label: ALL_TYPES,
    count: refinementTypes.length,
    // `All` should be seleted only when no other value is
    isRefined: !refinementTypes.some((el) => el.isRefined),
    filterKey: 'type',
    shouldClear: true,
  })

  return {
    title: 'Products',
    items,
    filterKey: 'type',
  }
}

/** Prepares data for the shop sidebar from category refinement options  */
export const getCategoryRefinementGroup = (categories: MenuItem[]): ProductFilterGroup | undefined => {
  const filteredCats: ProductFilterGroup['items'] = categories
    .filter((cat) => {
      /** This "trim" and "lowerCase" shouldn't be necessary but it seems the algolia hook for useMenu modifies the case of the categories strings. In algolia dashboard they are correct, but here they are received as "CSA shares", instead of "CSA Shares". This means if a farm has a special category that matches this string (case insensitive) it won't be shown in the shop. It's perhaps not a big deal. But that's why. */
      return cat.value.trim().toLowerCase() !== AlgoliaShareCategory.trim().toLowerCase()
    })
    .map((cat) => ({
      ...cat,
      label: capitalizeFirst(cat.label),
      filterKey: 'categoryId',
    }))

  if (!filteredCats.length) return undefined

  // Add an "All" category
  filteredCats.unshift({
    value: ALL_CATEGORIES,
    label: ALL_CATEGORIES,
    count: categories.length,
    // `All` should be seleted only when no other value is
    isRefined: !categories.some((cat) => cat.isRefined),
    filterKey: 'categoryId',
    shouldClear: true,
  })

  return {
    title: 'Categories',
    items: filteredCats,
    filterKey: 'categoryId',
  }
}

/** Transforms db locations to MenuItem type, in order to be used in the UI */
export const getLocationRefinementGroup = (
  dbLocs: Location[],
  algoliaLocItems: MenuItem[],
): ProductFilterGroup | undefined => {
  const algoliaLocIds = algoliaLocItems.map((el) => el.value)

  const items: ProductFilterGroup['items'] = dbLocs
    .filter((dbLoc) => algoliaLocIds.includes(dbLoc.id))
    .map((loc) => {
      const algoliaLoc = algoliaLocItems.find((el) => el.value === loc.id)!
      return {
        ...algoliaLoc,
        label: loc.name,
        subtitle: getLocationSubtitle(loc),
        filterKey: 'locationId',
      }
    })

  if (!items.length) return undefined

  items.unshift({
    value: ALL_TYPES,
    label: ALL_TYPES,
    count: items.length,
    // `All` should be seleted only when no other value is
    isRefined: !items.some((el) => el.isRefined),
    filterKey: 'locationId',
    shouldClear: true,
  })

  return { title: 'Locations', filterKey: 'locationId', items: items.filter(isNonNullish) }
}

/** Creates a refinement group of algolia locations */
export const getAlgoliaLocationRefinementGroup = (algoliaLocs?: MenuItem[]): ProductFilterGroup | undefined => {
  if (!algoliaLocs?.length) return undefined

  const items: ProductFilterGroup['items'] = algoliaLocs.map((loc) => {
    return {
      ...loc,
      filterKey: 'locationId',
    }
  })

  items.unshift({
    value: ALL_TYPES,
    label: ALL_TYPES,
    count: items.length,
    // `All` should be seleted only when no other value is
    isRefined: !items.some((el) => el.isRefined),
    filterKey: 'locationId',
    shouldClear: true,
  })

  return { title: 'Locations', filterKey: 'locationId', items: items.filter(isNonNullish) }
}

/** Creates a text value that represents the address (LocalPickup) or the regions (NonPickup) for the location */
const getLocationSubtitle = (item: Location) => {
  if (isLocalPickup(item)) {
    if (!isValidAddress(item.address)) {
      return ''
    }
    return formatAddress(item.address)
  }

  const text = isDelivery(item) ? 'Zip codes: ' : 'States: '
  const regions = item.regions.join(', ')
  return text + regions
}

export const getEbtRefinementGroup = (isRefined: boolean): ProductFilterGroup | undefined => {
  return {
    title: 'SNAP/EBT',
    filterKey: 'ebtOnly',
    items: [
      {
        value: 'ebt',
        label: 'Only SNAP/EBT products',
        count: 0,
        isRefined,
        filterKey: 'ebtOnly',
      },
    ],
  }
}

export const getVisibleTags = (groups: ProductFilterGroup[]) => {
  const visibleKeys: ProductFilterGroup['filterKey'][] = ['categoryId', 'type']

  const filteredGroups = groups.filter((group) => visibleKeys.includes(group.filterKey))

  const tags = filteredGroups
    .map((el) =>
      el.items.find((el) => {
        // Show just refined values that are not 'All'
        return el.isRefined && !el.shouldClear
      }),
    )
    .filter(isNonNullish)
  return tags
}

export const getFarmGroup = (farms: MenuItem[]): ProductFilterGroup | undefined => {
  if (!farms.length) return undefined

  const items: ProductFilterGroup['items'] = farms.map((item) => ({
    ...item,
    filterKey: 'farm',
  }))

  // Add an "All" category
  items.unshift({
    value: ALL_CATEGORIES,
    label: ALL_CATEGORIES,
    count: items.length,
    // `All` should be seleted only when no other value is
    isRefined: !items.some((itm) => itm.isRefined),
    filterKey: 'farm',
    shouldClear: true,
  })

  return {
    title: 'Farms',
    items,
    filterKey: 'farm',
  }
}

/** Prepares data for the shop sidebar from practices refinement options  */
export const getPracticesRefinementGroup = (practices: MenuItem[] | undefined): ProductFilterGroup | undefined => {
  if (!practices?.length) return undefined

  const items: ProductFilterGroup['items'] = practices.map((itm) => ({
    ...itm,
    label: capitalizeFirst(itm.label),
    filterKey: 'practices',
  }))

  // Add an "All" category
  items.unshift({
    value: ALL_CATEGORIES,
    label: ALL_CATEGORIES,
    count: practices.length,
    // `All` should be seleted only when no other value is
    isRefined: !practices.some((itm) => itm.isRefined),
    filterKey: 'practices',
    shouldClear: true,
  })

  return {
    title: 'Certifications',
    items,
    filterKey: 'practices',
  }
}

/**
 * Generates a list of range refinement options, filtering and refining based on the provided limits and current selected value.
 *
 * @param options - List of available range options, each containing a min and max value.
 * @param noFilterLabel - Label used for the option that represents "no filter" (e.g., all values or no refinement).
 * @param limits - Range limits used to filter the options.
 * @param currValue - The current selected value with `min` and `max` that is marked as refined.
 */
export const getRangeRefinementGroupItems = (
  options: RangeItem[],
  noFilterLabel: string,
  limits: RangeItem['value'],
  currValue?: RangeItem['value'],
): RangeItem[] | undefined => {
  const filtered = options
    .filter((el) => shouldOptionShow(el, limits))
    .map((el) => {
      return { ...el, isRefined: currValue?.max === el.value.max && currValue?.min === el.value.min }
    })

  if (!filtered.length) return undefined

  filtered.unshift({
    value: { min: undefined, max: undefined },
    label: noFilterLabel,
    isRefined: !currValue?.min && !currValue?.max,
  })

  return filtered
}

/** Whether the range filter option should show
 * - Should show when an option has its values within the limits
 * - i.e on `$10 - 25`limits, we should have both `$0 - 20$` and `$20 - $40` options
 */
const shouldOptionShow = (option: RangeItem, limits: RangeItem['value']): boolean => {
  const { min, max } = option.value

  // Check if the option overlaps with the limits
  const isMinWithinBounds = min === undefined || limits.max === undefined || min <= limits.max
  const isMaxWithinBounds = max === undefined || limits.min === undefined || max >= limits.min

  return isMinWithinBounds && isMaxWithinBounds
}
