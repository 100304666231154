import { getAppModeFromEnv } from '@/config/Environment'
import { Logger } from '@/config/logger'
import { setWholesale } from '@/redux/actions/appPersist'
import signout from '@/redux/actions/signout'
import { onUserAuthChange } from '@/redux/actions/user'
import { userSelector, wholesaleSelector } from '@/redux/selectors'
import { signInWithCustomToken } from '@api/Sessions'
import { getWholesaleAuthorization } from '@api/Wholesale'
import { Modal, Toast } from '@elements'
import { AppMode } from '@models/Global'
import { SignedInState } from '@models/User'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WholesaleBetaSignup } from '../../components/modals/WholesaleBetaSignup'

/** Handles setting global wholesale data
 * @returns {boolean} whether the wholesale mode is initialized
 */
export function useSetWholesale(): boolean {
  const [isLoading, setLoading] = useState(false)
  const { isWholesale, institutionMemberId, wholesaleAccessRole } = useSelector(wholesaleSelector)
  const dispatch = useDispatch()
  const user = useSelector(userSelector)

  const isInitialized = typeof isWholesale === 'boolean'

  /** Sets the global wholesale mode */
  useEffect(() => {
    // TODO: Temporarily disabled to reset everyone to the correct app mode based on their environment
    //  should remove && __DEV__ in a few days once everyone has logged into the app in grownby-dev.web.app
    if (isWholesale !== undefined && __DEV__) return

    const appMode = getAppModeFromEnv()
    dispatch(setWholesale({ isWholesale: appMode === AppMode.Wholesale }))
  }, [dispatch, isWholesale])

  /** Async flow that requests wholesale authorization, and logs in to the institution if permission is granted */
  const runWholesaleAuthFlow = useCallback(
    async (memberId: string) => {
      setLoading(true)

      try {
        const res = await getWholesaleAuthorization()

        if (res) {
          // User is authorized
          if (res.token) {
            // The user is an institution member
            await signout()
            await signInWithCustomToken(res.token)
            // Set redux state to mark them as a Wholesale user
            dispatch(setWholesale({ wholesaleAccessRole: res.role, institutionMemberId: memberId }))
          } else {
            // If no token, means a farmer was authorized. We still set the authorized role, but no memberId is required, and no signout is required.
            dispatch(setWholesale({ wholesaleAccessRole: res.role, wholesaleAccessType: res.accessType }))
          }
        } else {
          // Not a wholesale user.
          await signout()
          Modal(<WholesaleBetaSignup />, { title: 'GrownBy Wholesale is coming soon!' })
        }
      } catch (error) {
        signout()
        Logger.error(error)
        Toast('Something went wrong while signing you in, please contact support.')
      } finally {
        setLoading(false)
      }
    },
    [dispatch],
  )

  /** In wholesale mode, will manage the global wholesale data related to the user */
  useEffect(() => {
    if (!isWholesale || isLoading) return

    return onUserAuthChange(({ state, userId }) => {
      if (state === SignedInState.SIGNEDIN) {
        if (userId !== user.id) {
          // wait for the user data to be loaded
          return
        }
        if (
          user.institution &&
          institutionMemberId &&
          user.institution.authorizedUserIds.includes(institutionMemberId)
        ) {
          // an institution account is already logged in, so do nothing
          return
        }

        if (wholesaleAccessRole) {
          // The user is logged in to wholesale through a personal account, wholesale enabled farm or support account
          return
        }

        runWholesaleAuthFlow(userId)
      } else if (state === SignedInState.SIGNEDOUT) {
        dispatch(setWholesale({ wholesaleAccessRole: undefined, institutionMemberId: undefined }))
      }
    })
  }, [isWholesale, isLoading, dispatch, user, institutionMemberId, runWholesaleAuthFlow, wholesaleAccessRole])

  return isInitialized
}
