import { FEATURED_FARMS, getAlgoliaFarmsByIds, WHOLESALE_FEATURED_FARMS } from '@api/Farms'
import { memo, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { FeaturedFarmsList } from './FeaturedFarmsList'
import { NearbyProdsGroup } from './NearbyProdsGroup'
import { NearbyProdsGroupSkeleton } from './NearbyProdsGroupSkeleton'

import { getRequiredNFarms, useGetNearbyProds } from '@/hooks/useGetNearbyProds'
import { userSelector, wholesaleSelector } from '@/redux/selectors'
import { LoadingView, Spinner } from '@elements'
import { groupBy, nonEmptyString } from '@helpers/helpers'

import { useApiFx } from '@/hooks/useApiFx'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { getSortAlgoliaProducts } from '@helpers/sorting'
import { GooglePlace } from '@models/Address'

/** Searches for nearby products based on the search or user location, and progressively displays the results */
export const NearbyProductsResults = memo(function NearbyProductsResults({
  place,
}: {
  /** The coordinates to use for the product search */
  place: GooglePlace
}) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { availAddonsIds } = useAvailAddons()
  const user = useSelector(userSelector)

  /*** If a selected location is defined, it will be used for the product search. Otherwise it will use the user coordinate if available. */
  const { loading, data: nearbyProds } = useGetNearbyProds(place)

  const featuredFx = useApiFx(
    getAlgoliaFarmsByIds,
    [isWholesale ? Object.values(WHOLESALE_FEATURED_FARMS) : Object.values(FEATURED_FARMS), isWholesale],
    // Lazy load featured farms only when no nearby products are found
    typeof isWholesale === 'boolean' && !loading && nearbyProds?.length === 0,
    { initialState: { loading: false } },
  )

  /** Products grouped by farm */
  const farmgroups = useMemo(() => {
    const groups = groupBy(nearbyProds, (p) => p.farm.id)
    groups.forEach((group) => {
      // it is OK to sort inline because these groups are being recreated on memo update.
      // TODO: The memo is not doing anything here because the dependencies are being recreated on each render, so if this starts having more data will be a performance issue.
      //  https://github.com/farmgenerations/grownby/issues/9716
      group.sort(getSortAlgoliaProducts(availAddonsIds))
    })
    return groups
  }, [nearbyProds, availAddonsIds])

  // If loading finishes without products default to showing Featured
  // Also if we are in wholesale mode and the user us logged out, don't allow them to interact with nearby products so we show featured farms
  const shouldShowFeaturedFarms = (!loading && nearbyProds.length === 0) || (isWholesale && !nonEmptyString(user.id))
  if (shouldShowFeaturedFarms) {
    return (
      <LoadingView loading={featuredFx.loading} error={featuredFx.err} spinnerProps={{ style: styles.spinner }}>
        <FeaturedFarmsList items={featuredFx.data ?? []} />
      </LoadingView>
    )
  }

  // This checks if there are more farms that are still loading
  // If so, we should show skeleton for those farms
  const nPendingFarms = loading ? getRequiredNFarms() - farmgroups.length : 0

  return (
    <View>
      {farmgroups.map((products) => (
        <NearbyProdsGroup key={products[0].farm.id} prods={products} />
      ))}
      {Array.from({ length: nPendingFarms }).map((_, i) => (
        <NearbyProdsGroupSkeleton key={i} />
      ))}
      {/*loading state should not block results*/}
      {loading && <Spinner style={styles.spinner} />}
    </View>
  )
})

const styles = StyleSheet.create({
  spinner: {
    margin: 20,
  },
})
