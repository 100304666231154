import { Ionicons } from '@expo/vector-icons'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types'
import { memo, useCallback } from 'react'
import { Pressable, View } from 'react-native'

import ShoppingCart from '../../components/ShoppingCart'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { ShoppingStackParamList } from '@/navigation/types'
import { navPropsSelector } from '@/redux/selectors'
import { HeaderText } from '@elements'
import { useSelector } from 'react-redux'

/** Mobile version of the shop header.
 *
 * This component is used only in farmshop, and only in non-large mobile devices.
 * The reason is: In large devices and web, we use the regular <Header />, and in mobile non-large devices, we had been using the default navigation header with elements specified via the navigator options. The problem with this approach is it causes styling issues whenever we add complex elements in it, like the locationDropdown, whose width will vary depending on the location name selected. So a custom header for farmshop, for mobile non-large devices is required to accommodate flexible header UIs, styles, and future requirements.
 */
export const MobileShopHeader = memo(function MobileShopHeader() {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const {
    params: { goBack, farmSlug },
  } = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()
  const { farm } = useSelector(navPropsSelector)

  const onGoBack = useCallback(() => {
    if (goBack === 'home') {
      return navigation.navigate('Home', { screen: 'HomeScreen' })
    }
    if (goBack === 'explore') {
      navigation.navigate('Home', { screen: 'ExploreScreen' })
    }
    return navigation.navigate('FarmDetailScreen', { farmSlug })
  }, [farmSlug, goBack, navigation])

  const styles = useStyles()

  return (
    <View style={styles.main}>
      <Pressable onPress={onGoBack}>
        <Ionicons name="arrow-back" size={27} color="black" />
      </Pressable>
      <HeaderText numberOfLines={1} style={styles.text} size={16}>
        {farm?.name ?? 'Farm shop'}
      </HeaderText>
      <ShoppingCart />
    </View>
  )
})

const useStyles = () =>
  useLayoutFnStyles(({ top }) => ({
    main: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: top,
      paddingBottom: 10,
      paddingHorizontal: 10,
      justifyContent: 'space-between',
    },
    text: {
      flex: 1,
    },
  }))
