import { ProductAutoComplete } from '@/admin/components/ProductAutoComplete'
import { ProductSnapshot } from '@/admin/screens/Products/AdminProductListScreen/components/ProductSnapshot'
import { Toast, UniversalTag } from '@elements'
import { AlgoliaAdminProduct } from '@models/Algolia'
import { Product } from '@models/Product'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { CSAFormik } from './type'

export function CSASuggestedProducts() {
  const { values, setFieldValue } = useFormikContext<CSAFormik>()
  const isValidNewSuggestedProd = (prods: string[], item: AlgoliaAdminProduct) => {
    if (prods.includes(item.id)) {
      Toast('Product already selected')
      return false
    }
    if (prods.length >= 3) {
      Toast('Cannot add more than 3 products')
      return false
    }
    return true
  }

  const onProdSelect = (item: AlgoliaAdminProduct) => {
    if (!isValidNewSuggestedProd(values.suggestedProducts ?? [], item)) {
      return
    }
    setFieldValue('suggestedProducts', [...(values.suggestedProducts ?? []), item.id])
  }

  const onRemoveSuggested = (item: Product) => {
    setFieldValue(
      'suggestedProducts',
      values.suggestedProducts!.filter((id) => id !== item.id),
    )
  }

  return (
    <>
      <ProductAutoComplete inline onSelect={(item) => onProdSelect(item)} />
      <View style={styles.prodsCont}>
        {(values.suggestedProducts ?? []).map((id) => (
          <ProductSnapshot
            key={id}
            prodId={id}
            extraData={values.suggestedProducts}
            Component={({ prod }) => <UniversalTag onPress={() => onRemoveSuggested(prod)} label={prod.name} />}
          />
        ))}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  prodsCont: {
    marginVertical: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})
