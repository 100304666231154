/* eslint-disable no-restricted-imports */
import { FirestoreError } from '@firebase/firestore'
import { errorToString, extendErr, isTruthy, nonEmptyString, removeDuplicates, splitToGroups } from '@helpers/helpers'
import mergeDeep from '@helpers/mergeDeep'
import { ErrorCode, ErrorTypes, ErrorWithCode, NotFoundError } from '@shared/Errors'
import type {
  DocumentData,
  DocumentSnapshot,
  PartialWithFieldValue,
  Query,
  QueryCompositeFilterConstraint,
  QueryConstraint,
  QueryNonFilterConstraint,
  QuerySnapshot,
  SetOptions,
  UpdateData,
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { DateTime } from 'luxon'

import { db } from '../db'
import { marshalMeta, unmarshalMeta } from '../encoding/Meta'

import { Logger } from '@/config/logger'
import { errorCatcher } from '@api/Errors'
import { marshalDate } from '@api/encoding/Time'
import { PartialPick } from '@helpers/typescript'
import { DataVersion, Meta } from '@models/Meta'

type Marshaller<T> = (object: Partial<T>, isNested?: boolean) => DocumentData
type Unmarshaller<T> = (idOrSnapshot: DocumentSnapshot | string, incomingData?: DocumentData) => T

/** Collection identifies a database document collection configuration. */
export class ClientCollection<
  T extends {
    id: string
  },
> {
  connection: Connection
  marshal: Marshaller<T>
  unmarshal: Unmarshaller<T>
  dataVersion: DataVersion

  constructor(
    collectionName: string | Connection,
    marshal: Marshaller<T>,
    unmarshal: Unmarshaller<T>,
    // This is the version of data that the client expects from Firestore
    dataVersion: DataVersion,
  ) {
    if (collectionName instanceof Connection) {
      this.connection = collectionName
    } else {
      this.connection = new Connection(collectionName)
    }
    this.marshal = marshal
    this.unmarshal = unmarshal
    this.dataVersion = dataVersion
  }

  /** Will get a reference to a doc by id, if the id is not passed, it will generate an id and reference */
  reference(id?: string) {
    // If the id is missing then we must not pass any second argument to doc
    if (!id) return doc(this.connection.collection())
    return doc(this.connection.collection(), id)
  }

  /**
   * this overload is used to create a query with a composite filter and other query constraints using and() and or()
   * functions provided in firestore package
   */
  query(compositeFilter: QueryCompositeFilterConstraint, ...queryConstraints: QueryNonFilterConstraint[]): Query
  /**
   *
   * @param queryConstraints Firestore query constraints (where, orderBy, limit, etc.)
   */
  query(...queryConstraints: QueryConstraint[]): Query
  /**
   *  query returns a Firestore query object that can be used to fetch a collection of objects that meet the query criteria.
   */
  query(...queryConstraints: any[]) {
    return query(this.connection.collection(), ...queryConstraints)
  }

  /** fetch returns the collection's document identified by the supplied identifier.
   * It uses the activated database handle provided by the db function. */
  async fetch(id: string): Promise<T> {
    if (!id) {
      throw new ErrorWithCode({
        code: ErrorCode.MissingData,
        type: ErrorTypes.Validation,
        devMsg: 'Cannot call fetch without an id string',
      })
    }
    const snapshot = await getDoc(this.reference(id))
    if (!snapshot.exists()) {
      throw new NotFoundError(this.connection.collection().id, id)
    }
    return this.unmarshal(snapshot.id, snapshot.data())
  }

  /** Receives a query created by the collection's query method, and retrieves the resulting objects from this query */
  async fetchQuery(query: ReturnType<typeof this.query>): Promise<T[]> {
    let results: QuerySnapshot | undefined
    try {
      results = await getDocs(query)
    } catch (e) {
      Logger.error(extendErr(e, `Error while calling fetchAll method of ${this.connection.collection().id} api class.`))
      throw e
    }
    return results.docs
      .filter((s) => s.exists())
      .map((snapshot) => {
        try {
          return this.unmarshal(snapshot)
        } catch (e) {
          Logger.error(
            `There was an error while unmarshaling a member of a ${this.connection.collection().id} snapshots array: ${
              snapshot.id
            }. Error: ${errorToString(e)}`,
          )
          return undefined
        }
      })
      .filter(isTruthy)
  }

  /** Receives a composite filter as first argument, and other complimentary query constraints as the subsequent args.
   * @param compositeFilter an 'or' constraint.
   * @param queryConstraints an array of constraints compatible with the 'or' constraint
   */
  async fetchAll(
    compositeFilter: QueryCompositeFilterConstraint,
    ...queryConstraints: QueryNonFilterConstraint[]
  ): Promise<T[]>
  /**
   * @param queryConstraints Firestore query constraints (where, orderBy, limit, etc.)
   */
  async fetchAll(...queryConstraints: QueryConstraint[]): Promise<T[]>
  /**
   * @param queryConstraints Firestore query constraints (where, orderBy, limit, etc.)
   */
  async fetchAll(...queryConstraints: any[]): Promise<T[]> {
    const q = this.query(...queryConstraints)

    return this.fetchQuery(q)
  }

  /** Will create a snapshot listener for a single document that will listen for changes and call the callback with any changes */
  snapshotDoc(id: string, callback: (data?: T) => void, onError: (err: FirestoreError) => void = errorCatcher) {
    if (!nonEmptyString(id)) {
      throw new Error('Missing document id')
    }
    return onSnapshot(
      this.reference(id),
      (snapshot) => {
        if (!snapshot.exists()) return callback()
        const data = this.unmarshal(snapshot)
        callback(data)
      },
      onError,
    )
  }

  /**
   * Count returns the number of documents that match the supplied query constraints. (note this charges just 1 read per 1000 docs)
   * @param queryConstraints Firestore query constraints (where, orderBy, limit, etc.)
   * @returns the number of documents that match the query
   */
  async count(...queryConstraints: QueryConstraint[]): Promise<number> {
    const q = this.query(...queryConstraints)
    const results = await getCountFromServer(q)
    return results.data().count
  }

  /** Will create a snapshot listener for a set of documents that will listen for changes and call the callback with any changes */
  snapshotMany(qRef: Query, callback: (data: T[]) => void, onError: (err: FirestoreError) => void = errorCatcher) {
    return onSnapshot(
      qRef,
      (snapshot) => {
        if (snapshot.empty) return callback([])
        const data = snapshot.docs.filter((d) => d.exists()).map((snapshot) => this.unmarshal(snapshot))
        callback(data)
      },
      onError,
    )
  }

  /** create adds the supplied document to the collection. */
  async create(doc: Partial<T>): Promise<T> {
    const data = this.marshal(doc)

    // Adds meta to the doc
    data.meta = marshalMeta({
      createdAt: DateTime.now(),
      updatedAt: DateTime.now(),
      lastAccessed: DateTime.now(),
      version: this.dataVersion,
    })
    const { id } = await addDoc(this.connection.collection(), data)

    return this.unmarshal(id, data)
  }

  /** updates the supplied document. fieldValueUpdates allows you to send field modifier actions as part of the update, on any deeply nested field */
  async update(
    snapshot: Partial<T> & {
      id: string
    },
    fieldValueUpdates?: PartialWithFieldValue<T>,
  ): Promise<void> {
    const data = this.marshal(snapshot)

    // generate updatedAt time
    const updatedAt = marshalDate(DateTime.now())

    const updateData: UpdateData<DocumentData> = fieldValueUpdates
      ? mergeDeep(data, fieldValueUpdates as DocumentData)
      : data

    // This should also update meta.updatedAt field
    await updateDoc(this.reference(snapshot.id), {
      ...updateData,
      'meta.updatedAt': updatedAt,
    })
  }

  /** create supplied document with and Id. This extract the functionality of the original set to only be used for creation. Please use this for setting or creating data for first time. This is also a useful case for creating data for test files. */
  async createWithId(snapshot: T & { id: string }, options?: SetOptions): Promise<void> {
    const data = this.marshal(snapshot)

    // create meta on the doc
    const meta = marshalMeta({
      createdAt: DateTime.now(),
      updatedAt: DateTime.now(),
      lastAccessed: DateTime.now(),
      version: this.dataVersion,
    })
    data.meta = meta

    if (options !== undefined) {
      await setDoc(this.reference(snapshot.id), data, options)
    } else {
      await setDoc(this.reference(snapshot.id), data)
    }
  }

  /** sets (creates or overwrites) the supplied document. It is mainly to be used to overwrite existing data */
  async set<DocType extends T>(snapshot: PartialPick<DocType, 'id'>, options?: SetOptions): Promise<void> {
    //@ts-expect-error // this error is tricky but this is correct because a PartialPick will always be assignable to a Partial
    const data = this.marshal(snapshot)

    const docRef = this.reference(snapshot.id)
    const docSnapshot = await getDoc(docRef)

    //create meta on the doc
    let meta: Meta = {
      createdAt: DateTime.now(),
      updatedAt: DateTime.now(),
      lastAccessed: DateTime.now(),
      version: this.dataVersion,
    }

    /**
     * If the doc already exists, we want to keep old meta but update updatedAt*/
    if (docSnapshot.exists()) {
      const oldData = docSnapshot.data()
      const oldMeta = unmarshalMeta(oldData.meta) ?? meta
      // update updatedAt
      meta = { ...oldMeta, updatedAt: DateTime.now() }
    }

    data.meta = marshalMeta(meta)

    if (options !== undefined) {
      await setDoc(this.reference(snapshot.id), data, options)
    } else {
      await setDoc(this.reference(snapshot.id), data)
    }
  }

  /** delete removes the document with the supplied ID. */
  async delete(id: string): Promise<void> {
    await deleteDoc(this.reference(id))
  }

  /** Returns all the documents whose id is included in the ids array */
  async fetchByIds(ids: string[]): Promise<T[]> {
    if (ids.length === 0) {
      return []
    }
    const dedupedIds = removeDuplicates(ids)

    const chunks = splitToGroups(dedupedIds, 10)
    const promises = chunks.map((chunk) => this.fetchAll(where(documentId(), 'in', chunk)))
    const res = await Promise.all(promises)

    return res.flat()
  }
}

export class ChildCollection<
  TParent extends {
    id: string
  },
  TChild extends {
    id: string
  },
> extends ClientCollection<TChild> {
  parent: ClientCollection<TParent> | ChildCollection<TChild, any>

  constructor(
    parent: ClientCollection<TParent> | ChildCollection<TChild, any>,
    collectionName: string | Connection,
    marshal: Marshaller<TChild>,
    unmarshal: Unmarshaller<TChild>,
    dataVersion: DataVersion,
  ) {
    if (collectionName instanceof Connection) {
      super(collectionName, marshal, unmarshal, dataVersion)
    } else {
      const connection = new Connection(collectionName)
      super(connection, marshal, unmarshal, dataVersion)
    }
    this.parent = parent
  }

  /** resolve returns a copy of the ChildCollection with the supplied document resolution IDs. */
  resolve(id: string): ChildCollection<TParent, TChild> {
    const childConnection = new Connection(
      this.parent.connection.collection().path,
      id,
      this.connection.collection().path,
    )
    return new ChildCollection(this.parent, childConnection, this.marshal, this.unmarshal, this.dataVersion)
  }
}

/** This connection is used to delay calling Firestore collection(db()) until we want to use and the database is initialized */
export class Connection {
  parentCollectionName: string
  collectionPath: string[]

  constructor(name: string, ...pathSegments: string[]) {
    this.parentCollectionName = name
    this.collectionPath = pathSegments
  }

  db() {
    return db()
  }

  collection() {
    return collection(this.db(), this.parentCollectionName, ...this.collectionPath)
  }
}
