import { getNearbyShares } from '@api/Products'
import { CustomShareShopBanner, Header, paddedProdWidthSM, ProductCardSquare } from '@components'
import { Divider, HeaderText, KeyboardAvoidingScrollView, LightStatusBar, ModalInline, Text } from '@elements'
import { validCoords } from '@helpers/coordinate'
import { groupBy, isNonNullish } from '@helpers/helpers'
import { sortByName } from '@helpers/sorting'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { ComponentProps, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { ItemCardList, ItemCardListData, useItemCardListSetter } from '../../components/ItemCardList'
import { ColumnSize } from '../../components/ResponsiveList'
import { SeeMoreList } from '../../components/SeeMoreList'
import Colors from '../../constants/Colors'
import { isWeb } from '../../constants/Layout'
import { useLocation } from '../../hooks/useSessionLocation'
import { withConsumerIndex } from '../../hooks/withAlgoliaIndex'
import { farmsSelector, sessionLocationSelector, wholesaleSelector } from '../../redux/selectors'
import { GeoSearchCard, geoSearchCardWidth } from '../Explore/GeoSearchCard'
import { DoubleFeatureCards, FarmerOwnedFeatureCard, RiverQueenFeatureCard } from './FeatureCards'
import { HomeHeader } from './HomeHeader'
import { SmallHomeHeader } from './MobileHomeHeader'
import { TopFarmCard, topFarmCardWidth } from './TopFarmCard'
import { WebFooter } from './WebFooter'

import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'
import { useProgressiveLoading } from '@/hooks/useProgressiveLoading'
import { HomeParamList } from '@/navigation/types'
import { productsCollection } from '@api/framework/ClientCollections'
import { AvailAddonResult } from '@helpers/addons'
import { shouldShow } from '@helpers/products'
import { AddonShare } from '@models/Product'
import { documentId, where } from 'firebase/firestore'
import { homeHasCustomAppHeader } from './helpers'
import { NearbyProducts } from './NearbyProducts'

function HomeScreenComp() {
  useLocation()
  const { isWholesale } = useSelector(wholesaleSelector)
  const { isLargeDevice, isSmallDevice, ...layout } = useLayout()
  const sessionLoc = useSelector(sessionLocationSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const { availAddonCsas, availAddonsResults } = useAvailAddons()
  const [nearbyShares, setNearbyShares] = useState<AlgoliaGeoDoc<AlgoliaGeoProduct>[]>([])
  const [modalData, _setModalDataState] = useState<(ItemCardListData<any, any> & { title: string }) | null>(null)
  const fassocs = useSelector(farmsSelector)
  const favorites = useMemo(() => fassocs.filter((f) => f.isFavorite).map((f) => f.farm!), [fassocs])
  const setModalData = useItemCardListSetter<{ title: string }>(_setModalDataState)

  useCancelableFocusFx(
    async (isCurrent) => {
      if (!sessionLoc?.coordinate || !validCoords(sessionLoc.coordinate) || isWholesale !== false) {
        // should only run in retail because there's no shares in wholesale
        return
      }
      const shares = await getNearbyShares(sessionLoc?.coordinate)
      if (!isCurrent) return

      setNearbyShares(shares)
    },
    // Only re-run if primitive values change in location, or isWholesale changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionLoc?.coordinate.latitude, sessionLoc?.coordinate.longitude, isWholesale],
    { noRefocus: true },
  )

  /** The availAddonResults for which the isAvail property is set to true, meaning they are available to add to cart */
  const availTrueResults = useMemo(() => availAddonsResults.filter((res) => !!res.isAvail), [availAddonsResults])

  /** Given the list of avail addons results, will progressively fetch the addon product object for each result which is available, and will merge it into the corresponding results object */
  const { data: availAddons } = useProgressiveLoading<AvailAddonResult & { addon: AddonShare }, number>({
    getPageData: async (cursor, pageLength) => {
      const currentCursor = cursor ?? 0
      const nextCursor = currentCursor + pageLength

      // This is the list of ids to be fetched for the current page
      const pageIds: string[] = availTrueResults.map((res) => res.id).slice(currentCursor, nextCursor)

      // The addons for the current page ids
      const addons = (await productsCollection.fetchAll(where(documentId(), 'in', pageIds))) as AddonShare[]

      // This is the current page of data. This merges the addons with the results
      const data = availTrueResults
        .filter((res) => pageIds.includes(res.id))
        .map((res) => {
          const addon = addons.find((a) => a.id === res.id)
          if (!addon) {
            Logger.error(
              Error(
                'The query returned no data for an available addon from the results ids. This should not happen because these results are all "isAvail:true".',
              ),
            )
            return null
          }
          return { ...res, addon }
        })
        .filter(isNonNullish)

      return { data, lastCursor: nextCursor }
    },
    // If the available addons data changes, the addons should be fetched, so it must be a dependency
    deps: [availTrueResults],
    runCondition: availTrueResults.length > 0,
    failedConditionMode: 'stop-loading',
    // page length shouldn't exceed 10 for this instance because its 'getPageData' depends on firestore's limit of 10 when using `where(doucmentId(), 'in', [...])`
    pageLength: 10,
    maxCalls: Math.ceil(availTrueResults.length / 10),
    noRefocus: true,
    filter: (res) => shouldShow(res.addon) && !res.addon.isPrivate,
    transform: (results) => results.sort((a, b) => sortByName(a, b, (res) => res.addon.name)),
    abortOnPageLengthUnfulfilled: false,
  })

  const memoAvailAddons = useDeepCompareMemo(
    () =>
      groupBy(availAddons, (aa) => aa.addon.farm.id).map((group) => (
        <SeeMoreList
          key={group[0].addon.farm.id}
          cardWidth={paddedProdWidthSM}
          title={`Available Add-Ons from ${group[0].addon.farm.name}`}
          data={group.sort((a, b) => sortByName(a, b, (aa) => aa.addon.name))}
          keyExtractor={({ id }) => id}
          renderItem={({ addon, matchingCSAIds }) => {
            const csa = availAddonCsas.find(({ id }) => matchingCSAIds.includes(id))
            return (
              <ProductCardSquare
                product={addon}
                small
                cardAction="addcart"
                csa={csa}
                key={addon.id}
                onPressMobileNavigate={() =>
                  navigation.navigate('Shopping', {
                    screen: 'ProductDetails',
                    params: {
                      farmSlug: addon.farm.urlSafeSlug,
                      productId: addon.id,
                      csaId: csa?.id,
                      goBack: 'home',
                    },
                  })
                }
              />
            )
          }}
        />
      )),
    [availAddonCsas, availAddons, navigation],
  )

  const styles = useStyles()

  return (
    <View style={styles.screen}>
      {/* When not showing the custom header, the homescreen uses the light status bar for contrast with the dark image background, and no safe area insets so the background image covers the status bar area, that's intentional as part of the mobile-small design */}
      {homeHasCustomAppHeader() ? <Header noSafeareaMargin /> : <LightStatusBar />}
      <CustomShareShopBanner includeSafeArea />
      <KeyboardAvoidingScrollView contentContainerStyle={styles.scroll}>
        {!isLargeDevice ? <SmallHomeHeader /> : <HomeHeader />}
        <View style={styles.main}>
          {favorites.length > 0 && (
            <SeeMoreList
              cardWidth={topFarmCardWidth}
              title="Your Favorite Farms"
              data={favorites}
              seeAllPress={() => navigation.navigate('Shopping', { screen: 'MyFarmsScreen' })}
              renderItem={(farm: Farm) => <TopFarmCard farm={farm} />}
              keyExtractor={({ id }) => id}
            />
          )}
          <NearbyProducts />

          <DoubleFeatureCards />
          <View style={globalStyles.margin20} />
          <RiverQueenFeatureCard />
          <View style={globalStyles.margin20} />
          {!!nearbyShares?.length && (
            <SeeMoreList
              cardWidth={geoSearchCardWidth}
              title="Subscribe for the Season"
              seeAllPress={() =>
                setModalData<ComponentProps<typeof GeoSearchCard>, 'data'>({
                  items: nearbyShares,
                  itemProp: 'data',
                  title: 'Subscribe for the Season',
                  component: GeoSearchCard,
                  cardProps: { onCardPress: () => _setModalDataState(null) },
                })
              }
              data={nearbyShares}
              renderItem={(product: AlgoliaGeoDoc<AlgoliaGeoProduct>) => <GeoSearchCard data={product} />}
              keyExtractor={({ objectID }) => objectID}
            />
          )}
          <View style={globalStyles.margin20} />

          <FarmerOwnedFeatureCard />

          {memoAvailAddons.length > 0 && (
            <View style={styles.addonsCont}>
              <Divider large clear />
              <HeaderText size={26}>Add to your subscription</HeaderText>
              <Text style={styles.addonsText}>
                These add-ons are available for you to purchase as an addition to your primary share.
              </Text>
              {memoAvailAddons}
            </View>
          )}

          <Divider large clear />
        </View>
        {isWeb && <WebFooter />}
      </KeyboardAvoidingScrollView>
      <ModalInline
        visible={!!modalData}
        webWidth={layout.width * (isSmallDevice ? 1 : 0.9)}
        onDismiss={() => _setModalDataState(null)}
        header
        title={modalData?.title ?? ''}
      >
        <>
          {modalData && (
            <ItemCardList
              items={modalData.items}
              Component={modalData.component}
              itemProp={modalData.itemProp as string}
              cardProps={modalData.cardProps}
              smallHorizontal={false}
              onPress={() => _setModalDataState(null)}
              columnSize={ColumnSize.Large}
            />
          )}
        </>
      </ModalInline>
    </View>
  )
}

// Wrap consumer index here so that we can use algolia in the dual search
export const HomeScreen = withConsumerIndex(HomeScreenComp, true)

const useStyles = () =>
  useLayoutFnStyles(({ height, isLargeDevice, top }) => ({
    screen: {
      /** This screen must have a defined height, because the autocomplete positioning calculation assumes the screen component has a container acting as a viewport or window, with a height limited to the physical screen's height  */
      height,
      backgroundColor: Colors.shadeGold,
      // marginTop needs the top inset only when the custom app header is shown in mobile, which only happens for mobile-large
      marginTop: homeHasCustomAppHeader() ? top : undefined,
    },
    scroll: { paddingBottom: isWeb ? 0 : 30 },
    main: { marginHorizontal: !isLargeDevice ? 10 : '8%' },
    addonsCont: { marginLeft: 10 },
    addonsText: { paddingTop: 10 },
    infoBanner: {
      flexDirection: 'row',
      backgroundColor: Colors.lightGold,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
    },
    bannerImage: {
      width: 60,
      height: 60,
      marginRight: 30,
    },
    infoCardCont: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      marginVertical: 10,
    },
  }))
