import { Input, InputProps } from 'react-native-elements'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { FormInputLabel } from './FormInputLabel'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'

type FormInputProps = FormSharedProps & InputProps

/** This input component can be used to produce consistently styled inputs*/
export function FormInput({
  label,
  labelStyle,
  inputStyle,
  inputContainerStyle,
  containerStyle,
  errorStyle,
  style,
  ...props
}: FormInputProps) {
  const styles = useDynamicFormStyles(!!props?.errorMessage, !!props.row)

  return (
    <Input
      placeholderTextColor={Colors.shades['200']}
      errorStyle={[formStyles.error, errorStyle]}
      label={label === null ? undefined : <FormInputLabel labelStyle={labelStyle} label={label ?? props.placeholder} />}
      accessibilityLabel={isLabelAccessible(label) ? label : props.placeholder}
      labelStyle={[formStyles.inputLabel, labelStyle]}
      inputStyle={[styles.inputStyle, inputStyle]}
      inputContainerStyle={[styles.inputContainer, inputContainerStyle]}
      containerStyle={[props.row && globalStyles.flex1, containerStyle]}
      style={[styles.main, style]}
      {...props}
    />
  )
}
