import { useFarmData } from '@/hooks/useFarmData'
import { buildCustomShareBox, buildCustomShareCart, loadShareCustomization } from '@api/CustomShares'
import { updateUser } from '@api/Users'
import { csasCollection } from '@api/framework/ClientCollections'
import { ConsumerScroll } from '@components'
import { Alert, Text, hideModal, Toast } from '@elements'
import { errorToString } from '@helpers/helpers'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { isErrorWithCode } from '@shared/Errors'
import { CustomShareBoxContentsExpanded } from '@shared/types/v2/customShares'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Logger } from '../../../config/logger'
import { useApiFx } from '../../../hooks/useApiFx'
import { useCartService } from '../../../hooks/useCart'
import { useCartInfo } from '../../../hooks/useCart/useCartInfo'
import { withAuth } from '../../../hooks/withAuth'
import { ShoppingStackParamList } from '../../../navigation/types'
import { userSelector } from '../../../redux/selectors'
import { AboutCSA } from '../CSADetails/AboutCSA'
import { CurrentShareSection, CustomShareLoading, ErrorComponent } from './components'
import { alertReplaceCart } from './helpers'
import { SaveButtons } from './SaveButtons'
import { SharePreferencesSection } from './SharePreferencesSection'
import { setCustomShareModalData } from '../../../redux/actions/appPersist'
import { getNextShareCloseDateTime } from '@helpers/custom_shares/share_window'
import { FarmHeaderSnippet } from '../FarmShop/components'
import { globalStyles } from '../../../constants/Styles'
import { View } from 'react-native'

/** Landing page for people looking to customize their shares. */
function ShareCustomizationComp() {
  const user = useSelector(userSelector)
  const route = useRoute<RouteProp<ShoppingStackParamList, 'ShareCustomization'>>()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList>>()
  const dispatch = useDispatch()
  const { farmSlug, csaId, customShareId } = route.params
  const farmData = useFarmData(farmSlug, { fetchCsas: false, fetchProds: false, fetchLocs: false })
  const {
    farm: { data: farm },
  } = farmData
  const { cart } = useCartService({ farmId: farm?.id })
  const { cartId } = useCartInfo()
  const [customBox, setCustomBox] = useState<CustomShareBoxContentsExpanded>([])
  const [loadError, setLoadError] = useState<unknown>()
  const shareData = useApiFx(loadShareCustomization, [customShareId, user.id, user.preferences], undefined, {
    onStateSet: (state) => {
      if (state.data) setCustomBox(state.data.customBox)
    },
    onError: setLoadError,
    // We don't want to reload when the preferences change
    once: true,
  })
  const csaApi = useApiFx(csasCollection.fetch.bind(csasCollection), [csaId], !!csaId)

  // If we open the share customization page we should not show the share customization modal for this period
  useEffect(() => {
    if (!shareData.data?.customShare) return
    const nextCloseDate = getNextShareCloseDateTime(shareData.data?.customShare).toMillis()

    dispatch(setCustomShareModalData({ lastSeenModalTimestamp: nextCloseDate }))
  }, [shareData.data?.customShare, dispatch])

  /** This allows us to regenerate the custom box when preferences change. */
  const onPreferenceChange = async (newPrefs: string) => {
    if (!shareData.data) return
    const { product, totalNumSharesToCustomize, customShare } = shareData.data
    try {
      // We don't make this a promise so that we don't need to wait as we can be fairly confident it won't fail and not
      // a huge deal if it does. The customer will just need to re-enter it next time
      updateUser({ id: user.id, preferences: newPrefs }).catch(() => {
        Toast('Error saving preferences, please try again.')
      })
      const newBox = await buildCustomShareBox({
        shareValue: product.value,
        customShare,
        numSharesToCustomize: totalNumSharesToCustomize,
        // If the farmer didn't set a min number of items we default to 5 so that the share is still somewhat diverse
        minShareItems: product.minShareItems ?? 5,
        userPickup: {
          userId: user.id,
          locationId: shareData.data.pickup.locationId,
        },
        preferences: newPrefs,
      })
      setCustomBox(newBox)
    } catch (err) {
      Alert(
        'Unable to rebuild your share',
        `We were unable to create a new share with your preferences. You can still customize it yourself by clicking "Edit Share" below. Your preferences are saved and will be used next time you customize your share.`,
      )
    }
  }

  const onConfirmShare = async (type: 'edit' | 'confirm') => {
    if (!shareData.data || !farm) {
      return Alert('Error Customizing Share', "Couldn't load data for share customization. Please contact support.")
    }
    if (!cartId) {
      return Alert('Error Customizing Share', "Couldn't load your cart. Please contact support.")
    }

    if (cart.length > 0) {
      const confirmed = await alertReplaceCart()
      // We don't need to clear the cart as that will be part of saving the custom share, we just need to make sure the
      // user agrees to have their cart cleared
      if (!confirmed) {
        return
      }
    }

    // We mark the share as customized so we won't show the banner for the rest of the current share window
    const nextCloseDate = getNextShareCloseDateTime(shareData.data.customShare).toMillis()
    dispatch(setCustomShareModalData({ lastCustomizedShareTimestamp: nextCloseDate }))

    try {
      // Call our endpoint to build the custom cart with the applied discounts
      await buildCustomShareCart(customShareId, cartId, user.id, shareData.data.promoCode, customBox)

      // On successfully creating the cart we should take the customer to check out or to the CSA page to customize
      if (type === 'edit') {
        navigation.navigate('Shopping', {
          screen: 'CSADetails',
          params: {
            farmSlug: farm.urlSafeSlug,
            csaId,
            goBack: 'home',
            type: 'standard',
          },
        })
      } else if (type === 'confirm') {
        hideModal()
        return navigation.navigate('MyCart')
      }
    } catch (err: unknown) {
      if (isErrorWithCode(err)) {
        Alert('Error Customizing Share', errorToString(err))
      } else {
        Logger.error('Error Customizing Share', err)
        Alert(
          'Error Customizing Share',
          'We apologize, there was an unexpected error while trying to build your custom share.',
        )
      }
    }
  }

  return (
    <ConsumerScroll>
      <View>{!!farm && <FarmHeaderSnippet farm={farm} />}</View>
      {shareData.loading ? (
        <CustomShareLoading />
      ) : loadError ? (
        <ErrorComponent error={loadError} />
      ) : (
        <View>
          <Text size={20} type="medium" style={globalStyles.margin10}>
            Your {shareData.data!.product.name} is ready to customize!
          </Text>
          {!!shareData.data?.customerNumSharesToCustomize && shareData.data.customerNumSharesToCustomize > 1 && (
            <Text style={globalStyles.marginHorizontal10}>
              You have {shareData.data.customerNumSharesToCustomize} shares to customize. You must customize them one at
              a time, after customizing this share you can re-open this link to customize the next one
            </Text>
          )}
          <SharePreferencesSection
            onPreferenceChange={onPreferenceChange}
            userPrefs={user.preferences}
            continueToEdit={onConfirmShare}
          />
          <CurrentShareSection customBox={customBox} shareValue={shareData.data!.product.value} />
          <SaveButtons onConfirmPress={onConfirmShare} />
        </View>
      )}
      <AboutCSA loading={csaApi.loading} csa={csaApi.data} />
    </ConsumerScroll>
  )
}

export const ShareCustomization = withAuth(ShareCustomizationComp)
