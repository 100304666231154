import { anonymizeEmail, anonymizePhone } from '@helpers/display'
import { SignInProviders, User } from '@models/User'

import { Alert } from '../elements/Overlays/Popups'

/** Tells the user how to proceed when the register provider was incorrect */
export function providerAlert(user: User, type: 'Login' | 'Signup' | 'Reset') {
  switch (user.signInProvider) {
    case SignInProviders.Email:
      Alert(
        `${type} Failed`,
        `This account is associated with email ${anonymizeEmail(user.email)}. Please login with your email.`,
      )
      break
    case SignInProviders.Phone:
      Alert(
        `${type} Failed`,
        `This account is associated with phone number ${anonymizePhone(
          user.phoneNumber!,
        )}. Please login with your phone number.`,
      )
      break
    case SignInProviders.Apple:
      Alert(`${type} Failed`, `This email is associated with an Apple account. Please login with your apple account.`)
      break
    case SignInProviders.Google:
      Alert(
        `${type} Failed`,
        `This email is associated with a Google account ${anonymizeEmail(
          user.email,
        )}. Please login with your google account.`,
      )
      break
  }
}
