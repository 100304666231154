import Colors from '@/constants/Colors'
import { Icon, Spinner, Text } from '@elements'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

/** Component that will show when the message list is empty */
export const ConversationViewEmpty = memo(function ConversationViewEmpty({ isLoading }: { isLoading: boolean }) {
  return (
    <View style={styles.main}>
      {isLoading ? (
        <Spinner size="small" />
      ) : (
        <>
          <Icon color={Colors.shades['200']} noHover iconSet="Feather" name="message-square" size={40} />
          <Text>No messages yet</Text>
        </>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
  },
})
