import {
  CustomShareCustomer,
  CustomShareProduct,
  getCustomerPreferredProducts,
  isWithinAllocationPercentages,
} from './algorithm-helpers'
import { MoneyCalc } from '../money'

/** This allocation function will attempt to fill a customers share starting with the most expensive products which will
 * allow us to maximize for the share value. */
export function allocateProductsToMaximizeValue(products: CustomShareProduct[], customers: CustomShareCustomer[]) {
  // Build the customer product lists: customerId -> list of products sorted by preference and price
  const customerProductList: {
    [customerId: string]: CustomShareProduct[]
  } = Object.fromEntries(customers.map((c) => [c.customerId, getCustomerPreferredProducts(c, products)]))

  // This will determine if we were able to allocate any products in the last loop, if not it means no one else can take more products and we are done
  let allocationPossible = true

  while (allocationPossible) {
    allocationPossible = false

    // Iterate over customers
    for (const customer of customers) {
      let shouldConsiderLessFavorableProds = false

      // Get customer's product list
      const productsToConsider = customerProductList[customer.customerId]

      // Skip if no products left to consider
      if (!productsToConsider || productsToConsider.length === 0) continue

      const uniqueProductCount = Object.keys(customer.allocatedProducts).length

      for (let i = 0; i < productsToConsider.length; i++) {
        const product = productsToConsider[i]
        // Once we have exhausted the list of products with ranking 3 and above or have reached the end of the list, we should remove all restrictions for diversity, and continue adding products we skipped for diversity reasons
        const hasReachedEndOfFavorableProds =
          !productsToConsider[i + 1] || customer.preferences[productsToConsider[i + 1].productId] < 3

        if (hasReachedEndOfFavorableProds && !shouldConsiderLessFavorableProds) {
          shouldConsiderLessFavorableProds = true
          // We must set allocation possible so that if this is the last product it will not consider the list exhausted and will take at least one more pass to try and allocate a product
          allocationPossible = true
        }

        const currentAllocation = customer.allocatedProducts[product.productId] ?? 0
        const shouldAllowDuplicates =
          uniqueProductCount >= customer.minNumItems && isWithinAllocationPercentages(product, customer)

        if (!shouldAllowDuplicates && currentAllocation > 0 && !shouldConsiderLessFavorableProds) {
          continue
        }

        if (customer.canAllocate(product)) {
          customer.allocateProduct(product)
          allocationPossible = true

          // Remove the product from the customer's list if maxPerOrder reached
          if (currentAllocation + 1 >= product.maxPerOrder) {
            productsToConsider.splice(i, 1)
            i--
          }
          // Move to the next customer
          break
        } else {
          // Remove the product from the customer's list if it cannot be allocated
          productsToConsider.splice(i, 1)
          i--
        }
      }
    }
  }
}

/** This allocation function will remove expensive items for customers and replace them with cheaper items attempting to
 * reach the minimum number of items for the share. */
export function adjustAllocationToMeetMinItems(customer: CustomShareCustomer, products: CustomShareProduct[]) {
  let itemsNeeded = customer.minNumItems - Object.values(customer.allocatedProducts).reduce((sum, qty) => sum + qty, 0)

  if (itemsNeeded <= 0) return

  // Get allocated products sorted by price descending
  const allocatedProductsByPriceDesc = Object.entries(customer.allocatedProducts)
    .map(([productId, qty]) => {
      const product = products.find((p) => p.productId === productId)!
      return { product, qty }
    })
    // Products the customer loves should be taken off the table for removal even if they are expensive
    .filter((product) => customer.preferences[product.product.productId] < 5)
    // Sort by the amount of money the product takes up in the cart. So if it is only $6, but we have 4 of them that would
    // rank higher than something that costs $12, but we have 1
    .sort((a, b) => MoneyCalc.cents(b.product.price) * b.qty - MoneyCalc.cents(a.product.price) * a.qty)

  // Get affordable products not yet allocated to the customer, sorted by preference and price ascending
  const affordableProducts = products
    .filter((product) => !(product.productId in customer.allocatedProducts))
    .filter((product) => customer.preferences[product.productId] >= 2) // Prefer products the customer doesn't dislike
    .sort((a, b) => {
      const preferenceDiff = customer.preferences[b.productId] - customer.preferences[a.productId]
      if (preferenceDiff !== 0) return preferenceDiff
      return MoneyCalc.cents(a.price) - MoneyCalc.cents(b.price)
    })

  // Try to adjust allocations
  for (const { product: expensiveProduct } of allocatedProductsByPriceDesc) {
    // Remove one unit of the expensive product
    if (customer.deallocateProduct(expensiveProduct)) {
      itemsNeeded++

      // Try to add cheaper products to meet the minNumItems
      for (const cheapProduct of affordableProducts) {
        // We have successfully met the minimum number of items so we can move on
        if (itemsNeeded <= 0) break

        if (customer.canAllocate(cheapProduct)) {
          customer.allocateProduct(cheapProduct)
          itemsNeeded--
        }
      }

      if (itemsNeeded <= 0) break
    }

    if (itemsNeeded <= 0) break
  }
}
