import Colors from '@/constants/Colors'
import { displayTimeStamp, isAfter } from '@helpers/time'
import { StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { adminFarmSelector } from '@/redux/selectors'
import { Icon, Text, Touchable } from '@elements'
import { dateTimeInZone } from '@models/Timezone'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCacheMinute } from '../helpers/reports'

type TimestampViewProps = {
  /** isUsingCache by default is false. */
  isUsingCache: boolean
  onRefreshDisabled?: boolean
  onRefreshPressed: () => void
  /** preferLatestTimeStamp is used to show the preferred latest timestamp which doesn't depend on the refreshData to update current time. (Only preferLatestTimeStamp is greater than current time, it will take effect.) */
  preferLatestTimeStamp?: DateTime
}

/** This will set how long the user must wait until they can press the refresh button again */
const DELAY_TIME = 10000

/** This component shows updated timestamp with refresh button */
export function TimestampView({
  isUsingCache = false,
  onRefreshDisabled,
  onRefreshPressed,
  preferLatestTimeStamp,
}: TimestampViewProps) {
  const [suspend, setSuspend] = useState(false)
  const farm = useSelector(adminFarmSelector)
  const [now, setNow] = useState(dateTimeInZone(farm.timezone))

  useFocusFx(
    () => {
      const preferTimeStamp = preferLatestTimeStamp?.setZone(farm.timezone)
      if (preferTimeStamp && isAfter(preferTimeStamp, now)) setNow(preferTimeStamp)
    },
    // only need to check when preferLatestTimeStamp is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preferLatestTimeStamp],
    { noRefocus: true },
  )

  // When the timezone is changed, the timestamp should be updated immediately.
  useFocusFx(
    () => {
      setNow(dateTimeInZone(farm.timezone))
    },
    [farm.timezone],
    { noRefocus: true },
  )

  const refreshData = useCallback(() => {
    setSuspend(true)
    setNow(dateTimeInZone(farm.timezone))
    onRefreshPressed()
    /** This will set state asynchronously.
     *  However, it is complicated to handle not setting state in callback.
     *  If it is unmounted and this component won't ever be unmounted because it is used at the screen level.
     *  If used on components we should consider blocking setting this state if the component is unmounted.
     * */
    setTimeout(() => {
      setSuspend(false)
    }, DELAY_TIME)
  }, [onRefreshPressed, farm.timezone])

  return (
    <View style={globalStyles.flexRowCenter}>
      <Text color={Colors.red}>Updated {displayTimeStamp(getCacheMinute(now, isUsingCache), !isUsingCache)}</Text>
      <Touchable disabled={onRefreshDisabled || suspend} style={styles.refreshFiltersIcon} onPress={refreshData}>
        <Icon color={suspend ? Colors.shades['300'] : undefined} iconSet="FontAwesome" name="refresh" />
      </Touchable>
    </View>
  )
}

const styles = StyleSheet.create({
  refreshFiltersIcon: { marginLeft: 5 },
})
