import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'
import { Header, SeeMoreList } from '@components'
import { KeyboardAvoidingScrollView, TextH1 } from '@elements'
import { Farm } from '@models/Farm'
import { NearbyProducts } from '@screens/Home/NearbyProducts'
import { TopFarmCard, topFarmCardWidth } from '@screens/Home/TopFarmCard'
import { homeHasCustomAppHeader } from '@screens/Home/helpers'
import React, { useContext } from 'react'
import { View } from 'react-native'
import { WebFooter } from '../../WebFooter'
import { HomeScreenWholesaleContext } from '../HomeScreenWholesale'
import { CategoryCards } from './CategoryCards/CategoryCards'
import { CategoryList } from './CategoryList/CategoryList'
import { LogisticsSelection } from './LogisticsSelection/LogisticsSelection'

/** Ui layer for the HomeScreen Wholesale screen */
export function HomeScreenWholesaleView() {
  const { isLargeDevice, isExtraSmallDevice } = useLayout()
  const styles = useStyles()

  const { favorites, onFavoritesSeeAllPress, categories } = useContext(HomeScreenWholesaleContext)

  return (
    <View style={styles.screen}>
      <Header noSafeareaMargin />
      <KeyboardAvoidingScrollView contentContainerStyle={styles.scroll}>
        <View style={styles.main}>
          {categories && <CategoryList items={categories} />}
          <LogisticsSelection />
          <TextH1 style={globalStyles.margin20} center size={isLargeDevice ? 30 : isExtraSmallDevice ? 18 : 24}>
            Shop local food, from local farms
          </TextH1>

          <CategoryCards />

          <View style={globalStyles.margin20} />
          {favorites.length > 0 && (
            <SeeMoreList
              cardWidth={topFarmCardWidth}
              title="Your Favorite Farms"
              data={favorites}
              seeAllPress={onFavoritesSeeAllPress}
              renderItem={(farm: Farm) => <TopFarmCard farm={farm} />}
              keyExtractor={({ id }) => id}
            />
          )}

          <NearbyProducts />
        </View>
        {isWeb && <WebFooter />}
      </KeyboardAvoidingScrollView>
    </View>
  )
}

const useStyles = () =>
  useLayoutFnStyles(({ height, isLargeDevice, top }) => ({
    screen: {
      /** This screen must have a defined height, because the autocomplete positioning calculation assumes the screen component has a container acting as a viewport or window, with a height limited to the physical screen's height  */
      height,
      backgroundColor: Colors.shadeGold,
      // marginTop needs the top inset only when the custom app header is shown in mobile, which only happens for mobile-large
      marginTop: homeHasCustomAppHeader() ? top : undefined,
    },
    scroll: { paddingBottom: isWeb ? 0 : 30 },
    main: { marginHorizontal: !isLargeDevice ? 10 : '8%' },

    spacing30: {
      height: 30,
    },
    spacing10: {
      height: 10,
    },
  }))
