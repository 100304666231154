import { Text, Toast } from '@elements'
import { AlgoliaAdminInvoice } from '@models/Algolia'
import { Invoice, InvoiceStatus } from '@models/Invoice'
import * as Clipboard from 'expo-clipboard'
import { useCallback } from 'react'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../../../../redux/selectors'

function CopyInvoiceUrl({ invoice }: { invoice: AlgoliaAdminInvoice | Invoice }) {
  const { isWholesale } = useSelector(wholesaleSelector)

  const receiptUrl = invoice.pdf ?? `${grownbyWebsiteBaseUrl(isWholesale)}/external/invoice/${invoice.id}`
  const displayText = [InvoiceStatus.Paid, InvoiceStatus.Refunded].includes(invoice.status)
    ? 'Copy Receipt URL'
    : 'Copy Invoice URL'
  return (
    <Text
      type="bold"
      onPress={useCallback(() => {
        Clipboard.setStringAsync(receiptUrl).then(() => Toast('Link copied!'))
      }, [receiptUrl])}
      style={{ color: Colors.blue }}
    >
      {displayText}
    </Text>
  )
}

export default CopyInvoiceUrl
