import {
  MessageWithIcon,
  ProductCardSquare,
  ResponsiveGrid,
  paddedProdWidth,
  paddedProdWidthSM,
  prodHeight,
  prodSharedStyles,
  CustomShareShopBanner,
} from '@components'
import { SafeAreaView } from '@elements'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { FooterBtn } from '../../components/FooterBtn'
import ParallaxHeader, { ParallaxButtons } from '../../components/ParallaxHeader'
import { Reviews } from '../../components/Reviews'
import { Button } from '../../components/elements/Button'
import { Divider } from '../../components/elements/Divider'
import { HeaderText } from '../../components/elements/Text'
import { WithAuthCallback } from '../../hooks/WithAuthCallback'
import { FarmStatus } from '../../models/Farm'

import { globalStyles } from '@/constants/Styles'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCartService } from '@/hooks/useCart'
import { useLayout } from '@/hooks/useLayout'
import { wholesaleSelector } from '@/redux/selectors'
import { ProductListSkeleton } from '@screens/Shopping/FarmShop/components'
import { getCardActionShop } from '@screens/Shopping/FarmShop/helpers'
import { useSelector } from 'react-redux'
import { FarmDetailDataType } from './FarmDetailsScreen.controller'
import { About } from './components/About'
import { FarmDetailsSkeleton } from './components/FarmDetailsSkeleton'
import { StickyFarmHeader } from './components/StickyFarmHeader'

/** View layer for the FarmDetailsScreen*/
export function FarmDetailsScreenView({
  onGoBackPress,
  isStarred,
  setIsFavoriteCallbackOpen,
  loadingFarm,
  farm,
  numFavorites,
  onShareFarm,
  featuredProds,
  loadingProds,
  onFooterPress,
  isFavoriteCallbackOpen,
  onToggleFavorite,
  onPressCsaNavigate,
  onPressMobileNavigate,
  onDeleteReview,
  onFlagPress,
  onUpdateReview,
  reviewsApiFx,
}: FarmDetailDataType) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const layout = useLayout()
  const { cart } = useCartService()
  const { availAddonsIds } = useAvailAddons()

  const buttons: ParallaxButtons = useMemo(
    () => ({
      left: {
        icon: 'times',
        onPress: onGoBackPress,
      },
      right: {
        icon: isStarred === undefined ? 'loading' : 'heart',
        isSolid: isStarred,
        onPress: () => {
          setIsFavoriteCallbackOpen(true)
        },
      },
    }),
    [onGoBackPress, isStarred, setIsFavoriteCallbackOpen],
  )

  if (loadingFarm || isWholesale === undefined) {
    // Should wait for isWholesale to be defined, before rendering
    return <FarmDetailsSkeleton />
  }

  if (!farm) {
    return (
      <MessageWithIcon title="Load error">
        There was a problem loading this farm, please check your internet connection and try again.
      </MessageWithIcon>
    )
  }

  return (
    <SafeAreaView style={globalStyles.flex1}>
      <CustomShareShopBanner />
      <ParallaxHeader
        title={farm?.name ?? 'Farm Details'}
        media={farm?.media}
        buttons={!layout.isLargeDevice ? buttons : undefined}
        webElements={{
          sticky: (
            <StickyFarmHeader
              farm={farm}
              isStarred={isStarred}
              numFavorites={numFavorites}
              setIsFavoriteCallbackOpen={setIsFavoriteCallbackOpen}
              shareFarm={onShareFarm}
            />
          ),
        }}
        back={onGoBackPress}
      >
        <View>
          <About farm={farm} />
          {farm.status === FarmStatus.Registered && (
            <>
              <Divider large />
              {loadingProds ? (
                <ProductListSkeleton itemsNo={layout.isLargeDevice ? 10 : 4} />
              ) : featuredProds?.length ? (
                <View style={styles.productGridCont}>
                  <ResponsiveGrid
                    ListHeaderComponent={<HeaderText style={styles.featuredTitle}>Featured products</HeaderText>}
                    estimatedItemSize={prodHeight}
                    itemBaseWidth={layout.isExtraSmallDevice ? paddedProdWidthSM : paddedProdWidth}
                    data={featuredProds}
                    renderItem={({ item }) => (
                      <ProductCardSquare
                        style={prodSharedStyles.responsiveWrapper}
                        product={item}
                        small={layout.isExtraSmallDevice}
                        cardAction={getCardActionShop(
                          item,
                          availAddonsIds,
                          cart.map((itm) => itm.product.id),
                          isWholesale,
                        )}
                        onPressMobileNavigate={onPressMobileNavigate}
                        onPressCsaNavigate={onPressCsaNavigate}
                      />
                    )}
                    extraData={[availAddonsIds, cart, isWholesale]}
                  />
                </View>
              ) : null}

              <Reviews
                data={reviewsApiFx.data}
                loading={reviewsApiFx.loading}
                error={reviewsApiFx.err}
                onDeleteReview={onDeleteReview}
                onFlagPress={onFlagPress}
                onUpdateReview={onUpdateReview}
                farm={farm}
              />
            </>
          )}
        </View>
      </ParallaxHeader>

      {layout.isSmallDevice && farm.status && (
        <FooterBtn withTabs style={styles.footerBtn}>
          <Button
            title={farm.status === FarmStatus.Registered ? 'Shop Now' : 'Invite to GrownBy'}
            onPress={onFooterPress}
          />
        </FooterBtn>
      )}
      {farm && isFavoriteCallbackOpen && (
        <WithAuthCallback onDismiss={() => setIsFavoriteCallbackOpen(false)} callback={onToggleFavorite} />
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  footerBtn: {
    position: 'absolute',
    bottom: 0,
    padding: 0,
    paddingBottom: 0,
  },
  productGridCont: {
    minHeight: 100, // This fixes the flashlist <2px warning
  },
  featuredTitle: {
    marginBottom: 15,
  },
})
