export default {
  shades: {
    50: '#FDFDFD',
    75: '#F2F2F2',
    100: '#DDDDDD',
    200: '#AAAAAA',
    300: '#999999',
    400: '#777777',
    500: '#444444',
    600: '#222222',
  },
  gold: '#FFA400',
  lightGold: '#FFDEA4',
  green: '#6DBE4B',
  lightGreen: '#F8FBF7',
  blue: '#238DC1',
  lightBlue: '#C6ECFF',
  red: '#AA182C',
  lightOrange: '#FFF2DA',
  purple: '#3F2B56',
  lightPurple: '#EEE7FF',
  brightPurple: '#551459',
  brown: '#544627',
  darkGreen: '#2F5D40',
  transparent: '#FFFFFF00',
  semiTransparent: 'rgba(0, 0, 0, 0.5)',
  semiGreen: '#00820011',
  yellow: '#FFDC62',
  secondaryGreen3: '#F8FBF7',
  lightGray: '#E7E7E7',
  primaryGray: '#606060',
  darkGray: '#231F20',
  white: '#FFFFFF',
  black: '#000000',
  facebook: '#4267B2',
  twitter: '#1DA1F2',
  blueLink: '#0000FF',
  google: '#DB4437',
  shadeGold: '#F8F5EF',
  black66: '#00000066',
}

//Will convert the percent value to a hex value
function percentHex(percent: number) {
  const value = Math.round((percent * 255) / 100)

  if (percent < 7) return '0' + value.toString(16).toUpperCase()
  return value.toString(16).toUpperCase()
}

// Given a color and percent 0-1, will create a semi-transparent version of the color
export const withAlpha = (color: string, alpha: number) => {
  return `${color}${percentHex(alpha * 100)}`
}

/**
 * @param color Hex value format: #ffffff
 * @param decimal lighten or darken decimal value, example 0.5 to lighten by 50% or 1.5 to darken by 50%.
 */
export function shadeColor(color: string, decimal: number): string {
  let r = parseInt(color.substring(1, 3), 16)
  let g = parseInt(color.substring(3, 5), 16)
  let b = parseInt(color.substring(5, 7), 16)

  r = Math.round(r / decimal)
  g = Math.round(g / decimal)
  b = Math.round(b / decimal)

  r = r < 255 ? r : 255
  g = g < 255 ? g : 255
  b = b < 255 ? b : 255

  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16)
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16)
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16)

  return `#${rr}${gg}${bb}`
}
export type HexColor = `#${string}`

export const colorBankCodes: Record<HexColor, string> = {
  '#ca223f': 'Rustic Berry',
  '#b76264': 'Terracotta Rose',
  '#FF5733': 'Harvest Sunset',
  '#FFA400': 'Golden Harvest',
  '#a4ae2b': 'Meadow Green',
  '#3cc226': 'Fresh Shoot',
  '#27755c': 'Crop Field',
  '#3498DB': 'Blue Sky Horizon',
  '#3a31cb': 'Cornflower Twilight',
  '#7f00ff': 'Violet Orchard',
  '#7a689b': 'Lavender Mist',
  '#a825af': 'Mulberry Bloom',
  '#0A0A0A': 'Night Black',
}
export const colorBank = Object.keys(colorBankCodes) as HexColor[]
