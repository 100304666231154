import { ErrorText } from '@elements'
import { useFormikContext } from 'formik'
import { AdvancedPricingForm } from '../../AdvancedPricing'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { BasicInfoForm } from '../BasicInformation.helpers'
import { PhotoSection } from './PhotoSection'

/** Wrapper used for product image selection */
export function PhotoSelection() {
  const { values, errors, touched, setFieldValue } = useFormikContext<
    BasicInfoForm & AdvancedPricingForm & ProductTypeForm
  >()

  return (
    <>
      <PhotoSection
        onMediaChange={(media) => setFieldValue('media', media)}
        value={values.media ?? []}
        disabled={!!values.templateProductId}
      />
      {!!touched.media && !!errors.media && <ErrorText>{errors.media as string}</ErrorText>}
    </>
  )
}
