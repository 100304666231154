import { nonEmptyString } from '@helpers/helpers'
import { ReportEndpointMap, ReportEndpointNames } from '@shared/types/reports/endpoint'
import { getRunConfiguration, isEmul } from '../../config/Environment'
import { Logger } from '../../config/logger'

/**
 * Generate a URL with parameters for opening a report
 * @param reportName The name of the report to generate
 * @param queryParams Query params for generating the specific report
 * @param dateRange An optional date range to filter the report by
 */
export function generateReportingUrl<T extends ReportEndpointNames>(
  reportName: T,
  queryParams: ReportEndpointMap[T]['query'] & Record<string, string | undefined>,
  dateRange: ReportEndpointMap[T]['dateRange'],
): string {
  const url = reportingEndpointBaseUrl()
  url.searchParams.append('reportName', reportName)

  // For reports, we currently only care about accuracy to the date, not hour. So we will format it to ISODate
  if (dateRange) {
    url.searchParams.append('startDate', dateRange.startDate.toISODate())
    url.searchParams.append('endDate', dateRange.endDate.toISODate())
  }

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (nonEmptyString(value)) {
        url.searchParams.append(key, value)
      } else {
        Logger.error(`Unexpected parameter of type ${typeof value} passed to the reporting url`)
      }
    })
  }

  return url.href
}

/** reportingEndpointBaseUrl returns a base URL for reporting. */
function reportingEndpointBaseUrl(): URL {
  const config = getRunConfiguration()

  // For testing local changes to reports
  if (isEmul) {
    return new global.URL(`http://localhost:5001/${config.projectId}/us-central1/reporting`)
  }
  return new global.URL(`https://us-central1-${config.projectId}.cloudfunctions.net/reporting`)
}
