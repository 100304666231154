import { AccountHelp } from '@components'
import { Modal, Text } from '@elements'
import { userName } from '@models/User'
import { StyleSheet, View } from 'react-native'

import { Logger } from '@/config/logger'
import { isAdminSelector, unreadMessagesNoSelector, userSelector } from '@/redux/selectors'
import { auth } from '@api/db'
import shareContent from '@helpers/sharing'
import { COOP_WEB_ONBOARD_URL, GROWNBY_APP_URL } from '@shared/BaseUrl'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { SectionItemButton, SectionView } from '../../../components/SectionView'
import Colors from '../../../constants/Colors'
import Feedback from '../Feedback'
import { PrivacyPolicy } from '../PrivacyPolicy'
import { TermsAndConditions } from '../TermsAndConditions'

/** Section containing user account-related navigation items like profile, addresses, payments, etc. */
export function AccountSection() {
  const hasPasswordAuth = auth().getProvider() === 'password'
  const unreadMessages = useSelector(unreadMessagesNoSelector)

  return (
    <SectionView title="Account">
      <SectionItemButton title="Personal Information" url="/profile/view" />
      <SectionItemButton title="Addresses" url="/profile/addresses" />
      <SectionItemButton title="Payments" url="/profile/payments" />
      <SectionItemButton title="Farm Credits" url="/profile/balances" />
      <SectionItemButton
        title="Messages"
        rightElement={unreadMessages ? <UnreadBadge unreadMessages={unreadMessages} /> : undefined}
        url="/profile/messages"
      />
      <SectionItemButton title="Notification Settings" url="/profile/notifications" />
      {hasPasswordAuth && <SectionItemButton title="Password & Security" url="/profile/security" />}
    </SectionView>
  )
}

const UnreadBadge = memo(function UnreadFlagComp({ unreadMessages }: { unreadMessages: number }) {
  return (
    <View style={styles.badgeCont}>
      <Text size={10} type="medium" color={Colors.white}>
        {unreadMessages > 10 ? '10+' : unreadMessages}
      </Text>
    </View>
  )
})

/** Section for farmer-specific features - shows different options based on admin status */
export function FarmerSection() {
  const isAdmin = useSelector(isAdminSelector)

  return (
    <SectionView title="Farmers">
      {isAdmin ? (
        <SectionItemButton title="Manage your farm" subtitle="Click here to go to your farm" url="/admin" />
      ) : isAdmin === false ? (
        <SectionItemButton
          title="Start Selling"
          subtitle="Are you a farmer? Start selling your products today"
          url={COOP_WEB_ONBOARD_URL}
        />
      ) : (
        <SectionItemButton
          title="Loading your farmer status..."
          subtitle="Are you a farmer? Start selling your products today"
          onPress={() => Logger.debug('Still loading farmer status')}
          disabled
        />
      )}
    </SectionView>
  )
}

/** Section containing friend invitation functionality with sharing capabilities */
export function InvitationsSection() {
  const user = useSelector(userSelector)

  const inviteFriends = useCallback(async () => {
    await shareContent({
      message: `${userName(
        user,
      )} invited you to GrownBy, a platform to find local food and support farmers. Download here: ${GROWNBY_APP_URL}`,
      title: 'Invite to GrownBy',
    })
  }, [user])

  return (
    <SectionView title="Invitations">
      <SectionItemButton title="Invite Friends" onPress={inviteFriends} />
    </SectionView>
  )
}

/** Section for help and feedback options, including modals for support and feedback submission */
export function SupportSection() {
  return (
    <SectionView title="Support">
      <SectionItemButton title="Get Help" onPress={() => Modal(<AccountHelp />, { title: 'Account Help' })} />
      <SectionItemButton title="Provide Feedback" onPress={() => Modal(<Feedback />, { title: 'Provide Feedback' })} />
    </SectionView>
  )
}

/** Section containing legal documentation access like Terms of Service and Privacy Policy */
export function LegalSection() {
  return (
    <SectionView title="Legal">
      <SectionItemButton
        title="Terms of Service"
        onPress={() => Modal(<TermsAndConditions />, { title: 'Terms and Conditions' })}
      />
      <SectionItemButton title="Privacy Policy" onPress={() => Modal(<PrivacyPolicy />, { title: 'Privacy Policy' })} />
    </SectionView>
  )
}

const styles = StyleSheet.create({
  badgeCont: {
    minWidth: 18,
    minHeight: 18,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.red,
    paddingHorizontal: 3,
    marginLeft: 5,
  },
})
