import { MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { isNonNullish } from '../helpers'

/** We will avoid adding any product that will make the percent of that product value or quantity more than these. They
 * are to help with share diversity. */
const SINGLE_ITEM_VALUE_MAX_PERCENT = 0.4
const SINGLE_ITEM_QUANTITY_MAX_PERCENT = 0.4

/** This is a representation of a product that can hold its price and how much has been allocated/ what is remaining */
export class CustomShareProduct {
  constructor(
    public productId: string,
    public productName: string,
    public description: string,
    public price: Money,
    public availableQuantity: number,
    public maxPerOrder = Infinity,
    public availableLocations: string[] = [],
  ) {
    this.totalAllocated = 0
  }

  totalAllocated: number // Track total allocated units

  isAvailableAtLocation(locationId: string | undefined): boolean {
    return !locationId || this.availableLocations.includes(locationId)
  }

  allocate(quantity: number): boolean {
    if (this.totalAllocated + quantity <= this.availableQuantity) {
      this.totalAllocated += quantity
      return true
    }
    return false
  }

  deallocate(quantity: number): boolean {
    if (this.totalAllocated >= quantity) {
      this.totalAllocated -= quantity
      return true
    }
    return false
  }
}

/** This customer will hold products that have been allocated to them as well and what they can still afford. */
export class CustomShareCustomer {
  public allocatedProducts: { [productId: string]: number } = {}

  constructor(
    public readonly customerId: string,
    public readonly shareValue: Money,
    public readonly preferences: { [productId: string]: number },
    public readonly minNumItems = 0,
    public readonly locationId: string | undefined = undefined,
  ) {
    this.remainingShareValue = shareValue
  }

  remainingShareValue: Money // Track customer's remaining budget

  canAfford(product: CustomShareProduct): boolean {
    return MoneyCalc.isGTE(this.remainingShareValue, product.price)
  }

  canAllocate(product: CustomShareProduct): boolean {
    const currentAllocation = this.allocatedProducts[product.productId] ?? 0

    return (
      product.totalAllocated < product.availableQuantity &&
      currentAllocation < product.maxPerOrder &&
      this.canAfford(product) &&
      product.isAvailableAtLocation(this.locationId)
    )
  }

  allocateProduct(product: CustomShareProduct): boolean {
    if (this.canAfford(product)) {
      if (product.allocate(1)) {
        this.remainingShareValue = MoneyCalc.subtract(this.remainingShareValue, product.price)
        this.allocatedProducts[product.productId] = (this.allocatedProducts[product.productId] || 0) + 1
        return true
      }
    }
    return false
  }

  // Add neutral preferences for any products not listed
  addNeutralPreferences(allProductIds: string[]) {
    for (const productId of allProductIds) {
      if (!(productId in this.preferences)) {
        this.preferences[productId] = 3
      }
    }
  }

  deallocateProduct(product: CustomShareProduct): boolean {
    const allocatedQty = this.allocatedProducts[product.productId] || 0
    if (allocatedQty > 0) {
      // Decrease allocated quantity
      this.allocatedProducts[product.productId] = allocatedQty - 1
      if (this.allocatedProducts[product.productId] === 0) {
        delete this.allocatedProducts[product.productId]
      }

      // Refund the product price
      this.remainingShareValue = MoneyCalc.add(this.remainingShareValue, product.price)

      // Decrease product's total allocated quantity
      product.deallocate(1)

      return true
    }
    return false
  }
}

/**
 * Calculates the percentage of the share value and quantity that a product would take up
 * if allocated one more time to a customer, and returns if it will remain within the constraints
 * for share diversity
 */
export function isWithinAllocationPercentages(product: CustomShareProduct, customer: CustomShareCustomer): boolean {
  const totalAllocatedValue = MoneyCalc.subtract(customer.shareValue, customer.remainingShareValue)
  const totalAllocatedQuantity = Object.values(customer.allocatedProducts).reduce((a, b) => a + b, 0)

  const productCurrentQuantity = customer.allocatedProducts[product.productId] ?? 0
  const productCurrentValue = MoneyCalc.multiply(product.price, productCurrentQuantity)

  // Values if one more allocated
  const productNextValue = MoneyCalc.add(productCurrentValue, product.price)
  const productNextQuantity = productCurrentQuantity + 1

  const valuePercent =
    MoneyCalc.cents(productNextValue) / MoneyCalc.cents(MoneyCalc.add(totalAllocatedValue, product.price))
  const quantityPercent = productNextQuantity / (totalAllocatedQuantity + 1)

  // If the value and quantity percent will remain under the constraints after adding these products
  return valuePercent < SINGLE_ITEM_VALUE_MAX_PERCENT && quantityPercent < SINGLE_ITEM_QUANTITY_MAX_PERCENT
}

/** Will extract customer preferences and sort each product by rank for the given customer */
export function getCustomerPreferredProducts(customer: CustomShareCustomer, products: CustomShareProduct[]) {
  // Get all products the customer want sorted in order of preference
  const preferredProducts = Object.entries(customer.preferences)
    // Exclude products customers hate, EG. if they are vegan never include cheese
    .filter(([_, score]) => score > 1)
    .map(([productId, preferenceScore]) => {
      const product = products.find((p) => p.productId === productId)!
      return { product, preferenceScore }
    })
    // This will filter out any products the customer may have in their preferences that aren't in the products list
    .filter((item) => isNonNullish(item.product))
    // Filter out any products that are not available at the customer's location
    .filter((item) => item.product.isAvailableAtLocation(customer.locationId))
    // Shuffle the products so that if all the products are ranked the same and have the same price we don't always prioritize the same product. Ranking and price will
    // adjust this order, this is just for when there are exact matches
    .sort(() => Math.random() - 0.5)

  // Sort products to determine which products we should pick first for a customer
  preferredProducts.sort(sortProductsByPreferenceAndAvailability)

  // Return just the products after they have been sorted
  return preferredProducts.map((item) => item.product)
}

/** This will put all products more than $8 above products that are less than that. This is because we want to save products under $3 to fill shares to the full value. */
const getPriceGroup = (priceInCents: Money) => {
  if (MoneyCalc.isGTE(priceInCents, MoneyCalc.fromCents(800))) return 3
  if (MoneyCalc.isGTE(priceInCents, MoneyCalc.fromCents(300))) return 2
  return 1
}

/** This will put products that have less than 30 or less than 10 below products that have many in stock. */
const getQuantityGroup = (quantity: number) => {
  if (quantity >= 30) return 3
  if (quantity >= 10) return 2
  return 1
}

/** Sort the products by customer preference */
function sortProductsByPreferenceAndAvailability(
  a: { product: CustomShareProduct; preferenceScore: number },
  b: { product: CustomShareProduct; preferenceScore: number },
) {
  // If the customer prefers a product it will always be ranked above the others
  if (b.preferenceScore !== a.preferenceScore) {
    return b.preferenceScore - a.preferenceScore
  }

  // Next if one of the products has significantly more quantity we want to push that product
  const quantityGroupA = getQuantityGroup(a.product.availableQuantity)
  const quantityGroupB = getQuantityGroup(b.product.availableQuantity)
  if (quantityGroupB !== quantityGroupA) {
    return quantityGroupB - quantityGroupA
  }

  // Lastly we rank by price group, so if a product is more expensive than $8 it will rank higher than the rest because
  // it is more important to fill the share fully
  const priceGroupA = getPriceGroup(a.product.price)
  const priceGroupB = getPriceGroup(b.product.price)
  if (priceGroupB !== priceGroupA) {
    return priceGroupB - priceGroupA
  }

  // If the products compare similarly then don't change the order as they are already randomized
  return 0
}
