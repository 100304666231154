import { buildCartItem, CartItemFields } from '@helpers/builders/buildCartItem'
import { dequal } from '@helpers/customDequal'
import { isCompatibleLocation, isSameAddress } from '@helpers/location'
import {
  isOutofPickups,
  matchesAppModeBO,
  matchesAppModePrice,
  matchesAppModeProduct,
  matchesAppModeSchedule,
} from '@helpers/products'
import { pick } from '@helpers/typescript'
import { Distribution } from '@models/Distribution'
import { isLocalPickup, isNonPickup } from '@models/Location'
import { CartDigital, CartItem, CartItemBase, CartShare, CartStandard, isCartPhysical } from '@models/Order'
import { GlobalStandard, hasUnits, hasUnitStock, isShare, isStandard, Product, UnitStandard } from '@models/Product'
import { ErrorTypes, ErrorWithCode, isNotFound } from '@shared/Errors'

import { MoneyCalc } from '@helpers/money'
import { getPaymentSchedules } from '@helpers/order'
import { isSameDay } from '@helpers/time'
import { getFarmCartItems } from '@models/Cart'
import DecimalCalc from './decimal'
import { removeComplexDuplicates } from './helpers'
import { InvalidItemDetails, isLoadErr, ItemRejectReason, ProdFetchResult } from './validateCart-helpers'

/** Will validate a group of cart items being checked out in a way that helps handle each item with problems.
 * - This will be reused in the client and server side, so it should hold any shared order validation for carts.
 * - It will be called before entering checkout, then when placing the order client side and lastly when the server starts processing the order.
 */
export function validateCart({
  cart: cartProp,
  uniqueProdsInCartDb,
  onInvalidItem,
  farmId,
  isAdmin,
  isWholesale,
}: {
  /** An array of cart items from the same farm to validate for placing an order */
  cart: CartItem[]
  /** A list of products loaded from Firestore that are in the cart. This should be a unique list of products, so that the validation doesn't repeat the same product validation multiple times. */
  uniqueProdsInCartDb: ProdFetchResult[]
  /** A callback function that handles each invalid item. If the reject reason is `Insufficient`, it should receive the `quantity` argument with the most up-to-date quantity, so that cart quantity can be matched to it. */
  onInvalidItem: (details: InvalidItemDetails) => void
  farmId: string
  /** Whether the validation is for an admin or consumer request */
  isAdmin: boolean
  /** Whether the validation is happening in wholesale mode */
  isWholesale: boolean
}): void {
  if (!farmId) {
    throw new Error('Cart validation requires the farm id of the farm whose products are being checked out.')
  }

  const cart = getFarmCartItems({ items: cartProp, farmId, isWholesale })

  if (!cart.length) {
    throw new Error("Can't check out an empty cart")
  }

  if (!uniqueProdsInCartDb.length) {
    throw new Error('No DB products to use for validation')
  }

  checkMultipleDatesWholesale({ isWholesale, farmId, cart })

  // This section validates any aspect about cart items that depends on most recent product data for the item
  uniqueProdsInCartDb.forEach((prodResult) => {
    //get any items in cart that have the same product id (could be more than one item, if it's a multi-unit prod)
    const itemsWithProduct = cart.filter((ci) => ci.product.id === prodResult.id)

    if (isLoadErr(prodResult)) {
      // Check if the product has been deleted
      if (isNotFound(prodResult.err)) {
        // There's nothing else to validate in this case, because all tha items will get the same reject reason
        // So return
        return itemsWithProduct.forEach((ci) => {
          onInvalidItem({ id: ci.id, reason: ItemRejectReason.Deleted })
        })
      }

      /** If there was an error other than not found, validation should not proceed because any results would be misleading. This error needs to be handled by the caller perhaps with an alert, and an option to retry */
      throw prodResult.err
    }

    // Check if the product price has changed
    itemsWithProduct.forEach((ci) => {
      if (isShare(prodResult)) {
        const typedItem = ci as CartShare

        const prodPaymentSchedules = getPaymentSchedules({ product: prodResult })
        // We need to check that no payment schedule has changed on the cart, because the user can still change the PS
        // from checkout, so if any have changed we should not let them proceed with this product
        typedItem.product.paymentSchedules.forEach((cartPs) => {
          const matchingPS = prodPaymentSchedules.find((prodPs) => prodPs.frequency === cartPs.frequency)
          // The fields we want to validate are amount, deposit, and endDate because these are ones that affect pricing
          if (
            !matchingPS ||
            !MoneyCalc.isEqual(matchingPS.amount, cartPs.amount) ||
            !MoneyCalc.isEqual(matchingPS.deposit, cartPs.deposit) ||
            !isSameDay(matchingPS.paymentDates.endDate, cartPs.paymentDates.endDate)
          ) {
            onInvalidItem({ id: typedItem.id, reason: ItemRejectReason.PriceChanged })
          }
        })
      } else if (hasUnits(prodResult)) {
        const typedItem = ci as CartStandard | CartDigital

        // Match the price selected in the cart with the product price
        const priceList = prodResult.units.flatMap((unit) => unit.prices)
        const unitPrice = priceList.find((price) => price.id === typedItem.price.id)

        // If the selected price no longer exists on the product or the amount has changed then the item is invalid
        if (!unitPrice || !MoneyCalc.isEqual(unitPrice.amount, typedItem.price.amount)) {
          onInvalidItem({ id: ci.id, reason: ItemRejectReason.PriceChanged })
        }
      }
    })

    // Check if product has enough in stock
    if (hasUnitStock(prodResult)) {
      //if the dbProd is unit stock, make sure the cartitems with this product don't exceed the quantity of their selected unit, based on the most recent data.
      //if there's multiple units, there may be multiple items in cart, each with the same prod but a different unit.
      itemsWithProduct.forEach((ci) => {
        const item = ci as CartItemBase<UnitStandard>
        const updatedUnit = prodResult.units.find((u) => u.id === item.unit.id)
        if (!updatedUnit) {
          return onInvalidItem({ id: item.id, reason: ItemRejectReason.UnitDeleted })
        }
        if (updatedUnit.quantity < 1) {
          return onInvalidItem({ id: item.id, reason: ItemRejectReason.OutofStock })
        }
        // for unit stock, there's no need divide by the multiplier for comparing quantity in this context
        if (ci.quantity > updatedUnit.quantity) {
          return onInvalidItem({ id: item.id, reason: ItemRejectReason.Insufficient, quantity: updatedUnit.quantity })
        }
      })
    } else if (hasUnits(prodResult)) {
      //if the dbProd is a global stock standard product, make sure the cartitems with this product don't exceed the global most-recent quantity available, considering all of these items and their multipliers.
      //if the sum of the items' adjusted quantities exceed the global up-to-date quantity, it's a scenario that doesn't have a clear-cut correct solution. so for the purpose of handling it gracefully, it may be better to use an arbitrary policy

      //arbitrary solution: algorithm that iterates over each item in cart, and keeps a counter variable of the global quantity remaining. for each item, get the adjusted quantity (quantity * multi) and subtract it from global stock. if remaining stock is zero or more, allow item in cart. if eventually the remaining stock is less than zero, it means the global stock maxed out, so stop the iteration, and reject this and every remaining item.

      let globStock = prodResult.quantity
      let maxedOut = false
      const globItms = itemsWithProduct as CartItemBase<GlobalStandard>[]
      //sorting by ascending multiplier will allow as many items as possible before getting insufficient quantity kicks in and rejected items will only be the last ones with large multipliers
      globItms.sort((a, b) => (a.unit.multiplier < b.unit.multiplier ? -1 : 1))

      //this should run as a `for` loop, not as `forEach`, because it needs to be sequential
      for (const item of globItms) {
        if (maxedOut) {
          // This refers to subsequent items which count against the same global stock, after it has been depleted by previous items in this iteration

          onInvalidItem({ id: item.id, reason: ItemRejectReason.OutofStock })
          continue
        }
        const adjustedUnits = DecimalCalc.multiply(item.quantity, item.unit.multiplier)
        if (globStock - adjustedUnits >= 0) {
          globStock = DecimalCalc.subtract(globStock, adjustedUnits)
          continue
        } else {
          maxedOut = true //Setting this to true means if there are more buying options to check after the current one, they will be out of stock

          const newQty = Math.floor(DecimalCalc.divide(globStock, item.unit.multiplier)) //how many times can this unit multiplier fit inside the remaining stock
          globStock = DecimalCalc.subtract(globStock, DecimalCalc.multiply(newQty, item.unit.multiplier))
          onInvalidItem({ id: item.id, reason: ItemRejectReason.Insufficient, quantity: newQty })
        }
      }
    } else if (isShare(prodResult)) {
      //for shares, there can't be multiple items in cart that have the same share. so just check that quantity is less than new data for the first and only item
      const item = itemsWithProduct[0]
      if (item) {
        if (prodResult.quantity < 1) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.OutofStock })
        } else if (item.quantity > prodResult.quantity) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.Insufficient, quantity: prodResult.quantity })
        }
      }
    }

    // Check for pickups left
    if (
      isOutofPickups(prodResult, {
        ignoreOrderCutoffWindow: isAdmin,
        excludeClosedDistros: !isAdmin,
        isWholesale,
      })
    ) {
      itemsWithProduct.forEach((item) => {
        onInvalidItem({ id: item.id, reason: ItemRejectReason.Unavailable })
      })
    }

    // Check that all standard products meet the minimum pickups required
    if (isStandard(prodResult) && !isWholesale && !isAdmin && prodResult.minPickups !== undefined) {
      itemsWithProduct.forEach((item) => {
        if (item.pickups && item.pickups.length < prodResult.minPickups!) {
          onInvalidItem({
            id: item.id,
            reason: ItemRejectReason.InsufficientPickups,
            minPickups: prodResult.minPickups,
          })
        }
      })
    }

    /** Validate any potential changes to the product schedules will not affect the cart items */
    itemsWithProduct.forEach((item) => {
      if (isCartPhysical(item)) {
        const itemDistro = item.distribution

        if (isNonPickup(itemDistro.location)) {
          const itemLoc = itemDistro.location
          const dbLoc = prodResult.distributions?.find((d) => d.id === itemDistro.id)?.location
          if (!dbLoc || !isNonPickup(dbLoc)) {
            onInvalidItem({ id: item.id, reason: ItemRejectReason.LocationNotFound })
          } else {
            dbLoc['address'] = itemLoc.address
            const isCompatible = isCompatibleLocation(dbLoc, dbLoc.type, dbLoc.address)
            if (!isCompatible) {
              onInvalidItem({ id: item.id, reason: ItemRejectReason.IncompatibleLocation })
            }
          }
        } else if (isLocalPickup(itemDistro.location)) {
          const itemLoc = itemDistro.location
          const dbSchedule = prodResult.distributions?.find((d) => d.id === itemDistro.id)
          const dbLoc = dbSchedule?.location

          if (!dbLoc || !isLocalPickup(dbLoc)) {
            onInvalidItem({ id: item.id, reason: ItemRejectReason.LocationNotFound })
          } else {
            if (!isSameAddress(dbLoc.address, itemLoc.address)) {
              onInvalidItem({ id: item.id, reason: ItemRejectReason.AddressChanged, newAddress: dbLoc.address })
            }
          }
        }

        /** This array of schedule fields to check should only be those fields that if changed would either make the item invalid (As in the case of "closed" or "isHidden") or would cause a very confusing change in the item's distribution schedule ("schedule"). Everything else at the moment of this writing doesn't seem like an impediment for keeping the item in the cart. For example, if the deliveryFee changed, that's OK they will still see the new total in the checkout screen. Things like that are harmless for the purpose of cart item validation in pre-checkout */
        const fieldsToCheck: (keyof Distribution)[] = ['isHidden', 'schedule', 'priceGroup']
        // We should only check the field "closed" if the user is not an admin, because admins can checkout even if the distro is closed
        if (!isAdmin) fieldsToCheck.push('closed')

        const dbSchedule = prodResult.distributions?.find((d) => d.id === itemDistro.id)

        if (dbSchedule && !dequal(pick(dbSchedule, ...fieldsToCheck), pick(itemDistro, ...fieldsToCheck))) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.ScheduleChanged })
        }
      }
    })
  })

  // Use the cartItem validator for general validation of items, with the most recent db data for each product
  const dbProds = uniqueProdsInCartDb.filter((res): res is Product => !isLoadErr(res))

  cart.forEach((item) => {
    const dbProd = dbProds.find((p) => p.id === item.product.id)

    if (!dbProd) {
      onInvalidItem({ id: item.id, reason: ItemRejectReason.CartItemError })
    } else {
      // Substitute the item's product with the most recent product before cart item general validation
      const newItem: CartItemFields & { isAdmin: boolean; isWholesale?: boolean } = {
        ...item,
        product: dbProd,
        isAdmin,
        isWholesale,
      }
      try {
        buildCartItem(newItem)
      } catch (err) {
        onInvalidItem({ id: item.id, reason: ItemRejectReason.CartItemError })
      }
    }
  })

  // Check if the item options are compatible with the app mode
  cart.forEach((item) => {
    // check each item product is compatible with the app mode
    if (!matchesAppModeProduct(isWholesale)(item.product)) {
      return onInvalidItem({ id: item.id, reason: ItemRejectReason.IncompatibleOptionsForCatalog })
    }

    // check each item schedule is compatible with the app mode
    if (item.distribution && !matchesAppModeSchedule(isWholesale)(item.distribution)) {
      return onInvalidItem({ id: item.id, reason: ItemRejectReason.IncompatibleOptionsForCatalog })
    }

    // check each item BO is compatible with the app mode
    if (item.unit && !matchesAppModeBO(isWholesale)(item.unit)) {
      return onInvalidItem({ id: item.id, reason: ItemRejectReason.IncompatibleOptionsForCatalog })
    }

    // check each item price is compatible with the app mode
    if (item.price && !matchesAppModePrice(isWholesale)(item.price)) {
      return onInvalidItem({ id: item.id, reason: ItemRejectReason.IncompatibleOptionsForCatalog })
    }
  })
}

/** Performs the cart validation that prevents wholesale orders with multiple dates */
export function checkMultipleDatesWholesale({
  cart,
  farmId,
  isWholesale,
}: {
  isWholesale: boolean
  cart: CartItem[]
  farmId: string
}) {
  if (isWholesale) {
    if (!farmId)
      throw new Error('A farm id must be provided to check against multiple cart dates per farm in wholesale')

    const items = getFarmCartItems({ items: cart, farmId, isWholesale })

    const uniqueDatesInCart = removeComplexDuplicates(
      items.flatMap((itm) => itm.pickups ?? []),
      isSameDay,
    )

    // Check there's no more than one date among the items
    if (uniqueDatesInCart.length > 1) {
      throw new ErrorWithCode({
        type: ErrorTypes.Validation,
        code: 'MultipleDatesWholesale',
        devMsg: 'Wholesale orders must have a single date',
        data: { isWholesale, farmId },
      })
    }
  }
}
