import { SearchResponse } from '@algolia/client-search'
import { isArray, isNonNullish } from '@helpers/helpers'
import { DateTime } from 'luxon'
import { QueryFilterArray } from '../../models/Algolia'

/**
 * Builds a query filter string from a query filter array, joining elements using AND or OR depending on whether the filter is an array.
 *
 * @param filters - An array of filters where each filter can be a string or an array of strings.
 * @returns A formatted filter string with filters joined by AND and grouped by OR if any arrays are provided.
 *
 * - Should be used as the value for Algolia's `filter` prop, as it is compatible with its syntax
 * @example
 * buildQueryFilter(['docType:product', ['type:primary', 'type:addon']]);
 * // Returns: 'docType:product AND (type:primary OR type:addon)'
 */
export function buildQueryFilter(filters: QueryFilterArray): string {
  const mapped = filters.map((innerFilter) => {
    // If its an array, it means it is an OR operation
    if (isArray(innerFilter)) {
      if (innerFilter.length === 0) return ''

      // Do not add parenthesis if only one
      if (innerFilter.length === 1) return innerFilter

      return `(${innerFilter.join(` OR `)})`
    }

    return innerFilter
  })

  return mapped.join(' AND ')
}

/**
 * Parse Algolia results to ensure that hits is an array and that it is not nullish. This is important because the type
 * from Algolia is not guaranteed to be correct, so we should validate it and ensure that it is correct before using it.
 * @param result
 */
export function parseAlgoliaResults<ReturnType>(result: SearchResponse<ReturnType>) {
  let hits = result?.hits
  if (!isNonNullish(hits) || !isArray(hits)) {
    hits = []
  } else {
    // Sometimes algolia will return [null] which needs to be filtered
    hits = hits.filter(isNonNullish)
  }

  return { ...result, hits }
}

/**
 * Creates the numeric filters for product availability
 * @param bufferMinutes - Minutes to add to current time for availability window
 */
export const createAvailabilityFilter = (bufferMinutes = 5) =>
  `lastAvailStamp > ${DateTime.now().plus({ minutes: bufferMinutes }).toMillis()}`
