import { globalStyles } from '@/constants/Styles'
import { ToolTips } from '@components'
import { ButtonClear, FormInputLabel, FormMoneyInput, FormPickerInput } from '@elements'
import { findPriceForAppMode } from '@helpers/products'
import { formatCatalog } from '@helpers/wholesale'
import { DefaultCatalog } from '@models/Product'
import { StyleSheet, View } from 'react-native'
import { buildPrice, BuyingOptionSectionProps } from '../../AdvancedPricing-helpers'

type PricePerCatalogProps = Pick<BuyingOptionSectionProps, 'formik' | 'formValues'>

/** Price per catalog component. Used on multi-catalog products*/
export function PricePerCatalog({ formik, formValues }: PricePerCatalogProps) {
  const { values: boValues, showSimpleError, setFieldValue, handleBlur } = formik

  return (
    <View style={styles.pricePerCatalogCont}>
      {boValues.prices.map((pr, prIx, prices) => (
        <View style={globalStyles.flexRow} key={`price[${pr.id}]`}>
          <View style={styles.pricesCont}>
            <FormPickerInput
              row
              onValueChange={(v) => {
                setFieldValue(`prices[${prIx}].priceGroup`, { type: 'default-catalog', catalog: v })
              }}
              label={<FormInputLabel label="Price Group" />}
              value={pr.priceGroup?.type === 'default-catalog' ? pr.priceGroup.catalog : ''}
              items={catalogOpts}
              errorMessage={showSimpleError(`prices[${prIx}].priceGroup`)}
              onBlur={handleBlur(`prices[${prIx}].priceGroup`)}
            />
            <FormMoneyInput
              row
              value={pr.amount}
              label={
                <FormInputLabel
                  label={`Price${formValues.unitStock ? ' (calculated-editable)' : '*'}`}
                  tooltipId={formValues.unitStock ? ToolTips.PRICE_OPTION : ToolTips.PRICE_GLOBAL}
                />
              }
              onChangeText={(val) => {
                setFieldValue(`prices[${prIx}].amount`, val)
              }}
              errorMessage={showSimpleError(`prices[${prIx}].amount`)}
              onBlur={handleBlur(`prices[${prIx}].amount`)}
            />
          </View>
          <ButtonClear
            icon="times"
            disabled={boValues.prices.length === 1}
            size={14}
            style={styles.removePriceBtn}
            onPress={() => {
              setFieldValue(
                `prices`,
                prices.filter((prInner) => prInner.id !== pr.id),
              )
            }}
          />
        </View>
      ))}
      {boValues.prices.length < 2 && (
        <ButtonClear
          title="+ Add price"
          size={14}
          style={styles.addPrice}
          onPress={() => {
            const hasRetail = !!findPriceForAppMode(boValues.prices, false)
            const newPrice = buildPrice(hasRetail ? DefaultCatalog.Wholesale : DefaultCatalog.Retail)
            setFieldValue(`prices`, [...boValues.prices, newPrice])
          }}
        />
      )}
    </View>
  )
}

const catalogOpts = [DefaultCatalog.Retail, DefaultCatalog.Wholesale].map((v) => ({
  value: v,
  label: formatCatalog(v),
}))

const styles = StyleSheet.create({
  addPrice: {
    alignSelf: 'flex-end', // This forces the button to appear on the right side of the form, below the prices
  },
  pricesCont: {
    flex: 1,
    flexDirection: 'row',
  },
  removePriceBtn: {
    height: 41.5,
    width: 41.5,
    marginTop: 40, // covers the input label so it will be aligned with the actual input
  },

  pricePerCatalogCont: {
    maxWidth: 700,
    width: '100%',
    alignSelf: 'flex-end',
  },
})
