import { DrawerItem as DrawerItemDefault } from '@react-navigation/drawer'
import Colors from '@/constants/Colors'
import { SIDEBAR_WIDTH } from '@/constants/Layout'
import { IconLibsNames, IconProps, Icon, typography } from '@elements'
import { DrawerNavigationState, ParamListBase, PartialRoute, Route } from '@react-navigation/native'
import { StyleSheet } from 'react-native'
import { AdminDrawerParamList } from './types'
import { NavigateArgs, NavigationRoute } from './helperTypes'
import { nonEmptyString } from '@helpers/helpers'
import { useContext } from 'react'
import { DrawerContentContext } from './AdminDrawerNavigator'

/** A function that receives the current state, route, and subRoute of the drawer navigator, and returns a boolean */
type DrawerItemStateHandler = (
  route: DrawerNavigationState<AdminDrawerParamList>['routes'][0],
  subRoute: NavigationRoute<ParamListBase, string> | PartialRoute<Route<string>> | undefined,
) => boolean

/**These are the options to create a new @type {DrawerItemData} object */
export type DrawerItemProps<
  K extends keyof AdminDrawerParamList,
  LibName extends IconLibsNames | undefined = undefined,
> =
  | /**Option 1: You provide a route and maybe a sub screen, with no onPress */ {
      /** label for the button */
      label: string
      icon?: IconProps<LibName>['name']
      iconSet?: LibName
      /** If noIcon is true and the icon is undefined, this item shouldn't have any extra left spacing or default transparent icon */
      noIcon?: boolean
      /** Screen or navigator child of the admin drawer */
      route: K
      screen?: AdminDrawerParamList[K] extends { screen: infer S } | undefined ? S : undefined
      /** Whether pressing this button should navigate to the specified screen inside the route. If return value is false, it will only navigate to the route without specifying the screen */
      shouldGoToScreen?: DrawerItemStateHandler
      onPress?: undefined
      /** Whether this button should be highlighted */
      isSelected: DrawerItemStateHandler
      /** Determines if the drawer item should be shown or not. If undefined, will be shown by default */
      shouldShow?: DrawerItemStateHandler
      /** Whether this item is a sub route */
      isSub?: boolean
    }
  | /**Option 2: You provide no route or screen, but onPress is required. This is useful for external links such as opening the shop in a new tab */ {
      label: string
      icon?: IconProps<LibName>['name']
      iconSet?: LibName
      /** If noIcon is true and the icon is undefined, this item shouldn't have any extra left spacing or default transparent icon */
      noIcon?: boolean
      route?: undefined
      screen?: undefined
      shouldGoToScreen?: undefined
      /** Button onPress. This is intended for non-navigation buttons such as external links */
      onPress: () => any
      isSelected?: undefined
      /** Determines if the drawer item should be shown or not. If undefined, will be shown by default */
      shouldShow?: DrawerItemStateHandler
      /** Whether this item is a sub route */
      isSub?: boolean
    }

/** Wraps the default drawer item from navigation and manages the showing/ hiding of the drawer item/ button */
export function DrawerItem<K extends keyof AdminDrawerParamList, LibName extends IconLibsNames | undefined = undefined>(
  props: DrawerItemProps<K, LibName>,
) {
  /** navigation and state inside the drawer content component must be obtained from the DrawerContentComponentProps passed into the main drawer content component. If you use useNavigation here the resulting type won't be as you expect. This component really needs the DrawerContentComponentProps to get all the data it needs. That's why this uses a context to pass those props down here, instead of using useNavigation */
  const { navigation, state } = useContext(DrawerContentContext)
  const drawerState = state as DrawerNavigationState<AdminDrawerParamList>

  const currRoute = drawerState.routes[drawerState.index]
  const subIx = currRoute.state?.index ?? (currRoute.state ? currRoute.state.routes.length - 1 : undefined)
  const subRoute = subIx === undefined ? undefined : currRoute.state?.routes[subIx]

  const onPress = // If an onPress was provided, that's the onPress value
    props.onPress ??
    // If no opts.onPress, we provide a new function that should navigate
    function onPresItemNavigate() {
      if (!props.route) return // Route is required for navigation, if onPress was not provided

      const params: any[] = [props.route] //these are the params for navigate()

      /** Whether we are already in a screen of this navigator */
      const isInRoute = currRoute.name === props.route

      /** If a shouldGoToScreen is specified, that'll determine whether it goes into the screen. Otherwise the default is it goes to the screen if we're currently inside that route (Inside a screen of that route's navigator) */
      const navigateToSubRoute = props?.shouldGoToScreen?.(currRoute, subRoute) ?? isInRoute

      /** This will make it navigate to the specific screen inside the navigator route.
       * - When returning to a previous navigator, we want to be taken to the last visited screen of the navigator.
       * Therefore this should only navigate to the specific screen if we are already inside that navigator
       */
      if (nonEmptyString(props.screen) && navigateToSubRoute) {
        //if an sub-screen was provided, we should include the second argument which will specify the screen for the navigator route
        params.push({ screen: props.screen })
      }
      //this type casting is safe because the params were validated by `DrawerItemTypeOpts<K>`
      navigation.navigate(...(params as NavigateArgs<AdminDrawerParamList, K>))
    }

  const isSelected = props.isSelected?.(currRoute, subRoute) ?? false

  const shouldShow = props.shouldShow?.(currRoute, subRoute) ?? true

  if (!shouldShow) return null

  return (
    <DrawerItemDefault
      icon={() =>
        /** If no icon specified and we specifically want no alignment for this item, it needs to return null at this level. Otherwise if null is returned inside the DrawerIcon, the element will still get a default spacing */
        !props.icon && props.noIcon ? null : (
          <DrawerIcon name={props.icon} iconSet={props.iconSet} focused={isSelected} isSub={!!props.isSub} />
        )
      }
      labelStyle={[
        !props.icon && props.noIcon ? null : styles.labelStyle,
        isSelected ? { fontFamily: typography.body.bold } : null,
      ]}
      style={[styles.drawerItemStyle, props.isSub ? { marginLeft: 25 } : null]}
      label={props.label}
      onPress={onPress}
      focused={isSelected}
      activeBackgroundColor={Colors.lightGreen}
      pressColor={Colors.green}
      activeTintColor={Colors.green}
    />
  )
}

type DrawerIconProps<LibName extends IconLibsNames | undefined = undefined> = {
  focused: boolean
  isSub: boolean
  name?: IconProps<LibName>['name']
} & Omit<IconProps<LibName>, 'color' | 'name'>

/** Icon for the drawer */
export function DrawerIcon<LibName extends IconLibsNames | undefined = undefined>({
  name,
  focused,
  isSub,
  iconSet,
  ...props
}: DrawerIconProps<LibName>) {
  return name ? (
    <Icon
      size={15}
      name={name}
      iconSet={iconSet}
      solid
      color={focused ? Colors.green : Colors.shades[200]}
      {...props}
    />
  ) : isSub ? (
    /** If it's a sub route, and no icon specified, render a circle */
    <Icon
      size={8}
      name={focused ? 'dot-circle' : 'circle'}
      color={focused ? Colors.green : Colors.shades[200]}
      {...props}
    />
  ) : (
    /** By default if no icon and not sub, will render a transparent icon, to ensure the label is aligned with the rest */
    <Icon size={8} name="circle" color={Colors.transparent} {...props} />
  )
}
export const styles = StyleSheet.create({
  container: {
    paddingVertical: 30,
  },
  isFocused: {
    backgroundColor: Colors.shades[75],
  },
  drawerItemStyle: {
    maxWidth: SIDEBAR_WIDTH,
  },
  labelStyle: {
    // This helps the label appear closer to the left icon because by default the padding is too large
    marginLeft: -10,
    // By default there is a large margin right, which will trim the label text if it goes close to the right edge, especially if it's a sub-route because sub-routes get extra padding. So setting this to zero prevents trimming the text in most circumstances going forward
    marginRight: 0,
  },
})
