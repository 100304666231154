import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { Button, typography } from '@elements'
import { useCallback, useRef, useState } from 'react'
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputChangeEventData,
  TextInputKeyPressEventData,
  View,
} from 'react-native'

const minInputHeight = 50
const maxInputHeight = 200

type ConversationInputProps = {
  onSendMessagePress: (msg: string) => void
}

/** TextInput wrapper that follows the design for chat screen */
export function ConversationInput({ onSendMessagePress }: ConversationInputProps) {
  const [isFocused, setIsFocused] = useState(false)
  const [input, setInput] = useState('')

  const inputRef = useRef<TextInput | null>(null)

  const onSendPress = useCallback(() => {
    onSendMessagePress(input)

    setInput('')
    inputRef.current?.clear()
    resetWebHeight(inputRef)
  }, [input, onSendMessagePress])

  /** Adjusts the height of the TextInput element for web to fit the content. */
  const adjustTextInputSizeWeb = useCallback((evt: NativeSyntheticEvent<TextInputChangeEventData>) => {
    const element = evt?.nativeEvent.target as unknown as HTMLTextAreaElement
    if (!element) return

    element.style.height = '0px'
    const newHeight = element.offsetHeight - element.clientHeight + element.scrollHeight

    element.style.height = `${newHeight}px`
  }, [])

  /**
   * Handles key press events for the TextInput.
   * - Will send the message if Enter key is pressed without the Shift key (Web).
   */
  const onKeyPressWeb = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      const shiftPressed = (e.nativeEvent as KeyboardEvent).shiftKey === true

      if (!shiftPressed && e.nativeEvent.key === 'Enter') {
        e.preventDefault()
        onSendPress()
      }
    },
    [onSendPress],
  )

  return (
    <View style={[styles.main, isFocused && styles.focused]}>
      <TextInput
        blurOnSubmit={false}
        ref={inputRef}
        multiline
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholderTextColor={Colors.shades['200']}
        onChange={isWeb ? adjustTextInputSizeWeb : undefined}
        style={[styles.input, isWeb && styles.webInput]}
        placeholder="Type your message"
        value={input}
        onKeyPress={isWeb ? onKeyPressWeb : undefined}
        onChangeText={setInput}
      />

      <Button
        disabled={!input.trim().length}
        onPress={onSendPress}
        style={styles.sendBtn}
        small
        icon="paper-plane"
        title="Send"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades['200'],
    margin: 10,
    gap: 10,
  },
  input: {
    minWidth: 50,
    fontFamily: typography.body.regular,
    flex: 1,
    padding: 10,
    maxHeight: maxInputHeight,
    lineHeight: 22,
  },
  focused: {
    borderColor: Colors.green,
  },

  webInput: {
    // This will remove the default border applied on focus (WEB only) as it will change the borderColor only for the input and will not include the icons as well
    //@ts-ignore
    outlineStyle: 'none',
    height: minInputHeight, // Height is set only on web as on mobile will prevent auto-expanding input's size.
  },
  sendBtn: {
    alignSelf: 'flex-end',
    marginRight: 5,
    marginVertical: 5,
  },
})

/** Resets web height when the message is sent */
const resetWebHeight = (inputRef: React.MutableRefObject<TextInput | null>) => {
  if (!isWeb || !inputRef.current) return
  const element = inputRef.current as unknown as HTMLTextAreaElement
  element.style.height = `${50}px` // Update height
}
