import {
  Image,
  paddedProdWidth,
  paddedProdWidthSM,
  prodHeight,
  ProductCardHomeSkeleton,
  ResponsiveGrid,
} from '@components'
import { ButtonClear, HeaderText, Text, UniversalTag } from '@elements'
import React, { memo, useContext, useMemo } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { MessageWithIcon } from '../../../components/LoaderWithMessage'
import { WebFooter } from '../../Home/WebFooter'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { AlgoliaFarmDataContext } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { FarmDataContext } from '@/hooks/useFarmData'
import { useDeviceSize, useLayout } from '@/hooks/useLayout'
import { ShoppingStackParamList } from '@/navigation/types'
import { emptyProductFilters } from '@models/Filters'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProductFilterDropdown } from '../components/ProductFilterDropdown/ProductFilterDropdown'
import { ProductTermFilter } from '../components/ProductTermFilter/ProductTermFilter'
import { SharesDropdown } from '../components/SharesDropdown/SharesDropdown'
import { getVisibleTags } from './Filtering/filterUtils'
import { shouldShowSidebar } from './helpers'
import { useShopSidebarData } from './sidebarHelpers'
import { Farm } from '@models/Farm'

/** Product Card Skeletons */
export function ProductListSkeleton({ itemsNo = 8 }: { itemsNo?: number }) {
  const { isExtraSmallDevice } = useDeviceSize()
  return (
    <View style={styles.gridWrapper}>
      <ResponsiveGrid
        scrollEnabled={false}
        estimatedItemSize={prodHeight}
        itemBaseWidth={isExtraSmallDevice ? paddedProdWidthSM : paddedProdWidth}
        data={Array(itemsNo).fill(0)}
        renderItem={() => <ProductCardHomeSkeleton />}
      />
    </View>
  )
}

export type ListEmptyProps = {
  /** Whether the algolia products are loading (i.e. when filtering is applied) */
  prodsLoading: boolean
  /** Whether it has filters applied */
  hasFiltering: boolean
  /** Whether there was a search error */
  hasError: boolean
}
/** Empty List component. Will show `ProductListSkeleton` if loading is true, or message otherwise */
export function ListEmpty(props: ListEmptyProps) {
  const { prodsLoading, hasError, hasFiltering } = props
  if (prodsLoading) {
    return <ProductListSkeleton />
  }
  if (hasError) {
    return (
      <MessageWithIcon icon="tractor" title="Load error">
        <Text center>There was an error while loading the products. Please try again.</Text>
      </MessageWithIcon>
    )
  }
  if (hasFiltering) {
    return (
      <MessageWithIcon icon="tractor" title="Sorry, no products were found">
        <Text center>We couldn't find any matching results. Please adjust your search.</Text>
      </MessageWithIcon>
    )
  }
  return (
    <MessageWithIcon icon="tractor" title="No Products!">
      <Text center>
        This farm does not have any products available at this time. Please contact the farmer above and request them to
        update their shop.
      </Text>
    </MessageWithIcon>
  )
}

/** Will show `HomeFooter` on web, if it's not loading */
export function ListFooter(props: { loading: boolean }) {
  if (!isWeb || props.loading) return null
  return <WebFooter />
}

type ShopListHeaderProps = {
  /** Text placed above the products */
  title: string
  /** Callback for learnMore button press */
  onLearnMorePress?: () => void
}

/** List header used in FarmShop & CSA details screens */
export const ShopListHeader = memo(function ShopListHeader({ title, onLearnMorePress }: ShopListHeaderProps) {
  const layout = useLayout()
  const { products, errorMsg, searchTerm } = useContext(AlgoliaFarmDataContext)

  const { filterGroups } = useShopSidebarData()

  const {
    farm: { data: farm },
  } = useContext(FarmDataContext)

  // Url props are the same for both FarmShop & CSADetails
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const route = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()
  const isCsaScreen = useComponentRoute()?.name === 'CSADetails'

  const csaRefinementGroup = useMemo(() => filterGroups.find((group) => group.filterKey === 'csaId'), [filterGroups])

  const tags = useMemo(() => getVisibleTags(filterGroups), [filterGroups])

  return (
    <View style={styles.shopListHeaderContainer}>
      {!shouldShowSidebar(layout.isLargeDevice) && (
        <View>
          <View style={styles.searchInputWrapper}>
            <ProductTermFilter initialQuery={searchTerm ?? ''} farmName={farm?.name ?? ''} />
          </View>
          <View style={styles.filterContainer}>
            <Text>{products.length} items found</Text>
            <View style={styles.modalBtnsCont}>
              {/* Shares dropdown should show only on shop page */}
              {farm && !!csaRefinementGroup && (
                <SharesDropdown
                  filter={csaRefinementGroup}
                  onItemPress={(item) => {
                    navigation.navigate('CSADetails', { farmSlug: farm.urlSafeSlug, csaId: item.value })
                  }}
                />
              )}
              <ProductFilterDropdown />
            </View>
          </View>
        </View>
      )}

      {shouldShowSidebar(layout.isLargeDevice) && (
        <View style={globalStyles.flexRowCenter}>
          {/* This is not checking for loading state intentionally because it doesn't look good to toggle
        this text on each applied filter */}
          {!errorMsg && <Text size={12}>{`${products.length} items found`}</Text>}
          <View style={globalStyles.margin10} />

          {isCsaScreen && (
            <UniversalTag
              // This will create a filter-like button with the name of the csa.
              label={title}
              onPress={() => {
                // Go to shop when the csa name filter is pressed
                navigation.navigate('FarmShop', { farmSlug: route.params.farmSlug })
              }}
            />
          )}
          {tags.map((tag) => (
            <UniversalTag
              key={tag.filterKey}
              label={tag.label}
              onPress={() => navigation.setParams({ [tag.filterKey]: undefined })}
            />
          ))}

          {!!tags.length && (
            <ButtonClear
              onPress={() => {
                // If we are on the CSADetails page, this will navigate to the shop page (filters will be cleared too)
                if (isCsaScreen) {
                  navigation.navigate('FarmShop', { farmSlug: route.params.farmSlug })
                } else {
                  // If we are on the shop page, clear the current filters
                  navigation.setParams(emptyProductFilters)
                }
              }}
              small
              textStyle={styles.underline}
              title="Reset filters"
            />
          )}
        </View>
      )}

      <View style={globalStyles.margin10} />
      <Text size={22} type="medium">
        {title}
      </Text>
      {onLearnMorePress && (
        <ButtonClear
          style={styles.learnMoreBtn}
          onPress={onLearnMorePress}
          textStyle={styles.underline}
          small
          title="Learn more"
        />
      )}
    </View>
  )
})

type TitleProps = {
  farm: Farm
  onPress?: () => void
}

/** Renders basic information about the farm */
export function FarmHeaderSnippet({ farm, onPress }: TitleProps) {
  return (
    <View style={styles.titleCont}>
      <TouchableOpacity onPress={onPress}>
        <Image type="logo" style={styles.farmLogo} source={{ uri: farm.logo }} />
      </TouchableOpacity>

      <View style={globalStyles.padding10} />
      <View style={globalStyles.flex1}>
        <HeaderText size={20} numberOfLines={2}>
          {farm.name}
        </HeaderText>

        <Text>
          {farm.address.city}, {farm.address.state}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  shopListHeaderContainer: {
    marginBottom: 20,
  },
  learnMoreBtn: {
    alignSelf: 'flex-start',
    marginVertical: 10,
  },
  underline: {
    textDecorationLine: 'underline',
    fontWeight: '400',
  },
  gridWrapper: {
    flex: 1,
    // Resolves Flashlist <2px warning
    minHeight: 100,
  },
  modalBtnsCont: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  searchInputWrapper: {
    borderBottomWidth: 1,
    borderColor: Colors.shades['100'],
    paddingBottom: 10,
  },
  filterContainer: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  titleCont: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  farmLogo: {
    width: 100,
    height: 100,
    borderRadius: 6,
  },
})
