import { View } from 'react-native'
import { CustomShare, CustomShareRunTypes } from '@models/CustomShare'
import { Button, hideModal, LoadingView, Text } from '@elements'
import { useApiFx } from '../../hooks/useApiFx'
import { loadCSA } from '@api/CSAs'
import { Image, ResizedSuffix } from '@components'
import * as React from 'react'
import { useSizeFnStyles } from '../../hooks/useFnStyles'
import { useMemo } from 'react'
import { formatRunTime } from '@helpers/custom_shares/display'

type CustomizationReminderProps = {
  customShare: CustomShare
}

/** This modal will show to customers once per share window and remind them to place an order for their customization box. */
export function CustomizationReminderModal({ customShare }: CustomizationReminderProps) {
  const { data: csa, loading } = useApiFx(loadCSA, [customShare.csa.id], !!customShare.csa.id)
  const styles = useStyles()
  const orderCloseDate = useMemo(() => formatRunTime(customShare, CustomShareRunTypes.CloseOrdering), [customShare])

  return (
    <LoadingView loading={loading}>
      <View style={styles.container}>
        <Image
          type="farm"
          style={styles.image}
          source={{ uri: csa?.images[0].storageUrl }}
          resizeSuffix={ResizedSuffix.THUMB}
          resizeMode="cover"
        />
        <Text>
          The customization window for {customShare.farm.name} ends {orderCloseDate}
        </Text>
      </View>
      <View style={styles.btnContainer}>
        <Button
          title={"Let's Go"}
          onPress={hideModal}
          url={`/farms/${customShare.farm.id}/shop/csa/${customShare.csa.id}/share/${customShare.id}`}
        />
      </View>
    </LoadingView>
  )
}
const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    container: {
      flexDirection: isSmallDevice ? 'column' : 'row',
      alignItems: 'center',
      margin: 20,
      gap: 10,
    },
    image: {
      width: 200,
      aspectRatio: 3 / 2,
      borderRadius: 10,
    },
    btnContainer: {
      alignItems: isSmallDevice ? undefined : 'flex-end',
    },
  }))
