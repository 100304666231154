import { listCoupons } from '@api/Coupons'
import { ToolTips } from '@components'
import { ButtonClear, DropdownButton, DropdownMenuBtn, HeaderText, Modal, Text, ToggleButton, Tooltip } from '@elements'
import { formatMoney } from '@helpers/display'
import { Coupon, isPercentCoupon } from '@models/Coupon'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { Button } from '../../../components/elements/Button'
import { useApiFx } from '../../../hooks/useApiFx'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { adminFarmSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import { OfflineTable, OfflineTableProps } from '../../components/OfflineTable/OfflineTable'
import { CouponsParamList } from '../../navigation/types'
import { AddEditCoupon } from './AddEditCoupon'
import { EmptyCoupons } from './components'

import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { Permission } from '@helpers/Permission'
import { DecimalCalc } from '@helpers/decimal'
import { DatePeriods, convertPeriodToRange } from '@helpers/time'
import { useNavigation } from '@react-navigation/native'
import { globalStyles } from '../../../constants/Styles'
import { openCouponRedemptionCSV } from '@api/reports/Summaries'

function CouponListComp() {
  const navigation = useNavigation<StackNavigationProp<CouponsParamList, 'CouponList'>>()

  const { isSmallDevice } = useDeviceSize()
  const [showArchived, setShowArchived] = useState(false)

  const farm = useSelector(adminFarmSelector)
  const couponsFx = useApiFx(listCoupons, [farm.id, showArchived])

  const addCoupon = useCallback(() => {
    Modal(
      <AddEditCoupon
        onAdded={(newCoupon: Coupon) => {
          navigation.navigate('CouponDetails', { id: newCoupon.id, addPromo: true })
          couponsFx.data?.push(newCoupon)
        }}
        onUpdate={(updatedCoupon: Coupon) => {
          // If the coupon was archived then reload to get the up-to-date list
          if (updatedCoupon.archived) return couponsFx.refresh()
          // If not we can apply the update in place
          const idx = couponsFx.data?.findIndex((c) => c.id === updatedCoupon.id)
          if (couponsFx.data && idx) {
            couponsFx.data[idx] = updatedCoupon
          }
        }}
      />,
      { title: 'Add Coupon/Discount' },
    )
  }, [navigation, couponsFx])

  const viewCoupon = useCallback(
    (coupon: Coupon) => {
      navigation.navigate('CouponDetails', { id: coupon.id })
    },
    [navigation],
  )

  const styles = useStyles()

  const renderItem = useCallback<NonNullable<OfflineTableProps<Coupon>['renderItem']>>(
    ({ item, index }) => {
      return (
        <ExpandableRow<Coupon>
          item={item}
          index={index}
          key={`coupomList_${item.name}_${index}`}
          columns={[
            {
              process: (item) => item.name,
              widthFlex: 2,
            },
            {
              process: (item) =>
                isPercentCoupon(item) ? DecimalCalc.multiply(item.value, 100) + '%' : formatMoney(item.value),
              widthFlex: 1,
            },
            {
              process: (item) => item.timesRedeemed.toString(),
              widthFlex: 1,
            },
            {
              process: (item) => (
                <ButtonClear small onPress={() => viewCoupon(item)} style={styles.btnFlex} title="View" />
              ),
              widthFlex: 0.5,
            },
          ]}
          onRowPress={() => viewCoupon(item)}
          isLastRow={(couponsFx.data?.length ?? 0) - 1 === index}
          rowContainerStyle={item.archived ? { backgroundColor: Colors.shades[100] } : undefined}
        />
      )
    },
    [styles, viewCoupon, couponsFx.data?.length],
  )

  return (
    <AdminView style={styles.container}>
      <View>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={30}>Promotions</HeaderText>
          <Tooltip id={ToolTips.CouponsList} size={15} title="Promotions" />
        </View>

        <View style={styles.headerRow}>
          <View style={styles.buttonsWrapper}>
            <View style={styles.btnCont}>
              <DropdownButton
                small
                title="Export Coupon Redemptions CSV"
                outline
                buttons={dropdownButtons}
                data={{
                  farmId: farm.id,
                  zone: farm.timezone,
                }}
                bottomSheetProps={{ ListHeaderComponent: () => <Text>Select a date range to generate CSV</Text> }}
              />
            </View>

            <View style={styles.btnCont}>
              <Button small title="Add Coupon" onPress={addCoupon} />
            </View>
          </View>

          <ToggleButton value={showArchived} title="Show archived" onChange={setShowArchived} />
        </View>
      </View>
      <OfflineTable<Coupon>
        /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem.
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
        scrollEnabled={isWeb || !isSmallDevice}
        headerColumns={[
          { title: 'Coupon Name', widthFlex: 2 },
          { title: 'Discount Value', widthFlex: 1 },
          { title: 'Times Redeemed', widthFlex: 1 },
          { widthFlex: 0.5 },
        ]}
        renderItem={renderItem}
        data={couponsFx.data}
        isLoading={couponsFx.loading}
        ListEmptyComponent={EmptyCoupons}
        containerStyle={styles.offlineTableContainer}
      />
    </AdminView>
  )
}
export const CouponList = withAdminAuth(CouponListComp, Permission.ProductSetup)

const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, height }) => {
    return {
      container: {
        paddingHorizontal: isSmallDevice ? 10 : 30,
        paddingTop: isSmallDevice ? 10 : 30,
      },
      buttonsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%',
      },
      offlineTableContainer: {
        /** If it is not smallDevice or not extraSmallDevice, it means CouponList is enabled to have nested scroll, so the maxHeight should be set to height * 0.74 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
         * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
         */
        maxHeight: !isSmallDevice ? height * 0.74 : '100%',
      },
      btnCont: {
        marginBottom: 10,
        marginRight: 10,
      },
      headerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      },
      btnFlex: {
        flex: 0.5,
        alignItems: 'center',
      },
      horizSpacing: {
        width: 25,
      },
    }
  })

/** Dropdown buttons associtated with the default date ranges
 * - onPress will generate the csv based on the current farm id and selected range
 */
const dropdownButtons: DropdownMenuBtn<{ farmId: string; zone: string }>[] = Object.values(DatePeriods)
  .filter((period) => period !== DatePeriods.Custom)
  .map((period) => {
    return {
      title: period,
      onPress: ({ farmId, zone }) => {
        //Range will always be truthy as Custom is filtered above
        const dateRange = convertPeriodToRange(period, zone)!
        openCouponRedemptionCSV(farmId, dateRange)
      },
    }
  })
