import { isNonNullish } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { findPriceForAppMode, getUnits } from '@helpers/products'
import { CustomShare } from '@models/CustomShare'
import { Money } from '@models/Money'
import { GlobalStandard, Product, isGlobalStandard } from '@models/Product'
import { User } from '@models/User'
import { ErrorWithCode } from '@shared/Errors'
import { CustomShareBoxContents } from '@shared/types/v2/customShares'
import { adjustAllocationToMeetMinItems, allocateProductsToMaximizeValue } from './algorithm-allocation'
import { CustomShareCustomer, CustomShareProduct } from './algorithm-helpers'

/**
 * Allocation Algorithm Explanation:
 *
 * The goal of this algorithm is to allocate products to customers in a way that:
 * - Maximizes the total share value for each customer, getting as close as possible to their budget.
 * - Prioritizes products that customers prefer more highly.
 * - Promotes share diversity by ensuring a mix of different products is allocated to each customer.
 * - Meets minimum item requirements for each share.
 *
 * Process Overview:
 * 1. **First Pass - Value Maximization**:
 *    - For each customer, create a sorted list of products based on their preferences and price.
 *    - Perform round-robin allocation with diversity constraints:
 *      - **Unique Product Count**: Initially allocate one quantity per product until minimum items reached
 *      - **Allocation Percentage**: Ensure no single product exceeds set percentage of total value/quantity
 *    - After exhausting preferred products (rating >= 3), remove diversity constraints to fill remaining budget
 *
 * 2. **Second Pass - Meeting Minimum Items**:
 *    - For customers who haven't met their minimum item requirement:
 *      - Remove expensive products (starting with highest total cost items)
 *      - Replace with cheaper alternatives the customer doesn't dislike (rating >= 2)
 *      - Continue until minimum item count is reached or no more adjustments possible
 *
 * 3. **Third Pass - Final Value Maximization**:
 *    - With minimum items met, perform another round of allocation
 *    - Focus on maximizing remaining budget without diversity constraints
 *    - Add any remaining products that fit within budget constraints
 *
 * The algorithm prioritizes customer preferences while ensuring share diversity and minimum item requirements.
 * If trade-offs are necessary, it will sacrifice expensive items to meet minimum item counts, then attempt
 * to maximize value with remaining budget.
 */

/** The main function that loops through products and assigns them to customers taking into account preferences and ranking */
export function allocateProductsToCustomers(products: CustomShareProduct[], customers: CustomShareCustomer[]): void {
  const allProductIds = products.map((p) => p.productId)

  // Ensure each customer has preferences for all products
  customers.forEach((customer) => customer.addNeutralPreferences(allProductIds))

  // First pass: Maximize value of the share
  allocateProductsToMaximizeValue(products, customers)

  // // Second pass: Adjust allocations to meet minNumItems
  for (const customer of customers) {
    const allocatedItemsCount = Object.values(customer.allocatedProducts).reduce((sum, qty) => sum + qty, 0)

    if (allocatedItemsCount < customer.minNumItems) {
      // Attempt to adjust the customer's allocation
      adjustAllocationToMeetMinItems(customer, products)
    }
  }

  // // Third pass: Now that minNumItems are met we can fit as much product as we have left into the box
  allocateProductsToMaximizeValue(products, customers)
}

function buildCustomShareProds(prods: Product[]) {
  return prods
    .map((prod) => {
      // We only allow global standard products for custom shares
      if (!isGlobalStandard(prod)) return undefined

      const unit = getUnits(prod, { isWholesale: false })[0]
      if (!unit) return undefined
      const unitPrice = findPriceForAppMode(unit.prices, false)?.amount
      // For global stock items each purchased quantity will take the multiplier number of units, so for the algorithm we
      // simplify this so that it can treat each unit as one unit of inventory
      const quantity = Math.floor(prod.quantity / unit.multiplier)
      // If any required fields are missing we should not continue
      if (!unit || !unitPrice || !quantity) return undefined
      // Don't include products that have a price of $0, or we will add the entire inventory to the customers
      // TODO: If maxPerOrder is set then it would be OK to include it, if we do this validations should no longer show this as a warning
      if (MoneyCalc.isZero(unitPrice)) return undefined

      const availableLocations = prod.distributions.map((d) => d.location.id)
      return new CustomShareProduct(
        prod.id,
        prod.name,
        prod.description,
        unitPrice,
        quantity,
        prod.maxPerOrder,
        availableLocations,
      )
    })
    .filter(isNonNullish)
}

/**
 * Builds a set of custom share boxes for a custom share and a set of customers
 * @param customShare The custom share we are building boxes from
 * @param prods The list of products to build boxes from
 * @param users The users to build boxes for and the value of their box
 */
export function buildCustomShareBoxes(
  customShare: Pick<CustomShare, 'rankedProducts'>,
  prods: GlobalStandard[],
  users: {
    userId: string
    value: Money
    preferences?: { [productId: string]: number }
    minNumItems: number | undefined
    locationId: string | undefined
  }[],
): Record<User['id'], CustomShareBoxContents> {
  // Build the farmerRanking object that will be used as a secondary set of preferences to the customers ranking
  const farmerRanking = Object.fromEntries(customShare.rankedProducts.map((rank) => [rank.product.id, rank.score]))

  // The more customers we do at once the better spread out the products will be.
  const customers = users.map((u) => {
    // The combined ranking will prefer the customers rankings, but fallback to farmer ranking for any products the user doesn't have ranking for
    const combinedRanking = { ...farmerRanking, ...u.preferences }
    return new CustomShareCustomer(u.userId, u.value, combinedRanking, u.minNumItems, u.locationId)
  })

  const customShareProds = buildCustomShareProds(prods)

  allocateProductsToCustomers(customShareProds, customers)

  const customerProdMap = customers.map((customer) => {
    const products = Object.entries(customer.allocatedProducts).map(([prodId, qty]) => {
      const product = prods.find((p) => p.id === prodId)
      if (!product)
        throw new ErrorWithCode({
          code: 'PRODUCT_NOT_FOUND',
          devMsg: 'Product not found in custom products list',
          uiMsg: 'Unable to build custom share box.',
        })
      return { product: { id: product.id }, quantity: qty }
    })
    return [customer.customerId, products]
  })

  return Object.fromEntries(customerProdMap)
}
