import { hasRetailOptions, hasWholesaleOptions } from '@helpers/products'
import { Distribution } from '@models/Distribution'
import {
  AddonShare,
  BaseProduct,
  DefaultCatalog,
  DigitalStandard,
  EbtEligibility,
  FarmBalance,
  GlobalStandard,
  hasUnits,
  isGlobalUnit,
  isPayInFull,
  isPhysical,
  isShare,
  isStandard,
  isStockUnit,
  PhysicalBase,
  PrimaryShare,
  Product,
  ProductType,
  Share,
  ShareCommon,
  Standard,
  StandardCommon,
  StockDependent,
  StockType,
  UnitBase,
  UnitProductCommon,
  UnitStandard,
} from '@models/Product'
import { Frequency, isWiderFreq } from '@models/Schedule'
import { isProdErr, ProductError } from '@shared/errors/product'
import * as Yup from 'yup'

import { deepClone, isObject, isTruthy } from '@helpers/helpers'
import { isBefore } from '@helpers/time'
import { isValidSlug } from '@helpers/urlSafeSlug'
import { YUP_MONEY_OPTIONAL, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { FeeType } from '@models/ProductFee'
import { ErrorWithCode } from '@shared/Errors'
import { MoneyCalc } from '../money'
import { getDistributionPickups } from '../order'
import { findPriceForAppMode, validateScheduleNDateConstr } from '../products'
import { intersect, keys, PartialPick, Undefine, values } from '../typescript'
import { ProductFeeBuilder } from './ProductFeeBuilder'
import { unitPriceSchema } from './UnitPriceSchema'
import { validateMoney } from './validators/validateMoney'

/** ProductOptions includes all the properties of all subtypes of Product */
export type ProductFields = Partial<
  BaseProduct & PhysicalBase & StandardCommon & UnitProductCommon & StockDependent & ShareCommon
>

/** This data must exist in yup context whenever a product is validated, to ensure all schema tests get the required data.
 * isProductForm is used for any yup validation that should behave differently on the product form vs buildProduct validator */
export type ProductSchemaContext = Pick<Required<ProductFields>, 'type' | 'unitStock' | 'defaultCatalog'> & {
  isProductForm: boolean
}

/** Produces schema context object from validation data */
export const getSchemaContextFromFields = (data: ProductFields) => ({
  isProductForm: false,
  type: data.type!,
  defaultCatalog: data.defaultCatalog!,
  unitStock: data.unitStock!,
})

export function buildProduct<T extends Product>(obj: T): T
export function buildProduct(obj: Omit<ProductFields, 'type'> & { type: ProductType.PrimaryShare }): PrimaryShare
export function buildProduct(obj: Omit<ProductFields, 'type'> & { type: ProductType.AddonShare }): AddonShare
export function buildProduct(obj: Omit<ProductFields, 'type'> & { type: ProductType.Digital }): DigitalStandard
export function buildProduct(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock: true },
): UnitStandard
export function buildProduct(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock?: false | undefined },
): GlobalStandard
/** buildProduct receives the properties of a Product and returns a subtype of Product if data adheres to a valid subtype.
 * If data doesn't pass validations, will throw human readable errors.
 */
export function buildProduct(obj: ProductFields): Product {
  const { type, unitStock } = obj
  if (!type) throw new Error('missing type')

  let result: Product

  if (type === ProductType.PrimaryShare || type === ProductType.AddonShare) {
    const baseProd = getBaseProd(obj)
    const basePhysical = getBasePhysical(obj)
    const shareCommon = getShareCommon(obj)
    const share: Share = {
      ...baseProd,
      ...basePhysical,
      ...shareCommon,
      type,
    }

    result = share
  } else if (type === ProductType.Standard) {
    const baseProd = getBaseProd(obj)
    const basePhysical = getBasePhysical(obj)

    if (unitStock) {
      const unitProdCommon = getUnitCommon(obj)
      const standardCommon = getStandardCommon(obj)
      const stockDependent = getStockDep({ ...obj, type: ProductType.Standard, unitStock: true })
      const standardProps = intersect(unitProdCommon, stockDependent)
      const unitStandard: UnitStandard = {
        ...baseProd,
        ...basePhysical,
        ...standardCommon,
        ...standardProps,
        type,
      }
      result = unitStandard
    } else {
      const unitProdCommon = getUnitCommon(obj)
      const standardCommon = getStandardCommon(obj)
      const stockDependent = getStockDep({ ...obj, type: ProductType.Standard, unitStock: false })
      const standardProps = intersect(unitProdCommon, stockDependent)
      const globalStandard: GlobalStandard = {
        ...baseProd,
        ...basePhysical,
        ...standardCommon,
        ...standardProps,
        type,
      }
      result = globalStandard
    }
  } else if (type === ProductType.Digital || type === ProductType.FarmBalance) {
    const baseProd = getBaseProd(obj)
    const digitalCommon = getDigitalCommon(obj)
    const stockDependent = getStockDep({ ...obj, type })
    const digitalProps = intersect(digitalCommon, stockDependent)
    const digitalStandard: DigitalStandard = {
      ...baseProd,
      ...digitalProps,
      type: ProductType.Digital,
    }
    const farmBalance: FarmBalance = {
      ...baseProd,
      ...digitalProps,
      type: ProductType.FarmBalance,
    }
    result = type === ProductType.Digital ? digitalStandard : farmBalance
  } else {
    throw new Error('Not implemented')
  }
  return result
}

const getBaseProd = ({
  category,
  description,
  farm,
  images,
  longDescription,
  name,
  type,
  id,
  isHidden,
  isPrivate,
  urlSafeSlug,
  hideFromShop,
  ...rest
}: ProductFields): BaseProduct => {
  if (!category) throw new Error('Missing category')
  if (!description) throw new Error('Missing description')
  if (!farm) throw new Error('Missing farm')
  if (!images?.length) throw new Error('Missing images')
  if (!longDescription || !longDescription.length) throw new Error('Missing long description')
  if (!name || !name.length) throw new Error('Missing name')
  if (!type) throw new Error('Missing type')
  if (typeof isHidden !== 'boolean') throw new Error('isHidden should not be undefined')
  if (typeof isPrivate !== 'boolean') throw new Error('isPrivate should be a boolean')
  if (!urlSafeSlug || !isValidSlug(urlSafeSlug))
    throw new ErrorWithCode({
      code: 'validation-error',
      devMsg: 'urlSafeSlug does not comply with the basic slug validation requirements in isValidSlug()',
      uiMsg: 'Name is invalid, name must contain at least 3 consecutive letters',
    })
  if (hideFromShop && !rest.csa?.length) {
    throw new Error('If "Only buy on CSA" is active, you must select at least one CSA')
  }
  const taxesAndFees = validateTaxesAndFees(rest.taxesAndFees)

  return {
    category,
    description,
    farm,
    images,
    longDescription,
    name,
    type,
    isHidden,
    isPrivate,
    urlSafeSlug,
    taxesAndFees,
    hideFromShop,
    id: id as string,
    csa: rest.csa,
    hashtags: rest.hashtags,
    isDraft: rest.isDraft,
    isFeatured: rest.isFeatured,
    purchasedQuantity: rest.purchasedQuantity,
  }
}

/** This function is used to validate taxesAndFees object for all products */
const validateTaxesAndFees = (taxesAndFees: ProductFields['taxesAndFees']): BaseProduct['taxesAndFees'] => {
  const localTaxesAndFees = deepClone(taxesAndFees)

  if (!localTaxesAndFees || !isObject(localTaxesAndFees)) throw new Error('Missing taxes and fees')
  if (typeof localTaxesAndFees.isTaxExempt !== 'boolean') throw new Error('isTaxExempt should be a boolean')
  if (localTaxesAndFees.isTaxExempt && localTaxesAndFees.fees?.some((fee) => fee.type === FeeType.Tax))
    throw new Error('Tax Exempt products cannot have taxes')

  // Each fee and tax must be validated
  if (localTaxesAndFees.fees) {
    localTaxesAndFees.fees.forEach((fee) => {
      // We need to create a local product fee builder when we are using a builder inside another builder
      const productFeeBuilder = new ProductFeeBuilder()
      productFeeBuilder.validate(fee)
    })
  }

  return localTaxesAndFees
}

const getBasePhysical = (product: ProductFields): PhysicalBase => {
  const { distributions, producer, defaultCatalog, ...rest } = product
  if (!distributions) throw new Error('Missing distributions')

  // Here we validate that retail products contain retail schedules and options, wholesale validation is done only for standard products in getStandardCommon
  if (!defaultCatalog || defaultCatalog !== DefaultCatalog.Wholesale) {
    if (!hasRetailOptions(product as Product)) throw new Error('Retail product has no retail options')
  }

  distributions.forEach((dist) => {
    validateProdDistro(product as Product, dist)
  })

  return {
    distributions,
    distributionConstraints: rest.distributionConstraints,
    productionMethod: rest.productionMethod,
    certification: rest.certification,
    producer,
  }
}

/** Checks whether the distro is compatible with the product.
 * - Considers any distro constraint inside the product, for the distro being checked
 * - Checks that both the dateRange and frequency constraints are valid
 * - Checks the number of pickups fits with the distro and constraint
 * - Throws human readable error messages */
export const validateProdDistro = (prod: Product, dist: Distribution): void => {
  if (isPhysical(prod)) {
    const constraint = prod.distributionConstraints?.find((constraint) => dist.id === constraint.id)

    //Validate date constraint
    if (constraint?.dateRange) {
      try {
        validateScheduleNDateConstr(constraint?.dateRange, dist.schedule)
      } catch (err) {
        // Here we're simply re-throwing the error, but with the full data, for the message to include more details
        if (isProdErr(err)) {
          switch (err.data.code) {
            case 'NeedsEndDate':
              throw new ProductError({ code: 'NeedsEndDate', dist, prod })
            case 'InvalidDateRangeConstraint':
              throw new ProductError({ code: 'InvalidDateRangeConstraint', constraint, dist, prod })
            case 'InvalidSeason':
              throw new ProductError({ code: 'InvalidSeason', dist, prod })
            case 'InvalidConstraintStartDate':
              throw new ProductError({ code: 'InvalidConstraintStartDate', dist, constraint, prod })
            case 'InvalidConstraintEndDate':
              throw new ProductError({ code: 'InvalidConstraintEndDate', dist, constraint, prod })
          }
        } else throw err
      }
    }

    //Validate frequency constraint
    if (constraint?.frequency)
      try {
        validateFreqConstraint(
          constraint.frequency,
          dist.schedule.frequency,
          /** Should use strict true here because this validation is for db */ true,
        )
      } catch (err) {
        // re-throw with the complete data on the error constructor
        throw new ProductError({ code: 'InvalidFrequency', prod, dist, constraint })
      }
  }

  validateConstraintNumberPickups(dist, prod)
}

const getShareCommon = ({
  csa,
  numberPickups,
  paymentSchedules,
  quantity,
  shareInfo,
  sku,
  vacationWeeks,
  ...rest
}: ProductFields): ShareCommon => {
  if (!csa) throw new Error('Missing csa')

  if (!numberPickups) throw new Error('Missing numberPickups')
  if (typeof numberPickups !== 'number') throw new Error('numberPickups must be a number')
  if (!Number.isInteger(numberPickups)) throw new Error('Number of pickups must be a whole number')
  if (numberPickups === 0) throw new Error('You cannot have a share with zero pickups')

  if (!paymentSchedules || !paymentSchedules.length) throw new Error('Missing payment schedules')
  validatePaymentSchedules(paymentSchedules, { ...rest, numberPickups } as PartialPick<
    Share,
    'type' | 'numberPickups' | 'distributions'
  >)

  if (quantity === undefined) throw new Error('Share must have quantity')
  if (!shareInfo) throw new Error('Missing shareInfo')
  if (vacationWeeks === undefined) throw new Error('Missing vacationWeeks')
  return {
    csa,
    numberPickups,
    paymentSchedules,
    quantity,
    shareInfo,
    sku,
    vacationWeeks,
    cancellationPolicy: rest.cancellationPolicy,
    isChild: rest.isChild,
    templateProduct: rest.templateProduct,
    waitlist: rest.waitlist,
  }
}

type validatePaymentSchedulesOpts = {
  /** If true, the payment schedule's last payment date will be allowed even if it's before the first pickup of any schedule */
  allowLastPymtDateEarly: boolean
}

/** Checks that each paymentSchedule has compatible payment-end-dates for each distribution-schedule. */
export const validatePaymentSchedules = (
  paySchedules: Share['paymentSchedules'],
  rest: PartialPick<Share, 'type' | 'numberPickups' | 'distributions'>,
  opts: validatePaymentSchedulesOpts = { allowLastPymtDateEarly: true },
) => {
  paySchedules.forEach((ps) => {
    if (isPayInFull(ps)) return

    //Check deposit and total price. Deposit should be less thant Total Price. Otherwise, throw error here.
    if (MoneyCalc.isGreaterThan(ps.deposit, ps.amount))
      throw new Error(`Deposit in payment schedule "${ps.frequency}" must be less than the Total Price.`)

    if (!ps.paymentDates.endDate)
      throw new Error(`Payment schedule "${ps.frequency}" must have a final installment date.`)

    // This section is validating the first and last payment dates in relation to the pickups for each schedule.
    rest.distributions?.forEach((sch) => {
      // The pickups should be calculated from the start of the schedule (pre-season date), so we can compare the pymt dates against the original pickup dates, in the case the product is old data from past seasons
      const pickups = getDistributionPickups(
        sch,
        { ...rest, distributions: rest.distributions ?? [] },
        { excludeHiddenDistros: true, excludeClosedDistros: true },
      )
      if (
        pickups.length &&
        !opts.allowLastPymtDateEarly &&
        isBefore(ps.paymentDates.endDate, pickups[0], { granularity: 'day', zone: sch.location.timezone })
      )
        throw new ProductError({ code: 'LastPaymentDateTooEarly', dist: sch, frequency: ps.frequency })

      // Scenario "last payment date too late": We don't need to make sure the endDate is before the last pickup. If the endDate happened after the last pickup, invoices will be unaffected. I.e. the last payment date would be ignored because it is not constraining the invoices to end earlier than the last pickup, which is the sole purpose of the last payment date.
    })
  })
}

const standardCommonSchema: Yup.ObjectSchema<StandardCommon, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>().shape({
    commodity: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string().required(),
      })
      .default(undefined), // default(undefined) is required so this value will be used when the field is not defined
    costOfProduction: YUP_MONEY_OPTIONAL('Cost of production', { allowZero: true }),
    disableBuyInFuture: Yup.boolean(),
    variety: Yup.string(),
    defaultCatalog: Yup.mixed<DefaultCatalog>()
      .oneOf(Object.values(DefaultCatalog))
      .test('only-standard', 'Only standard products can be wholesale', function (value) {
        if (!value || value === DefaultCatalog.Retail) return true

        const { type } = (this.options.context as ProductSchemaContext) ?? {}

        return isStandard({ type })
      }),
    ebtEligibility: Yup.mixed<Standard['ebtEligibility']>()
      .required()
      .test('valid ebt eligibility', 'The EBT eligibility data is invalid', (val) => {
        return isValidEbtEligibility(val)
      })
      .when('defaultCatalog', {
        is: (val: DefaultCatalog) => val === DefaultCatalog.Wholesale,
        then: (s) =>
          s.test('wholesale-not-ebt', "Wholesale products can't be eligible for EBT", function (val) {
            return val === EbtEligibility.NotEbtEligible
          }),
      }),
    minPickups: Yup.number()
      .when('defaultCatalog', {
        is: (val: DefaultCatalog) => val === DefaultCatalog.Wholesale,
        then: (s) =>
          s.test('wholesale-no-min-pickups', "Wholesale products can't have minPickups", function (val) {
            return val === undefined
          }),
      })
      .test(
        'buy-in-future-not-disabled',
        'If "Disable buy in future" is active, there must be no minimum pickups',
        function (val, context) {
          if (context?.parent?.disableBuyInFuture) {
            return val === undefined || val === 0
          }
          return true
        },
      ),
    maxPerOrder: Yup.number().test(
      'min-pickups-restriction',
      'Minimum number of pickups cannot be more than the max per order',
      function (val, context) {
        if (context?.parent?.minPickups && val !== undefined) {
          return context?.parent?.minPickups <= val
        }
        return true
      },
    ),
  })

/** Extracts the properties common to standard products. Assumes the data is for a standard subtype */
const getStandardCommon = (data: ProductFields): StandardCommon => {
  const { defaultCatalog } = data

  if (defaultCatalog) {
    if (defaultCatalog !== DefaultCatalog.Retail) {
      if (!hasWholesaleOptions(data as Standard)) throw new Error('Wholesale product has no wholesale options')
    }
  }

  return standardCommonSchema.validateSync(data, {
    context: getSchemaContextFromFields(data),
  })
}

/** Fields common to product types that use units, for example standard and digital types with units */
const getUnitCommon = ({ baseUnit, unitStock, ...rest }: ProductFields): UnitProductCommon => {
  if (!baseUnit) throw new Error('Missing baseUnit')
  if (unitStock === undefined) throw new Error('Missing unitStock field')

  return {
    baseUnit,
    unitStock,
    quantity: rest.quantity,
    unitSkuPrefix: rest.unitSkuPrefix,
    pricePerUnit: rest.pricePerUnit,
  }
}

function getStockDep(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Digital | ProductType.FarmBalance },
): StockDependent<StockType.Global>
function getStockDep(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock: true },
): StockDependent<StockType.Unit>
function getStockDep(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock?: false | undefined },
): StockDependent<StockType.Global>
function getStockDep(data: ProductFields): StockDependent {
  const { unitStock, units, pricePerUnit, quantity } = data

  if (!units) throw new Error('Missing units')

  buyingOptionsArraySchema.validateSync(units, {
    strict: undefined, // DO NOT ENABLE STRICT: TRUE. And do not remove this comment. If true, the yup .transform won't run and the money validators receive the price as an object instead of a number value.
    context: getSchemaContextFromFields(data),
  })

  if (unitStock) {
    const unitStockUnits: UnitBase<StockType.Unit>[] = units
      .map((u) => {
        if (isGlobalUnit(u)) throw new Error('UnitStock unit should have unit quantity')
        const unitQty = u.quantity
        if (typeof unitQty !== 'number' || unitQty < 0) throw new Error('Quantity must be a positive number')
        if (typeof u.multiplier !== 'number' || u.multiplier <= 0)
          throw new Error('Multiplier must be a positive number')
        if (!u.prices || !u.prices.length) throw new Error('Buying options must have a price')

        return u
      })
      .filter(isStockUnit)

    if (!pricePerUnit) throw new Error('UnitStandard products should have a price per unit')
    validateMoney(pricePerUnit)

    if (quantity !== undefined) throw new Error("UnitStandard products shouldn't have global quantity")

    const stockDepUnit: StockDependent<StockType.Unit> = {
      units: unitStockUnits,
      pricePerUnit,
      unitStock: true,
    }
    return stockDepUnit
  } else {
    const globStockUnits: UnitBase<StockType.Global>[] = units
      .map((u) => {
        if (isStockUnit(u)) throw new Error("GlobalStandard products unit shouldn't have unit quantity")
        if (typeof u.multiplier !== 'number' || u.multiplier <= 0)
          throw new Error('Multiplier must be a positive number')
        if (!u.prices || !u.prices.length) throw new Error('Buying options must have a price')

        return u
      })
      .filter(isGlobalUnit)

    if (pricePerUnit !== undefined) throw new Error("GlobalStandard products shouldn't have a price per unit")
    if (typeof quantity !== 'number') throw new Error('GlobalStandard products should have global quantity')
    if (quantity < 0) throw new Error('Quantity must be a positive number')

    const stockDepUnit: StockDependent<StockType.Global> = {
      units: globStockUnits,
      pricePerUnit,
      unitStock: false,
      quantity,
    }
    return stockDepUnit
  }
}

export const pricesSchema = Yup.array(unitPriceSchema)
  .required()
  .min(1, 'should have at least one unit price')
  .max(
    2,
    'There cannot be more than two prices for a buying option because the maximum is one for retail and one for wholesale',
  )
  .test(
    "Can't have two prices for the same catalog in the BO",
    'Each price inside a buying option must be for a different catalog',
    function (prices) {
      if (prices.length === 1) return true

      // If there's more than one price, check that each does not belong to the same catalog
      const counts = prices
        // get the catalog of each price
        .map((pr) => pr.priceGroup?.type === 'default-catalog' && pr.priceGroup.catalog)
        .filter(isTruthy)
        // count the number of times each catalog appears
        .reduce((agg, curr) => {
          if (keys(agg).includes(curr)) agg[curr] += 1
          else agg[curr] = 1

          return agg
        }, {} as { [key: string]: number })

      return !values(counts).some((count) => count > 1)
    },
  )

/** Schema for a single buying option object.
 * - This can't be used outside the product model because it depends on the product schema context which assumes this has access to the rest of the product fields.
 */
const buyingOptionSchema = Yup.object<ProductSchemaContext>().shape({
  id: Yup.string().required(),
  name: Yup.string().required('Name is required'),
  sku: Yup.string(),
  quantity: Yup.number().when('$unitStock', {
    is: true,
    then: () => YUP_WHOLE_NUMBER_REAL('Quantity', { allowDecimal: true, allowZero: true }),
    otherwise: () =>
      Yup.mixed().test(
        'Global stock quantity',
        'Global stock products should not have a unit quantity',
        function (value) {
          const ctx = this.options.context
          if (!ctx) return this.createError({ message: "Can't access form context" })

          // We do not want to check this for validation in the product form as it will be stripped on creation
          if (ctx.isProductForm) return true
          // Check that global stock is undefined, and return true only if it is
          return value === undefined
        },
      ),
  }),
  multiplier: Yup.number()
    .test('Is positive', 'Multiplier must be greater than zero', (val) => typeof val === 'number' && val > 0)
    .required('Multiplier is required')
    .typeError('Multiplier must be a number'),
  prices: pricesSchema,
})

/** This schema validates the buying options array for a product, considering the product type */
export const buyingOptionsArraySchema = Yup.array<ProductSchemaContext, UnitBase>().when('type', {
  is: (type: ProductType) => hasUnits({ type }),
  then: (schema) =>
    schema
      .required()
      .min(1)
      .of(buyingOptionSchema)
      .test(
        'Wholesale-retail one price each',
        'If the product is wholesale-retail there must be at least one wholesale price and one retail price throughout the buying options',
        function (bos) {
          if (!bos) return true

          const ctx = this.options.context
          if (!ctx) {
            return this.createError({ message: "Can't access form context" })
          }
          const { defaultCatalog } = ctx
          if (defaultCatalog !== DefaultCatalog.WholesaleRetail) return true

          const allPrices = bos.flatMap((bo) => bo.prices)

          const wholesalePrice = findPriceForAppMode(allPrices, true)
          const retailPrice = findPriceForAppMode(allPrices, false)

          return !!wholesalePrice && !!retailPrice
        },
      )
      .test('Wholesale only', '', function (bos) {
        if (!bos) return true

        const ctx = this.options.context
        if (!ctx) {
          return this.createError({ message: "Can't access form context" })
        }
        const { defaultCatalog } = ctx
        if (defaultCatalog !== DefaultCatalog.Wholesale) return true

        for (const bo of bos) {
          if (bo.prices.length > 1)
            return this.createError({
              message: 'The buying options of a wholesale-only product must have a single wholesale price',
            })

          if (!findPriceForAppMode(bo.prices, true)) {
            return this.createError({
              message: 'A wholesale-only product can only have wholesale prices',
            })
          }
        }
        return true
      })
      .test('Retail only', '', function (bos) {
        if (!bos) return true

        const ctx = this.options.context
        if (!ctx) {
          return this.createError({ message: "Can't access form context" })
        }
        const { defaultCatalog } = ctx
        if (defaultCatalog !== DefaultCatalog.Retail) return true

        for (const bo of bos) {
          if (bo.prices.length > 1)
            return this.createError({
              message: 'The buying options of a retail-only product must have a single retail price',
            })

          if (!findPriceForAppMode(bo.prices, false)) {
            return this.createError({
              message: 'A retail-only product can only have retail prices',
            })
          }
        }
        return true
      }),
})

const getDigitalCommon = ({
  baseUnit,
  unitStock,
  certification,
  commodity,
  costOfProduction,
  distributionConstraints,
  distributions,
  producer,
  productionMethod,
  variety,
  pricePerUnit,
  disableBuyInFuture,
  ...rest
}: ProductFields): Undefine<PhysicalBase> & Undefine<UnitProductCommon, 'pricePerUnit'> => {
  if (!baseUnit) throw new Error('Missing baseUnit')
  if (unitStock) throw new Error('Digital products should be unitStock false')
  if (certification) throw new Error("Digital products shouldn't have field 'certification'")
  if (commodity) throw new Error("Digital products shouldn't have field 'commodity'")
  if (costOfProduction) throw new Error("Digital products shouldn't have field 'costOfProduction'")
  if (distributionConstraints) throw new Error("Digital products shouldn't have distributionConstraints")
  if (distributions) throw new Error("Digital products shouldn't have field 'distributions'")
  if (typeof producer === 'string') throw new Error("Digital products shouldn't have field 'producer'")

  if (typeof productionMethod === 'string') throw new Error("Digital products shouldn't have field 'productionMethod'")
  if (typeof variety === 'string') throw new Error("Digital products shouldn't have field 'variety'")
  if (pricePerUnit !== undefined) throw new Error("Digital products shouldn't have field 'pricePerUnit'")
  if (disableBuyInFuture !== undefined) throw new Error("Digital products shouldn't have a 'disableBuyInFuture' field")
  return {
    baseUnit,
    unitStock: false,
    certification,
    distributionConstraints,
    distributions,
    pricePerUnit,
    producer,
    productionMethod,
    quantity: rest.quantity,
    unitSkuPrefix: rest.unitSkuPrefix,
  }
}

/** Will verify whether a share's number of pickups can fit within the effective pickup window of a product + distro combo,
 * considering any distro constraints.
 */
export const validateConstraintNumberPickups = (dist: Distribution, product: Product): void => {
  // For all non-shares we don't need to validate number of pickups
  if (!isShare(product)) return

  /** Get all pickups from before the start of the distribution, considering distro constraints
  This method of obtaining the maximum possible pickups ensures the calculation remains the same even if the share is in the past */
  const maxPossiblePickups = getDistributionPickups(dist, product).length

  // Make sure that we have at least as many pickups as we are expecting available in the date range
  if (maxPossiblePickups < product.numberPickups) {
    throw new ProductError({
      code: 'MissingPickups',
      dist,
      distPickups: maxPossiblePickups,
      numberPickups: product.numberPickups,
      prod: product,
    })
  }
}

/**
 * Checks whether a frequency constraint can be applied to a distribution frequency.
 * @param freqConstrain the frequency constraint to be applied. It must be narrower (or equal if non strict) than the distribution frequency.
 * @param distroFreq the normal frequency of the distribution
 * @param strict if true, constraint must be narrower than distro freq. Else, constraint can be equal.
 * @returns void. Throws an error if the constraint is invalid.
 */
export const validateFreqConstraint = (freqConstrain: Frequency, distroFreq: Frequency, strict = true): void => {
  if (!isWiderFreq(freqConstrain, distroFreq, strict)) throw new ProductError({ code: 'InvalidFrequency' })
}

/** EBT ELIGIBILITY */
/**
 * Checks if the provided EbtEligibility value is valid.
 * @param ebtEligibility - The value to check.
 * @returns A boolean indicating whether the provided value is a valid EbtEligibility.
 */
export const isValidEbtEligibility = (ebtEligibility: any): ebtEligibility is EbtEligibility => {
  return Object.values(EbtEligibility).some((v) => v === ebtEligibility)
}
