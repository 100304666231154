import { AdminProductsParamList, ProductDetailsRoutes } from '@/admin/navigation/types'
import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import { PartialPick } from '@helpers/typescript'
import { Farm } from '@models/Farm'
import { Product, ProductType } from '@models/Product'
import { RouteProp } from '@react-navigation/native'
import { ObjectSchema } from 'yup'
import { AdvancedPricingForm } from '../AdvancedPricing'
import { AdvancedPricing } from '../AdvancedPricing-helpers'
import { FormikBasicInformation } from '../BasicInformation/BasicInformation'
import { BasicInfoForm } from '../BasicInformation/BasicInformation.helpers'
import { FormikSchedules, SchedulesFormType } from '../SchedulesComponent'
import { BillingFormType, FormikShareBilling } from '../ShareBillingOptions'
import { FormikSharePricing, SharePricingForm } from '../SharePricing'
import { FormikUnits, UnitsForm } from '../UnitsComponent'
import { FormikTypeInformation, ProductTypeForm } from './ProductTypeInfo'

export const productDetailsFormComponents = [
  FormikTypeInformation,
  FormikBasicInformation,
  FormikSchedules,
  AdvancedPricing,
  FormikSharePricing,
  FormikShareBilling,
  FormikUnits,
]

/** The combined fields of the form components that make up the entire product form */
export type ProductDetailsForm = ProductTypeForm &
  BasicInfoForm &
  SchedulesFormType &
  AdvancedPricingForm &
  UnitsForm &
  SharePricingForm &
  BillingFormType

/** Combines validation and initialValues from the imported components helpers */
export const productFormSchema = () =>
  productDetailsFormComponents
    .map((c) => c.validator as ObjectSchema<ProductDetailsForm, ProductSchemaContext>)
    .reduce((a, b) => b.concat(a))

/** Generates the complete product data from the entire details form state */
export const buildProductFromFormik = async (values: ProductDetailsForm): Promise<Partial<Product>> => {
  const request = productDetailsFormComponents.map((comp) => comp.fromFormik(values))

  const result = await Promise.all(request)

  return result.reduce((a, b) => ({ ...a, ...b } as Partial<Product>))
}

/** Used when generating the initial form values for formik, based on the product and the screen params */
export const buildInitialValues = ({
  product,
  farm,
  params,
  name,
}: {
  /** the product being added or edited. If being added, the type must be defined */
  product: PartialPick<Product, 'type'>
  /** farm is required only for the initial values of a new farm balance */
  farm?: Pick<Farm, 'media' | 'name'>
} & Pick<RouteProp<AdminProductsParamList, ProductDetailsRoutes>, 'params' | 'name'>) => {
  let formValues = productDetailsFormComponents
    .map((comp) => comp.toFormik(product, { params, name }))
    .reduce((a, b) => ({ ...a, ...b }), {}) as ProductDetailsForm

  if (name === 'AddProduct' && !!farm && product.type === ProductType.FarmBalance) {
    formValues = {
      ...formValues,
      name: 'Farm Credit',
      media: farm.media,
      shortDescription: 'Farm credit',
      longDescription: `Buy credit from ${farm.name}`,
      category: 'Farm Credit',
      baseUnit: 'USD',
      globalQuantity: 1000000,
    }
  }
  return formValues
}

/** Generates the expected schema context from the form values */
export const getSchemaContext = (
  formValues: Pick<ProductDetailsForm, 'type' | 'unitStock' | 'catalog'>,
): ProductSchemaContext => ({
  isProductForm: true,
  type: formValues.type,
  unitStock: formValues.unitStock,
  defaultCatalog: formValues.catalog,
})
