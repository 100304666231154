import { AdminView } from '@/admin/components/AdminView'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmSelector } from '@/redux/selectors'
import { loadTemplateProductsByFarmId } from '@api/TemplateProduct'
import { ToolTips } from '@components'
import { Button, HeaderText, Tooltip } from '@elements'
import { TemplateProduct } from '@models/Product'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { ShareTemplateRow } from '@screens/ShareTemplates/components/ShareTemplateRow'
import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

function ShareTemplatesScreen() {
  const { isSmallDevice } = useDeviceSize()
  const farm = useSelector(adminFarmSelector)
  const { data, refresh, loading } = useApiFx(loadTemplateProductsByFarmId, [farm.id])

  const navigation = useNavigation<DrawerNavigationProp<AdminDrawerParamList, 'Products'>>()

  const renderItem: NonNullable<OfflineTableProps<TemplateProduct>['renderItem']> = ({
    item: templateProduct,
    index,
  }) => {
    return <ShareTemplateRow refreshFn={refresh} templateProduct={templateProduct} index={index} />
  }

  const styles = useStyles()

  return (
    <AdminView hideFooter style={styles.container}>
      <View style={styles.header}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={isSmallDevice ? 22 : 30}>Share Templates</HeaderText>
          <Tooltip size={15} id={ToolTips.SHARE_TEMPLATES} />
        </View>

        <Button
          style={styles.addButton}
          small
          title="Add new template"
          onPress={() => {
            navigation.navigate('Products', { screen: 'AddTemplate', params: { goBack: 'ShareTemplates' } })
          }}
        />
      </View>
      <OfflineTable<TemplateProduct>
        isLoading={loading}
        data={data}
        headerColumns={[
          { widthFlex: 0.25 },
          { widthFlex: 0.05 },
          { title: 'Template Product Name', widthFlex: 2 },
          { title: 'Type', widthFlex: 1.5 },
          { widthFlex: 0.5 },
          { widthFlex: 0.5 },
        ]}
        renderItem={renderItem}
        minWidth={600}
        scrollEnabled={isWeb || !isSmallDevice}
        containerStyle={styles.offlineTableContainer}
      />
    </AdminView>
  )
}

const useStyles = () => {
  return useLayoutFnStyles(({ isSmallDevice, height, bottom }) => ({
    container: {
      paddingHorizontal: isSmallDevice ? 10 : 30,
      paddingTop: isSmallDevice ? 10 : 30,
      // Adds safe area padding at the bottom, with a minimum of 10 if no safe area exists
      paddingBottom: bottom || 10,
    },
    offlineTableContainer: {
      /** If it is not smallDevice or not extraSmallDevice, it means templates table is enabled to have nested scroll, so the maxHeight should be set to height * 0.78 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
       * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
       */
      maxHeight: !isSmallDevice ? height * 0.78 : '100%',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    addButton: {
      marginRight: 20,
    },
  }))
}

export default withAdminAuth(ShareTemplatesScreen)
