import {
  CoordString,
  getCoordString,
  hasSignificantCoordDifference,
  lengthenCoord,
  shortenCoordinate,
  validCoords,
} from '@helpers/coordinate'
import { AlgoliaGeoDoc } from '@models/Algolia'
import { RouteProp, useRoute } from '@react-navigation/native'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import { useContext, useMemo, useState } from 'react'
import { connectGeoSearch } from 'react-instantsearch-native'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import env from '../../config/Environment'
import { HomeParamList } from '../../navigation/types'
import { setCurrentHover, setRegionAction } from '../../redux/actions/appState'
import { currentHoverSelector, sessionLocationSelector } from '../../redux/selectors'
import { UserLocationMarker } from './CurrentLocation'
import { renderMarkers } from './Markers/Markers'
import { PopUp } from './PopUp'
import { LoadingState } from './components/HelperComponents'
import { MapBottomOverlay } from './components/MapBottomOverlay'
import { MapControls } from './components/MapControls'

import { useCoords } from '@/hooks/useCoords/useCoords'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { GeoSearchProvided } from 'react-instantsearch-core'
import { SelectedMarker } from './Markers/MarkerCommon'
import { ExploreContext } from './components/ExploreContext'
import { maxZoom } from './helpers'

function ConnectedGeoSearchComp({ hits }: GeoSearchProvided<AlgoliaGeoDoc>) {
  const { redoSearch } = useContext(ExploreContext)
  const dispatch = useDispatch()
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const sessionLoc = useSelector(sessionLocationSelector)
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker>(null)
  const coords = useCoords(params?.near as CoordString)
  const currentHover = useSelector(currentHoverSelector) //Becomes the coordstring of the hovered location

  // Temp. workaround for markers jumping in web is to hide them while draggin. Issue is open in their github. Seems like it's a problem since react 18 and can't be fixed unless they re-do this library. https://github.com/google-map-react/google-map-react/issues/1143#issuecomment-1285612893
  const [markersOpacity, setMarkersOpacity] = useState(1)

  /**defaultCenter is never meant to change, because it's only for initialization purposes. Prevents a warning in the map saying this prop isn't supposed to change */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultCenter = useMemo(() => (coords ? shortenCoordinate(coords) : undefined), [])

  /** This stores the current map bounds and updates on move */
  const [region, setRegion] = useState<Partial<ChangeEventValue>>({
    center: defaultCenter,
    zoom: 10,
  })

  /** On change to the coordinates value move the map to the new region */
  useDeepCompareFocusFx(() => {
    if (!coords) return
    setRegion((prev) => ({ ...prev, center: shortenCoordinate(coords) }))
  }, [coords])

  /** Reset hover on hits change */
  useFocusFx(() => {
    if (!currentHover) return

    if (hits.find((h) => getCoordString(h._geoloc) === currentHover)) {
      // if it still exists in the new hits, do nothing
      return
    }

    // otherwise reset
    if (hits?.[0]?._geoloc && validCoords(hits?.[0]?._geoloc)) {
      dispatch(setCurrentHover(getCoordString(hits[0]._geoloc)))
    } else dispatch(setCurrentHover(null))
  }, [hits, dispatch, currentHover])

  return (
    <View style={styles.container}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: env.API_KEY!, libraries: ['places'] }}
        defaultCenter={defaultCenter}
        /** On the web library we MUST pass the region as a controlled component because it doesn't have a ref api where you might set the map region manually. But unlike the mobile library this one doesn't jump the view when you finish dragging */
        center={region.center}
        defaultZoom={10}
        options={{
          minZoom: 5,
          maxZoom,
          fullscreenControl: false,
          zoomControlOptions: { position: 8 },
        }}
        onChange={(region) => {
          setRegion(region)
          dispatch(
            setRegionAction({
              latitude: region.center.lat,
              longitude: region.center.lng,
              longitudeDelta: region.marginBounds.ne.lng - region.marginBounds.nw.lng,
              latitudeDelta: region.marginBounds.nw.lat - region.marginBounds.sw.lat,
            }),
          )
          // Prevent redoing the search when navigating to a new area directly by coords
          if (hasSignificantCoordDifference(coords, { latitude: region.center.lat, longitude: region.center.lng })) {
            redoSearch(lengthenCoord(region.center))
          }
        }}
        onDrag={() => setMarkersOpacity(0)}
        onDragEnd={() => setMarkersOpacity(1)}
      >
        {sessionLoc && (
          <UserLocationMarker lat={sessionLoc.coordinate.latitude} lng={sessionLoc.coordinate.longitude} />
        )}
        {renderMarkers({ hits, onMarkerPress: setSelectedMarker, currentHover, markersOpacity })}
      </GoogleMapReact>
      <LoadingState />

      <PopUp marker={selectedMarker} />
      <MapControls />
      <MapBottomOverlay hasSelectedMarker={!!selectedMarker} />
    </View>
  )
}

export const ConnectedGeoSearch = connectGeoSearch(ConnectedGeoSearchComp)

const styles = StyleSheet.create({
  container: { flex: 1, position: 'relative' },
})
