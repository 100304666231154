import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useDeviceSize } from '@/hooks/useLayout'
import { FarmBadges } from '@components'
import { Divider, HeaderText, ReadMore } from '@elements'
import { hyperlinkOpenUrl } from '@helpers/links'
import { Farm } from '@models/Farm'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import HyperLink from 'react-native-hyperlink'
import { ContactUs } from './ContactUs'

export const About = memo(function About({ farm }: { farm: Farm }) {
  const { isSmallDevice } = useDeviceSize()

  return (
    <View style={!isSmallDevice && styles.mainLarge}>
      <ContactUs farm={farm} />
      {isSmallDevice && <Divider large />}

      <View style={globalStyles.flex1}>
        <HeaderText size={18}>About</HeaderText>
        <FarmBadges practices={farm.practices} size="large" />

        <HyperLink linkStyle={styles.linkStyle} onPress={hyperlinkOpenUrl}>
          {/* splits on newline character and joins with rendered linebreaks*/}
          <ReadMore>{farm.about.replace('\\n', '\n\n')}</ReadMore>
        </HyperLink>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  mainLarge: {
    flexDirection: 'row-reverse',
    gap: 20,
  },
  linkStyle: {
    color: Colors.blueLink,
  },
})
