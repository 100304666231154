import { uploadImageAsync } from '@api/FirebaseStorage'
import { updateUser } from '@api/Users'
import { Text, Toast } from '@elements'
import { userName } from '@models/User'
import { SaveFormat } from 'expo-image-manipulator'
import { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Colors from '@/constants/Colors'
import { setUser } from '@/redux/actions/user'
import { userSelector } from '@/redux/selectors'
import { ImageSelect } from '@components'

/**
 * Header component for the User Screen that displays the user's avatar and basic information.
 * Allows users to view and update their profile picture.
 */
export function UserScreenHeader() {
  const user = useSelector(userSelector)
  const dispatch = useDispatch()

  const uploadNewImage = useCallback(
    async (image: string) => {
      try {
        const upload = await uploadImageAsync(`${user.id}/avatar`, {
          storageUrl: image,
          type: 'image',
        })
        await updateUser({ id: user.id, avatar: upload.storageUrl })
        dispatch(setUser({ ...user, avatar: upload.storageUrl }))
      } catch (err) {
        Toast('Something went wrong. Please try again in a little while.')
      }
    },
    [dispatch, user],
  )

  return (
    <View style={styles.optionsWrapper}>
      <View>
        <ImageSelect
          imageType="profile"
          defaultImage={user.avatar}
          editOptions={{
            width: 200,
            format: SaveFormat.JPEG,
          }}
          onChange={uploadNewImage}
          imageStyle={styles.img}
        />
      </View>
      <View style={styles.nameContainer}>
        <Text size={24}>{userName(user)}</Text>
        <Text numberOfLines={1} size={14} color={Colors.shades['400']} testID="user-email">
          {user.email}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  nameContainer: {
    marginLeft: 10,
    flex: 1,
  },

  img: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  optionsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -10,
  },
})
