import InputLabel from '@/admin/components/InputLabel'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { Button, Picker, hideModal } from '@elements'
import { openPackListLabelPDF } from '@helpers/links'
import { DateRange, SummaryFilter } from '@models/Schedule'
import { PickerItemProps } from '@react-native-picker/picker'
import { ItemsCount, LabelSize, LabelType } from '@shared/types/v2/label'
import { memo, useCallback, useMemo } from 'react'
import { View } from 'react-native'

type ChooseLabelModalProps = {
  farmId: string
  dateRange: DateRange
  filters: SummaryFilter
}

type StateType = {
  itemsPerLabel: string
  labelSize: LabelSize
  type: LabelType
}

const itemsPerLabelOptions: PickerItemProps<string>[] = Object.values(ItemsCount).map((val) => ({
  label: val,
  value: val,
}))

const labelsSizeOptions: PickerItemProps<LabelSize>[] = Object.values(LabelSize).map((val) => ({
  label: val,
  value: val,
}))

/** This Modal offers multiple options for label generation*/
export const ChooseLabelModal = memo(function ChooseLabelModal({ farmId, dateRange, filters }: ChooseLabelModalProps) {
  const [{ itemsPerLabel, labelSize, type }, set] = useKeyedState<StateType>({
    itemsPerLabel: ItemsCount.SEVEN,
    labelSize: LabelSize.FOURxSIX,
    type: LabelType.ROLL,
  })

  const labelTypeOptions = useMemo<PickerItemProps<LabelType>[]>(() => {
    const items = Object.values(LabelType).map((val) => ({
      label: val,
      value: val,
    }))

    if (labelSize === LabelSize.TWO_FOURxTHREE) {
      set('type', LabelType.CONTINUOUS_ROLL)
      return items.filter((item) => item.value === LabelType.CONTINUOUS_ROLL)
    }
    set('type', LabelType.ROLL)
    return items.filter((item) => item.value !== LabelType.CONTINUOUS_ROLL)
  }, [labelSize, set])

  const styles = useStyles()

  /** exportPDF will build required extra queryParams and call the server to build chosen PDF format */
  const exportPDF = useCallback(() => {
    const extraQueryString = `itemsPerLabel=${itemsPerLabel}&labelSize=${labelSize.replaceAll('"', '')}&type=${type}`
    openPackListLabelPDF(farmId, dateRange, filters, extraQueryString)
    hideModal()
  }, [farmId, dateRange, filters, itemsPerLabel, labelSize, type])

  return (
    <View style={styles.container}>
      <View style={styles.pickerGroup}>
        <View>
          <InputLabel label="Items per label" />
          <Picker
            style={styles.picker}
            value={itemsPerLabel}
            placeholder={null}
            items={itemsPerLabelOptions}
            onValueChange={(val) => set('itemsPerLabel', val)}
          />
        </View>

        <View>
          <InputLabel label="Label size" />
          <Picker
            style={styles.picker}
            value={labelSize}
            placeholder={null}
            items={labelsSizeOptions}
            onValueChange={(val) => set('labelSize', val as LabelSize)}
          />
        </View>

        {/* TODO: Sheet and continuous Labels will be released once we are able to test with actual stickers */}
        {/*  <View>*/}
        {/*    <InputLabel label="Type" />*/}
        {/*    <Picker*/}
        {/*      style={styles.picker}*/}
        {/*      value={type}*/}
        {/*      placeholder={null}*/}
        {/*      items={labelTypeOptions}*/}
        {/*      onValueChange={(val) => set('type', val as LabelType)}*/}
        {/*    />*/}
        {/*  </View>*/}
      </View>

      <Button title="Submit" onPress={exportPDF} style={styles.button} />
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    container: {
      marginHorizontal: 20,
      marginBottom: 20,
    },
    pickerGroup: {
      flexDirection: isSmallDevice ? 'column' : 'row',
      gap: 20,
    },
    picker: {
      marginTop: 8,
    },
    button: {
      marginTop: 30,
      marginHorizontal: 0,
      alignSelf: isSmallDevice ? 'stretch' : 'flex-end',
    },
  }))
