import { FontAwesome5 } from '@expo/vector-icons'
import { StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import Colors from '../constants/Colors'
import { Text, TextH3 } from './elements/Text'
import { Touchable } from './elements/Touchable'

export type SectionButton = {
  title: string
  onPress?: () => void
  url?: string
  subtitle?: string
  icon?: string
  disabled?: boolean
  rightElement?: React.ReactNode
}

export type Section = {
  title: string
  children?: React.ReactNode
}

/** Section View */
export function SectionItemButton({
  title,
  onPress,
  subtitle,
  icon = 'chevron-right',
  url,
  disabled = false,
  rightElement,
}: SectionButton) {
  return (
    <Touchable onPress={onPress} style={styles.container} url={url} disabled={disabled}>
      <View style={globalStyles.flexRowCenter}>
        <View>
          <Text size={14} color={disabled ? Colors.shades['300'] : undefined}>
            {title}
          </Text>
          {!!subtitle && (
            <Text size={10} color={disabled ? Colors.shades['100'] : Colors.shades['400']}>
              {subtitle}
            </Text>
          )}
        </View>
        {rightElement}
      </View>
      <FontAwesome5 name={icon} size={20} />
    </Touchable>
  )
}

/** Component that shows a section title with its buttons */
export function SectionView({ title, children }: Section) {
  return (
    <View style={styles.sectionViewCont}>
      <TextH3>{title}</TextH3>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionViewCont: {
    marginTop: 20,
  },
})
