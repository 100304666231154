import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import {
  Alert,
  Button,
  ButtonClear,
  FormInput,
  KeyboardAvoidingScrollView,
  Loader,
  Modal,
  Text,
  hideModal,
} from '@elements'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'

type PreferenceProps = {
  onPreferenceChange: (newPrefs: string) => Promise<void>
  userPrefs?: string
}

/** This component will render a button to allow the user to change their preferences. */
export function SharePreferencesSection({
  onPreferenceChange,
  userPrefs,
  continueToEdit,
}: PreferenceProps & { continueToEdit: (type: 'edit') => Promise<void> }) {
  const [hasEditedPrefs, setHasEditedPrefs] = useState(false)

  const openPreferencesModal = () => {
    if (hasEditedPrefs) {
      Alert(
        "It's your turn! :)",
        "We've added a few things to the cart, but you can make any changes in the farm shop.",
        [
          {
            text: 'Edit your share',
            onPress: () => {
              Loader(true)
              continueToEdit('edit').finally(() => {
                Loader(false)
              })
            },
          },
        ],
      )
      return
    }

    Modal(<SharePreferencesModal onPreferenceChange={savePreferences} userPrefs={userPrefs} />, {
      title: 'Change Preferences',
    })
  }

  const savePreferences = async (preferences: string) => {
    const trimmedPrefs = preferences.trim()

    if (trimmedPrefs === userPrefs?.trim()) {
      return
    }

    if (trimmedPrefs.length < 5) {
      Alert('Preferences not enough', 'Please share a little more about your preferences.')
      return
    }
    hideModal()
    Loader(true)
    await onPreferenceChange(trimmedPrefs)
    setHasEditedPrefs(true)
    Loader(false)
  }

  return (
    <View>
      <ButtonClear title="Change Preferences" size={10} onPress={openPreferencesModal} />
    </View>
  )
}

function SharePreferencesModal({ onPreferenceChange, userPrefs }: PreferenceProps) {
  const [preferences, setPreferences] = useState<string>(userPrefs || '')

  return (
    <KeyboardAvoidingScrollView contentContainerStyle={styles.contentCont}>
      <Text size={11} color={Colors.shades[500]} style={globalStyles.marginHorizontal10}>
        What would you like in your share? You can include phrases like "no tomatoes", "I'm a vegetarian" or "extra
        green beans." Based on your requests, we will do our best to match what you'd like and what the farmer is
        selling.
      </Text>
      <FormInput
        placeholder="I love carrots and kale but please no onions or garlic"
        label=""
        value={preferences}
        onChangeText={setPreferences}
        inputStyle={styles.textArea}
        multiline
        numberOfLines={4}
        maxLength={200}
      />

      <Button title="Save Preferences" onPress={() => onPreferenceChange(preferences)} />
    </KeyboardAvoidingScrollView>
  )
}

const styles = StyleSheet.create({
  contentCont: {
    padding: 10,
  },
  textArea: {
    padding: 10,
    height: 150,
  },
})
