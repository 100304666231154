import { useCreateSearchParams } from '@components'
import { Spinner, Text, Touchable } from '@elements'
import { Feather, MaterialIcons } from '@expo/vector-icons'
import { isArray } from '@helpers/helpers'
import { AlgoliaGeoDoc, AlgoliaGeoFarm } from '@models/Algolia'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useRef, useState } from 'react'
import { AutocompleteProvided, SearchBoxProvided } from 'react-instantsearch-core'
import { connectAutoComplete, connectSearchBox } from 'react-instantsearch-native'
import {
  LayoutChangeEvent,
  ListRenderItem,
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputSubmitEditingEventData,
  TouchableOpacity,
  View,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Colors from '../constants/Colors'
import { useDebouncedValue } from '../hooks/useDebounce'
import { useRouteName } from '../hooks/useRouteName'
import { withConsumerIndex } from '../hooks/withAlgoliaIndex'
import { FarmsAndCities, HomeParamList } from '../navigation/types'
import { setSearchLocation } from '../redux/actions/appState'
import { navPropsSelector, navRouteSelector, searchLocationSelector, sessionLocationSelector } from '../redux/selectors'
import { GooglePlacesSearch } from './GooglePlacesSearch'
import { DividerVertical } from './elements/DividerVertical'

import { isMobile, isWeb } from '@/constants/Layout'
import { AutoCompleteItem, useAutoComplete } from '@/hooks/useAutoComplete'
import { useDeepCompareCallback, useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { GooglePlace } from '@models/Address'

function ConsumerSearchComp({
  refine,
  ...rest
}: AutocompleteProvided<AlgoliaGeoDoc<AlgoliaGeoFarm>> | SearchBoxProvided) {
  const routeName = useSelector(navRouteSelector)
  const isExplore = routeName === 'ExploreScreen'
  const isShop = routeName === 'FarmShop'
  const isFarmPage = routeName === 'FarmDetailScreen'
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'> | RouteProp<any>>() //Can't be certain of type because it's shared in many screens
  const sessionLoc = useSelector(sessionLocationSelector)
  const searchLoc = useSelector(searchLocationSelector)
  const currentFarmName = useSelector(navPropsSelector).farm?.name
  const farmInputRef = useRef<TextInput>(null)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<FarmsAndCities>>()
  const [width, setWidth] = useState<number[]>([360, 240])
  const [refinement, setRefinement] = useState<string>(params?.q || '')
  const debouncedRefinement = useDebouncedValue(refinement, 300)
  const createSearchParams = useCreateSearchParams()
  const {
    state: acProps,
    autoCompleteOverlay,
    showAutocomplete,
    updateAutocomplete,
    hideAutocomplete,
  } = useAutoComplete()

  // Will hold the hits if it is autocomplete else an empty list
  const itemsAutoComplete: AutoCompleteItem<AlgoliaGeoDoc<AlgoliaGeoFarm>>[] = useDeepCompareMemo(() => {
    if (isExplore || !rest) return []
    const hits = (rest as AutocompleteProvided<AlgoliaGeoDoc<AlgoliaGeoFarm>>).hits
    if (!hits || !isArray(hits)) return []
    return hits.map((hit) => ({ text: hit.name, data: hit }))
  }, [isExplore, rest])

  const onItemPress = useCallback(
    (item: AutoCompleteItem<AlgoliaGeoDoc<AlgoliaGeoFarm>>) => {
      /** This is only for mobile because the Touchable will navigate with url in web */
      if (isMobile) {
        /** Must navigate using the entire path because this component is in header, which may be anywhere as a starting point */
        navigation.navigate('Consumer', {
          screen: 'Shopping',
          params: { screen: 'FarmDetailScreen', params: { farmId: item.data.farm.id } },
        })
      }
      hideAutocomplete()
    },
    [hideAutocomplete, navigation],
  )

  const farmRenderItem: ListRenderItem<AutoCompleteItem<AlgoliaGeoDoc<AlgoliaGeoFarm>>> = useCallback(
    ({ item }) => {
      return (
        <Touchable
          onPress={() => onItemPress(item)}
          style={styles.valueCont}
          /** On web, it uses url for navigation. On mobile, it uses navigate(), in an equivalent way */
          url={isWeb ? `/farms/${item.data.id}` : undefined}
          key={item.text}
        >
          <Text size={14}>{item.data.name}</Text>
        </Touchable>
      )
    },
    [onItemPress],
  )

  const onFocus = useCallback(
    () =>
      !isExplore &&
      showAutocomplete('farmSearch', farmInputRef, itemsAutoComplete, undefined, {
        renderItem: farmRenderItem,
      }),
    [isExplore, itemsAutoComplete, showAutocomplete, farmRenderItem],
  )

  /** This is helpful when the autocomplete has hidden and then the user presses the textInput area. Will make the dropdown reappear */
  const onTouchStart = useCallback(() => {
    /** This should display the results on mobile. However this has a bad behavior on web, so if not mobile it should return */
    if (!isMobile) return
    if (acProps && !acProps.value)
      showAutocomplete('farmSearch', farmInputRef, itemsAutoComplete, undefined, {
        renderItem: farmRenderItem,
      })
  }, [acProps, itemsAutoComplete, farmRenderItem, showAutocomplete])

  /** Handles the text change on main text input by setting the new refinement to state, which will then trigger debounce */
  const onChangeText = useCallback(
    (text: string) => {
      setRefinement(text)
      if (acProps && !acProps.value)
        showAutocomplete('farmSearch', farmInputRef, itemsAutoComplete, undefined, {
          renderItem: farmRenderItem,
        })
    },
    [setRefinement, acProps, showAutocomplete, farmInputRef, farmRenderItem, itemsAutoComplete],
  )

  /** Updates the autocomplete list on results change */
  useDeepCompareFocusFx(() => {
    if (isExplore) return //explore is not for autocomplete, so this effect should not run here
    if (farmInputRef.current?.isFocused()) {
      if (acProps?.value) updateAutocomplete(farmInputRef, itemsAutoComplete)
      else
        showAutocomplete('farmSearch', farmInputRef, itemsAutoComplete, undefined, {
          renderItem: farmRenderItem,
        })
    }
    // the farmInputRef.current?.isFocused() will make sure the autocomplete is updated when the input is focused again.
  }, [
    itemsAutoComplete,
    isExplore,
    farmInputRef.current?.isFocused(),
    updateAutocomplete,
    acProps?.value,
    farmRenderItem,
    showAutocomplete,
  ])

  /** Performs search refinement on debounced change */
  useFocusFx(() => {
    // In explore we only set the param but not refine from here because that's handled in <SearchConfigure />
    if (isExplore) navigation.setParams(createSearchParams({ refinement: debouncedRefinement }))
    // Everywhere else, this will be the autocomplete search
    else refine(debouncedRefinement)
  }, [isExplore, navigation, createSearchParams, debouncedRefinement, refine])

  /** searchByCurrLocationAndRefinement is usable from any current screen. It goes to explore screen with currentLocation as map location and the current refinement as search value*/
  const searchByCurrLocationAndRefinement = useDeepCompareCallback(() => {
    if (!sessionLoc) return
    hideAutocomplete()
    dispatch(setSearchLocation({ ...sessionLoc }))
    if (isExplore) {
      navigation.setParams(createSearchParams({ coords: sessionLoc.coordinate }))
    } else {
      if (navigation.getState().routeNames.includes('ExploreScreen')) {
        navigation.navigate('ExploreScreen', createSearchParams({ coords: sessionLoc.coordinate, refinement }))
      } else {
        navigation.navigate('Consumer', {
          screen: 'Home',
          params: {
            screen: 'ExploreScreen',
            params: createSearchParams({ coords: sessionLoc.coordinate, refinement }),
          },
        })
      }
    }
  }, [isExplore, createSearchParams, refinement, dispatch, hideAutocomplete, sessionLoc, navigation])

  /** If currently in explore screen, re-sets the search refinement when the user presses 'enter'. Else, we're on the consumer screens, so pressint enter will search by current location */
  const onSubmitEditing = useCallback(
    (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) =>
      isExplore
        ? navigation.setParams(createSearchParams({ refinement: e.nativeEvent.text }))
        : searchByCurrLocationAndRefinement(),
    [isExplore, createSearchParams, searchByCurrLocationAndRefinement, navigation],
  )

  /** Sets the width of the two search inputs based on the size of the container.
   * - Using `%55` syntax has previously been associated with jittery behavior */
  const setWidthPair = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => setWidth([width * 0.55, width * 0.45]),
    [],
  )

  const onSelectCity = useCallback(
    (item: GooglePlace) => {
      const { coordinate, name } = item
      dispatch(setSearchLocation({ name, coordinate }))
      if (isExplore) {
        navigation.setParams(createSearchParams({ coords: coordinate }))
      } else {
        if (navigation.getState().routeNames.includes('ExploreScreen')) {
          navigation.navigate('ExploreScreen', createSearchParams({ coords: coordinate }))
        } else {
          navigation.navigate('Consumer', {
            screen: 'Home',
            params: {
              screen: 'ExploreScreen',
              params: createSearchParams({ coords: coordinate }),
            },
          })
        }
      }
    },
    [isExplore, createSearchParams, dispatch, navigation],
  )

  return (
    <View style={[styles.container, isShop && { minWidth: 0 }]} onLayout={setWidthPair}>
      {autoCompleteOverlay}
      <View style={[styles.inputCont, { width: !isShop ? width[0] : '100%' }]}>
        <View style={styles.leftIcon}>
          <Feather style={{ paddingHorizontal: 5 }} name="search" size={20} color={Colors.shades['200']} />
        </View>
        <TextInput
          ref={farmInputRef}
          onChangeText={onChangeText}
          value={refinement}
          placeholder={
            currentFarmName && (isFarmPage || isShop)
              ? currentFarmName
              : isExplore
              ? 'Search for farms or products'
              : 'Search for farms'
          }
          style={styles.textInput}
          onFocus={onFocus}
          onSubmitEditing={onSubmitEditing}
          onTouchStart={onTouchStart}
        />
        {debouncedRefinement !== refinement && (
          <Spinner style={{ position: 'absolute', right: 0, padding: 10 }} size="small" />
        )}
      </View>

      {!isShop && (
        <>
          <DividerVertical style={{ height: '80%', position: 'absolute', left: '55%' }} />
          <View style={[styles.inputCont, { width: width[1] }]}>
            <View style={styles.leftIcon}>
              <Feather style={{ paddingHorizontal: 5 }} name="map-pin" size={20} color={Colors.shades['200']} />
            </View>
            <GooglePlacesSearch
              contStyle={[styles.textInput, { paddingRight: 30 }]}
              types="geocode"
              onSelectGooglePlace={onSelectCity}
              initialValue={searchLoc?.name}
              enableReinitialize
            />
            <View style={styles.rightIcon}>
              <TouchableOpacity
                onPress={searchByCurrLocationAndRefinement}
                style={{ paddingHorizontal: 5 }}
                disabled={!sessionLoc}
              >
                <MaterialIcons name="my-location" color={Colors.darkGreen} size={20} />
              </TouchableOpacity>
            </View>
          </View>
        </>
      )}
    </View>
  )
}

const AutoComplete = withConsumerIndex(connectAutoComplete(ConsumerSearchComp), true)
const SearchBox = connectSearchBox(ConsumerSearchComp)

/** Complex component for the main app header which operates in a different mode when displayed in the map VS the rest of the app */
export function ConsumerSearch() {
  const routeName = useRouteName()
  if (routeName === 'ExploreScreen') return <SearchBox />
  return <AutoComplete />
}

const styles = StyleSheet.create({
  container: {
    flex: 0.9,
    maxWidth: 700,
    minWidth: 325,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['200'],
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  leftIcon: {
    position: 'absolute',
    height: 40,
    justifyContent: 'center',
    left: 0,
  },
  rightIcon: {
    position: 'absolute',
    height: 40,
    justifyContent: 'center',
    right: 3,
  },
  textInput: {
    height: 40,
    borderRadius: 10,
    width: '100%',
    paddingLeft: 30,
  },
  inputCont: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueCont: {
    paddingHorizontal: 10,
    paddingVertical: 15,
  },
})
