import { loadFarmCategories } from '@api/Categories'
import { addTemplateProduct, loadTemplateProductById, updateTemplateProduct } from '@api/TemplateProduct'
import { FormBuilder, ToolTips } from '@components'
import { AddNewDropdownItem, Alert, ErrorText, FormPickerInput, LoadingView, Spinner, Toast } from '@elements'
import { Optional, pick } from '@helpers/typescript'
import { TemplateProduct, TemplateType } from '@models/Product'
import { Media } from '@models/shared/Media'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikProps } from 'formik'
import { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import { AdminProductsParamList, TemplatePageType } from '../../../navigation/types'
import { PhotoSection } from '../ProductForm/BasicInformation/components/PhotoSection'
import { createUnitOrSetValue } from '../ProductForm/helpers/baseUnitAdd.helper'
import FormSectionHeader from '../components/FormSectionHeader'
import { EditHeader } from '../components/ProductHeader'
import { goBack, imageUploadAsync, isEdit } from './helpers/TemplateDetailScreen.helper'

import { AdminView } from '@/admin/components/AdminView'
import InputLabel from '@/admin/components/InputLabel'
import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSize } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'

//template type
type TemplateValidationType = {
  name: string
  sku?: string
  shortDescription: string
  longDescription: string
  category: string
  producer?: string
  media: Media[]
  shareInfo?: {
    minimumNumberOfItems?: number
    maximumNumberOfItems?: number
    suggestedFamilySize?: number
  }
  productionMethod?: string
}

//template schema
const templateVaildationSchema = Yup.object<TemplateValidationType>().shape({
  name: Yup.string().trim().label('Name').required(),
  sku: Yup.string().label('SKU'),
  shortDescription: Yup.string().trim().label('Short Description').required(),
  longDescription: Yup.string().trim().label('Long Description').required(),
  category: Yup.string().trim().label('Category').required(),
  producer: Yup.string().trim().label('Producer'),
  media: Yup.array<Media>().label('Images').min(1, 'Must upload at least one image'),
  shareInfo: Yup.object({
    minimumNumberOfItems: Yup.number().typeError('Must be a number and can not left empty.'),
    maximumNumberOfItems: Yup.number().typeError('Must be a number and can not left empty.'),
    suggestedFamilySize: Yup.number().typeError('Must be a number and can not left empty.'),
  }),
  productionMethod: Yup.string().trim().label('Production Method'),
})

// Main component
function TemplateDetailScreen() {
  const farm = useSelector(adminFarmSelector)
  const { isLargeDevice, isSmallDevice } = useDeviceSize()
  const categoriesFx = useApiFx(loadFarmCategories, [farm.localCategories])
  const producers = useMemo(() => farm.localProducers ?? [], [farm.localProducers])
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'AddTemplate' | 'EditTemplate'>>()

  const { params, name: routeName } = useRoute<RouteProp<AdminProductsParamList, 'AddTemplate' | 'EditTemplate'>>()

  const [{ loading, pageType, template, invalidError }, set, setState] = useKeyedState<{
    loading: boolean
    pageType: TemplatePageType
    template?: TemplateProduct
    invalidError?: string
  }>({
    loading: true,
    pageType: routeName as TemplatePageType,
  })

  /** Initializes the screen state based on navigation params */
  useDeepCompareFocusFx(() => {
    if (pageType === TemplatePageType.ADD) {
      set('loading', false)
    } else if (pageType === TemplatePageType.EDIT) {
      const templateId = (params as AdminProductsParamList['EditTemplate']).templateId
      if (!templateId) set('invalidError', 'Missing the ID of the template you wish to edit')
      loadTemplateProductById(templateId).then((template) => {
        set('template', template)
        set('loading', false)
      })
    } else {
      Logger.error(new Error('Trying to navigate in the wrong navigator screen'))
    }
  }, [pageType, params, set])

  //buildInitialValues
  const buildInitialValues = useCallback((template?: TemplateProduct) => {
    return {
      name: template?.name ?? '',
      sku: template?.sku ?? '',
      shortDescription: template?.description ?? '',
      longDescription: template?.longDescription ?? '',
      category: template?.category ?? '',
      producer: template?.producer ?? '',
      media:
        template?.images?.map((img) => ({
          storageUrl: img,
          type: 'image',
        })) || ([] as Media[]),
      shareInfo: template?.shareInfo ?? {
        minimumNumberOfItems: 0,
        maximumNumberOfItems: 0,
        suggestedFamilySize: 0,
      },
      productionMethod: template?.productionMethod ?? '',
    }
  }, [])

  //handle save action
  const saveForm = useCallback(
    async (values: TemplateValidationType) => {
      const images = await imageUploadAsync(values.media)
      if (!images) throw new Error('Something went wrong while uploading images')

      const base: Optional<TemplateProduct, 'id'> = {
        name: values.name,
        sku: values.sku,
        farm: pick(farm, 'id', 'address', 'name', 'timezone'),
        type: TemplateType,
        description: values.shortDescription,
        longDescription: values.longDescription,
        category: values.category,
        producer: values.producer,
        images,
        shareInfo: Object.fromEntries(
          Object.entries(values.shareInfo ?? {}).filter(([_, value]) => Number(value) !== 0),
        ),
        productionMethod: values.productionMethod,
      }

      Toast('Saving template...')

      try {
        if (isEdit(template)) {
          const newTemplate: TemplateProduct = { ...base, id: template.id }
          await updateTemplateProduct(newTemplate)
          Toast('Template updated successfully')
        } else {
          await addTemplateProduct(base)
          Toast('Template added successfully')
        }
        //when operation completes, reset the screen state
        setState({ loading: true, pageType: TemplatePageType.ADD, invalidError: undefined, template: undefined })

        //go back to previous screen
        goBack({ navigation, isDirty: false, params, pageType, template })
      } catch (error) {
        Logger.error(error)
      }
    },
    [farm, navigation, pageType, params, setState, template],
  )

  const styles = useStyles()

  return (
    <LoadingView
      loading={loading}
      error={invalidError}
      switchMode //SwitchMode is necessary for AdminView scroll to work
    >
      <Formik
        initialValues={buildInitialValues(template)}
        onSubmit={saveForm}
        validationSchema={templateVaildationSchema}
      >
        {function Form({
          isSubmitting,
          dirty,
          handleChange,
          handleSubmit,
          isValid,
          values,
          errors,
          touched,
          setTouched,
          handleBlur,
          setFieldValue,
        }: FormikProps<TemplateValidationType>) {
          // Category dropdown picker handler
          const categoryOnChange = useCallback(
            (value: string) => {
              createUnitOrSetValue(value, 'category', farm.id, (v) =>
                setFieldValue('category', v).then(() => setTouched({ ...touched, category: true })),
              )
              handleChange({
                target: {
                  name: 'category',
                  value,
                },
              })
            },
            [setFieldValue, handleChange, setTouched, touched],
          )
          // Producer dropdown picker handler
          const producerOnChange = useCallback(
            (value: string) => {
              createUnitOrSetValue(value, 'producer', farm.id, (v) =>
                setFieldValue('producer', v).then(() => setTouched({ ...touched, producer: true })),
              )
            },
            [setFieldValue, setTouched, touched],
          )

          // product header go action handler (submit form)
          const goAction = useCallback(() => {
            handleSubmit()
            if (!isValid) Alert('Missing Required Fields', 'Please fill out all required fields before submitting.')
          }, [handleSubmit, isValid])

          return (
            <AdminView
              customHeader={
                <EditHeader
                  isLoading={isSubmitting}
                  goBack={() => goBack({ navigation, isDirty: dirty, params, pageType, template })}
                  title={isEdit(template) ? `Editing: ${template.name}` : 'Add a new template'}
                  actionTitle="Submit"
                  goAction={goAction}
                />
              }
            >
              <View style={styles.container}>
                <View style={styles.basicSection}>
                  <FormSectionHeader title="Basic Information" />
                  <FormBuilder style={styles.inputs}>
                    <FormBuilder row>
                      <Input
                        value={values.name}
                        placeholder="Template Product Name"
                        label={<InputLabel label="Template Product Name" tooltipId={ToolTips.TEMPLATE_NAME} required />}
                        onChangeText={handleChange('name')}
                        onBlur={handleBlur('name')}
                        errorMessage={touched.name ? errors.name : ''}
                      />

                      <Input
                        value={values.sku}
                        label={<InputLabel label="SKU" tooltipId={ToolTips.OPTION_SKU} />}
                        placeholder="SKU"
                        onChangeText={handleChange('sku')}
                        onBlur={handleBlur('sku')}
                        errorMessage={touched.sku ? errors.sku : ''}
                      />
                    </FormBuilder>
                    <Input
                      value={values.shortDescription}
                      label={<InputLabel label="Short Description" tooltipId={ToolTips.SHORTDESCRIPTION} required />}
                      placeholder="Short Description"
                      onChangeText={handleChange('shortDescription')}
                      onBlur={handleBlur('shortDescription')}
                      errorMessage={touched.shortDescription ? errors.shortDescription : ''}
                    />
                    <Input
                      value={values.longDescription}
                      label={<InputLabel label="Long Description" tooltipId={ToolTips.LONGDESCRIPTION} required />}
                      placeholder="A detailed description of the product"
                      onChangeText={handleChange('longDescription')}
                      onBlur={handleBlur('longDescription')}
                      errorMessage={touched.longDescription ? errors.longDescription : ''}
                      numberOfLines={isLargeDevice ? 4 : 2}
                      multiline
                      style={{ height: 100 }}
                    />

                    <Input
                      value={values.productionMethod?.toString() || ''}
                      label="Production Method"
                      placeholder="Organic, Conventional, Grassfed, etc"
                      onChangeText={handleChange('productionMethod')}
                      onBlur={handleBlur('productionMethod')}
                      errorMessage={touched.productionMethod ? errors.productionMethod : ''}
                    />

                    <FormBuilder row>
                      {!!categoriesFx.data && !categoriesFx.loading ? (
                        <FormPickerInput
                          onValueChange={categoryOnChange}
                          label={<InputLabel label="Category" tooltipId={ToolTips.BASIC_CATEGORY} required />}
                          value={values.category}
                          items={categoriesFx.data
                            .map((category) => ({ label: category.name, value: category.name }))
                            .concat([AddNewDropdownItem])}
                          errorMessage={touched.category ? errors.category : ''}
                        />
                      ) : (
                        <Spinner />
                      )}

                      <FormPickerInput
                        onValueChange={producerOnChange}
                        label={<InputLabel label="Producer" tooltipId={ToolTips.BASIC_PRODUCER} />}
                        errorMessage={errors.producer}
                        value={values.producer}
                        items={
                          producers.length
                            ? producers
                                ?.map((producer) => ({ label: producer, value: producer }))
                                .concat([AddNewDropdownItem])
                            : [AddNewDropdownItem]
                        }
                      />
                    </FormBuilder>

                    <FormBuilder row>
                      <Input
                        value={values.shareInfo?.suggestedFamilySize?.toString()}
                        label={<InputLabel label="Suggested family size" />}
                        placeholder="ex. 4"
                        onChangeText={handleChange('shareInfo.suggestedFamilySize')}
                        onBlur={handleBlur('shareInfo.suggestedFamilySize')}
                        //errors.shareInfo will expect shareInfo as a string, not an object
                        errorMessage={
                          //@ts-ignore
                          touched.shareInfo?.suggestedFamilySize ? errors.shareInfo?.suggestedFamilySize : ''
                        }
                      />
                      <Input
                        value={values.shareInfo?.minimumNumberOfItems?.toString()}
                        label={<InputLabel label="Minimum items" />}
                        placeholder="ex. 8"
                        onChangeText={handleChange('shareInfo.minimumNumberOfItems')}
                        onBlur={handleBlur('shareInfo.minimumNumberOfItems')}
                        errorMessage={
                          // @ts-ignore
                          touched.shareInfo?.minimumNumberOfItems ? errors.shareInfo?.minimumNumberOfItems : ''
                        }
                      />
                      <Input
                        value={values.shareInfo?.maximumNumberOfItems?.toString()}
                        label={<InputLabel label="Maximum items" />}
                        placeholder="ex. 8"
                        onChangeText={handleChange('shareInfo.maximumNumberOfItems')}
                        onBlur={handleBlur('shareInfo.maximumNumberOfItems')}
                        //@ts-ignore
                        errorMessage={
                          // @ts-ignore
                          touched.shareInfo?.maximumNumberOfItems ? errors.shareInfo?.maximumNumberOfItems : ''
                        }
                      />
                    </FormBuilder>
                  </FormBuilder>
                </View>

                <FormBuilder style={styles.photosWrapper}>
                  <PhotoSection onMediaChange={(media) => setFieldValue('media', media)} value={values.media} />
                  {!!touched.media && !!errors.media && <ErrorText>{errors.media as string}</ErrorText>}
                </FormBuilder>
              </View>
            </AdminView>
          )
        }}
      </Formik>
    </LoadingView>
  )
}

export default withAdminAuth(TemplateDetailScreen, Permission.ProductSetup, AccessRight.Edit)

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      paddingHorizontal: 25,
      paddingBottom: 10,
    },
    basicSection: {},
    inputs: {},
    photosWrapper: {},
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      container: {
        paddingHorizontal: 0,
        paddingBottom: 0,
      },
      photosWrapper: {
        flexDirection: 'row',
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      container: { flexDirection: 'column' },
      inputs: {
        width: '100%',
        flexDirection: 'column',
      },
    },

    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      photosWrapper: {
        flex: 0.4,
      },
      basicSection: {
        flex: 0.6,
      },
    },
  },
)
