import { Farm } from '@models/Farm'
import { LocalPickup, Location, NonPickup, isLocalPickup, isNonPickup } from '@models/Location'
import { NotFoundError, UnauthorizedError } from '@shared/Errors'
import { Unsubscribe, limit, or, orderBy, where } from 'firebase/firestore'

import { errorCatcher } from './Errors'
import { updateFarm } from './Farms'
import { distrosCollection, locationsCollection } from './framework/ClientCollections'

/** Listener for local pickup locations */
export function snapshotLocalPickup(
  handler: (location?: LocalPickup) => void,
  onErr: (error: unknown) => void,
  id: string | undefined,
  farmId: string | undefined,
): Unsubscribe {
  if (!id || !farmId) {
    handler(undefined)
    return () => {}
  }
  return locationsCollection.snapshotDoc(
    id,
    (snap) => {
      if (!snap) {
        onErr(new NotFoundError('locations', id))
        return
      }
      if (snap?.farm.id !== farmId) {
        onErr(new UnauthorizedError('locations', id))
        return
      }
      if (!isLocalPickup(snap)) {
        onErr(new Error('This location is not a local pickup'))
        return
      }

      handler(snap)
    },
    (error) => onErr(error),
  )
}

/** Listener for local pickup locations */
export function snapshotDelivery(
  handler: (location?: NonPickup) => void,
  onErr: (error: unknown) => void,
  id: string | undefined,
  farmId: string | undefined,
): Unsubscribe {
  if (!id || !farmId) {
    handler(undefined)
    return () => {}
  }
  return locationsCollection.snapshotDoc(
    id,
    (snap) => {
      if (!snap) {
        onErr(new NotFoundError('locations', id))
        return
      }
      if (snap?.farm.id !== farmId) {
        onErr(new UnauthorizedError('locations', id))
        return
      }
      if (!isNonPickup(snap)) {
        onErr(new Error('This location is not a local pickup'))
        return
      }

      handler(snap)
    },
    (error) => onErr(error),
  )
}

/** SnapshotLocationsByFarm returns the locations assigned to the supplied farm ID. */
export function snapshotLocationsByFarm(
  updateFn: (locations: Location[]) => void,
  errorFn = errorCatcher,
  farmSlug: string,
): () => void {
  const q = locationsCollection.query(
    or(where('farm.id', '==', farmSlug), where('farm.urlSafeSlug', '==', farmSlug)),
    orderBy('nameUppercase'),
  )
  return locationsCollection.snapshotMany(q, updateFn, errorFn)
}

/** loadLocationsByFarm returns the locations assigned to the supplied farm ID. */
export async function loadLocationsByFarm(farmSlug: string): Promise<Location[]> {
  return locationsCollection.fetchAll(
    or(where('farm.id', '==', farmSlug), where('farm.urlSafeSlug', '==', farmSlug)),
    orderBy('nameUppercase'),
  )
}

/** addLocation adds a new location to the database. */
export async function addLocation(location: Location, farm?: Farm): Promise<Location> {
  // Update onboard walk-through
  if (farm && farm.onboardSteps && !farm.onboardSteps?.locations_zones) {
    updateFarm({ id: farm.id, onboardSteps: { ...(farm.onboardSteps || {}), locations_zones: true } })
  }
  return locationsCollection.create(location)
}

/** Checks if a location has any distributions associated with it. If so, the location cannot be deleted. */
export async function canLocationBeDeleted(farmId: string, locationId: string): Promise<boolean> {
  try {
    const distros = await distrosCollection.fetchAll(
      where('farm.id', '==', farmId),
      where('location.id', '==', locationId),
      limit(1),
    )
    return distros.length === 0
  } catch (error) {
    return false
  }
}

/** Delete location by id */
export async function deleteLocation(locationId: string): Promise<boolean> {
  try {
    await locationsCollection.delete(locationId)
    return true
  } catch (err) {
    return false
  }
}
