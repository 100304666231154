import { loadFirstOrderPickup } from '@api/Orders'
import { Button, Modal, Text, TextH1 } from '@elements'
import { getOrderNum, plural } from '@helpers/display'
import { sortCartItems as sortItemsCategory } from '@helpers/sorting'
import { Invoice } from '@models/Invoice'
import { Order, OrderItem as OrderItemType } from '@models/Order'
import { DateTime } from 'luxon'
import { memo, useEffect, useState } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { AdminOrderCancel } from '../ModalAdminOrderCancel'
import { isFarmCreditOnlyFunc } from '../helpers/orderCancelHelper'
import OrderItem from './OrderItem'

import Colors from '@/constants/Colors'

type Props = {
  currentOrder: Order
  invoices: Invoice[]
  cancelledItems: OrderItemType[]
  setCancelledItems: (items: OrderItemType[]) => void
  /** Will be called on order cancel */
  callback: () => void
}

export const ItemBreakDown = memo(function ItemBreakDown({
  currentOrder,
  invoices,
  cancelledItems,
  setCancelledItems,
  callback,
}: Props): JSX.Element {
  const styles = useStyles()
  const [firstPickupMap, setFirstPickupMap] = useState<{ itemId: string; firstPickup: DateTime }[]>([])

  const getNumPayments = (id: string) => {
    // Get payments number for a specific item.
    // Exclude upfront payments (no 'installment' in description)
    if (!invoices) return 0
    let invLen = 0
    invoices.forEach((inv) => {
      const hasItem = inv.items.filter((itm) => itm.id === id && itm.description.toLowerCase().includes('installment'))
      invLen += hasItem.length > 0 ? 1 : 0
    })
    return invLen
  }

  useEffect(() => {
    if (!currentOrder.id) return
    loadFirstOrderPickup(currentOrder.id).then(setFirstPickupMap)
  }, [currentOrder.id])

  const cancelItems = (cancelledItems: OrderItemType[]) => {
    const cancelledItemsIds = cancelledItems.map((item) => item.id)
    // whether all items are going to be cancelled in the current order
    const allItemsWillBeCanceled = currentOrder.items
      .filter((itm) => !cancelledItemsIds.includes(itm.id))
      .every((itm) => itm.cancelled)

    Modal(
      <AdminOrderCancel
        currentOrder={currentOrder}
        cancelledItems={cancelledItems}
        onClose={callback}
        entireOrder={allItemsWillBeCanceled}
      />,
      {
        title:
          'Cancel Order - ' +
          getOrderNum(currentOrder.orderNum) +
          `${' - ' + cancelledItems.length + plural(cancelledItems.length, ' Item')}`,
      },
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <TextH1 size={18}>Item Breakdown</TextH1>
        {cancelledItems.length > 0 && (
          <View style={styles.buttonTextGroups}>
            <Text size={12} style={styles.title}>
              {cancelledItems.length === 1 ? '1 item selected' : `${cancelledItems.length} items selected`}
            </Text>
            <Button
              title="Cancel Items"
              onPress={() => cancelItems(cancelledItems)}
              disabled={isFarmCreditOnlyFunc(currentOrder)}
            />
          </View>
        )}
      </View>
      <View style={styles.ItemsContainer}>
        {[...currentOrder.items].sort(sortItemsCategory).map((item, idx) => (
          <OrderItem
            key={idx}
            item={item}
            currentOrder={currentOrder}
            firstPickup={firstPickupMap.find((itm) => itm.itemId === item.id)?.firstPickup}
            numPayments={getNumPayments(item.id)}
            cancelledItems={cancelledItems}
            setCancelledItems={setCancelledItems}
          />
        ))}
      </View>
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginTop: -5,
    },
    headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    buttonTextGroups: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    ItemsContainer: {
      borderWidth: 1,
      borderColor: Colors.shades['200'],
      borderRadius: 10,
      overflow: 'hidden',
    },
    title: {
      marginRight: 12,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      container: {
        marginTop: -15,
      },
      headerContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
)
