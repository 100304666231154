import { Pickup } from '@models/Order'

import { OrderCreatorStatePayments } from '../OrderCreatorScreen/OrderCreatorScreen.helper'

import { UseApiFxReturn } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { KeyedState } from '@/hooks/useKeyedState'
import { Farm } from '@models/Farm'
import { usePaymentsAndTotalsData_reusable } from '../OrderCreatorScreen/usePaymentsAndTotalsData-reusable'

/** Data layer for state related to payments, totals, amounts, etc; Indended for the order edit screen specifically */
export function usePaymentsAndTotalsData({
  custPickupsFx,
  keyedState,
  farm,
  isWholesale,
}: {
  custPickupsFx: UseApiFxReturn<(userId: string, farmId: string) => Promise<Pickup[]>>
  keyedState: KeyedState<OrderCreatorStatePayments>
  farm: Farm | undefined
  isWholesale: boolean | undefined
}) {
  const cartService = useCartService({
    cartServiceType: 'orderEdit',
    farmId: farm?.id,
    isWholesale,
  })
  return usePaymentsAndTotalsData_reusable({
    custPickupsFx,
    keyedState,
    cartService,
    farm,
    isWholesale,
  })
}
